import React from "react";
import { ReactSVG } from 'react-svg';
import { showIconAmenity } from 'constants/amenityTypes';
import { Tabs as AntTabs } from 'antd';
import './Tabs.scss';
const { TabPane } = AntTabs;

export const Tabs = props => {
  
  const items = props.items || [ ];

  return (
    !!items.length && (
      <AntTabs {...props} className='scroll-tab'>
        { items.map((item, index) => (
            <TabPane
              tab={
                <div className="Tabs">
                  <ReactSVG
                    className="svg"
                    src={showIconAmenity(item.type_id).img_sub_menu}
                    alt="Section"
                  />
                  <span className="span">{item.name}</span>
                </div>
              }
              key={index}
            />
          )
        )}
      </AntTabs>
    )
  );
};

export default Tabs;
