import React, { Component } from 'react';
import SimpleModal from 'components/SimpleModal';
import Form from 'components/Form';

import './ReportModal.scss';

class ReportModal extends Component {
  constructor(props) {
    super(props);
  }

  onSubmit = values => {
    const { property } = this.props.params;
    
    values = {
      ...values,
      initial_date: values.range.start,
      final_date: values.range.end
    };

    if (values.type === 'residents') {
      this.props.downloadResidentsReport(property, values);
    } else {
      this.props.downloadActiveReport(property, values);
    }
    this.props.onClose();
  };

  render() {
    return (
      <SimpleModal title='Generar reporte' { ...this.props }>
        <Form
          onSubmit={ this.onSubmit }
          submitText='Enviar'
          controls={[
            {
              name: 'type',
              type: 'select',
              props: {
                items: [
                  {
                    value: 'residents',
                    label: 'Transacciones de residentes'
                  },
                  {
                    value: 'actives',
                    label: 'Transacciones del condominio'
                  }
                ],
                defaultValue: 'residents'
              },
            },
            {
              name: 'range',
              type: 'range',
              props: {
                required: true
              }
            }
          ]}
        >
        </Form>
      </SimpleModal>
    );
  }
}

export default ReportModal;
