import React, { useState, useEffect } from 'react';
import SimpleReactCalendar from 'simple-react-calendar';
import moment from 'moment';
import './Calendar.scss';
import { defaultDateFormat } from 'constants/dates';

export const Calendar = props => {
  
  const [ selectedDate, setSelectedDate ] = useState(moment().format(defaultDateFormat));
  
  const [ selectedRange, setSelectedRange ] = useState({
    start: moment().startOf('week'),
    end: moment().endOf('week')
  });

  const getEventsBetweenRange = range => {
    let events = [ ];
    const rangeStart = range.start.clone();
    while(rangeStart.diff(range.end) < 0) {
      const dayEvents = (props.events || { })[rangeStart.format(defaultDateFormat)];
      rangeStart.add(1, 'day');

      if (dayEvents) {
        events = events.concat(dayEvents);
      }
    }

    return events;
  }

  const setRangeMode = () => {
    const { mode } = props;
    const date = moment(selectedDate);
    let range;

    if (mode === 'week') {
      range = {
        start: date.startOf('week'),
        end: date.clone().endOf('week')
      };
    } else {
      range = {
        start: date.startOf('day'),
        end: date.clone().endOf('day')
      };
    }
    
    setSelectedRange({
      start: range.start.toDate(),
      end: range.end.toDate()
    });

    props.onChange && props.onChange({
      ...range,
      events: getEventsBetweenRange(range)
    });
  }

  const onDaySelected = date => {
    !date.disabled && setSelectedDate(date.key);
  }

  const maxPreviewEventsPerDay = 4;

  const eventsSortedByPendingStatus = (date) => {
    return ((props.events || { })[date.key] || []).sort(a => a.status.toLocaleLowerCase().indexOf('pending') > -1 ? -1 : 0);
  }

  const datePreview = date => eventsSortedByPendingStatus(date).slice(0, maxPreviewEventsPerDay).map(
    (item, key) => (
      <span
        className={ `${ item.status.toLocaleLowerCase() }-solid-colors` }
        key={ key }
      ></span>
    )
  )


  useEffect(setRangeMode, [ selectedDate, props.mode, props.events ])
  
  useEffect(() => {
    setSelectedDate(props.selectedDate);
  }, [ props.selectedDate ])

  const selectedProps = { };

  if (props.mode === 'week') {
    selectedProps.highlighted = selectedRange;
  } else {
    selectedProps.selected = selectedRange.start || moment().startOf('week');
  }

  return <div className='Calendar'>
    <SimpleReactCalendar
      daysOfWeek={ ['L','M','X','J','V','S','D'] }
      weekStartsOn={ 1 }
      renderDay={date => (
        <button
          key={ date.key }
          onClick={ _ => onDaySelected(date) }
          className={
            `
              calendar-week-day
              ${ date.isHighlighted && 'is-highlighted' }
              ${ date.isToday && 'is-today' }
              ${ date.isSelected && 'is-selected' }
              ${ date.isDisabled && 'is-disabled' }
              ${ date.isSelectionStart && 'is-start_selection' }
              ${ date.isSelectionEnd && 'is-end_selection' }
            `
          }
        >
          { moment(date.date).format('DD') }
          <div className='events row space-between'>
            { datePreview(date) }
          </div>
        </button>
      )}
      { ...selectedProps }
      { ...props.calendarProps }
    />
  </div>
};

export default Calendar;
