import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PropagateLoader } from 'react-spinners';

class Spinner extends Component {
  render() {
    const { position, margin, loading } = this.props;
    const style = {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: (position ? position : 'center'),
      marginBottom: `${(margin ? margin : 0)}px`,
    };
    const container = {
      minWidth: '200px',
      backgroundColor: 'red',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      top: '-10px',
    };
    return (
      <div style={style} className='Spinner'>
        <div style={container}>
          <PropagateLoader
            color='#ffB65B'
            size={20}
            loading={loading}
          />
        </div>
      </div>
    );
  }
}

Spinner.propTypes = {
  position: PropTypes.number,
  margin: PropTypes.number,
  loading: PropTypes.bool.isRequired
};

Spinner.defaultProps = {
  position: null,
  margin: null,
};

export default Spinner;
