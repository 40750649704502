import * as ACTION from './actionTypes';
import {
  getBudgetAllN,
  getCatalogServicesN,
  deleteConceptN,
  setConceptN,
  putConceptN,
  createServiceN,
  getActivesN,
  getAssetsN,
  getConceptsN,
  getPeriodsN,
  getBudgetsAndFeesN,
  getBudgetsAndFeesAmenitiesN,
  getQuotaDetailN,
  putFeeDweelingN,
  createPocahontasRegistryN,
  getExpensesN,
  getConceptsToValidateN,
  setProviderPayN,
  getProviderPaymentPendingN,
  putProviderPaymentsPendingsN,
  getIncomesChargedN,
  getIncomesChargeredN,
  putInitialBalanceN,
  setCodeToPayN,
  setFundN,
  setFundTransferN,
  getFundsN,
  getAccessN,
  setAccessN,
  putProviderPaymentsN,
  getReportN,
  getResidentReportURL,
  getActiveReportURL,
  getFundTransferN,
  getFundIcomeN,
  deleteFundsN,
  putFundsN,
  putVariableFundN,
  putAccessN,
  deleteAccessN,
  getFundTransferAccessN,
  putFundTransferN,
  setTransferFundN,
  getTransferFundN,
  postExtraProviderPaymentN,
  adjustBalanceN,
  putNeighborPaymentsN,
  getNeighborPaymentsN,
} from '../../services/budgetService';
import { 
  getDwellingAccountN
} from '../../services/dwellings-service';

export function addData(data) {
  return {
    type: ACTION.ADD_DATA_BUDGET,
    data
  };
}

export function addDwellingData(data) {
  return {
    type: ACTION.ADD_DWELLING_DATA,
    data
  };
}

export function clearData() {
  return {
    type: ACTION.CLEAR_BUDGET
  };
}

export function clearDataConcepts() {
  return {
    type: ACTION.CLEAR_BUDGET_CONCEPTS
  };
}

export function getBudgetAll({ property }) {
  return {
    types: [
      ACTION.LOADING_GET_BUDGETS,
      ACTION.RECEIVE_GET_BUDGETS,
      ACTION.ERROR_GET_BUDGETS,
    ],
    promise: getBudgetAllN({ property })
  };
}

export function getActives({ property }) {
  return {
    types: [
      ACTION.LOADING_GET_ACTIVES,
      ACTION.RECEIVE_GET_ACTIVES,
      ACTION.ERROR_GET_ACTIVES,
    ],
    promise: getActivesN({ property })
  };
}

export function getActivesWithoutSelected({ property }) {
  return {
    types: [
      ACTION.LOADING_GET_ACTIVES,
      ACTION.RECEIVE_GET_ACTIVES_UPDATE,
      ACTION.ERROR_GET_ACTIVES,
    ],
    promise: getActivesN({ property })
  };
}

export function getAssets({ property, active }) {
  return {
    types: [
      ACTION.LOADING_GET_ASSETS,
      ACTION.RECEIVE_GET_ASSETS,
      ACTION.ERROR_GET_ASSETS,
    ],
    promise: getAssetsN({ property, active })
  };
}

export function getConcepts(params) {
  return {
    types: [
      ACTION.LOADING_GET_CONCEPTS,
      ACTION.RECIVED_GET_CONCEPTS,
      ACTION.ERROR_GET_CONCEPTS,
    ],
    promise: getConceptsN(params)
  };
}

export function getPeriods() {
  return {
    types: [
      ACTION.LOADING_GET_PERIODS,
      ACTION.RECIVED_GET_PERIODS,
      ACTION.ERROR_GET_PERIODS,
    ],
    promise: getPeriodsN()
  };
}

export function getBudgetsAndFees({ bid }) {
  return {
    types: [
      ACTION.LOADING_GET_BUDGETS_FEES,
      ACTION.RECIVED_GET_BUDGETS_FEES,
      ACTION.ERROR_GET_BUDGETS_FEES,
    ],
    promise: getBudgetsAndFeesN({ bid })
  };
}

export function getBudgetsAndFeesAmenities({ amenity_id }) {
  return {
    types: [
      ACTION.LOADING_GET_BUDGETS_FEES_AMENITIES,
      ACTION.RECIVED_GET_BUDGETS_FEES_AMENITIES,
      ACTION.ERROR_GET_BUDGETS_FEES_AMENITIES,
    ],
    promise: getBudgetsAndFeesAmenitiesN({ amenity_id })
  };
}

export function setConcept({
  conceptData
}) {
  return {
    types: [
      ACTION.LOADING_SET_CONCEPT,
      ACTION.RECIVED_SET_CONCEPT,
      ACTION.ERROR_SET_CONCEPT,
    ],
    promise: setConceptN({
      conceptData
    })
  };
}

export function putConcept(params) {
  return {
    types: [
      ACTION.LOADING_PUT_CONCEPT,
      ACTION.RECIVED_PUT_CONCEPT,
      ACTION.ERROR_PUT_CONCEPT,
    ],
    promise: putConceptN(params)
  };
}

export function createService(name, asset_type) {
  return {
    types: [
      ACTION.LOADING_POST_SERVICE,
      ACTION.RECIVED_POST_SERVICE,
      ACTION.ERROR_POST_SERVICE,
    ],
    promise: createServiceN(name, asset_type)
  };
}

export function updateConcept( conceptData ) {
  return {
    type: ACTION.UPDATE_CONCEPT,
    conceptData
  }
} 

export function clearUpdateConcept( id ) {
  return {
    type: ACTION.CLEAR_UPDATE_CONCEPT,
    id
  }
} 

export function deleteConcept(params) {
  return {
    types: [
      ACTION.LOADING_DELETE_CONCEPT,
      ACTION.RECIVED_DELETE_CONCEPT,
      ACTION.ERROR_DELETE_CONCEPT,
    ],
    promise: deleteConceptN(params)
  };
}

export function getCatalogServices({ types }) {
  return {
    types: [
      ACTION.LOADING_GET_CATALOG_SERVICES,
      ACTION.RECIVED_GET_CATALOG_SERVICES,
      ACTION.ERROR_GET_CATALOG_SERVICES,
    ],
    promise: getCatalogServicesN({ types })
  };
}

export function getCatalogAmenities() {
  return {
    types: [
      ACTION.LOADING_GET_CATALOG_AMENITIES,
      ACTION.RECIVED_GET_CATALOG_AMENITIES,
      ACTION.ERROR_GET_CATALOG_AMENITIES,
    ],
  };
}

export function getQuotaDetail({ idBuilding }) {
  return {
    types: [
      ACTION.LOADING_GET_QUOTAS,
      ACTION.RECEIVE_GET_QUOTAS,
      ACTION.ERROR_GET_QUOTAS,
    ],
    promise: getQuotaDetailN({ idBuilding }),
  };
}

export function putFeeDweeling({ fees }) {
  return {
    types: [
      ACTION.LOADING_PUT_FEE,
      ACTION.RECEIVE_PUT_FEE,
      ACTION.ERROR_PUT_FEE,
    ],
    promise: putFeeDweelingN({ fees }),
  };
}

export function createPocahontasRegistry({ property }) {
  return {
    types: [
      ACTION.LOADING_CREATE_POCAHONTAS,
      ACTION.RECEIVE_CREATE_POCAHONTAS,
      ACTION.ERROR_CREATE_POCAHONTAS,
    ],
    promise: createPocahontasRegistryN({ property }),
  };
}

export function getExpenses(active) {
  return {
    types: [
      ACTION.LOADING_GET_EXPENSES,
      ACTION.RECEIVE_GET_EXPENSES,
      ACTION.ERROR_GET_EXPENSES
    ],
    promise: getExpensesN(active),
  };
}

export function getConceptsToValidate({ property, active }) {
  return {
    types: [
      ACTION.LOADING_GET_CONCEPTS_TO_VALIDATE,
      ACTION.RECEIVE_GET_CONCEPTS_TO_VALIDATE,
      ACTION.ERROR_GET_CONCEPTS_TO_VALIDATE
    ],
    promise: getConceptsToValidateN({ property, active }),
  };
}

export function setProviderPay(params) {
  return {
    types: [
      ACTION.LOADING_SET_PROVIDER_PAY,
      ACTION.RECEIVE_SET_PROVIDER_PAY,
      ACTION.ERROR_SET_PROVIDER_PAY
    ],
    promise: setProviderPayN(params)
  };
}

export function setTransferFund ({
  property, active, dataTransfer
}) {
  return {
    types: [
      ACTION.LOADING_SET_TRANSFERFUND,
      ACTION.RECEIVE_SET_TRANSFERFUND,
      ACTION.ERROR_SET_TRANSFERFUND
    ],
    promise: setTransferFundN({
      property, active, dataTransfer
    }),
  };
}

export function getTransferFund ({
  property, active
})
{
  return {
    types: [
      ACTION.LOADING_GET_TRANSFERFUND,
      ACTION.RECEIVE_GET_TRANSFERFUND,
      ACTION.ERROR_GET_TRANSFERFUND
    ],
    promise: getTransferFundN({
      property, active
    }),
  };
}

export function getProviderPaymentPending({ property, active }) {
  return {
    types: [
      ACTION.LOADING_GET_PROVIDER_PAYMENT_PENDING,
      ACTION.RECEIVE_GET_PROVIDER_PAYMENT_PENDING,
      ACTION.ERROR_GET_PROVIDER_PAYMENT_PENDING
    ],
    promise: getProviderPaymentPendingN({ property, active }),
  };
}

export function putProviderPaymentsPendings({
  property, active, data
}) {
  return {
    types: [
      ACTION.LOADING_PUT_PROVIDER_PAYMENTS_PENDINGS,
      ACTION.RECEIVE_PUT_PROVIDER_PAYMENTS_PENDINGS,
      ACTION.ERROR_PUT_PROVIDER_PAYMENTS_PENDINGS,
    ],
    promise: putProviderPaymentsPendingsN({
      property, active, data
    }),
  };
}

export function putProviderPayments({
  property, active, payment, status, reason
}) {
  return {
    types: [
      ACTION.LOADING_PUT_PROVIDER_PAYMENTS,
      ACTION.RECEIVE_PUT_PROVIDER_PAYMENTS,
      ACTION.ERROR_PUT_PROVIDER_PAYMENTS,
    ],
    promise: putProviderPaymentsN({
      property, active, payment, status, reason
    }),
  };
}

export function getIncomesCharged({ property, active }) {
  return {
    types: [
      ACTION.LOADING_GET_INCOMES_CHARGED,
      ACTION.RECEIVE_GET_INCOMES_CHARGED,
      ACTION.ERROR_GET_INCOMES_CHARGED,
    ],
    promise: getIncomesChargedN({ property, active }),
  };
}

export function getIncomesChargered({ property, active }) {
  return {
    types: [
      ACTION.LOADING_GET_INCOMES_CHARGERED,
      ACTION.RECEIVE_GET_INCOMES_CHARGERED,
      ACTION.ERROR_GET_INCOMES_CHARGERED,
    ],
    promise: getIncomesChargeredN({ property, active }),
  };
}

export function putInitialBalance({
  property, active, balance
}) {
  return {
    types: [
      ACTION.LOADING_PUT_INITIAL_BALANCE,
      ACTION.RECEIVE_PUT_INITIAL_BALANCE,
      ACTION.ERROR_PUT_INITIAL_BALANCE,
    ],
    promise: putInitialBalanceN({
      property, active, balance
    }),
  };
}

export function setCodeToPay({ property, active }) {
  return {
    types: [
      ACTION.LOADING_SET_CODE_TO_PAY,
      ACTION.RECEIVE_SET_CODE_TO_PAY,
      ACTION.ERROR_SET_CODE_TO_PAY,
    ],
    promise: setCodeToPayN({ property, active }),
  };
}

export function setFundTransfer({ pid, data }) {
  return {
    types: [
      ACTION.LOADING_SET_FUND_TRANSFER,
      ACTION.RECEIVE_SET_FUND_TRANSFER,
      ACTION.ERROR_SET_FUND_TRANSFER,
    ],
    promise: setFundTransferN({ pid, data }),
  };
}
export function putFundTransfer({ pid, ftid, data }) {
  return {
    types: [
      ACTION.LOADING_PUT_FUND_TRANSFER,
      ACTION.RECEIVE_PUT_FUND_TRANSFER,
      ACTION.ERROR_PUT_FUND_TRANSFER,
    ],
    promise: putFundTransferN({ pid, ftid, data }),
  };
}

export function getFundTransfer({ pid, aid }) {
  return {
    types: [
      ACTION.LOADING_GET_FUNDS_TRANSFER,
      ACTION.RECEIVE_GET_FUNDS_TRANSFER,
      ACTION.ERROR_GET_FUNDS_TRANSFER,
    ],
    promise: getFundTransferN({ pid, aid })
  };
}

export function getFundTransferAccess({ pid, aid,type }) {
  return {
    types: [
      ACTION.LOADING_GET_FUNDS_TRANSFER_ACCESS,
      ACTION.RECEIVE_GET_FUNDS_TRANSFER_ACCESS,
      ACTION.ERROR_GET_FUNDS_TRANSFER_ACCESS,
    ],
    promise: getFundTransferAccessN({ pid, aid,type })
  };
}

export function getFundIcome({ pid, aid }) {
  return {
    types: [
      ACTION.LOADING_GET_FUNDS_ICOME,
      ACTION.RECEIVE_GET_FUNDS_ICOME,
      ACTION.ERROR_GET_FUNDS_ICOME,
    ],
    promise: getFundIcomeN({ pid, aid })
  };
}

export function setFund({ pid, data }) {
  return {
    types: [
      ACTION.LOADING_SET_FUND,
      ACTION.RECEIVE_SET_FUND,
      ACTION.ERROR_SET_FUND,
    ],
    promise: setFundN({ pid, data }),
  };
}

export function getFunds({ pid, aid }) {
  return {
    types: [
      ACTION.LOADING_GET_FUNDS,
      ACTION.RECEIVE_GET_FUNDS,
      ACTION.ERROR_GET_FUNDS,
    ],
    promise: getFundsN({ pid, aid })
  };
}

export function getNeighborPayments({ did }) {
  return {
    types: [
      ACTION.LOADING_GET_NEIGHBOR_PAYMENTS,
      ACTION.RECEIVE_GET_NEIGHBOR_PAYMENTS,
      ACTION.ERROR_GET_NEIGHBOR_PAYMENTS,
    ],
    promise: getNeighborPaymentsN({ did })
  };
}

export function putNeighborPayments({ did, fid, data }) {
  return {
    types: [
      ACTION.LOADING_PUT_NEIGHBOR_PAYMENTS,
      ACTION.RECEIVE_PUT_NEIGHBOR_PAYMENTS,
      ACTION.ERROR_PUT_NEIGHBOR_PAYMENTS,
    ],
    promise: putNeighborPaymentsN({ did, fid, data })
  };
}

export function putFunds({ fid, data }) {
  return {
    types: [
      ACTION.LOADING_PUT_FUNDS,
      ACTION.RECEIVE_PUT_FUNDS,
      ACTION.ERROR_PUT_FUNDS,
    ],
    promise: putFundsN({ fid, data })
  };
}

export function putVariableFund({ did, fid, data }) {
  return {
    types: [
      ACTION.LOADING_PUT_FUNDS,
      ACTION.RECEIVE_PUT_FUNDS,
      ACTION.ERROR_PUT_FUNDS,
    ],
    promise: putVariableFundN({ did, fid, data })
  };
}

export function deleteFunds({ pid, aid, fid }) {
  return {
    types: [
      ACTION.LOADING_DELETE_FUNDS,
      ACTION.RECEIVE_DELETE_FUNDS,
      ACTION.ERROR_DELETE_FUNDS,
    ],
    promise: deleteFundsN({ pid, aid, fid })
  };
}

export function getAccess() {
  return {
    types: [
      ACTION.LOADING_GET_ACCESS,
      ACTION.RECEIVE_GET_ACCESS,
      ACTION.ERROR_GET_ACCESS,
    ],
    promise: getAccessN()
  };
}

export function setAccess({ pid, data }) {
  return {
    types: [
      ACTION.LOADING_SET_ACCESS,
      ACTION.RECEIVE_SET_ACCESS,
      ACTION.ERROR_SET_ACCESS,
    ],
    promise: setAccessN({ pid, data }),
  };
}

export function putAccess({ pid, data }) {
  return {
    types: [
      ACTION.LOADING_PUT_ACCESS,
      ACTION.RECEIVE_PUT_ACCESS,
      ACTION.ERROR_PUT_ACCESS,
    ],
    promise: putAccessN({ pid, data }),
  };
}

export function deleteAccess({ pid, cid }) {
  return {
    types: [
      ACTION.LOADING_DELETE_ACCESS,
      ACTION.RECEIVE_DELETE_ACCESS,
      ACTION.ERROR_DELETE_ACCESS,
    ],
    promise: deleteAccessN({ pid, cid }),
  };
}

export function getReport({ pid, aid, data }) {
  return {
    types: [
      ACTION.LOADING_GET_REPORT,
      ACTION.RECEIVE_GET_REPORT,
      ACTION.ERROR_GET_REPORT,
    ],
    promise: getReportN({ pid, aid, data }),
  };
}

export function downloadResidentsReport(pid, data) {
  getResidentReportURL(pid, data)
  return {
    type: ACTION.DOWNLOAD_REPORT
  }
}

export function downloadActiveReport(pid, data) {
  getActiveReportURL(pid, data)
  return {
    type: ACTION.DOWNLOAD_REPORT
  }
}

export function getDwellingAccount(did) {
  return {
    types: [
      ACTION.LOADING_GET_DWELLING_ACCOUNT,
      ACTION.RECEIVE_GET_DWELLING_ACCOUNT,
      ACTION.ERROR_GET_DWELLING_ACCOUNT,
    ],
    param: did,
    promise: getDwellingAccountN(did),
  };
}

export function postExtraProviderPayment(aid, data) {
  return {
    types: [
      ACTION.LOADING_POST_EXTRA_PROVIDER_PAYMENT,
      ACTION.RECEIVE_POST_EXTRA_PROVIDER_PAYMENT,
      ACTION.ERROR_POST_EXTRA_PROVIDER_PAYMENT,
    ],
    promise: postExtraProviderPaymentN(aid, data),
  };
}

export function adjustBalance(aid, data) {
  return {
    types: [
      ACTION.LOADING_ADJUST_BALANCE,
      ACTION.RECEIVE_ADJUST_BALANCE,
      ACTION.ERROR_ADJUST_BALANCE
    ],
    promise: adjustBalanceN(aid, data)
  }
}