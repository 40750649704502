import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as Actions from '../../../budgetActions';
import Dialog from 'components/Dialogs';
import Table from 'components/Table';
import { FormattedMessage } from 'react-intl';
import BudgetAsset from '../../../components/BudgetAsset/BudgetAsset';

import './BudgetProgrammed.scss';

export class BudgetProgrammed extends Component {
  constructor(){
    super();
    this.state = {
      showSnack: false,
      disabledID:0
    };
  }
  componentDidMount(){
    const { property } = this.props.params;
    const { activeSelected } = this.props.budgetState;
    if(activeSelected){
      this.props.getAssets({
        property,
        active: activeSelected
      }).then(()=>{
        this.props.getConceptsToValidate({
          property,
          active: activeSelected
        });
      });
    }
  }

  componentDidUpdate(prevProps){
    const activeSelectedBefore = prevProps.budgetState.activeSelected;
    const activeSelectedNext = this.props.budgetState.activeSelected;
    if(activeSelectedBefore !== activeSelectedNext){
      const { property } = this.props.params;
      const { activeSelected } = this.props.budgetState;
      this.props.getAssets({
        property,
        active: activeSelected
      }).then(()=>{
        this.props.getConceptsToValidate({
          property,
          active: activeSelected
        });
      });
    }
  }

  componentWillUnmount(){
    this.props.clearDataConcepts();
  }

  handleChangeAmount = (assetID) => (row, event) => {
    const { id: conceptID } = row;
    const { conceptsToValidate } = this.props.budgetState;
    if (isNaN(Number(event.target.value))) {
      conceptsToValidate[assetID][conceptID].amount = 0;
      return null
    }
    conceptsToValidate[assetID][conceptID].amount = Number(event.target.value);
    this.props.addData({ conceptsToValidate });
  }

  handleValidateConcept(row) {
    const { id: concept_id, amount } = row;
    this.disabledID =concept_id
    const { property } = this.props.params;
    const { activeSelected } = this.props.budgetState;
    const dataConcept = {
      concept_id,
      amount,
      status: 'pending',
    };
    this.props.setProviderPay({
      concept_id,
      property,
      active: activeSelected,
      dataConcept
    }).then(()=>{
      this.props.getExpenses(activeSelected);
      this.props.getConceptsToValidate({
        property,
        active: activeSelected
      });
      this.setState({ showSnack: true });
    });
  }

  onCloseDialog = () => {
    this.setState({ showSnack: false });
  }

  handleCreateAssetSection = () => {
    const { conceptsToValidate, assets, loading } = this.props.budgetState;

    return Object.getOwnPropertyNames(conceptsToValidate || { }).map((asset, index) => {
      const infoAsset = (assets?.result || []).find((item)=> item.id === asset) || { };
      return (
        <div className='BudgetProgrammed' key={ index }>
          <BudgetAsset
            icon={ infoAsset.type }
            headTitle={ infoAsset.name }
          />
          <Table
            columns={[
              {
                headerName: <FormattedMessage id='BudgetProgrammed.Concept'/>,
                field: 'service_name'
              },
              {
                headerName: <FormattedMessage id='BudgetProgrammed.Provider'/>,
                field: 'provider_name'
              }, {
                headerName: <FormattedMessage id='BudgetProgrammed.Service'/>,
                field: 'period_type_name'

              },{
                headerName: <FormattedMessage id='BudgetProgrammed.Payment'/>,
                field: 'period_date'
              },
              {
                headerName: <FormattedMessage id='BudgetProgrammed.Amount'/>,
                field: 'amount',
                input: true,
                disabled: row => row.status !== 'toValidate',
                onChange: this.handleChangeAmount(asset),
              },
              {
                button: row => row.status === 'toValidate' ? 'Validar' : 'Validado',
                cellProps: {
                  align: 'right'
                },
                onClick: this.handleValidateConcept.bind(this),
                disabled: row => row.status !== 'toValidate' || this.disabledID == row.id,
              }
            ]}
            tableProps={{
              stickyHeader: true
            }}
            loading={ loading.GET_CONCEPTS_TO_VALIDATE?.status || loading.GET_ASSETS?.status}
            rows={ conceptsToValidate[asset] }
          />
        </div>
      );
    });
  }

  render() {
    const { showSnack } = this.state;
    return(
      <>
        <div className='BudgetProgrammed'>
            <Dialog
              title='¡Correcto!'
              message={ 'Tu concepto ya está validado, ahora puedes realizar el pago correspondiente en la sección de pagos pendientes.' }
              onClose={this.onCloseDialog}
              open={showSnack}
              onCancel={this.onCloseDialog}
              />
            { this.handleCreateAssetSection() }
        </div>
      </>
    );
  }
}

BudgetProgrammed.contextTypes = { 
  router: PropTypes.shape()
};

BudgetProgrammed.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  budgetState: state.get('budgetState'),
  profileState: state.get("profileState"),
});

export default connect(mapStateToProps, mapDispatchToProps)(BudgetProgrammed);

