import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import { ReactSVG } from 'react-svg';

import IconDelete from 'assets/img/icon-delete.svg';

const styles = theme => ({
  button: {
    width: 30,
    height: 30,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const FieldDelete = (props) => {
  const { classes, onClick,disab } = props;
  return (
    <IconButton className={classes.button} onClick={onClick} disabled={disab}>
      <ReactSVG
        src={IconDelete}
      />
    </IconButton>
  );
};

export default withStyles(styles)(FieldDelete);
