import * as ACTION from './actionTypes';
import {
  setInvitationsN,
  downloadFileN,
  uploadFileN,
  getPropertiesN,
  setPropertyN,
  putPropertyN,
  getPropertyN,
  delPropertyN,
  getBuildingsN,
  setBuildingN,
  getBuildingN,
  putBuildingN,
  delBuildingN,
  setDwellingsN,
  setPaymentResidentN,
  getDwellingsN,
  putDwellingN,
  delDwellingsN,
  getCatalogAmenitiesN,
  getAmenitiesN,
  deleteAmenityN,
  getAmenitiesAccessN,
  putAmenitiesAccessN,
  setAmenityN,
  getAmenityN,
  putAmenityN,
  getFounds,
  createAnAmenitiesN,
  createAnAmenitiesBulkN, 
  updateAmenitiesBulkN,
  getBookingsN,
  setSurchargeN,
  setDiscountN
} from '../../services/propertiesService';

export function addPropertyData(data) {
  return {
    type: ACTION.ADD_PROPERTY_DATA,
    data
  };
}

export function unmountBuild() {
  return {
    type: ACTION.UNMOUNT_BUILD,
  };
}

export function downloadMassiveTemplate() {
  return {
    types: [
      ACTION.LOADING_DOWNLOAD_MASIVE_TEMPLETE,
      ACTION.RECEIVE_DOWNLOAD_MASIVE_TEMPLETE,
      ACTION.ERROR_DOWNLOAD_MASIVE_TEMPLETE,
    ],
    promise: downloadFileN(),
    param: 'download'
  };
}

export function uploadMassiveTemplate({ idProperty, data }) {
  return {
    types: [
      ACTION.LOADING_UPLOAD_MASIVE_TEMPLETE,
      ACTION.RECEIVE_UPLOAD_MASIVE_TEMPLETE,
      ACTION.ERROR_UPLOAD_MASIVE_TEMPLETE,
    ],
    promise: uploadFileN({ idProperty, data }),
  };
}

export function setInvitations({ idProperty }) {
  return {
    types: [
      ACTION.LOADING_SEND_INVITATIONS,
      ACTION.RECEIVE_SEND_INVITATIONS,
      ACTION.ERROR_SEND_INVITATIONS,
    ],
    promise: setInvitationsN({ idProperty }),
  };
}

// ******************************** PROPERTY ******************************** //
export function getProperties() {
  return {
    types: [
      ACTION.LOADING_GET_PROPERTIES,
      ACTION.RECEIVE_GET_PROPERTIES,
      ACTION.ERROR_GET_PROPERTIES,
    ],
    promise: getPropertiesN()
  };
}

export function getProperty({ property }) {
  return {
    types: [
      ACTION.LOADING_GET_PROPERTY,
      ACTION.RECEIVE_GET_PROPERTY,
      ACTION.ERROR_GET_PROPERTY,
    ],
    promise: getPropertyN({ property }),
  };
}

export function setProperty({ property }) {
  return {
    types: [
      ACTION.LOADING_SET_PROPERTY,
      ACTION.RECEIVE_SET_PROPERTY,
      ACTION.ERROR_SET_PROPERTY,
    ],
    promise: setPropertyN({ property }),
  };
}

export function putProperty({ idProperty, property }) {
  return {
    types: [
      ACTION.LOADING_PUT_PROPERTY,
      ACTION.RECEIVE_PUT_PROPERTY,
      ACTION.ERROR_PUT_PROPERTY,
    ],
    promise: putPropertyN({ idProperty, property }),
  };
}

export function delProperty({ property }) {
  return {
    types: [
      ACTION.LOADING_DEL_PROPERTY,
      ACTION.RECEIVE_DEL_PROPERTY,
      ACTION.ERROR_DEL_PROPERTY,
    ],
    promise: delPropertyN({ property }),
  };
}


// ******************************** BUILDING ******************************** //
export function getBuildings({ idProperty }) {
  return {
    types: [
      ACTION.LOADING_GET_BUILDINGS,
      ACTION.RECEIVE_GET_BUILDINGS,
      ACTION.ERROR_GET_BUILDINGS,
    ],
    promise: getBuildingsN({ idProperty }),
  };
}

export function setBuilding({ idProperty, buildingData }) {
  return {
    types: [
      ACTION.LOADING_SET_BUILDING,
      ACTION.RECEIVE_SET_BUILDING,
      ACTION.ERROR_SET_BUILDING,
    ],
    promise: setBuildingN({ idProperty, buildingData }),
  };
}

export function getBuilding({ idProperty, idBuilding }) {
  return {
    types: [
      ACTION.LOADING_GET_BUILDING,
      ACTION.RECEIVE_GET_BUILDING,
      ACTION.ERROR_GET_BUILDING,
    ],
    promise: getBuildingN({ idProperty, idBuilding }),
  };
}


export function putBuilding({
  idProperty, idBuilding, buildingData
}) {
  return {
    types: [
      ACTION.LOADING_PUT_BUILDING,
      ACTION.RECEIVE_PUT_BUILDING,
      ACTION.ERROR_PUT_BUILDING,
    ],
    promise: putBuildingN({
      idProperty, idBuilding, buildingData
    }),
  };
}

export function delBuilding({ idProperty, idBuilding }) {
  return {
    types: [
      ACTION.LOADING_DEL_BUILDING,
      ACTION.RECEIVE_DEL_BUILDING,
      ACTION.ERROR_DEL_BUILDING,
    ],
    promise: delBuildingN({ idProperty, idBuilding }),
  };
}

// ******************************** DWELLING ******************************** //
export function setDwellings({
  idProperty, idBuilding, dwellingsData
}) {
  return {
    types: [
      ACTION.LOADING_SET_DWELLINGS,
      ACTION.RECEIVE_SET_DWELLINGS,
      ACTION.ERROR_SET_DWELLINGS,
    ],
    promise: setDwellingsN({
      idProperty, idBuilding, dwellingsData
    }),
  };
}

// para pago residentes
export function setPaymentResidents({
  did, data
}) {
  return {
    types: [
      ACTION.LOADING_SET_PAYMENT_RESIDENTS,
      ACTION.RECEIVE_SET_PAYMENT_RESIDENTS,
      ACTION.ERROR_SET_PAYMENT_RESIDENTS,
    ],
    promise: setPaymentResidentN({
       did, data
    }),
  }
}

export function getDwellings({ idProperty, idBuilding }) {
  return {
    types: [
      ACTION.LOADING_GET_DWELLINGS,
      ACTION.RECEIVE_GET_DWELLINGS,
      ACTION.ERROR_GET_DWELLINGS,
    ],
    promise: getDwellingsN({ idProperty, idBuilding }),
  };
}

export function putDwelling({
  idProperty, idBuilding, dwellingData
}) {
  return {
    types: [
      ACTION.LOADING_PUT_DWELLING,
      ACTION.RECEIVE_PUT_DWELLING,
      ACTION.ERROR_PUT_DWELLING,
    ],
    promise: putDwellingN({
      idProperty, idBuilding, dwellingData
    }),
  };
}

export function delDwellings({
  idProperty, idBuilding, idDwelling, idResident
}) {
  return {
    types: [
      ACTION.LOADING_DELETE_DWELLING,
      ACTION.RECEIVE_DELETE_DWELLING,
      ACTION.ERROR_DELETE_DWELLING,
    ],
    promise: delDwellingsN({
      idProperty, idBuilding, idDwelling, idResident
    }),
  };
}

// ******************************** AMENITY ******************************** //

export function getCatalogAmenities() {
  return {
    types: [
      ACTION.LOADING_GET_CATALOG_AMENITIES,
      ACTION.RECEIVE_GET_CATALOG_AMENITIES,
      ACTION.ERROR_GET_CATALOG_AMENITIES
    ],
    promise: getCatalogAmenitiesN(),
  };
}

export function setAmenity({ propertyId, amenity }) {
  return {
    types: [
      ACTION.LOADING_SET_AMENITY,
      ACTION.RECEIVE_SET_AMENITY,
      ACTION.ERROR_SET_AMENITY,
    ],
    promise: setAmenityN({ propertyId, amenity }),
  };
}

export function createAnAmenities({ data }) {
  return {
    types: [
      ACTION.LOADING_CREATE_AN_AMENITY,
      ACTION.RECEIVE_CREATE_AN_AMENITY,
      ACTION.ERROR_CREATE_AN_AMENITY
    ],
    promise: createAnAmenitiesN({ data }),
  };
}

export function createAnAmenitiesBulk({ data }) {
  return {
    types: [
      ACTION.LOADING_CREATE_AMENITY_BULK,
      ACTION.RECEIVE_CREATE_AMENITY_BULK,
      ACTION.ERROR_CREATE_AMENITY_BULK
    ],
    promise: createAnAmenitiesBulkN({ data }),
  };
}

export function updateAmenitiesBulk({ data }) {
  return {
    types: [
      ACTION.LOADING_PUT_AMENITY_BULK,
      ACTION.RECEIVE_PUT_AMENITY_BULK,
      ACTION.ERROR_PUT_AMENITY_BULK
    ],
    promise: updateAmenitiesBulkN({ data }),
  };
}

export function getAmenity({ idProperty, idAmenity }) {
  return {
    types: [
      ACTION.LOADING_GET_AMENITY,
      ACTION.RECEIVE_GET_AMENITY,
      ACTION.ERROR_GET_AMENITY,
    ],
    promise: getAmenityN({ idProperty, idAmenity }),
  };
}

export function putAmenity({
  idProperty, idAmenity, amenityData
}) {
  return {
    types: [
      ACTION.LOADING_PUT_AMENITY,
      ACTION.RECEIVE_PUT_AMENITY,
      ACTION.ERROR_PUT_AMENITY,
    ],
    promise: putAmenityN({
      idProperty, idAmenity, amenityData
    }),
  };
}

export function deleteAmenity({ idProperty, idAmenity }) {
  return {
    types: [
      ACTION.LOADING_DELETE_AMENITY,
      ACTION.RECEIVE_DELETE_AMENITY,
      ACTION.ERROR_DELETE_AMENITY,
    ],
    promise: deleteAmenityN({ idProperty, idAmenity }),
  };
}

export function getAmenities({ idProperty }) {
  return {
    types: [
      ACTION.LOADING_GET_AMENITIES,
      ACTION.RECEIVE_GET_AMENITIES,
      ACTION.ERROR_GET_AMENITIES,
    ],
    promise: getAmenitiesN({ idProperty }),
  };
}

export function getBookings(data) {
  return {
    types: [
      ACTION.LOADING_GET_PAGINATED_BOOKINGS,
      ACTION.RECEIVE_GET_PAGINATED_BOOKINGS,
      ACTION.ERROR_GET_PAGINATED_BOOKINGS,
    ],
    promise: getBookingsN(data)
  }
}

export function getBookingsByDate(data) {
  return {
    types: [
      ACTION.LOADING_GET_BOOKINGS,
      ACTION.RECEIVE_GET_BOOKINGS,
      ACTION.ERROR_GET_BOOKINGS,
    ],
    promise: getBookingsN(data)
  }
}

export function getAmenitiesAccess({ idProperty, idBuilding }) {
  return {
    param: idBuilding,
    types: [
      ACTION.LOADING_GET_AMENITIES_BIND,
      ACTION.RECEIVE_GET_AMENITIES_BIND,
      ACTION.ERROR_GET_AMENITIES_BIND,
    ],
    promise: getAmenitiesAccessN({ idProperty, idBuilding }),
  };
}

export function putAmenitiesAccess({
  idProperty, idBuilding, dataAccess
}) {
  return {
    types: [
      ACTION.LOADING_PUT_AMENITIES_BIND,
      ACTION.RECEIVE_PUT_AMENITIES_BIND,
      ACTION.ERROR_PUT_AMENITIES_BIND,
    ],
    promise: putAmenitiesAccessN({
      idProperty, idBuilding, dataAccess
    }),
  };
}
export function getFoundsN ({ pid, bid }) {
  return {
    types: [
      ACTION.LOADING_GET_FOUNDS,
      ACTION.RECEIVE_GET_FOUNDS,
      ACTION.ERROR_GET_FOUNDS
    ],
    promise: getFounds({ pid, bid })
  }
}

export function setSurcharge({ did, data }) {
  return {
    types: [
      ACTION.LOADING_SET_SURCHARGE,
      ACTION.RECEIVE_SET_SURCHARGE,
      ACTION.ERROR_SET_SURCHARGE,
    ],
    promise: setSurchargeN({
       did, data
    }),
  }
}

export function setDiscount({ did, data }) {
  return {
    types: [
      ACTION.LOADING_SET_DISCOUNT,
      ACTION.RECEIVE_SET_DISCOUNT,
      ACTION.ERROR_SET_DISCOUNT,
    ],
    promise: setDiscountN({
       did, data
    }),
  }
}