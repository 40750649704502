import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import * as PropertyActions from 'containers/Property/propertyActions';
import * as BudgetActions from 'containers/Budget/budgetActions';

import Button from 'components/Button';

import Drawer from 'components/Drawer/Drawer';
import WizardHeader from 'containers/Wizard/components/WizardHeader';
import Asset from './Asset';
import Concepts from './Concepts';
import { showIconAmenity } from 'constants/amenityTypes';
import { simpleDate } from 'utils/format';
import FoundModal from 'containers/Budget/Budget/FoundModal/FoundModal';
import Dialogs from 'components/Dialogs';
import Founds from './Founds';
import SecondaryTabView from 'components/SecondaryTabView';
import VariableAmountConcepts from './VariableAmountConcepts';

import './Detail.scss';
export class Detail extends Component {

  constructor() {
    super();
    this.state = {
      showModal: false,
      warningShow: false,
      dataEdit:'',
      dialog: {},
      data:{},
    };
  }

  componentDidMount() {
    this.getPropertyFromParams();
    this.getConcepts();
  }

  componentDidUpdate(props) {
    const beforeActiveSelected = props.budgetState.activeSelected;
    const nextActiveSelected = this.props.budgetState.activeSelected;

    if (nextActiveSelected !== beforeActiveSelected){
      this.handleRequestData(nextActiveSelected);
    }
    if(props.budgetState.activeSelected !== this.props.budgetState.activeSelected){
      this.mapActivesSelected();
    }
  }

  getPropertyFromParams() {
    const { property } = this.props.params;

		this.props.getProperty({
			property
		});
  }

  mapActivesSelected = () => {
    const { actives, activeSelected } = this.props.budgetState;

    if (activeSelected) {
      const objectActives = Object.values(actives.result);
      const propertySelected = objectActives.find(active => active.id === activeSelected);

      this.setState({ propertySelected });

      if(propertySelected.type === 'amenity'){
        const { id : amenity_id } = propertySelected?.amenities[0];

        this.props.getBudgetsAndFeesAmenities({amenity_id});
      } else {
        this.getBudgetsAndFees(propertySelected.building_id);
      }
    }
  }

  getBudgetsAndFees = (bid) => {
    this.props.getBudgetsAndFees({bid})
  }

  getFunds(active) {
    const { property } = this.props.params;
    const { activeSelected } = this.props.budgetState;

    return this.props.getFunds({ pid: property, aid: active || activeSelected });
  }

  getConcepts(active, page, limit) {
    const { property } = this.props.params;
    const { activeSelected } = this.props.budgetState;

    if (active || activeSelected) {
      return this.props.getConcepts({
        property,
        active: active || activeSelected,
        page: page || 1,
        limit: limit || 5
      });
    }
    return Promise.reject();
  }

  handleRequestData = active => {
    if(!active) { return null }
    
    const { property } = this.props.params;

    this.props.getAssets({
      property,
      active
    }).then(()=>{
      let { result : assets } = this.props.budgetState.assets;
      
      this.getConcepts(active).then(() => {
        assets.forEach( asset => {
          let concepts =  this.handleGetConcepts(asset.id);
          if (!concepts.length) {
             this.handleNewConcept(asset.id);
          }
        })
      });
      const activeSelected = this.props.budgetState.actives.result[active];
      let types;
      if (activeSelected.type === 'building') {
        let array = [];
        if (assets) {
          assets.map(asset => array.push(asset.type));
          types = array.join(',');
        }
      } else {
        types = activeSelected.icon;
      }
      this.props.getCatalogServices({ types });
      this.getFunds(active);
    });
  }

  handleSelectActive = (active) => {
    const { activeSelected } = this.props.budgetState;
    
    if (activeSelected !== active) {
      this.props.addData({
        activeSelected: active,
        concepts: {}
      });
    }
  }

  handleShowFound = () => {
    this.setState({ showModal: true, dataEdit: undefined })
  }

  handleShowFoundEdit = (data) => () => {
    this.setState({ showModal: true, dataEdit: data })
  }

  handleDeleteFund = () => {
    const { property } = this.props.params;
    const { activeSelected } = this.props.budgetState;
    const { id } = this.state.data;

    this.props.putFunds({
      pid: property,
      aid: activeSelected,
      fid: id,
      data: this.state.data
    }).then(() => {
      this.getFunds();
      this.mapActivesSelected();
      this.setState({ data:{}, dialog:{} });
    });
  }

  handleNewConcept = idAsset => () => {
    this.props.setConcept({
      conceptData: {
        asset_id: idAsset,
        service_id: 91,
        period_type_id: 4,
        period_date: simpleDate(new Date()),
        next_payment_date: simpleDate(new Date()),
        amount: 0,
        reminder: false,
        status: 'pending', // "pending, in_progress, complete"
        period_day: 1,
      }
    }).then(this.getConcepts.bind(this));
  }

  createVariableFund = () => () => {
    this.setFund({
      frequency: 'Mensual',
      name_found: '',
      target_amount: 0,
      type: 3,
      initial_balance: 0,
      active: true
    });
  }

  handleGetConcepts = (idAsset) =>{
    const { concepts } = this.props.budgetState;

    return concepts && concepts[idAsset] ? Object.values(concepts[idAsset]) : [];
  };

  handleGetActives = () => {
    const sideMenuItems = [
      { titleSection: 'Inmuebles', content: [], withAmount: true },
      { titleSection: 'Áreas compartidas', content: [], withAmount: false }
    ];
    const { actives } = this.props.budgetState;
    const result = Object.values(actives?.result || { });

    sideMenuItems[0].content = result.filter(item => item.type === 'building');
    sideMenuItems[1].content = result.filter(item => item.type === 'amenity');

    if (!sideMenuItems[1].content.length){
      sideMenuItems.splice(1,1);
    }
    if (!sideMenuItems[0].content.length){
      sideMenuItems.splice(0,1);
    }

    return sideMenuItems;
  }

  handleGetAssets = () => {
    const { assets, activeSelected } = this.props.budgetState;
    
    return (assets?.result || [ ]).filter(item => item.active_id === activeSelected);
  }

  handleGetFunds = () => {
    const { funds, activeSelected } = this.props.budgetState;
    
    return (funds?.result || []).filter(item => item.active_id === activeSelected && item.type !== 3);
  }

  listVariableConcepts = () => {
    const { funds, activeSelected } = this.props.budgetState;
    return (funds?.result || []).filter(item => item.active_id === activeSelected && item.type === 3);
  }

	savePropertyStatus() {
    const { name, address } = this.props.propertyState.new;
    const { property: idProperty, id: step } = this.props.params;
    const property = {
      step: parseInt(step),
      status_id: 21,
      name,
      address,
    }

    return this.props.putProperty({
      idProperty,
      property
    })
  }

  onReminderSubmit() {
    const { property, id } = this.props.params;
    const { activeSelected } = this.props.budgetState;
    const lastActive = this.getLastActive();
    const isComplete = lastActive.id === activeSelected;

    this.savePropertyStatus().then(() => {
      if(isComplete){
        this.context.router.push(`/Wizard/${(Number(id) + 10)}/property/${property}`);
      } else {
        this.getNextactive();
      }
    })

  }

  getLastActive = () => {
    const { result } = this.props.budgetState.actives;
    let actives = result || {};
    let mapkeys  = Object.keys(actives);
    let lastIndex  = mapkeys.length -1;
    
    return actives[mapkeys[lastIndex]];
  }

  getNextactive = () => {
    const { activeSelected } = this.props.budgetState;
    const actives = Object.values(this.props.budgetState.actives.result || { });
    const selectedIndex = actives.findIndex(active => active.id === activeSelected);

    this.handleSelectActive(actives[selectedIndex + 1].id);
  }

  handleOnChangeInitialBalance = (e) => {
    const { activeSelected, actives } = this.props.budgetState;
    const data = actives.result[activeSelected];
    const value = e.target.value === '' || e.target.value === '-' ? e.target.value :parseFloat(e.target.value);
    
    if (e.target.value === '' || e.target.value === '-'){
      data.initial_balance =value
    }else{
      data.initial_balance = isNaN(value) ? 0 : value;
    }

    this.props.addData({
      actives: {
        ...actives,
        [activeSelected]: data,
      }
    })
  }

  handleUpdateInitialBalance = () => {
    const { property } = this.props.params;
    const { activeSelected, actives } = this.props.budgetState;
    const { initial_balance } = actives.result[activeSelected].initial_balance ===''? 0:actives.result[activeSelected];

    this.props.putInitialBalance({
      property,
      active: activeSelected,
      balance: {
        balance: initial_balance
      }
    });
  }
  
  closeModal = () => {
    this.setState({showModal: false })
  }

  updateFunds() {
    this.closeModal();
    this.getFunds().then(() => this.bottomPage.scrollIntoView({ behavior: 'smooth' }));
    this.mapActivesSelected();
  }

  setFund = (data)  =>  {
    const { property } = this.props.params;
    const { activeSelected } = this.props.budgetState;
    const { fid } = data;

    if (fid){
      this.props.putFunds({
        pid: property,
        aid: activeSelected,
        fid: fid,
        data: data
      }).then(this.updateFunds.bind(this));
    } else {
      data.active_id = activeSelected;
      this.props.setFund({
        pid: property,
        data
      }).then(this.updateFunds.bind(this));
    }
  }

  handleCloseWarn() {
    this.setState({ warningShow: false });
  }

  handleOnDelete = (data) => () => {
    let dialog;
    if (data.type === 3) {
      dialog = {
        title: 'Elimiar gasto variable.',
        message: '¿Esta seguro de continuar con la eliminación de este gasto variable? Esta acción es irreversible.',
      };
    } else {
      dialog = {
        title: 'Elimiar Fondo.',
        message: '¿Esta seguro de continuar con la eliminación de este fondo? Esta acción es irreversible.',
      };
    }
    this.setState({
      data,
      dialog
    });
  }

  handleCloseDialog = () => {
    this.setState({ dialog: {}, data: {} });
  }

  disabledEnter = (event ) => {
    if( event.which === 13 ) {
      event.preventDefault()
      return false;
    } else {
      return true;
    }
  }

  handleTabChange = (selectedTab) => {
    this.setState({ selectedTab });
  }

  render() {
    const { actives, activeSelected, loading } = this.props.budgetState;
    const { warningShow, dialog, propertySelected } = this.state;
    const {
      name,
      icon,
      dwelling_count,
      type,
      initial_balance,
      balance,
      financial_cost
    } = actives.result[activeSelected] || { };
    const myAssets = this.handleGetAssets();
    const myFunds = this.handleGetFunds();

    return (
      <div className='BudgetDetail'>
        <Dialogs
          title="Agregar Presupuesto"
          message="Antes de continuar, elija el proveedor de servicio ya que falta indicarlo en algun Inmueble."
          onClose={ this.handleCloseWarn.bind(this) }
          open={ warningShow }
          onCancel={ this.handleCloseWarn.bind(this) }
        />

        <div className='BudgetDetail__drawer'>
          <Drawer
            items={this.handleGetActives()}
            value={activeSelected}
            onClick={active => this.handleSelectActive(active)}
          />
        </div>
        <div className='BudgetDetail__col'>
          <div className="BudgetDetail__content">

            <div className='row header-detail'>
                <WizardHeader
                  { ...this.props }
                  numberDwellings={dwelling_count}
                  name={name}
                  icon={icon}
                  showDeptos={type === 'building'}
                  showQuota={type === 'building'}
                  handleShowFound={this.handleShowFound}
                  financial_cost={financial_cost}
                  balance={ balance }
                  initial_balance={initial_balance}
                  onChange={this.handleOnChangeInitialBalance}
                  onBlur={this.handleUpdateInitialBalance}
                  disabledEnter={this.disabledEnter}
                  propertySelected={propertySelected}
                />
            </div>
            {
              type === 'amenity' && (
                <div className='BudgetDetail__content__label'>
                  <div>
                    <span>IMPORTANTE:</span>
                    El presupuesto asignado a las amenidades compartidas, modificará el presupuesto de tus inmuebles
                  </div>
                </div>
              )
            }
            {
              myAssets.length && !loading.GET_FUNDS?.status && !loading.GET_CONCEPTS?.status && <SecondaryTabView
                selectedTab={this.state.selectedTab?.value}
                onChange={this.handleTabChange.bind(this)}
                isLoading={loading.GET_FUNDS?.status || loading.GET_CONCEPTS?.status}
                items={[
                  {
                    value: 1,
                    label: "Gastos fijos",
                    view: (
                      (myAssets || []).map((item) => (
                        <Asset
                          key={`myAssets${item.id}`}
                          name={item.name}
                          image={showIconAmenity(item.type).img_line}
                          handleCreateConcept={this.handleNewConcept(item.id)}
                          is_shared={item.is_shared}
                        >
                          <Concepts
                            asset_type={item.type}
                            is_shared={item.is_shared}
                            params={this.props.params}
                            concepts={this.handleGetConcepts(item.id)}
                          />
                          </Asset>
                        ))
                    )
                  },
                  {
                    value: 2,
                    label: "Gastos por vecinos",
                    view: (
                      <Asset
                        name={'Gastos Variables'}
                        handleCreateConcept={this.createVariableFund()}
                      >
                        <VariableAmountConcepts
                          params={this.props.params}
                          concepts={this.listVariableConcepts()}
                          onDelete={this.handleOnDelete}
                        />
                      </Asset>
                    )
                  }
                ]}
              />
            }

            {
              this.state.showModal && (
                <FoundModal
                  params={this.props.params}
                  onSubmit={this.setFund}
                  closeModal={this.closeModal}
                  dataEdit={this.state.dataEdit}
                />
              )
            }


            { !!myFunds.length &&
              <Founds
                founds={myFunds}
                handleShowFound={this.handleShowFoundEdit}
                handleOnDelete={this.handleOnDelete}
              />
            }
            <div className="BudgetDetail__content__cost">
              {
                !!this.props.params.id && (
                  <div className='BudgetDetail__content__options'>
                    <Button variant='contained' color='secondary' onClick={this.onReminderSubmit.bind(this)}>Siguiente</Button>
                  </div>
                )
              }
            </div>

            <Dialogs
              title={dialog.title}
              message={dialog.message}
              onClose={this.handleCloseDialog}
              open={!!dialog.title}
              onSuccess={this.handleDeleteFund}
              onCancel={this.handleCloseDialog}
              textSuccess='Continuar'
              textCancel='Cancelar'
            />

          </div>
          <div style={{ float: 'left', clear: 'both' }} ref={(el) => { this.bottomPage = el }}></div>
        </div>
      </div>
    );
  }
}

Detail.contextTypes = {
  router: PropTypes.shape()
};

Detail.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({ ...PropertyActions, ...BudgetActions }, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  budgetState: state.get('budgetState'),
  routerState: state.get('routing'),
	propertyState: state.get('propertyState'),
});

export default connect(mapStateToProps, mapDispatchToProps)(Detail);
