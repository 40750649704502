import * as ACTION from './actionTypes';

import { setAccountN, getAccountsN, getCostsN, getAccountImoblyN, putCancelRecurringPaymentN } from '../../services/accountsService';

export function addData(data) {
  return {
    type: ACTION.ADD_DATA_ACCOUNTS, data
  };
}
export function setAccount({ property, account }) {
  return {
    types: [
      ACTION.LOADING_SET_ACCOUNT,
      ACTION.RECEIVE_SET_ACCOUNT,
      ACTION.ERROR_SET_ACCOUNT,
    ],
    promise: setAccountN({ property, account }),
  };
}
export function getAccounts({ property }) {
  return {
    types: [
      ACTION.LOADING_GET_ACCOUNTS,
      ACTION.RECEIVE_GET_ACCOUNTS,
      ACTION.ERROR_GET_ACCOUNTS,
    ],
    promise: getAccountsN({ property }),
  };
}
export function getCosts({ property }) {
  return {
    types: [
      ACTION.LOADING_GET_COSTS,
      ACTION.RECEIVE_GET_COSTS,
      ACTION.ERROR_GET_COSTS,
    ],
    promise: getCostsN({ property }),
  };
}

export function getAccountImobly({ property }) {
  return {
    types: [
      ACTION.LOADING_GET_ACCOUNT_IMOBLY,
      ACTION.RECEIVE_GET_ACCOUNT_IMOBLY,
      ACTION.ERROR_GET_ACCOUNT_IMOBLY,
    ],
    promise: getAccountImoblyN(property),
  };
}

export function putCancelRecurringPayment() {
  return {
    types: [
      ACTION.LOADING_PUT_CANCEL_RECURRING_PAYMENT,
      ACTION.RECEIVE_PUT_CANCEL_RECURRING_PAYMENT,
      ACTION.ERROR_PUT_CANCEL_RECURRING_PAYMENT,
    ],
    promise: putCancelRecurringPaymentN(),
  };
}
