export const LOG_OUT = 'LOG_OUT';

export const ADD_PROPERTY_DATA = 'ADD_PROPERTY_DATA';
export const UNMOUNT_BUILD = 'UNMOUNT_BUILD';

export const LOADING_DOWNLOAD_MASIVE_TEMPLETE = 'LOADING_DOWNLOAD_MASIVE_TEMPLETE';
export const RECEIVE_DOWNLOAD_MASIVE_TEMPLETE = 'RECEIVE_DOWNLOAD_MASIVE_TEMPLETE';
export const ERROR_DOWNLOAD_MASIVE_TEMPLETE = 'ERROR_DOWNLOAD_MASIVE_TEMPLETE';

export const LOADING_UPLOAD_MASIVE_TEMPLETE = 'LOADING_UPLOAD_MASIVE_TEMPLETE';
export const RECEIVE_UPLOAD_MASIVE_TEMPLETE = 'RECEIVE_UPLOAD_MASIVE_TEMPLETE';
export const ERROR_UPLOAD_MASIVE_TEMPLETE = 'ERROR_UPLOAD_MASIVE_TEMPLETE';

export const LOADING_SEND_INVITATIONS = 'LOADING_SEND_INVITATIONS';
export const RECEIVE_SEND_INVITATIONS = 'RECEIVE_SEND_INVITATIONS';
export const ERROR_SEND_INVITATIONS = 'ERROR_SEND_INVITATIONS';

// ******************************** PROPERTY ******************************** //

export const LOADING_GET_PROPERTIES = 'LOADING_GET_PROPERTIES';
export const RECEIVE_GET_PROPERTIES = 'RECEIVE_GET_PROPERTIES';
export const ERROR_GET_PROPERTIES = 'ERROR_GET_PROPERTIES';

export const LOADING_GET_PROPERTY = 'LOADING_GET_PROPERTY';
export const RECEIVE_GET_PROPERTY = 'RECEIVE_GET_PROPERTY';
export const ERROR_GET_PROPERTY = 'ERROR_GET_PROPERTY';

export const LOADING_SET_PROPERTY = 'LOADING_SET_PROPERTY';
export const RECEIVE_SET_PROPERTY = 'RECEIVE_SET_PROPERTY';
export const ERROR_SET_PROPERTY = 'ERROR_SET_PROPERTY';

export const LOADING_PUT_PROPERTY = 'LOADING_PUT_PROPERTY';
export const RECEIVE_PUT_PROPERTY = 'RECEIVE_PUT_PROPERTY';
export const ERROR_PUT_PROPERTY = 'ERROR_PUT_PROPERTY';

export const LOADING_DEL_PROPERTY = 'LOADING_DEL_PROPERTY';
export const RECEIVE_DEL_PROPERTY = 'RECEIVE_DEL_PROPERTY';
export const ERROR_DEL_PROPERTY = 'ERROR_DEL_PROPERTY';

// ******************************** BUILDING ******************************** //

export const LOADING_GET_BUILDINGS = 'LOADING_GET_BUILDINGS';
export const RECEIVE_GET_BUILDINGS = 'RECEIVE_GET_BUILDINGS';
export const ERROR_GET_BUILDINGS = 'ERROR_GET_BUILDINGS';

export const LOADING_SET_BUILDING = 'LOADING_SET_BUILDINGS';
export const RECEIVE_SET_BUILDING = 'RECEIVE_SET_BUILDINGS';
export const ERROR_SET_BUILDING = 'ERROR_SET_BUILDINGS';

export const LOADING_GET_BUILDING = 'LOADING_GET_BUILDING';
export const RECEIVE_GET_BUILDING = 'RECEIVE_GET_BUILDING';
export const ERROR_GET_BUILDING = 'ERROR_GET_BUILDING';

export const LOADING_PUT_BUILDING = 'LOADING_PUT_BUILDING';
export const RECEIVE_PUT_BUILDING = 'RECEIVE_PUT_BUILDING';
export const ERROR_PUT_BUILDING = 'ERROR_PUT_BUILDING';

export const LOADING_DEL_BUILDING = 'LOADING_DEL_BUILDING';
export const RECEIVE_DEL_BUILDING = 'RECEIVE_DEL_BUILDING';
export const ERROR_DEL_BUILDING = 'ERROR_DEL_BUILDING';

// ******************************** DWELLINGS ******************************** //

export const LOADING_SET_PAYMENT_RESIDENTS = 'LOADING_SET_PAYMENT_RESIDENTS';
export const RECEIVE_SET_PAYMENT_RESIDENTS = 'RECEIVE_SET_PAYMENT_RESIDENTS';
export const ERROR_SET_PAYMENT_RESIDENTS = 'ERROR_SET_PAYMENT_RESIDENTS';

export const LOADING_SET_DWELLINGS = 'LOADING_SET_DWELLINGS';
export const RECEIVE_SET_DWELLINGS = 'RECEIVE_SET_DWELLINGS';
export const ERROR_SET_DWELLINGS = 'ERROR_SET_DWELLINGS';

export const LOADING_GET_DWELLINGS = 'LOADING_GET_DWELLINGS';
export const RECEIVE_GET_DWELLINGS = 'RECEIVE_GET_DWELLINGS';
export const ERROR_GET_DWELLINGS = 'ERROR_GET_DWELLINGS';

export const LOADING_PUT_DWELLING = 'LOADING_PUT_DWELLING';
export const RECEIVE_PUT_DWELLING = 'RECEIVE_PUT_DWELLING';
export const ERROR_PUT_DWELLING = 'ERROR_PUT_DWELLING';

export const LOADING_DELETE_DWELLING = 'LOADING_DELETE_DWELLING';
export const RECEIVE_DELETE_DWELLING = 'RECEIVE_DELETE_DWELLING';
export const ERROR_DELETE_DWELLING = 'ERROR_DELETE_DWELLING';

// ******************************** AMENITY ******************************** //

export const LOADING_GET_CATALOG_AMENITIES = 'LOADING_GET_CATALOG_AMENITIES';
export const RECEIVE_GET_CATALOG_AMENITIES = 'RECEIVE_GET_CATALOG_AMENITIES';
export const ERROR_GET_CATALOG_AMENITIES = 'ERROR_GET_CATALOG_AMENITIES';

export const LOADING_GET_AMENITIES = 'LOADING_GET_AMENITIES';
export const RECEIVE_GET_AMENITIES = 'RECEIVE_GET_AMENITIES';
export const ERROR_GET_AMENITIES = 'ERROR_GET_AMENITIES';

export const LOADING_DELETE_AMENITY = 'LOADING_DELETE_AMENITY';
export const RECEIVE_DELETE_AMENITY = 'RECEIVE_DELETE_AMENITY';
export const ERROR_DELETE_AMENITY = 'ERROR_DELETE_AMENITY';

export const LOADING_PUT_AMENITIES_BIND = 'LOADING_PUT_AMENITIES_BIND';
export const RECEIVE_PUT_AMENITIES_BIND = 'RECEIVE_PUT_AMENITIES_BIND';
export const ERROR_PUT_AMENITIES_BIND = 'ERROR_PUT_AMENITIES_BIND';

export const LOADING_GET_AMENITIES_BIND = 'LOADING_GET_AMENITIES_BIND';
export const RECEIVE_GET_AMENITIES_BIND = 'RECEIVE_GET_AMENITIES_BIND';
export const ERROR_GET_AMENITIES_BIND = 'ERROR_GET_AMENITIES_BIND';

export const LOADING_SET_AMENITY = 'LOADING_SET_AMENITY';
export const RECEIVE_SET_AMENITY = 'RECEIVE_SET_AMENITY';
export const ERROR_SET_AMENITY = 'ERROR_SET_AMENITY';

export const LOADING_GET_AMENITY = 'LOADING_GET_AMENITY';
export const RECEIVE_GET_AMENITY = 'RECEIVE_GET_AMENITY';
export const ERROR_GET_AMENITY = 'ERROR_GET_AMENITY';

export const LOADING_PUT_AMENITY = 'LOADING_PUT_AMENITY';
export const RECEIVE_PUT_AMENITY = 'RECEIVE_PUT_AMENITY';
export const ERROR_PUT_AMENITY = 'ERROR_PUT_AMENITY';

export const LOADING_GET_REGULATIONS = 'LOADING_GET_REGULATIONS';
export const RECEIVE_GET_REGULATIONS = 'RECEIVE_GET_REGULATIONS';
export const ERROR_GET_REGULATIONS = 'ERROR_GET_REGULATIONS';

export const LOADING_GET_FOUNDS = 'LOADING_GET_FOUNDS';
export const RECEIVE_GET_FOUNDS = 'RECEIVE_GET_FOUNDS';
export const ERROR_GET_FOUNDS = 'ERROR_GET_FOUNDS';

export const LOADING_CREATE_AN_AMENITY = 'LOADING_CREATE_AN_AMENITY';
export const RECEIVE_CREATE_AN_AMENITY = 'RECEIVE_CREATE_AN_AMENITY';
export const ERROR_CREATE_AN_AMENITY = 'ERROR_CREATE_AN_AMENITY';

export const LOADING_CREATE_AMENITY_BULK = 'LOADING_CREATE_AMENITY_BULK';
export const RECEIVE_CREATE_AMENITY_BULK = 'RECEIVE_CREATE_AMENITY_BULK';
export const ERROR_CREATE_AMENITY_BULK = 'ERROR_CREATE_AMENITY_BULK';

export const LOADING_PUT_AMENITY_BULK = 'LOADING_PUT_AMENITY_BULK';
export const RECEIVE_PUT_AMENITY_BULK = 'RECEIVE_PUT_AMENITY_BULK';
export const ERROR_PUT_AMENITY_BULK = 'ERROR_PUT_AMENITY_BULK';

export const LOADING_GET_PAGINATED_BOOKINGS = 'LOADING_GET_PAGINATED_BOOKINGS';
export const RECEIVE_GET_PAGINATED_BOOKINGS = 'RECEIVE_GET_PAGINATED_BOOKINGS';
export const ERROR_GET_PAGINATED_BOOKINGS = 'ERROR_GET_PAGINATED_BOOKINGS';

export const LOADING_GET_BOOKINGS = 'LOADING_GET_BOOKINGS';
export const RECEIVE_GET_BOOKINGS = 'RECEIVE_GET_BOOKINGS';
export const ERROR_GET_BOOKINGS = 'ERROR_GET_BOOKINGS';

export const LOADING_SET_SURCHARGE = 'LOADING_SET_SURCHARGE';
export const RECEIVE_SET_SURCHARGE = 'RECEIVE_SET_SURCHARGE';
export const ERROR_SET_SURCHARGE = 'ERROR_SET_SURCHARGE';

export const LOADING_SET_DISCOUNT = 'LOADING_SET_DISCOUNT';
export const RECEIVE_SET_DISCOUNT = 'RECEIVE_SET_DISCOUNT';
export const ERROR_SET_DISCOUNT = 'ERROR_SET_DISCOUNT';