import { createStore, applyMiddleware, compose } from 'redux';
import { thunk } from 'redux-thunk';

import middleware from './middlewares/promise';
import reducer from './reducers';

const env = process.env.NODE_ENV;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = (initialState = null) => {

  if (env === 'production') {
    if (initialState) {
      return createStore(reducer, initialState, applyMiddleware(thunk, middleware));
    }
    return createStore(reducer, applyMiddleware(thunk, middleware));
  }
  if (initialState) {
    return createStore(reducer, initialState, composeEnhancers(applyMiddleware(thunk, middleware)));
  }
  return createStore(reducer, composeEnhancers(applyMiddleware(thunk, middleware)));
};

export default store;
