import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as Actions from '../regulationActions';
import Button from 'components/Button';
import Dropzone from '../Dropzone/Dropzone';
import { browserHistory } from 'react-router';
import './Regulation.scss';
class Regulation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false
    };

    this.onFilesAdded = this.onFilesAdded.bind(this);
  }

  componentDidMount() {
    const { idProperty, tipe} = this.props
    if (tipe) {
      this.props.getRegulations({ idProperty, tipe });
    }
  }
  
  onFilesAdded(files) {
    this.onHandleChange(files[0]);
    this.setState(prevState => ({
      files: prevState.files.concat(files)
    }));
  }

  onChangeFile = (evt) => {
    const file = evt.target.files[0];
    const { idProperty, tipe } = this.props;
    let data = new FormData();
    data.append('name', file.name);
    data.append('bucket', 'api-images');
    data.append('type', 'regulation');
    data.append('file', file);
    this.props.uploadRegulation({ idProperty, data }).then(() => {
      this.props.getRegulations({ idProperty, tipe });
    });
  }

  onHandleChange = (file) => {
    if (file) {
      const { idProperty, tipe } = this.props;
      let data = new FormData();
      data.append('name', file.name);
      data.append('bucket', 'api-images');
      data.append('type', 'regulation');
      data.append('file', file);
      this.props.uploadRegulation({ idProperty, data }).then(() => {
        this.props.getRegulations({ idProperty, tipe });
      });
    }
  }

  render() {
    const { regulations } = this.props.propertyState;
    if (regulations.result !== null) {
      if (regulations.result.length > 0) {
        return (
          <div className='RegulationContainer animate__animated animate__fadeIn'>
            <input
              style={{display: 'none' }}
              id="contained-button-file"
              type="file"
              onChange={this.onChangeFile}
            />
            <label htmlFor="contained-button-file">
              <Button variant="outlined" color='secondary' component="span">
                Crear reglamento
              </Button>
            </label>
            <div className='RegulationTable'>
              <div className='RegulationTable__header'>
                <div className='RegulationTable__header__tab'>
                  <span>Nombre de reglamento</span>
                </div>
                <div className='RegulationTable__header__tab date'>
                  <span>Fecha de creación</span>
                </div>
                <div className='RegulationTable__header__tab link'>
                  <span>Ver detalle</span>
                </div>
              </div>
              {regulations.result.map((regulation, index) => (
                <div key={`RegulationContainer${regulation}${index}`} className='RegulationTable__item'>
                  <div className='RegulationTable__item__section'>
                    <span>{regulation.name}</span>
                  </div>
                  <div className='RegulationTable__item__section date'>
                    <span>{regulation.created_at}</span>
                  </div>
                  <div className='RegulationTable__item__section link'>
                    <a href={regulation.url} target='_blank'>
                      <span>Ver reglamento</span>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      } else {
        return (
          <div className='Regulation'>
            <div className='Regulation_container'>
              <div className='Regulation__title'>Añade tus reglamentos</div>
              <div className='Regulation__text'>Comparte un archivo Word o PDF con tus vecinos</div>
              <Dropzone
                onFilesAdded={this.onFilesAdded}
                disabled={this.state.uploading || this.state.successfullUploaded}
              />
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className='Regulation'>
          <div className='Regulation_container'>
            <div className='Regulation__title'>Añade tus reglamentos</div>
            <div className='Regulation__text'>Comparte un archivo Word o PDF con tus vecinos</div>
            <Dropzone
              onFilesAdded={this.onFilesAdded}
              disabled={this.state.uploading || this.state.successfullUploaded}
            />
          </div>
        </div>
      );
    }
    
  }
}

Regulation.propTypes = {
  regulations: PropTypes.object,
  idProperty: PropTypes.string, 
  tipe: PropTypes.string
}

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  propertyState: state.get('propertyState'),
  profileState: state.get("profileState"),
  loading: state.get('propertyState').loading
});

export default connect(mapStateToProps, mapDispatchToProps)(Regulation);
