import React from 'react';
import PropTypes from 'prop-types';
import './Card.scss';

export const Card = props => (
  <div {...props} className={`SimpleCard ${ props.className }`}>
    {props.children}
  </div>
);

Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default Card;
