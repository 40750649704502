export const roleProf = (resident_role) => {
  let role;
  resident_role.forEach((element) => {
    if (element == 1) {
      role = 'VECINO';
    }
    if (element == 2) {
      role = 'ADMINISTRADOR';
    }
    if (element == 3) {
      role = 'PRESIDENTE';
    }
    if (element == 4) {
      role = 'SUB_ADMINISTRADOR';
    }
    if (element == 5) {
      role = 'SUPER_ADMINISTRADOR';
    }
  });
  return role;
};
