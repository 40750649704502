import React, { Component } from 'react';
import { ReactSVG } from 'react-svg';

import { showIconAmenity } from 'constants/amenityTypes';
import './TabAmenityItem.scss';

class TabAmenityItem extends Component {
  render() {
    const {
      active, onChange, iconType, title, subtitle 
    } = this.props;
    return (
      <div className='TabAmenityItem' onClick={onChange}>
        <div className='TabAmenityItem__content'>
          <div className={active ? 'TabAmenityItem__image' : 'TabAmenityItem__image TabAmenityItem__image__off'}>
            <ReactSVG src={ showIconAmenity(iconType).img} alt='Section' />
          </div>
          <div className='TabAmenityItem__info'>
            <div className={
              active
              ? 'TabAmenityItem__info__title TabAmenityItem__info__title__on'
              : 'TabAmenityItem__info__title'}>
              {title}
            </div>
            <div className={
              active
              ? 'TabAmenityItem__info__amount TabAmenityItem__info__amount__on'
              : 'TabAmenityItem__info__amount'}>
              {subtitle}
            </div>
          </div>
        </div>
        <div className={
          active
          ? 'TabAmenityItem__selector TabAmenityItem__selector__on'
          : 'TabAmenityItem__selector'}
        />
      </div>
    );
  }
}

export default TabAmenityItem;
