import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from './actions';
import TrialEditor from './TrialEditor';
import Table from 'components/Table';
import Card from 'components/Card';
import SubscriptionStatus from 'components/SubscriptionStatus';


import './Subscriptions.scss';

export const Subscriptions = props => {

  const { loading, admins } = props.subscriptionsState;
  
  useEffect(() => {
    props.getAdmins();
  }, [ ]);
  
  return (
    <div className='Subscriptions'>
      <h2>Súper Administrador</h2>
      <Card className='table-wrapper'>
        <div className='row status-types'>
          <SubscriptionStatus labeled value='inactive'/>
          <SubscriptionStatus labeled value='cancelled'/>
          <SubscriptionStatus labeled value='trial'/>
          <SubscriptionStatus labeled value='valid'/>
        </div>
        <Table
          columns={[
            {
              headerName: '',
              component: SubscriptionStatus,
              props: {
                value: 'status'
              },
              cellProps: {
                align: 'center'
              }
            },
            {
              headerName: 'Email',
              field: 'email',
              placeholder: 'No indicado'
            },
            {
              headerName: 'Nombre',
              field: 'name',
              placeholder: 'No indicado'
            },
            {
              headerName: 'Teléfono',
              field: 'phone',
              placeholder: 'No indicado'
            },
            {
              headerName: 'Casas/Deptos',
              field: 'count_dwellings'
            },
            {
              headerName: 'Fecha alta',
              field: 'create_date'
            },
            {
              headerName: 'P. de prueba',
              component: TrialEditor,
              props: {
                value: 'trial_weeks',
                adminId: 'admin_id'
              }
            },
            {
              headerName: 'Fin prueba',
              field: 'trial_end_date'
            },
            {
              headerName: 'Inicio suscripción',
              field: 'subscription_date'
            },
            {
              headerName: 'Renovación',
              field: 'renovation_date'
            }
          ]}
          tableProps={{
            stickyHeader: true
          }}
          loading={ loading.GET_ADMINS?.status }
          rows={ admins }
        />
      </Card>
    </div>
  );
}

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  subscriptionsState: state.get('subscriptionsState')
});

export default connect(mapStateToProps, mapDispatchToProps) (Subscriptions);