import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';

import Card from 'components/Card';
import Button from 'components/Button';
import Dialog from 'components/Dialogs';

import Tittle from '../components/Title/Title';
import ModalHomePage from './ModalHomePage';

import Form from './Form/Form';
import Table from './Table/Table';

import * as Actions from '../../Property/propertyActions';
import './AddHomePage.scss';
import { FormattedMessage } from 'react-intl';

export class AddHomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: {},
      showModal: false,
      showFocus: false,
      dialog: {},
      blockButton: false,
      error: {},
    };
  }

  componentDidMount() {
    this.handleDetermineModal();
    const { homeID } = this.props.params;
    if (homeID) { this.handleGetData(homeID); }
  }

  componentWillUnmount() {
    this.props.unmountBuild();
  }

  handleGetData = (homeID) => {
    const { property } = this.props.params;
    this.props.getBuilding({
      idProperty: property,
      idBuilding: homeID
    });
    this.props.getDwellings({
      idProperty: property,
      idBuilding: homeID
    });
  }

  handleDetermineModal = () => {
    const { showModal } = this.state;
    const { homeID } = this.props.params;
    const { result } = this.props.propertyState.buildings;
    const homeExist = result && result.length > 0 && result.filter(item => item.type_id === 2).length > 0;
    if (homeExist && !homeID) {
      this.setState({ showModal: true });
      return;
    }
    if (!homeID && !showModal) {
      this.setState({ showFocus: true });
      this.handleOffFocus();
      return;
    }
  }

  handleCloseModal = () => {
    this.setState({ showModal: false, showFocus: true });
    this.handleOffFocus()
  }

  handleOffFocus = () => {
    setTimeout(() => {
      this.setState({ showFocus: false });
    }, 2000);
  }

  handleGetDwellings = () => {
    const { data } = this.props.propertyState.build;
    const dwellings = Object.getOwnPropertyNames(data).length;
    let dwelling_number = 0;
    for (let i = 0; i < dwellings; i += 1) {
      dwelling_number += data[i].content.length;
    }
    return dwelling_number;
  }

  handleSetDwellings = () => {
    const { property } = this.props.params;
    const { dwelling_number, date_start_admin, id } = this.props.propertyState.build;
    const dwellingsData = [{
      dwelling_id: 0,
      dwelling_name: `Casa ${dwelling_number + 1}`,
      dwelling_number: `${dwelling_number + 1}`,
      dwelling_admin_date: date_start_admin,
      dwelling_floor: `0`,
      resident_id: 0,
      resident_name: '',
      resident_last_name: '',
      resident_sec_last_name: '',
      resident_email: '',
      resident_phone: '',
    }];
    this.props.setDwellings({
      idProperty: property,
      idBuilding: id,
      dwellingsData
    }).then(() => {
      this.props.getBuilding({
        idProperty: property,
        idBuilding: id
      });
      this.props.getDwellings({
        idProperty: property,
        idBuilding: id
      });
    });
  }

  handleDeleteDwellings = (idDwelling, idResident, callback) => () => {
    this.setState({
      dialog: {
        title: 'Eliminación de vivienda.',
        message: 'Estas seguro de eliminar esta casa/departamento.',
        dwelling_id: idDwelling,
        resident_id: idResident
      }
    });
    callback();
  }

  handleCloseDialog = () => {
    this.setState({
      dialog: {}
    });
  }

  handleSet = (buildingData) => {
    const { property } = this.props.params;
    this.props.setBuilding({
      idProperty: property,
      buildingData
    }).then(() => {
      const idBuilding = this.props.propertyState.build.idBuilding;
      const dwellingsData = [];
      const { data } = this.props.propertyState.build;
      const dwellings = Object.getOwnPropertyNames(data).length;
      for (let i = 0; i < dwellings; i += 1) {
        dwellingsData.push(...data[i].content);
      }

      this.props.setDwellings({
        idProperty: property,
        idBuilding,
        dwellingsData
      }).then(() => {
        this.goBack();
      });
    });
  }

  handlePut = (buildingData) => {
    const { property, homeID } = this.props.params;
    this.props.putBuilding({
      idProperty: property,
      idBuilding: homeID,
      buildingData
    }).then(() => {
      const { edited } = this.props.propertyState.build;
      if (edited) {
        for (const i in edited) {
          edited[i].forEach((key) => {
            const dwellingData = this.props.propertyState.build.data[i].content[key];
            this.props.putDwelling({
              idProperty: property,
              idBuilding: homeID,
              dwellingData
            });
          });
        }
      }
      this.goBack();
    });
  }

  handleDel = (idDwelling, idResident) => () => {
    const { property } = this.props.params;
    const { id } = this.props.propertyState.build;
    this.props.delDwellings({
      idProperty: property,
      idBuilding: id,
      idDwelling,
      idResident
    }).then(() => {
      this.props.getBuilding({
        idProperty: property,
        idBuilding: id
      });
      this.props.getDwellings({
        idProperty: property,
        idBuilding: id
      });
      this.setState({ dialog: {} });
    });
  }

  onSubmit = () => {
    const { name, date_start_admin, data, balance, error } = this.props.propertyState.build;
    const { homeID } = this.props.params;
    if (name === '') {
      this.setState({ error: { name:  <FormattedMessage id="Message.Home.Input.Name"/>  } })
      return
    }
    this.blockButton = true;
    if (error && error.length > 0) {
      this.setState({
        errorMessage: {
          open: true,
          title: 'Datos erroneos',
          message: 'Algunos campos tiene errores. Ingresa información correcta antes de continuar.'
        }
      });
      return null;
    }

    const buildingData = {
      name: name,
      balance: balance,
      date_start_admin: date_start_admin,
      floors: Object.getOwnPropertyNames(data).length,
      dep_in_lobby: false,
      dwelling_number: this.handleGetDwellings(),
      type_id: 2
    }
    if (homeID) {
      this.handlePut(buildingData);
      return null;
    }
    this.handleSet(buildingData);
    return null;
  }

  goBack = () => {
    const { id, property } = this.props.params;
    this.context.router.push(`/Wizard/${id}/property/${property}`);
  }

  goTo = idHome => () => {
    const { id, property } = this.props.params;
    this.handleGetData(idHome);
    this.setState({ showModal: false });
    this.context.router.push(`/Wizard/${id}/property/${property}/home/${idHome}`);
  }

  setError = (error) => {
    this.setState({ error: error })
  }

  render() {
    const { homeID } = this.props.params;
    const { showFocus, showModal, dialog } = this.state;
    let { data, floors, error } = this.props.propertyState.build;

    return (
      <div className='AddHomePage'>
        <Dialog
          title={dialog.title}
          message={dialog.message}
          open={!!dialog.dwelling_id}
          onCancel={this.handleCloseDialog}
          textCancel='Cancelar'
          onSuccess={this.handleDel(dialog.dwelling_id, dialog.resident_id)}
          textSuccess='Continuar'
        />
        <ModalHomePage
          show={showModal}
          onClose={this.handleCloseModal}
          goTo={this.goTo}
        />
        <Tittle
          title='Agregar casas'
          goBack={this.goBack}
          hideIcon={true}
        />
        <div className='AddHomePage__label'>
          Escribe el número de casas y registra los datos de cada residente por vivienda
        </div>
        <div className='AddHomePage__body'>
          <div className='AddHomePage__body__add' style={{ width: '275px' }}>
            <Card>
              <Form
                showFocus={showFocus}
                editable={!!homeID}
                error={this.state.error}
                setError={this.setError}
              />
            </Card>
          </div>
          <div className='AddHomePage__body__pane'>
            <Card>
              <div className='AddHomePage__body__pane__content'>
                <Table
                  content={data && data[0] ? data[0].content : []}
                  error={error}
                  showFocus={showFocus}
                  editable={!!homeID}
                  onDelete={this.handleDeleteDwellings}
                />
              </div>
            </Card>
            <div className='AddHomePage__options'>
              {floors === 0 ?
                <Button variant='contained' color='secondary' disabled>¡Listo!</Button>
                :
                <Button variant='contained' color='secondary' disabled={this.blockButton} onClick={this.onSubmit}>¡Listo!</Button>
              }
              {
                homeID
                  ? <div className='AddHomePage__add'>
                    <Tooltip title='Agregar casas al conjunto' placement="top-start">
                      <Fab color="secondary" onClick={this.handleSetDwellings}>
                        <AddIcon />
                      </Fab>
                    </Tooltip>
                  </div>
                  : null
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AddHomePage.contextTypes = {
  router: PropTypes.shape()
};

AddHomePage.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  propertyState: state.get('propertyState')
});

export default connect(mapStateToProps, mapDispatchToProps)(AddHomePage);
