import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as propertyActions from '../../Property/propertyActions';
import Agenda from "components/Agenda";
import EventDetail from "../EventDetail";
import RejectModal from '../RejectModal';
import PaymentModal from '../PaymentModal';
import ApproveModal from '../ApproveModal';
import CancellationModal from '../CancellationModal';
import moment from 'moment';

export const WeekCalendar = props => {

  const [ selectedRequest, setSelectedRequest ] = useState();

  const [ rejectModalVisible, setRejectModalVisibility ] = useState();

  const [ paymentModalVisible, setPaymentModalVisiblity ] = useState();

  const [ cancellationModalVisible, setCancellationModalVisiblity ] = useState();

  const [ approveModalVisible, setApproveModalVisibility ] = useState();

  const mapEvents = () => (props.requests || []).map(item => (
    {
      ...item,
      created: moment(item.created_date),
      start: moment(item.start_date),
      end: moment(item.end_date),
      value: item.booking_id,
      title: item.title,
      status: item.status,
      selected: props.selectedRequest?.booking_id === item.booking_id
    }
  ));

  const showRejectModal = request => {
    setSelectedRequest(request);
    setRejectModalVisibility(true);
  }

  const showApproveModal = request => {
    setSelectedRequest(request);
    setApproveModalVisibility(true);
  }

  const showPaymentModal = request => {
    setSelectedRequest(request);
    setPaymentModalVisiblity(true);
  }

  const showCancellationModal = request => {
    setSelectedRequest(request);
    setCancellationModalVisiblity(true);
  }
  
  const refresh = () => {
    props.refresh(moment().valueOf());
  }
  
  return (
    <div className='WeekCalendar'>
      <Agenda
          { ...props }
          onReject={request => showRejectModal(request)}
          onApprove={request => showApproveModal(request)}
          onRegisterPayment={request => showPaymentModal(request)}
          onCancel={request => showCancellationModal(request)}
          detailComponent={ EventDetail }
          selectedIntervals={ mapEvents() }
      />
      <RejectModal
        open={ rejectModalVisible }
        refresh={ refresh }
        request={ selectedRequest }
        onClose={_ => setRejectModalVisibility(false)}
        { ...props.rejectProps }
      />
      <PaymentModal
        open={ paymentModalVisible }
        refresh={ refresh }
        request={ selectedRequest }
        onClose={_ => setPaymentModalVisiblity(false)}
      />
      <ApproveModal
        open={ approveModalVisible }
        refresh={ refresh }
        request={ selectedRequest }
        onClose={_ => setApproveModalVisibility(false)}
      />
      <CancellationModal
        open={ cancellationModalVisible }
        refresh={ refresh }
        request={ selectedRequest }
        onClose={_ => setCancellationModalVisiblity(false)}
      />
    </div>
  );
}

const mapDispatchToProps = dispatch => bindActionCreators(propertyActions, dispatch);
const mapStateToProps = state => ({
    propertyState: state.get("propertyState"),
});

export default connect(mapStateToProps, mapDispatchToProps) (WeekCalendar);