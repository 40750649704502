import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popover from 'components/Popover';
import Frecuency from './Frecuency';

class ConceptPopover extends Component {
  showPop = (type) => {
    const { data, closePopover,isService } = this.props;
    const json = {
      frecuency: <Frecuency {...data} closePopover={closePopover} isService={isService}/>,
    };
    return json[type] || null;
  }

  render() {
    const { anchorEl, closePopover, type, top, left} = this.props;
    return (
      <Popover
        anchorEl={anchorEl}
        top={top}
        left={left}
        close={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        noButtonClose
      >
        {this.showPop(type)}
      </Popover>
    );
  }
}

ConceptPopover.propTypes = {
  closePopover: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default ConceptPopover;
