import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import * as Actions from '../../../Budget/budgetActions';

import ConceptItem from './ConceptItem/ConceptItem';

import { toMoneyFormat } from '../../../../utils/format';
import './Concepts.scss';
export class BudgetConcept extends Component {

  render() {
    const { asset_type, concepts, params, is_shared } = this.props;
    if(is_shared){
      return concepts.map((item)=>{
        return (
          <div className='BudgetConceptInfo'>
            ${toMoneyFormat(item.amount)}
          </div>
        );
      });
    }
    
    return (
      <div className='BudgetConcept'>
        <div className='BudgetConcept__header'>
          <div className='BudgetConcept__header__title'><FormattedMessage id="Budget.Concept.label.concept" /></div>
          <div className='BudgetConcept__header__title'><FormattedMessage id="Budget.Concept.label.provider" /></div>
          <div className='BudgetConcept__header__title'><FormattedMessage id="Budget.Concept.label.periodicity" /></div>
          <div className='BudgetConcept__header__title'><FormattedMessage id="Budget.Concept.label.nextPayment" /></div>
          <div className='BudgetConcept__header__title'><FormattedMessage id="Budget.Concept.label.quantity" /></div>
          <div className='BudgetConcept__header__remember'><FormattedMessage id="Budget.Concept.label.rememberPayment" /></div>
          <div className='BudgetConcept__header__delete'><FormattedMessage id="Budget.Concept.label.delete" /></div>
        </div>
        {
          !!concepts.length && (
          concepts.map((item, index) => (
              <ConceptItem
                key={`ConceptItem${index}`}
                concept={item}
                asset_type={asset_type}
                onDelete={this.deleteConcept}
                params={params}
              />
          )))
        }
      </div>
    );
  }
}

BudgetConcept.defaultProps = {
  concepts: [],
};

BudgetConcept.contextTypes = { 
  router: PropTypes.shape()
};

BudgetConcept.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  budgetState: state.get('budgetState')
});

export default connect(mapStateToProps, mapDispatchToProps)(BudgetConcept);
