import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Text from 'components/Text';

import * as Actions from '../../../Property/propertyActions';
import './Table.scss';
import { EMAIL_REGEX, PHONE_NUMBER_REGEX } from 'constants/globalRegex';

export class Table extends Component {
  constructor(){
    super();
    this.state = {
      anchor: {}
    };
  }


  handleOpenOptions = (key) => event => {
    const { anchor } = this.state;
    anchor[key] = event.currentTarget;
    this.setState({ anchor });
  }

  componentDidMount() {
    setTimeout(() => { this.bottomPage.scrollIntoView({ behavior: 'smooth'}) }, 1500);
  }

  handleCloseOptions = () => {
    this.setState({ anchor: {} });
  }


  handleTreatmentData = (params, event) => {
    if (params.indexX === 'dwelling_name'){
      event.target.value = event.target.value.substr(0,11);
    }
    return event;
  }


  handleValidatorErrors = (indexX, indexY, value) => {
    const { error } = this.props.propertyState.build;

    const letter = `${indexX}${indexY}`;
    const index = error.indexOf(letter);
    const exist = error.includes(letter);

    switch(indexX){
      case 'resident_name': case 'resident_last_name': case 'resident_sec_last_name':
        if(!/^[a-zA-Z\s\u00C0-\u017F\_\-\,\.\']*$/i.test(value)){
          if(!exist){
            error.push(letter)
          }
        }else{
          if(exist) {
            error.splice(index,1)
          }
        }
      break;
      case 'resident_phone':
        if (!PHONE_NUMBER_REGEX.test(value) && value !== ''){
          if(!exist) {
            error.push(letter);
          }
        }else{
          if(exist){
            error.splice(index,1)
          }
        }


      break;
      case 'resident_email':
        if (!EMAIL_REGEX.test(value) && value !== ''){
          if(!exist) {
            error.push(letter);
          }
        }else{
          if(exist){
            error.splice(index,1)
          }
        }
      break;
      default:
      break;
    }

    return error;
  }


  handleValidatorEdition = (indexY) => {
    const edited = this.props.propertyState.build.edited;
    const newContent = this.props.propertyState.build.data[0].content;
    if( newContent[indexY].dwelling_id > 0 ){
      if(!edited[0]){ edited[0] = []; }
      if(edited[0].indexOf(indexY) === -1 ){
        edited[0].push(indexY);
      }
    }
    return edited;
  }

  onChange = ( params, data ) => {
    const event = this.handleTreatmentData(params, data);
    const { build } = this.props.propertyState;
    const newContent = build.data[0].content;
    let edited = this.handleValidatorEdition(
      params.indexY,
    );
    const error = this.handleValidatorErrors(
      params.indexX,
      params.indexY,
      event.target.value
    );
    newContent[params.indexY][params.indexX] = event.target.value;
    this.props.addPropertyData({
      build: {
        ...build,
        data: {
          ...build.data,
          0: {
            ...build.data[0],
            content: newContent
          }
        },
        edited,
        error
      }
    });
  }


  render() {
    const { anchor } = this.state;
    const { content, showFocus, editable, error, onDelete } = this.props;
    return (
      <div className='Table'>
        <div className='Table__header'>
          <div className='Table__header__section1'><Text variant='body2' id='Home.table.section1' /></div>
          <div className='Table__header__section2'><Text variant='body2' id='Home.table.section2' /></div>
        </div>
        <div className='Table__headers'>
          <div>
          Número
          </div>
          <div>Fecha de <br/>de ocupación</div>
          <div>Nombre</div>
          <div>Apellido paterno</div>
          <div>Apellido materno</div>
          <div>Correo</div>
          <div>Teléfono</div>
        </div>
        <div className='Table__body'>
          {content.map((items, indexY) => {
              const determine = () => {
                const focus = showFocus ? `Table__row Table__rowOn` : `Table__row`;
                const select = !!anchor[indexY] ? 'Table__row__selected' : null;
                return `${focus} ${select}`;
              };
              return (
                <div
                  key={`row${indexY}`}
                  className={determine()}
                >
                  <div>
                    <input
                      type='text'
                      className='Table__input'
                      onChange={event => this.onChange({ indexX: 'dwelling_number', indexY }, event)}
                      value={items.dwelling_number}
                    />
                  </div>
                  <div>
                    <input
                      type='date'
                      className='Table__input'
                      onChange={event => this.onChange({ indexX: 'dwelling_admin_date', indexY }, event)}
                      value={items.dwelling_admin_date}
                    />
                  </div>
                  <div style={{ borderLeftWidth: 1, borderLeftStyle: 'solid', borderLeftColor: '#dcd8d8' }}>
                    <input
                      type='text'
                      placeholder='Nombre'
                      className={error && error.includes(`resident_name${indexY}`) ? 'Table__input__error' : 'Table__input'}
                      onChange={event => this.onChange({ indexX: 'resident_name', indexY }, event)}
                      value={items.resident_name}
                      maxLength="35"
                    />
                  </div>
                  <div>
                    <input
                      type='text'
                      placeholder='Apellido Paterno'
                      className={error && error.includes(`resident_last_name${indexY}`) ? 'Table__input__error' : 'Table__input'}
                      onChange={event => this.onChange({ indexX: 'resident_last_name', indexY }, event)}
                      value={items.resident_last_name}
                      maxLength="35"
                    />
                  </div>
                  <div>
                    <input
                      type='text'
                      placeholder='Apellido Materno'
                      className={error && error.includes(`resident_sec_last_name${indexY}`) ? 'Table__input__error' : 'Table__input'}
                      onChange={event => this.onChange({ indexX: 'resident_sec_last_name', indexY }, event)}
                      value={items.resident_sec_last_name}
                      maxLength="35"
                    />
                  </div>
                  <div>
                    <input
                      type='email'
                      placeholder='Correo'
                      className={error && error.includes(`resident_email${indexY}`) ? 'Table__input__error' : 'Table__input'}
                      onChange={event => this.onChange({ indexX: 'resident_email', indexY }, event)}
                      value={items.resident_email}
                      maxLength="35"
                    />
                  </div>
                  <div>
                    <input
                      type='tel'
                      placeholder='Teléfono'
                      className={error && error.includes(`resident_phone${indexY}`) ? 'Table__input__error' : 'Table__input'}
                      onChange={event => this.onChange({ indexX: 'resident_phone', indexY }, event)}
                      value={items.resident_phone}
                      maxLength="13"
                    />
                    <div style={{ float: 'left', clear: 'both' }} ref={(el) => { this.bottomPage = el }}></div>
                    {
                      editable
                      ? <IconButton onClick={this.handleOpenOptions(indexY)}>
                          <MoreVertIcon />
                        </IconButton>
                      : null
                    }
                    <Menu
                      anchorEl={anchor[indexY]}
                      open={!!anchor[indexY]}
                      onClose={this.handleCloseOptions}
                      PaperProps={{ style: { width: 200 } }}
                    >
                      <MenuItem onClick={onDelete(items.dwelling_id, items.resident_id, this.handleCloseOptions)}>Eliminar</MenuItem>
                    </Menu>
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
    );
  }
}

Table.contextTypes = {
  router: PropTypes.shape()
};

Table.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  propertyState: state.get('propertyState')
});

export default connect(mapStateToProps, mapDispatchToProps)(Table);
