import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import * as Actions from '../../Budget/budgetActions';
import * as propertyActions from '../../Property/propertyActions';

import Dialogs from 'components/Dialogs';
import Card from 'components/Card';

import QuotaDetail from './QuotaDetail/QuotaDetail';

import Title from '../components/Title/Title';

import './Quota.scss';

export class Quota extends Component {
  constructor() {
    super();
    this.state = {
      showDialog: false,
      dialogTitle: '',
      dialogMessage: '',
      dialogTextSuccess: '',
      dialogTextCancel: '',
      dialogMessageB: '',
    };
    this.scrollRef = React.createRef()
  }



  goBack = () => {
    const { property, id } = this.props.params;
    this.context.router.push(`/Wizard/${Number(id) - 10}/property/${property}`);
  }

  goTo = () => {
    const { property, id } = this.props.params;
    this.context.router.push(`/Wizard/${Number(id) + 10}/property/${property}/account/init`);
  }

  handleGetTotalQuota = () => {
    const { dwellings } = this.props.budgetState.quotas.result;
    let amount = 0;
    if( dwellings ){
      dwellings.forEach(item => {
        amount += item.fee_total;
      });
    }
    return amount;
  }

  handleValidQuotas = () => {
    const { dwellings, id } = this.props.budgetState.quotas.result;
    const { total_elements, result } = this.props.propertyState.buildings;
    const { property } = this.props.params;
    if (result){
      let element = result[total_elements-1]
      for (let i = 0; i < total_elements; i++) {
        const e = result[i];
        if (e.id === id){
          if(e.id !== element.id){
            if (this.validDwelligs(dwellings)){
              let r =result[i+1]
            this.props.addData({
              quotaSelected: r.id,
            }).then(() => {
              this.props.getBudgetsAndFees({ bid: r.id })
              this.props.getQuotaDetail({
                idBuilding: r.id
              });
            }).then(() => {
              this.props.getFoundsN({
                pid: property,
                bid: r.id
              });
            });
            return
            }
            return 'show';
          }else{
            if (this.validDwelligs(dwellings)){
              return 'finish';
            }
            return 'show';
          }

        }
      }
    }
  }

  validDwelligs = (dwellings) =>{
    let valid = true;
    if ( dwellings ){
      dwellings.forEach(item => {if (!item.is_valid) {valid =  false;return}});
    }
    return valid
  }

  handleVerifyQuotas = () => {
    const necessary = this.props.budgetState.quotas.result.monthly_fee;
    const reached = this.handleGetTotalQuota();
    const valid = this.handleValidQuotas();
    switch (valid) {
      case 'finish':
        if (reached < necessary) {
          this.setState({
            showDialog: true,
            dialogTitle: 'Cuotas insuficientes',
            dialogMessage: 'Las cuotas indicadas no son suficientes para cubrir tu presupuesto.',
            dialogTextSuccess: 'Continuar',
            dialogTextCancel: 'Corregir',
          });
          return null;
        }
        this.goTo();
        break;
      case 'show':
        this.showAlert();
        break;
      default:
        setTimeout(() => {
          this.scrollRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
        }, 500)
        break;
    }
  }
  showAlert = () => {
    this.setState({
      showDialog: true,
      dialogTitle: 'Validar Cuotas',
      dialogMessage: 'Debes validar las cuotas de todos tus residentes para continuar con tu configuración',
      dialogMessageB: 'Importante: ',
      dialogTextSuccess: 'Aceptar',
      dialogTextCancel: '',
    });
  }

  handleCloseDialog = () => {
    this.setState({ showDialog: false });
  }

  render() {
    const { params } = this.props;
    const { resident_last_view } = this.props.profileState;
    const { showDialog, dialogTitle, dialogMessage, dialogTextSuccess, dialogTextCancel, dialogMessageB } = this.state;
    return (
      <div className='Quota' ref={this.scrollRef}>
        <Dialogs
          title={dialogTitle}
          message={dialogMessage}
          messageB={ dialogMessageB }
          onClose={this.handleCloseDialog}
          open={showDialog}
          onSuccess={dialogTextCancel === '' ? this.handleCloseDialog :this.goTo}
          onCancel={dialogTextCancel === '' ? null : this.handleCloseDialog}
          textSuccess={dialogTextSuccess}
          textCancel={dialogTextCancel}
        />
        <Title
          title='Cuotas'
          helpMessage='Si deseas cambiar la cuota sugerida mensual lo puedes hacer manualmente para todas las viviendas.'
          goBack={this.goBack}
          property={resident_last_view ? resident_last_view: null}
        />
        <div className='Quota__container'>
          <Card>
            <QuotaDetail handleVerifyQuotas={this.handleVerifyQuotas} params={params} showAlert={this.showAlert}/>
          </Card>
        </div>
      </div>
    );
  }
}


Quota.contextTypes = {
  router: PropTypes.shape()
};

Quota.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({ ...Actions, ...propertyActions }, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  propertyState: state.get('propertyState'),
  budgetState: state.get('budgetState'),
  profileState: state.get('profileState'),

});

export default connect(mapStateToProps, mapDispatchToProps)(Quota);
