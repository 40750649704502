import React, { Component } from "react";
import './QuotaDatePayMount.scss';

export class QuotaDatePayMount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      court: props.dwellinwLimit_day === 0 ? 0: `${props.dwellinwLimit_day} de cada mes`,
      interest: props.dwellingInteres === 0 ? 0 : `${props.dwellingInteres} % de morosidad`,
      error: {},
      valid: this.props.valid
    };
  }

  onChangeCourt = (e) => {

    const value = e.target.value === '' | e.target.value === '-' ? e.target.value : parseFloat(e.target.value);
    if (e.target.value === '' || e.target.value === '-') {
      this.setState({ court: value });
    } else {
      this.setState({ court: isNaN(value) ? 0 : value });
    }

  }

  onBlurCourt = () => {

    const error = {};
    const { court } = this.state;

    if (court === 0) { 
      error.court = 'Campo requerido, no puede ser cero';
      this.setState({ court: this.props.dwellinwLimit_day, error });
      return;
    }

    if (court <= 0) {
      error.court = 'El dia no puede ser 0.';
      this.setState({ court: this.props.dwellinwLimit_day, error });
      return;
    }

    if (court > 28) {
      error.court = 'El dia no puede sobrepasar el 28.';
      this.setState({ court: this.props.dwellinwLimit_day, error });
      return;
    }

    if (court === '') {
      this.setState({ court: 0 });
      this.props.onChangeCourt({ target: { court: 0 } });
      return;
    }
    this.props.onChangeCourt({ target: { value: court } });
    this.setState({error: {}, court: `${court} de cada mes`});
  }

  onFocusCourt = (e) => {
    const { court } = this.state;
    const feeLimitDay = court.toString().replace(/[a-zA-Z/s]/gi, '').trim();
    this.setState({ court: parseInt(feeLimitDay) });
  }
  
  onChangeInterest = (e) => {

    const value = e.target.value === '' | e.target.value === '-' ? e.target.value : parseFloat(e.target.value);
    if (e.target.value === '' || e.target.value === '-') {
      this.setState({ interest: value });
    } else {
      this.setState({ interest: isNaN(value) ? 0 : value });
    }
  }

  onBlurInterest = () => {
    const error = {};
    const { interest } = this.state;

    if (interest < 0) {
      error.interest = 'Campo requerido, no puede ser 0';
      this.setState({ interest: this.props.dwellingInteres , error });
      return;
    }

    if (interest > 100) {
      error.interest = 'Campo requerido, no puede ser mayor a 100';
      this.setState({ interest: this.props.dwellingInteres, error });
      return;
    }


    if (interest === '') {
      this.setState({ interest: 0  });
      this.props.onChangeCourt({ targetInterest: { value: 0 } });
      return;
    }
    this.props.onChangeCourt({ targetInterest: { value: parseFloat(interest) } });
    this.setState({error: {}, interest:  `${interest} % de morosidad`});
  }

  onFocusInterest = () => {
    const { interest } = this.state;
    const feeLimitDay = interest.toString().replace(/[a-zA-Z/s]/gi, '').trim();
    this.setState({ interest: parseInt(feeLimitDay) });
  }

  render() {
    return (
      <>
      <div className="QuotaDatePayMount__form" >
        <div className={!this.state.valid ? 'inavlid-input' : '' }>
        <label htmlFor='dayPay'>Día límite de pago*</label>
          <input
            placeholder="Ej: el 10 de cada mes"
            type="text"
            name="court"
            id="date-pay"
            value={this.state.court === 0 ? undefined : this.state.court}
            maxLength={2}
            onChange={this.onChangeCourt}
            onBlur={this.onBlurCourt}
            onFocus={this.onFocusCourt}
            error={String(!!this.state.error.court)}
            disabled={!this.state.valid || this.props.loading}
          />
          <p>{this.state.error.court}</p>
        </div>
        
        <div>
        <label htmlFor='interest'>Interés moratorio*</label>
          <input
            placeholder="Ej: el 3% de morosidad"
            type="text"
            name="interest"
            id="day-pay"
            value={this.state.interest === 0 ? undefined : this.state.interest}
            maxLength={2}
            onChange={this.onChangeInterest}
            onBlur={this.onBlurInterest}
            onFocus={this.onFocusInterest}
            disabled={this.props.loading}  
          />
          <p>{this.state.error.interest}</p>
        </div>
        
      </div>
      <div className="QuotaDatePayMount" >
        <p>*Campos requeridos para continuar con la configuración.</p>
      </div>
      
      </>
    );
  }
}

export default QuotaDatePayMount;
