import FileSaver from 'file-saver';

const onError = (error, next, FAILURE) => {
  next({ error, type: FAILURE });
};

const download = (promise, next, SUCCESS, FAILURE, param) => (
  promise.then(
    (result) => {
      FileSaver.saveAs(result.data, 'masiveLoad-imobly.xlsx');
      next({ result, type: SUCCESS, param });
    },
    (error) => { onError(error, next, FAILURE); }
  ));


export default function promiseMiddlware() {
  return next => (action) => {
    const { promise, types, param } = action;
    if (!promise) {
      return new Promise(resolve => resolve(next(action)));
    }
    const [REQUEST, SUCCESS, FAILURE] = types;
    next({ type: REQUEST, param });
    if (promise && param === 'download') {
      download(promise, next, SUCCESS, FAILURE, param);
    }
    return promise.then(
      (result) => {
        next({ result, type: SUCCESS, param });
      },
      (error) => { onError(error, next, FAILURE); }
    );
  };
}
