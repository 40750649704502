import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactSVG } from 'react-svg';

import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import images from 'assets/img';
import './FormAmenity.scss';
import { showIconAmenity } from 'constants/amenityTypes';
import { Button } from 'components/Button/Button';
import { NUMBER_REGEX } from 'constants/globalRegex';

export class FormAmenity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amenity: props.amenity,
      error: false,
      message: ''
    };
  }

  componentDidUpdate(prevProps) {
    const { amenity } = prevProps;

    if (amenity !== this.props.amenity) {
      this.setState({ amenity: this.props.amenity });
    }
  }

  updateFormValue(name, value) {
    const object = Object.assign({}, this.state.amenity);
    switch (name) {
      case 'reservation_parameters':
        object.reservation_parameters.rate_type = value;
        this.setState({amenity: object});
      break;
      case 'reservation_amount':
        const num = !NUMBER_REGEX.test(value) ? Number(value) : 0;
        if(num < 0) {
          object.reservation_parameters.reservation_amount = '0';
        } else {
          object.reservation_parameters.reservation_amount = value;
        }
        this.setState({ amenity: object });
      break;
      case 'guaranty_amount':
        object.reservation_parameters.guaranty_amount = value;
        this.setState({ amenity: object });
      break;
      default:
        this.setState(state => ({
          amenity: {
            ...state.amenity,
            [name]: value
          }
        }));
      break;
    }
  }

  onChange = (event) => {
    const { value, name } = event.target;

    event.preventDefault();

    this.updateFormValue(name, value);
  }

  onBooleanChange(event) {
    let { value, name } = event.target;

    value = value === 'true' ? true : false;

    this.updateFormValue(name, value);
  }

  handleValidData = () => {
    const { onSave } = this.props;
    const { amenity } = this.state;

    if(!amenity.name.length) {
      this.setState({
        error: true,
        message: 'Campo obligatorio'
      });
    } else {
      onSave(this.state.amenity);
      this.setState({
        error: false,
        message: ''
      })
    }
  }

  render() {
    const { onDelete, onSend, complete } = this.props;
    const { name, label, description, is_reservable, id, require_authorization, reservation_parameters } = this.state.amenity || { };
    
    return (
      <div className="FormAmenity">
        <div className="FormAmenity__header">
          <h5>Datos generales</h5>
          <ReactSVG
            className="Delete"
            src={images.delete}
            onClick={onDelete(this.state.amenity)}
          />
        </div>


        <form className="FormAmenity__Form">
          <div className="FormAmenity__Form__Header">

            <ReactSVG className="FormAmenity__Form__Header__Img"
                      src={showIconAmenity(id).img} />

            <TextField
              name="name"
              className="FormAmenity__Form__Header__InputName"
              InputLabelProps={{ shrink: true }}
              label={label}
              onChange={ this.onChange.bind(this) }
              value={name}
              required
              fullWidth
              error={!!this.state.error}
              helperText={this.state.message}
            />
          </div>

          <TextField
            id="standard-multiline-static"
            name="description"
            InputLabelProps={{ shrink: true }}
            label={<FormattedMessage id="Wizard.Property.Amenity.Description" />}
            multiline
            rows={2}
            onChange={ this.onChange.bind(this) }
            value={description}
            fullWidth
          />

          <div className="FormAmenity__Form__Upload">
            <div className="FormAmenity__Form__Upload__Text">
              <FormattedMessage id="Wizard.Property.Amenity.Img" />
              <br />
              <FormattedMessage id="Wizard.Property.Amenity.ImgDescription" />
            </div>

            <Button variant="outlined" color="secondary">
              <FormattedMessage id="Wizard.Property.Amenity.Attach" />
            </Button>
          </div>

          <div className="FormAmenity__Form__Upload">
            <div className="FormAmenity__Form__Upload__Text">
              <FormattedMessage id="Wizard.Property.Amenity.Rules" />
              <br />
              <FormattedMessage id="Wizard.Property.Amenity.RulesDescription" />
            </div>

            <Button variant="outlined" color="secondary">
              <FormattedMessage id="Wizard.Property.Amenity.Attach" />
            </Button>
          </div>

          <hr />

          <h5>Reservación de amenidad</h5>

          <FormLabel component="legend" className="FormAmenity__Form__Question subtitle-2">
            <FormattedMessage id="Wizard.Property.Amenity.Question1" />
          </FormLabel>
          <RadioGroup
            row
            aria-label="position"
            name="is_reservable"
            defaultValue={!!is_reservable}
            value={!!is_reservable}
            onChange={ this.onBooleanChange.bind(this) }
          >
            <FormControlLabel
              value={ true }
              control={<Radio color="primary" />}
              label="Si"
              labelPlacement="end"
            />

            <FormControlLabel
              value={ false }
              control={<Radio color="primary" />}
              label="No"
              labelPlacement="end"
            />
          </RadioGroup>

          {
            is_reservable && <>
              <FormLabel component="legend" className="FormAmenity__Form__Question subtitle-2">
                <FormattedMessage id="Wizard.Property.Amenity.Question2" />
              </FormLabel>

              <RadioGroup
                row
                aria-label="position"
                name="require_authorization"
                defaultValue={!!require_authorization}
                value={!!require_authorization}
                onChange={ this.onBooleanChange.bind(this) }
              >
                <FormControlLabel
                  value={ true }
                  control={<Radio color="primary" />}
                  label="Si"
                  labelPlacement="end"
                />

                <FormControlLabel
                  value={ false }
                  control={<Radio color="primary" />}
                  label="No"
                  labelPlacement="end"
                />
              </RadioGroup>
            </>
          }

          {
            !!require_authorization && <>
              <FormLabel component="legend" className="FormAmenity__Form__Question subtitle-2">
                <FormattedMessage id="Wizard.Property.Amenity.Question3" />
              </FormLabel>
              <RadioGroup
                row
                aria-label="position"
                name="reservation_parameters"
                defaultValue='2'
                value={reservation_parameters.rate_type}
                onChange={ this.onChange.bind(this) }
              >
                <FormControlLabel
                  value="2"
                  control={<Radio color="primary" />}
                  label={<FormattedMessage id="Wizard.Property.Amenity.Question3.Rate" />}
                  labelPlacement="end"
                />
              </RadioGroup>
              <div className='row '>
                <TextField
                  name="reservation_amount"
                  InputLabelProps={{ shrink: true }}
                  label='Costo*'
                  className='FormAmenity__Form__Input col'
                  onChange={ this.onChange.bind(this) }
                  value={reservation_parameters.reservation_amount}
                />
                <TextField
                  name="guaranty_amount"
                  InputLabelProps={{ shrink: true }}
                  label='Pago en garantia*'
                  className='FormAmenity__Form__Input col'
                  onChange={ this.onChange.bind(this) }
                  value={reservation_parameters.guaranty_amount}
                />
              </div>
            </>
          }
          {
            complete ?
              <Button
                variant="contained"
                color="primary"
                className=''
                onClick={onSend}
              >
                Finalizar
              </Button>
              :
              <Button
                variant="contained"
                color="primary"
                className=''
                onClick={this.handleValidData}
              >
                Guardar
              </Button>
          }
        </form>
      </div>
    );
  }
}

FormAmenity.propTypes = {};

export default FormAmenity;
