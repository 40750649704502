import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as Actions from '../../../Budget/budgetActions';
import * as propertyActions from '../../../Property/propertyActions';
import Button from 'components/Button';
import Drawer from 'components/Drawer/Drawer';
import WizardHeader from 'containers/Wizard/components/WizardHeader';
import Dialog from 'components/Dialogs';
import AmenityRelations from './RelationsAmenity';
import QuotaHeader from './QuotaHeader';
import QuotaItem from './QuotaItem';
import QuotaFotter from './QuotaFotter';
import './QuotaDetail.scss';
import QuotaDatePayMount from './QuotaDatePayMount';
import {getLoading} from "utils/loader";


export class QuotaDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      details: null,
      showSnack:false,
      validTableCourt: false,
      error: {},
      moratorium_tax: 0,
      court: 0,
      isValid: false,
      dwellingID: 0,
    };
  }

  componentDidMount() {
    const { property } = this.props.params;

    this.getProperty();
    
    this.props.getBuildings({
      idProperty: this.props.params.property
    }).then(() => {
      const { buildings } = this.props.propertyState;
      const bid = buildings && buildings.result && buildings.result[0] ? buildings.result[0].id : 0;
      
      this.props.getBudgetsAndFees({ bid })

      this.props.addData({
        quotaSelected: bid,
      }).then(() => {
        const { quotaSelected } = this.props.budgetState;
        this.props.getQuotaDetail({
          idBuilding: quotaSelected
        });

        this.props.getFoundsN({
          pid: property,
          bid: quotaSelected
          
        });

      })
    });
  }

  getProperty() {
    const { property } = this.props.params;
    
		this.props.getProperty({
			property
		});
  }

  componentWillReceiveProps(nextProps) {
    const { result } = nextProps.budgetState.quotas;

    if (result !== this.props.budgetState.quotas.result) {
      this.CheckisValid(nextProps.budgetState.quotas);
    }

    if( result.dwellings ) {
      result.dwellings.forEach(item =>{
        if(item.fee_limit_day === 0 ) {
          this.setState({
            validTableCourt: true,
            moratorium_tax: 0,
            court: 0,
          });
          return
        }

        this.setState({
          moratorium_tax: item.fee_moratorium_tax,
          court: item.fee_limit_day,
          validTableCourt: false
        });

      });
    }
  }


  handleGetBuildings = () => {
    const array = [
      { titleSection: 'Inmuebles', content: [], withAmount: true },
    ];
    const { result } = this.props.propertyState.buildings;
    const content = [];
    result.forEach((item) => {
      const icon = item.type_id === 1 ? 'tower' : 'home';
      content.push({ name: item.name, icon, id: item.id, fee: item.fee });
    });
    array[0].content = content;
    return array;
  }

  handleGetTotalQuota = () => {
    const { dwellings, finantial_cost } = this.props.budgetState.quotas.result;
    let amount = 0;
    if (dwellings) {
      dwellings.forEach(item => {
        if(item.is_suggested_fee){
          amount += item.fee_total
        } else {
          amount += item.fee_total * (1 + finantial_cost/100);
        }
      });
    }
    return amount;
  }

  changeSelected = (bid) => {
    const { dwellings } = this.props.budgetState.quotas.result;
    const { property } = this.props.params;
    const { showAlert, params } = this.props;
    if (!this.validDwelligs(dwellings)){
      if(params.id){
        showAlert();
        return
      }
    }
    this.props.getBudgetsAndFees({ bid })
    this.props.addData({
      quotaSelected: bid,
    }).then(() => {

      this.props.getQuotaDetail({
        idBuilding: bid
      });
    }).then(() => {
      this.props.getFoundsN({
        pid: property,
        bid
      });
    });
  }

  validDwelligs = (dwellings) =>{
    let valid = true;
    if ( dwellings ){
      dwellings.forEach(item => {
        if (!item.is_valid) {
          valid =  false;
          return
        }
      });
    }
    return valid
  }

  onChangeBalance = (dwellingID) => (event) => {
    const balance = event.target.value;

    this.props.addDwellingData({
      dwellingID,
      data: {
        balance
      }
    });
  }


  onChangeCuota = (dwellingID) => (event) => {
    let fee_total;
    if (event.target) {
      fee_total = event.target.value;
    } else {
      return
    }

    if (parseFloat(fee_total) < 0) { fee_total = 0; }
    if (!fee_total) { return null; }

    this.props.addDwellingData({
      dwellingID,
      data: {
        fee_total
      }
    });
}
  onChangeCourt = _ => (event) => {
    let court = 0;
    let moratorium_tax = 0;
    let is_valid;

    if(event.target) {
      court = event.target.value;
      this.setState({ court });
    }

    if(event.targetInterest) {
      moratorium_tax = event.targetInterest.value;
      this.setState({ moratorium_tax });
    }
    if(event.targetCheck !== undefined) {
      is_valid = event.targetCheck;
      this.setState({ is_valid });
    }

    this.props.addDwellingData({
      data: {
        moratorium_tax,
        fee_moratorium_tax: moratorium_tax,
        fee_limit_day: court,
        is_valid,
      }
    })
  }

	savePropertyStatus() {
    const { name, address } = this.props.propertyState.new;
    const { property: idProperty, id: step } = this.props.params;
    const property = {
      step: parseInt(step),
      status_id: 21,
      name,
      address,
    }

    return this.props.putProperty({
      idProperty,
      property
    })
  }

  saveDwellings() {
    this.savePropertyStatus().then(() => {
      const { quotas } = this.props.budgetState;
      
      const { dwellings } = quotas.result;
  
      const fees = [];
  
      dwellings.forEach((item) => {
        fees.push({
          ...item,
          dwelling_id: item.id,
          total: item.fee_total,
          limit_day: item.fee_limit_day,
          frecuency_type_id: item.fee_frecuency_type_id,
          valid: item.is_valid,
          initial_balance: item.balance
        });
      });
      const hasInvalidLimitDay = fees.find(fee => !fee.limit_day);
  
      if (!hasInvalidLimitDay) {
        this.props.putFeeDweeling({
          fees
        }).then(() => {
          if (!this.props.budgetState.loading.PUT_QUOTAS) {
            const { quotaSelected } = this.props.budgetState;
    
            this.props.getQuotaDetail({
              idBuilding: quotaSelected
            })
            .then(_ => {
              this.props.handleVerifyQuotas && this.props.handleVerifyQuotas()
            });
          }
        });
      }
    })
  }

  onChangecheck = (item) => () => {
    this.props.addDwellingData({
      dwellingID: item.id,
      data: {
        is_valid: !item.is_valid
      }
    });
  }

  CheckisValid = (quotas) => {
    const { dwellings } = quotas.result;
    if (!dwellings) return;
    let isValid = true
    for (let i = 0; i < dwellings.length; i++) {
      if (!dwellings[i].is_valid){
        isValid = false;
        break;
      }
    }
    this.setState({ is_valid: isValid });
  }

  onCloseDialog = () => {
    this.setState({ showSnack: !this.state.showSnack });
  }

  render() {
    const { params } = this.props
    const { court, validTableCourt, is_valid } =  this.state;
    const { quotaSelected } = this.props.budgetState;

    const { result } = this.props.budgetState.quotas;

    const long = result.dwellings ? result.dwellings.length : 0;
    const {PUT_QUOTAS, GET_QUOTAS,SET_QUOTAS,PUT_DWELLING_BALANCE} =  this.props.budgetState.loading;
    let datosCuotas;
    let loading = getLoading(PUT_QUOTAS)||
      getLoading(GET_QUOTAS)  ||
      getLoading(PUT_DWELLING_BALANCE) ||
      getLoading(SET_QUOTAS);

    if(result.dwellings) {
      datosCuotas = result.dwellings[0];
    }

    return (
      <div className='QuotaDetail'>
        <div className='QuotaDetail__drawer'>
          <Drawer
            items={this.handleGetBuildings()}
            value={quotaSelected}
            onClick={this.changeSelected}
          />
        </div>
        <div className='QuotaDetail__content'>

          <WizardHeader
            numberDwellings={result.total_dwelling}
            monthlyBudget={result.monthly_fee}
            name={result.name}
            icon={result.type === 1 ? 'tower' : 'home'}
            showDeptos
          />

          <div>
            <h4 className="QuotaDetail__content__labelPay" > Fecha de Pago y Penalidades</h4>
            <p>
              Establece un día límite de pago para cada mes.
              Después de éste, aplicarán los intereses moratorios, a partir del primer día de retraso.
              Establece la tasa de interés moratoria mensual. Este interés se aplicará de forma diaria.
            </p>

            <br />
            {
              result.dwellings ?
                  <QuotaDatePayMount
                    valid={params.id && true}
                    loading={loading}
                    key={`QuotaDetailItem${datosCuotas.fee_total}${datosCuotas.id}`}
                    dwellingInteres={datosCuotas.fee_moratorium_tax}
                    dwellinwLimit_day={datosCuotas.fee_limit_day}
                    onChangeCourt={this.onChangeCourt()}
                  />
                : null
            }

          </div>

          <div className='QuotaDetail__content__relation'>
            <AmenityRelations
              amenities={result.assets ? Object.values(result.assets) : []}
              suggeste_monthly_fee={result.suggeste_monthly_fee || 0}
              total_dwelling={result.total_dwelling || 0}
              isFund={false}
              finantial_cost={result.finantial_cost}
              is_trial={result.is_trial}
            />
            {
              this.props.propertyState.founds ?
                this.props.propertyState.founds.result.funds.length > 0 ?
                  <AmenityRelations
                    amenities={this.props.propertyState.founds != null ? Object.values(this.props.propertyState.founds.result.funds) : []}
                    suggeste_monthly_fee={this.props.propertyState.founds.result.balance || 0}
                    total_dwelling={result.total_dwelling || 0}
                    isFund={true}
                    finantial_cost={result.finantial_cost}
                    is_trial={result.is_trial}
                  />
                  :null
                :null
              }
          </div>
          <div className="note">
            <p> <strong>IMPORTANTE:  </strong>   En caso de querer ajustar la cuota de mantenimiento
            por departamento, esto repercutirá directamente en el presupuesto anual.</p>
          </div>
          <br />
          <div className="table">
            <QuotaHeader
              type={result.type }
              onChangeCourt={this.onChangeCourt()}
              is_valid={is_valid}
              validTableCourt={loading}
            />
            {
              result.dwellings ?
                result.dwellings.map((item, index) => {
                  return (
                    <QuotaItem
                      onChangecheck={params.id ? this.onChangecheck(item) : null}
                      valid={params.id ? item.is_valid : true}
                      isLast={long === (index + 1)}
                      key={`QuotaDetailItem${item.fee_total}${item.id}`}
                      showDialog={this.handleShowDialog}
                      dwellingNumber={item.num}
                      residentName={item.name}
                      dwellingBalance={item.balance}
                      onChangeBalance={this.onChangeBalance(item.id)}
                      onChangeCuota={this.onChangeCuota(item.id)}
                      dwellinwLimit_day={item.fee_limit_day}
                      dwellingInteres={item.fee_moratorium_tax}
                      dwellingAmount={item.fee_total}
                      validTableCourt={validTableCourt}
                      loading={loading}
                    />
                  )
                })
                : null
            }
          </div>
          <QuotaFotter
            total_quotas={this.handleGetTotalQuota() || 0}
            total_budget={result.monthly_fee || 0}
          />
          <div className="QuotaDetail__content__cost">
            <div className='QuotaDetail__options'>
              <Button variant='contained' color='secondary' disabled={ court <= 0 || loading } onClick={() => this.saveDwellings()}>
                { params.id ? 'Continuar' : 'Guardar' }
              </Button>
            </div>
          </div>

          <Dialog
           title='¡Proveedor no seleccionado!'
           message='Tu concepto no tiene un proveedor asignado, asígnalo en la sección de'
           onClose={this.onCloseDialog}
           open={this.state.showSnack}
           onCancel={this.onCloseDialog}
         />
        </div>

      </div>
    );
  }
}

QuotaDetail.contextTypes = {
  router: PropTypes.shape()
};

QuotaDetail.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({ ...Actions, ...propertyActions }, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  propertyState: state.get('propertyState'),
  budgetState: state.get('budgetState')
});

export default connect(mapStateToProps, mapDispatchToProps)(QuotaDetail);
