import * as ACTION from 'containers/Login/actionTypes';

const initialState = {
  loading: {},
  Authorization: window && window.localStorage ? window.localStorage.getItem('imobly') : '',
  registro: '',
  restore: true,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.ADD_DATA_LOGIN:
      return { ...state, ...action.data };


    case ACTION.CHANGE_CAPTCHA_LOGIN:
      return { ...state, capcha: true };

    case ACTION.CLEAR_CHANGE_PASSWORD:
      return { ...state, restore: true };

    case ACTION.LOADING_NORMAL_LOGIN:
      return { ...state, loading: { ...state.loading, NORMAL_LOGIN: { status: false } } };
    case ACTION.RECEIVE_NORMAL_LOGIN: {
      localStorage.setItem('imobly', action.result.data );
      localStorage.setItem('imobly_SESSION', 'normal');
      return { ...state, loading: { ...state.loading, NORMAL_LOGIN: undefined }, Authorization: action.result.data };
    }
    case ACTION.ERROR_NORMAL_LOGIN:
      return { ...state, loading: { ...state.loading, NORMAL_LOGIN: { status: false, detail: action.error.response } } };


    case ACTION.LOADING_GOOGLE_LOGIN:
      return { ...state, loading: { ...state.loading, GOOGLE_LOGIN: { status: false } } };
    case ACTION.RECEIVE_GOOGLE_LOGIN: {
      localStorage.setItem('imobly', action.result.data );
      localStorage.setItem('imobly_SESSION', 'google');
      return { ...state, loading: { ...state.loading, GOOGLE_LOGIN: undefined }, Authorization: action.result.data };
    }
    case ACTION.ERROR_GOOGLE_LOGIN:
      return { ...state, loading: { ...state.loading, GOOGLE_LOGIN: { status: true, detail: action.error.response } } };


    case ACTION.LOADING_FACEBOOK_LOGIN:
      return { ...state, loading: { ...state.loading, FACEBOOK_LOGIN: { status: true } } };
    case ACTION.RECEIVE_FACEBOOK_LOGIN: {
      localStorage.setItem('imobly', action.result.data );
      localStorage.setItem('imobly_SESSION', 'facebook');
      return { ...state, loading: { ...state.loading, FACEBOOK_LOGIN: undefined }, Authorization: action.result.data };
    }
    case ACTION.ERROR_FACEBOOK_LOGIN:
      return { ...state, loading: { ...state.loading, FACEBOOK_LOGIN: { status: true, detail: action.error.response } } };

    case ACTION.LOADING_CHANGE_PASSWORD:
      return { ...state, loading: { ...state.loading, CHANGE_PASSWORD: { status: true } } };
    case ACTION.RECEIVE_CHANGE_PASSWORD:
      return { ...state, loading: { ...state.loading, CHANGE_PASSWORD: undefined }, restore: false };
    case ACTION.ERROR_CHANGE_PASSWORD:
      return { ...state, loading: { ...state.loading, CHANGE_PASSWORD: { status: false, detail: action.error.response } } };

    case ACTION.LOADING_REGISTER_SIGNUP:
      return { ...state, loading: { ...state.loading, REGISTER_SIGNUP: { status: true } } };
    case ACTION.RECEIVE_REGISTER_SIGNUP:
      return { ...state, loading: { ...state.loading, REGISTER_SIGNUP: undefined }, restore: false };
    case ACTION.ERROR_REGISTER_SIGNUP:
      return { ...state, loading: { ...state.loading, REGISTER_SIGNUP: { status: false, detail: action.error.response } } };

    case ACTION.LOADING_EDIT_REGISTER_SIGNUP:
      return { ...state, loading: { ...state.loading, EDIT_REGISTER_SIGNUP: { status: true } } };
    case ACTION.RECEIVE_EDIT_REGISTER_SIGNUP:
      return { ...state, loading: { ...state.loading, EDIT_REGISTER_SIGNUP: undefined }, restore: false };
    case ACTION.ERROR_EDIT_REGISTER_SIGNUP:
      return { ...state, loading: { ...state.loading, EDIT_REGISTER_SIGNUP: { status: false, detail: action.error.response } } };

    case ACTION.LOADING_RESET_SIGNUP:
      return { ...state, loading: { ...state.loading, RESET_SIGNUP: { status: true } } };
    case ACTION.RECEIVE_RESET_SIGNUP:
      return { ...state, loading: { ...state.loading, RESET_SIGNUP: undefined }, reset: 'ok' };
    case ACTION.ERROR_RESET_SIGNUP:
      return { ...state, loading: { ...state.loading, RESET_SIGNUP: { status: false, detail: action.error.response } } };


    case ACTION.LOADING_CODE_SIGNUP:
      return { ...state, loading: { ...state.loading, CODE_SIGNUP: { status: true } } };
    case ACTION.RECEIVE_CODE_SIGNUP:
      return { ...state, loading: { ...state.loading, CODE_SIGNUP: undefined }, registro: '', restore: true };
    case ACTION.ERROR_CODE_SIGNUP:
      return { ...state, loading: { ...state.loading, CODE_SIGNUP: { status: false, detail: action.error.response } } };


    case ACTION.LOADING_REMEMBER_LOGIN:
      return { ...state, loading: { ...state.loading, REMEMBER_LOGIN: { status: true } } };
    case ACTION.RECEIVE_REMEMBER_LOGIN:
      return { ...state, loading: { ...state.loading, REMEMBER_LOGIN: undefined }, remember: 'ok', capcha: undefined };
    case ACTION.ERROR_REMEMBER_LOGIN:
      return { ...state, loading: { ...state.loading, REMEMBER_LOGIN: { status: true, detail: action.error.response } }, capcha: undefined };

    case ACTION.LOG_OUT:
      return initialState;
      
    default:
      return { ...state };
  }
};

export default loginReducer;
