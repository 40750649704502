import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import stateWarning from 'assets/img/state-warning.svg';
import * as Actions from '../loginActions';
import './styles.scss';

class Unauthorized extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  clearError = () => {
    this.props.addLoginData({ loading: { ...this.props.loginState.loading, NORMAL_LOGIN: undefined } });
  }

  render() {
    return (
      <div className='Unauthorized'>
        <img src={stateWarning} alt='image ready' />
        <h2 className='Unauthorized__error'>¡Login fallido!</h2>
        <p>Usuario o contraseña erroneo.</p>
        <Button onClick={this.clearError} variant='contained' color='secondary'>Reintentar</Button>
      </div>
    );
  }
}

Unauthorized.contextTypes = {
  router: PropTypes.shape()
};

Unauthorized.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
});

export default connect(mapStateToProps, mapDispatchToProps)(Unauthorized);
