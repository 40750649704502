import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../actions';
import { FormattedMessage } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import SimpleModal from 'components/SimpleModal';
import Button from 'components/Button';
import moment from 'moment';
import { defaultDateFormat } from 'constants/dates';

import './PaymentModal.scss';

export const PaymentModal = (props) => {

    const {
        onClose,
        request
    } = props;

    const {
        title,
        email,
        full_name,
        building_name,
        dwelling_number
    } = request || { };

    
    const [ form, setForm ] = useState({
        payment_date: moment().format(defaultDateFormat),
        send_receipt: true,
        payment_method: 'TRANSFER'
    });
    
    const getAmenity = () => {
        if (request) {
            props.getAmenity(request.amenityId);
        }
    }

    const setReservationParams = () => {
        const { reservation_parameters } = props.bookingState.amenity || { };
        let amount = reservation_parameters?.reservation_amount + reservation_parameters?.guaranty_amount;

        if (isNaN(amount)) {
            amount = 0;
        }
        onChange('amount', amount)
    }

    const onSubmit = () => {
        const { booking_id } = props.request;
        
        props.onClose();
        props.bookingPayment(booking_id, {
            ...form,
            payment_date: moment(form.payment_date, defaultDateFormat).toDate(),
            amount: Number(form.amount)
        }).then(props.refresh);
    };
    
    useEffect(getAmenity, [ request ])

    useEffect(setReservationParams, [ props.bookingState.amenity ]);

    const onChange = (name, value) => {
        setForm({ ...form, [name]: value });
    };

    return (
        <SimpleModal title={ <FormattedMessage id='PaymentModal.title'/> } { ...props }>
            <div className='PaymentModal'>
                <form className='col'>
                    <div className='container-data'>
                        <div className='row space-between'>
                            <div>
                                <p className='subtitle text-green'><FormattedMessage id='PaymentModal.resident'/></p> 
                                <p className='body-text1'>{ (full_name || '').trim() || email }</p>
                            </div>
                            <div>
                                <p className='subtitle text-green'><FormattedMessage id='PaymentModal.building'/></p>
                                <p className='body-text1'>{ building_name }</p>
                            </div>
                        </div>
                        <div className='spacing-section'>
                            <p className='subtitle text-green'><FormattedMessage id='PaymentModal.dwellingNumber'/></p>
                            <p className='body-text1'>{ dwelling_number }</p>
                        </div>
                        <hr></hr>
                        <p className='disclaimer body-text1'>
                            <FormattedMessage id='PaymentModal.disclaimer'/>
                        </p>
                        <div className='spacing-section'>
                            <p className='subtitle text-green'><FormattedMessage id='PaymentModal.eventTitle'/></p>
                            <p className='body-text1'>{ title }</p>
                        </div>
                        <div className='row space-between spacing-section'>
                            <TextField
                                label={ <FormattedMessage id='PaymentModal.amount'/> }
                                value={ form.amount }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={event => onChange('amount', event.target.value)}
                            />
                            <TextField
                                label={ <FormattedMessage id='PaymentModal.date'/> }
                                type='date'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={ form.payment_date }
                                onChange={event => onChange('payment_date', event.target.value)}
                            />
                        </div>
                        <div className='col'>
                            <p className='subtitle text-green'><FormattedMessage id='PaymentModal.paymentMethod'/></p>
                                <RadioGroup
                                    row
                                    aria-label='payment-method'
                                    defaultValue={ form.payment_method }
                                    onChange={event => onChange('payment_method', event.target.value)}
                                >
                                    <FormControlLabel 
                                        value='TRANSFER'
                                        control={<Radio color='primary' />}
                                        label={ <FormattedMessage id='PaymentModal.transfer'/> }
                                        className='body-text3'
                                    />
                                    <FormControlLabel 
                                        value='CASH'
                                        control={<Radio color='primary' />}
                                        label={ <FormattedMessage id='PaymentModal.cash'/> }
                                        className='body-text3'
                                    />
                                    <FormControlLabel 
                                        value='CARD'
                                        control={<Radio color='primary' />}
                                        label={ <FormattedMessage id='PaymentModal.card'/> }
                                        className='body-text3'
                                    />                            
                                </RadioGroup>
                        </div>
                        <FormControlLabel
                            className='body-text3'
                            onChange={ _ => onChange('send_receipt', !form.send_receipt) }
                            control= { 
                                <Checkbox
                                    checked={ form.send_receipt }
                                />
                            }
                            label={ <FormattedMessage id='PaymentModal.sendEmail'/> }
                        />
                    </div>
                </form>
                <hr/>
                <div className='row row-flex-end'>
                    <Button variant="outlined" color='secondary' onClick={ onClose }>
                        <FormattedMessage id='Button.cancel'/>
                    </Button>
                    <Button variant="contained" color='primary' onClick={ onSubmit }>
                        <FormattedMessage id='PaymentModal.accept'/>
                    </Button>
                </div>
            </div>
        </SimpleModal>
    );
}

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);

const mapStateToProps = state => ({
  bookingState: state.get('bookingState'),
});

export default connect(mapStateToProps, mapDispatchToProps) (PaymentModal);