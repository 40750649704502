import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ROLES from 'constants/roles';
import * as propertyActions from '../Property/propertyActions';
import * as profileActions from '../Profile/ProfileActions';
import { logout } from '../Login/loginActions';

import PanelBlank from './PanelBlank/PanelBlank';
import PanelDashboard from './PanelDashboard/PanelDashboard';
import PanelWizard from './PanelWizard/PanelWizard';

import SnackBar from './SnackBar';

class Layout extends Component {

  state = { };

  componentDidMount() {
    const { Authorization } = this.props.loginState;
    if (!Authorization && this.context.router) { this.context.router.push(''); return; }
    
    this.props.getProfile().then(_ => {
      const { resident_role } = this.props.profileState;

      if ((resident_role || []).length && resident_role.includes(ROLES.SUPER_ADMIN)) {
        this.context.router.push(`/subscriptions`);
      }
      this.setState({
        validated: true
      });
    });
    this.props.getProperties();
  }

  handleLogout = () => {
    this.props.logout();
  }

  render() {
    const { validated } = this.state;
    const { children, params } = this.props;

    if (!validated) {
      return <div></div>
    }
    if (params?.id === 'info') {
      return (
        <PanelBlank params={params}>
          {children}
          <SnackBar router={ this.context.router } { ...this.props }/>
        </PanelBlank>
      );
    }
    if (params?.id) {
      return (
        <PanelWizard params={params} onLogout={this.handleLogout}>
          {children}
          <SnackBar router={ this.context.router } { ...this.props }/>
        </PanelWizard>
      );
    }
    return (
      <PanelDashboard
        params={params}
      >

        {children}
        <SnackBar router={ this.context.router } { ...this.props }/>
      </PanelDashboard>
    );
  }
}

Layout.contextTypes = {
  router: PropTypes.shape()
};
Layout.propTypes = {
  getProfile: PropTypes.func.isRequired,
  getProperties: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  ...propertyActions,
  ...profileActions,
  logout,
}, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  propertiesState: state.get('propertyState'),
  profileState: state.get("profileState"),
  loading: state.get('propertyState').loading
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);