import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Icon from 'components/Icon';

import iconDownload from 'assets/img/icon-download.svg';
import './MasiveCard.scss';
import { getBase64 } from 'utils/files';

const fileName = 'masiveLoad-imobly.xlsx';

class MasiveCard extends Component {
  constructor() {
    super();
    this.state = {
      files: null,
      error: false,
    };
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    this.setState({ files: acceptedFiles, error: false });
  }

  thisSubmit = () => {
    const { onSubmit } = this.props;
    const { files } = this.state;
    if (files && files[0] && files[0].name === fileName) {
      getBase64(files[0]).then((result) => {
        onSubmit(result);
      });
      return null;
    }
    this.setState({ error: true });
  }

  render() {
    const { onCancel, onDownload, isLoaded } = this.props;
    const { files, error } = this.state;
    const isCorrect = files && files[0] && files[0].name === fileName;
    return (
      <div className='MasiveCard'>
        <div className='MasiveCard__instructions'>
          <span>
            Para la carga masiva de edificios y/o casas es necesario
            <img src={iconDownload} alt='download' onClick={onDownload} />
            <span className='MasiveCard__instructions__cliked' onClick={onDownload}>descargar el formato</span>
            y rellenar los campos. Es importante que todos los campos marcados como obligatorios sean
            llenados sin excepción para evitar errores.
          </span>
        </div>
        <Dropzone onDrop={this.onDrop}>
          {
            ({ getRootProps, getInputProps, isDragActive }) => (
              <div {...getRootProps()} className='MasiveCard__zone'>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                      <div className='MasiveCard__zone__active'>Suelta el formato aqui...</div> :
                      <div className='MasiveCard__zone__inactive'>
                        {
                          !isLoaded
                          ? (
                            files && files[0]
                            ? <div className={isCorrect ? 'MasiveCard__zone__inactive__file' : 'MasiveCard__zone__inactive__nofile'}>
                              <Icon icon={isCorrect ? 'beenhere' : 'security'} />
                              {files[0].name}
                              { !isCorrect ? <p>Formato incorrecto, por favor verifique</p> : null }
                            </div>
                            : <div className='MasiveCard__zone__inactive__none'>Arrasta aquí tu archivo<button>Buscar en tu equipo</button></div>
                            )
                          : <Icon icon='done_all' style={{ fontSize: 70, color: 'green' }}/>
                        }
                      </div>
                  }
              </div>
              )
          }
        </Dropzone>
        {
          error
          ? <div className='MasiveCard__error'>No hay formato para continuar o el archivo indicado no contiene el formato necesario para continuar con el proceso. Por favor verifique.</div>
          : null
        }
        <div className='MasiveCard__options'>
          <Button variant='outlined' color='secondary' onClick={onCancel}>Cerrar</Button>
          <Button variant='contained' color='secondary' onClick={this.thisSubmit}>Guardar</Button>
        </div>
      </div>
    );
  }
}

MasiveCard.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
};

export default MasiveCard;
