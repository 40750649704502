import React from 'react'
import './TermsAndConditionsText.scss';
 const TermsAndConditionsText = () => {
     return (
         <>
            <div className="terms" >
                <h1 className="title-notice" >Aviso Legal</h1>
                <p className="date-update" >Fecha de actualización: 03 de Agosto del 2020</p>
                
                <div>
                    <section>
                         <p>
                         El presente aviso legal regula la utilización por el Usuario o Usuaria de los servicios de información y gestión que Travel Retail Services, S.A. de C.V, (en adelante “Imobly”) pone a su disposición. Al utilizar los servicios de su Membresía y su Sitio Web 
                         y Aplicación Móvil (ambos referidos en adelante como “Software”), usted (en adelante, el “Usuario”) acepta en su totalidad y se obliga a cumplir por completo los Términos y Condiciones de Uso así como la Política de Privacidad, lo anterior en la inteligencia 
                         de que si no acepta ambos no podrá hacer uso de la Membresía.
                        </p><br />
                        <p>
                        Imobly se reserva el derecho a modificar estos Términos y Condiciones de Uso cuando lo estime necesario. Por lo tanto, el Usuario debe leer atentamente los Términos y Condiciones de Uso antes de adquirir una Membresía o de utilizar el Software y cada que se 
                        le notifique de alguna actualización. Puede ver la versión vigente de estos términos en cualquier momento a través de la sección de Menú del Software y en el sitio web https://imobly.io. Estos Términos y Condiciones de Uso serán aplicables, exclusivamente, 
                        a los servicios de la Membresía y al uso del Software. Imobly opera otros servicios que están regidos por sus propios términos y condiciones.
                        </p>
                    </section>
                    
                    <section>
                        <h2> 1.- Términos y condiciones  </h2>
                        
                        <p> Imobly es una empresa privada de gestión, administración, intermediación, consignación, recolección, distribución, de recursos y fondos que facilita la gestión, comunicación y transacciones entre administradores de propiedades compartidas, 
                        servicios, bienes inmuebles o muebles y usuarios finales, Los Servicios pueden incluir, de forma enunciativa, más no limitativa, el cobro y pago de cuotas de administración, reservación, fondos especiales, intereses moratorios,servicios de proveedores, 
                        y otras actividades que faciliten la gestión, la comunicación y las transacciones entre las partes. </p>
                        <p><br />
                        El Servicio proporcionado por Imobly es una solución de Administración de Condominio que incluye una plataforma Web y una Aplicación móvil. 
                        Se comercializa bajo la modalidad SaaS (Software as a Service), es decir un derecho de uso mensual del SW. El Software, tanto de la Aplicación Móvil como de la plataforma Web, se mantiene en todo momento la propiedad exclusiva de Imobly.
                        </p>
                    </section>
                    
                    <section>
                        <h2>2.- Precios</h2>
                        
                        <p>Los precios que aplican son los indicados en el sitio www.imobly.io al momento de la contratación. Imobly tendrá la facultad de cambiar esos precios con previo aviso de 30 días de anticipación. Con el uso de la plataforma posteriormente a esos 30 días de aviso, el cliente acepta tácitamente este cambio.</p>
                        <p>Los precios no incluyen IVA.</p>
                        <p>Los precios incluyen el 100% de las funcionalidades disponibles de la última versión de Software vigente, y soporte técnico.</p><br />

                        <p>La subscripción que adquiera el cliente tiene la periodicidad que el cliente haya elegido y pagado, y tendrá renovación automática que podrá cancelarse en la plataforma Web con 1 día laborable de antelación a su vencimiento. El servicio se puede cancelar en cualquier momento en la plataforma Web y surtirá efecto a partir de la fecha de vencimiento del periodo pagado. El usuario podrá acceder a todas las funcionalidades de Imobly hasta la fecha de vencimiento del servicio pagado. Después de esta fecha, el usuario no podrá acceder a la plataforma. La información del usuario se guardará en la plataforma de imobly durante un periodo de 3 meses, salvo instrucción especifica del Usuario.</p><br />

                        <p>No hay devoluciones de cualquier monto pagado a Imobly para el derecho de uso de la plataforma Web. </p><br />
                        <p>En caso de no recibir el pago mensual correspondiente al servicio proporcionado, Imobly tendrá facultad de bloquear o cancelar la cuenta del Usuario. La información del Usuario se guardará en  la plataforma de Imobly durante 3 meses, salvo instrucción específica del Usuario.</p> <br />
                    </section>
                    
                    <section>
                        <h2>3. Período de prueba</h2>
                        <p>Al momento de contratar el servicio, Imobly otorga 1 mes de suscripción sin costo, como periodo de prueba. Para seguir usando la plataforma, el cliente tendrá que pagar el costo mensual indicado. En caso de no recibir el pago correspondiente, Imobly podrá deshabilitar la cuenta del cliente de forma inmediata.</p>
                    </section>

                    
                    <section>
                        <h2>4. Uso del Software</h2>
                        <p>El acceso al Software se encuentra restringido exclusivamente para el Usuario que hubiere adquirido una Membresía y, en algunos casos, para los usuarios que estén al corriente en los pagos de sus cuotas. Esto último en virtud de que este Software pretende volver más eficiente la gestión de los administradores de propiedades compartidas.</p><br />
                        <p>El Usuario se compromete a no usar el Software con fines fraudulentos, así como a no llevar a cabo conducta alguna que pudiera dañar la imagen, los intereses y los derechos de Imobly o de terceros. Asimismo, el Usuario se compromete a no realizar acto alguno con objeto de dañar, inutilizar o sobrecargar el Software, o que impidiera, de cualquier forma, la normal utilización y funcionamiento de esta.</p><br />
                        <p>El Contenido del Software ha sido diseñado para audiencias concretas, principalmente público general interesado en la gestión de un bien compartido. Dentro de los diversos servicios contenidos en el Software se encuentra el proporcionar una herramienta de información sobre las actividades de gestión y fondeo de las propiedades compartidas. El Usuario que se haya registrado podrá recibir información y noticias de Imobly mediante correo electrónico, SMS u otros medios de comunicación electrónica (consulte nuestra "Política de Privacidad").</p><br />
                    </section>

                    <section>
                        <h2>5. Derechos de autor</h2>
                        <p>Toda la información y contenido (imagenes, textos, graficos) que se muestran en el sitio www.Imobly.io es propiedad intelectual de Imobly.  </p>
                    </section>

                    <section>
                        <h2>6. Límites de responsabilidad</h2>
                        <p> El acceso al servicio requiere una conexión internet funcional. Dependiendo de las características (ancho de banda por ejemplo) de esta conexión, se podrá observar un tiempo de carga de la información variable. Durante los periodos de ausencia de conexión Internet no se podrá acceder al servicio. Esta conexión Internet es independiente de Imobly y imobly deslinda toda responsabilidad sobre la calidad y desempeño de la misma.
                        Diversos motivos, tales como crisis, eventos no previsibles, fuerza mayor, etc., así como el mal funcionamiento o interrupción de alguna otra parte del internet pueden ocasionar el entorpecimiento del acceso y la transmisión de información y mensajes, errores en la transmisión de los mismos o incluso la interrupción temporal del acceso al sitio, y tales circunstancias se encuentran absolutamente fuera del alcance de Imobly.
                        </p><br />
                        <p> La responsabilidad del resguardo y de la comunicación de la información confidencial del condominio es responsabilidad del Usuario titular de la cuenta abierta en www.imobly.io. Toda la información de operación del condominio, como datos personales de administradores, inquilinos y propietarios, información financiera, o cualquier tipo de informacíon relacionada con el condominio, así como su uso, es propiedad y responsabilidad exclusiva del Usuario. Imobly no asume ningún tipo de responsabilidad que pudiese resultar del mal uso, daños o perjuicios que pueda ocasionarse a cualquier persona por el uso de la información privada del condominio.</p><br />
                        <p>El Usuario libera de manera absoluta y sin limitación alguna a Imobly de cualquier tipo de responsabilidad directa o indirecta, civil, penal, administrativa, así como de los daños (patrimoniales o morales), perjuicios o cualquiera otra que pudiera emanar del uso del sitio y de la información disponible mediante el mismo.</p><br />
                        <p>El servicio proporcionado por Imobly incluye medios de comunicación como email, chat, reportes. Imobly no es responsable de la información que los usuarios publican en esos medios.</p><br />
                        <p>Imobly se compromete a poner los recursos técnicos y materiales que tenga disponibles para ofrecer un Servicio de calidad de acuerdo con los estándares vigentes, con buena fe y profesionalismo. Es corresponsabilidad del Usuario aportar toda la información necesaria para que el servicio que se le brinde sea oportuno y eficaz.</p><br />
                    </section>

                    <section>
                        <h2>7. Jurisdicción</h2>
                         <p>El Usuario, al hacer uso del sitio, acepta de manera expresa someterse, en caso
                         de cualquier controversia, a la jurisdicción de los tribunales de la Ciudad de México, CDMX,,
                         en los Estados Unidos Mexicanos, renunciando expresamente a cualquier otra jurisdicción que por motivo de su nacionalidad, residencia o lugar de negocios pudiese corresponderle ahora o en el futuro.
                         </p>
                    </section>

                    <section>
                        <h2>8. Soporte técnico:</h2>
                        <p> El soporte técnico está disponible en días hábiles (según calendario laboral de México) de 10am a 5pm. </p>
                        <p> Email: soporte@imobly.com, ventas@imobly.com</p>
                    </section>

                </div>
            </div>
         </>
      );
 }
 
 export default TermsAndConditionsText;