export const LOG_OUT = 'LOG_OUT';

export const ADD_DATA_LOGIN = 'ADD_DATA_LOGIN';
export const VERIFICA_EMAIL_SIGNUP = 'VERIFICA_EMAIL_SIGNUP';

export const LOADING_CHANGE_PASSWORD = 'LOADING_CHANGE_PASSWORD';
export const RECEIVE_CHANGE_PASSWORD = 'RECEIVE_CHANGE_PASSWORD';
export const ERROR_CHANGE_PASSWORD = 'ERROR_CHANGE_PASSWORD';

export const CLEAR_CHANGE_PASSWORD = 'CLEAR_CHANGE_PASSWORD';

export const LOADING_GOOGLE_LOGIN = 'LOGIN::LOADING_GOOGLE_LOGIN';
export const RECEIVE_GOOGLE_LOGIN = 'LOGIN::RECEIVE_GOOGLE_LOGIN';
export const ERROR_GOOGLE_LOGIN = 'LOGIN::ERROR_GOOGLE_LOGIN';

export const LOADING_FACEBOOK_LOGIN = 'LOGIN::LOADING_FACEBOOK_LOGIN';
export const RECEIVE_FACEBOOK_LOGIN = 'LOGIN::RECEIVE_FACEBOOK_LOGIN';
export const ERROR_FACEBOOK_LOGIN = 'LOGIN::ERROR_FACEBOOK_LOGIN';

export const LOADING_NORMAL_LOGIN = 'LOGIN::LOADING_NORMAL_LOGIN';
export const RECEIVE_NORMAL_LOGIN = 'LOGIN::RECEIVE_NORMAL_LOGIN';
export const ERROR_NORMAL_LOGIN = 'LOGIN::ERROR_NORMAL_LOGIN';

export const LOADING_REGISTER_SIGNUP = 'SIGNUP::LOADING_REGISTER_SIGNUP';
export const RECEIVE_REGISTER_SIGNUP = 'SIGNUP::RECEIVE_REGISTER_SIGNUP';
export const ERROR_REGISTER_SIGNUP = 'SIGNUP::ERROR_REGISTER_SIGNUP';

export const LOADING_EDIT_REGISTER_SIGNUP = 'SIGNUP::LOADING_EDIT_REGISTER_SIGNUP';
export const RECEIVE_EDIT_REGISTER_SIGNUP = 'SIGNUP::RECEIVE_EDIT_REGISTER_SIGNUP';
export const ERROR_EDIT_REGISTER_SIGNUP = 'SIGNUP::ERROR_EDIT_REGISTER_SIGNUP';

export const LOADING_RESET_SIGNUP = 'SIGNUP::LOADING_RESET_SIGNUP';
export const RECEIVE_RESET_SIGNUP = 'SIGNUP::RECEIVE_RESET_SIGNUP';
export const ERROR_RESET_SIGNUP = 'SIGNUP::ERROR_RESET_SIGNUP';

export const LOADING_CODE_SIGNUP = 'SIGNUP::LOADING_CODE_SIGNUP';
export const RECEIVE_CODE_SIGNUP = 'SIGNUP::RECEIVE_CODE_SIGNUP';
export const ERROR_CODE_SIGNUP = 'SIGNUP::ERROR_CODE_SIGNUP';

export const CHANGE_CAPTCHA_LOGIN = 'LOGIN::CHANGE_CAPTCHA_LOGIN';

export const LOADING_REMEMBER_LOGIN = 'LOGIN::LOADING_REMEMBER_LOGIN';
export const RECEIVE_REMEMBER_LOGIN = 'LOGIN::RECEIVE_REMEMBER_LOGIN';
export const ERROR_REMEMBER_LOGIN = 'LOGIN::ERROR_REMEMBER_LOGIN';
