import React from 'react';

import './SubscriptionStatus.scss';

const SubscriptionStatus = props => {

  const { labeled } = props;
  const value = (props.value || '').toLocaleLowerCase();
  
  const labels = {
    inactive: 'Inactiva',
    cancelled: 'Cancelada/pago rechazado',
    trial: 'Período de prueba',
    valid: 'Vigente'
  }

  const classMapper = {
    inactive: 'inactive-colors',
    cancelled: 'rejected-colors',
    trial: 'pending-colors',
    valid: 'approved-colors',
  }
  
  return (
    <div className={ `SubscriptionStatus ${ classMapper[value] } ${ labeled && 'labeled' }` }>
      { labeled && labels[value] }
    </div>
  )
}

export default SubscriptionStatus;
