import React, { Component } from 'react';
import PropTypes from 'prop-types';

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import environments from 'constants/environments';
import FacebookLogo from 'assets/img/flogo-rgb-hex-72.png';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Text from 'components/Text';
import Lang from 'components/Lang';
import './SignUpForm.scss';

class SignUpForm extends Component {
  render() {
    const {
      viewPassword,
      showPassword,
      name,
      email,
      password,
      confirmPassword,
      error,
      goTo,
      onChange,
      onSubmit,
      title,
      responseGoogle,
      responseFacebook
    } = this.props;
    return (
      <div className='SignUp'>
        <div className='SignUp__title'>
          <Lang id={title} />
        </div>
        <div className="SignUp__social">
          <div className="SignUp__social__google">
            <GoogleLogin
              clientId={ environments.googleClientId }
              autoLoad={false}
              onSuccess={responseGoogle}
              className='Login__social__google__button'
            >
              <span>Continua con Google</span>
            </GoogleLogin>
          </div>
          <div className="SignUp__social__facebook">
            <FacebookLogin
              appId={ environments.facebookAppId }
              language='es_MX'
              fields='name, email, picture.width(120)'
              callback={responseFacebook}
              
              textButton=''
              cssClass='Login__social__facebook__button'
              icon={<div className='Login__social__facebook__button__containerLogo'><img src={ FacebookLogo } alt='facebook-logo' className='Login__social__facebook__button__containerLogo__logo' /><span>Continua con Facebook</span></div>}
            />
          </div>
        </div>
        
        <div className='SignUp__options'>
          <Text variant='caption' id='LoginPage.or' />
          <div className='Login__options__divider' />
        </div>

        <div className='SignUp__input'>
          <TextField
            fullWidth
            name='name'
            label='Nombre'
            value={name}
            error={!!error.name}
            helperText={error.name}
            onChange={onChange}
          />
        </div>
        <div className='SignUp__input'>
          <TextField
            fullWidth
            name='email'
            label='Email'
            value={email}
            error={!!error.email}
            helperText={error.email}
            onChange={onChange}
          />
        </div>
        
        <div className='SignUp__input'>
          <TextField
            fullWidth
            label='Crea una contraseña'
            name='password'
            value={password}
            error={!!error.password}
            helperText={error.password}
            onChange={onChange}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={viewPassword} color='secondary'>
                    <Icon icon={showPassword ? 'visibility_off' : 'visibility'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className='SignUp__input'>
          <TextField
            fullWidth
            label='Confirma contraseña'
            name='confirmPassword'
            value={confirmPassword}
            error={!!error.confirmPassword}
            helperText={error.confirmPassword}
            onChange={onChange}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={viewPassword} color='secondary'>
                    <Icon icon={showPassword ? 'visibility_off' : 'visibility'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className='SignUp__button'>
          <Button onClick={onSubmit} variant='contained' color='secondary'>
            <Text variant='button' id='Register.regiter' />
          </Button>
        </div>
        <div className='Login__signup'>
          <div className='Login__signup__text'>
            <Text variant='caption' id='Register.existAccount' />
          </div>
          <span className='Login__signup__register' onClick={goTo('login')}>
            <Text variant='caption' id='LoginPage.login' />
          </span>
        </div>

        <div className='Login__signup'>
          <div className='Login__signup__text'>
            <Text variant='caption' id='Register.condiciones' />
          </div>
          <span className='Login__signup__register' onClick={goTo('terms-and-conditions')}>
            <Text variant='caption' id='Register.terms' />
          </span>
        </div>

        <div className='Login__signup'>
          <div className='Login__signup__text'>
            <Text variant='caption' id='Register.avise-1' />
          </div>
          <span className='Login__signup__register' onClick={goTo('terms-and-conditions')}>
            <Text variant='caption' id='Register.avise' />
          </span>
        </div>
      </div>
    );
  }
}

SignUpForm.defaultProps = {
  name: '',
  email: '',
  password: '',
  confirmPassword: '',
  error: {},
};

SignUpForm.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  password: PropTypes.string,
  confirmPassword: PropTypes.string,
  showPassword: PropTypes.bool.isRequired,
  viewPassword: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  goTo: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SignUpForm;
