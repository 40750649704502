import React from 'react';
import { FormattedMessage } from 'react-intl';
import SimpleModal from '../SimpleModal';
import Button from '../Button';
import { toMoneyFormat } from '../../utils/format';

import './ConfirmationModal.scss';

export const ConfirmationModal = props => {

    const getValue = control => {

        let value = props.values[control.name] || control.value;

        if (control.displayKey) {
            value = (value || {})[control.displayKey];
        }

        switch(control.type) {
            case 'radio':
                return control.props.options.find(option => option.value === value).label;
            case 'currency':
                return `$ ${ toMoneyFormat(value) }`;
            default:
                return value;
        }
    }

    const getKeys = controls => (controls || []).map((control, index) => {
        if (Array.isArray(control)) {
            return getKeys(control)
        }
        return !control.hideFromConfirmation && (
            <div key={ index } className='row regular-text'>
                <label>{ control.label || control.props?.label }: { getValue(control) }</label>
            </div>
        )
    });

    return (
        <SimpleModal { ...props }>
            <div className='ConfirmationModal col'>
                <p className='regular-text'>Comprueba que los datos que ingresaste son los correctos</p>
                <div className='values'>
                    { getKeys(props.confirmationDetails) }
                    { getKeys(props.controls) }
                </div>
                <div className='row row-flex-end'>
                    <Button disabled={ props.loading } variant="outlined" color='secondary' onClick={ props.onClose }>
                        <FormattedMessage id='Button.cancel'/>
                    </Button>
                    <Button disabled={ props.loading } variant="contained" color='primary' onClick={ props.onAccept }>
                        <FormattedMessage id='Button.accept'/>
                    </Button>
                </div>
            </div>
        </SimpleModal>
    );
};

export default ConfirmationModal;
