import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ImageIcon from '@material-ui/icons/Image';
import * as Actions from '../../../budgetActions';
import * as ProviderActions from '../../../../Providers/providerActions';
import SimpleModal from 'components/SimpleModal';
import Form from 'components/Form';
import paymentMethods from 'constants/paymentMethods';

import './ExtraordinaryPaymentModal.scss';

export const ExtraordinaryPaymentModal = props => {

  const [ serviceId, setServiceId ] = useState();

  useEffect(_ => {
    const { property } = props.params;
    
    props.getProviders({ idProperty: property });
  }, [ ])

  const onSubmit = (values) => {
    const { activeSelected : active } = props.budgetState;
    const { property } = props.params;
    const { file } = values;

    props.postExtraProviderPayment(active, {
      ...values,
      file: file?.file
    }).then(() => { 
      props.getProviderPaymentPending({
        property,
        active
      });
      props.getActivesWithoutSelected({
        property
      });
      props.getExpenses(active);
      props.getTransferFund({
        property,
        active
      });
      props.onClose();
    });
  }

  const onFormChange = (name, value) => {
    if (name === 'provider_id' && value === 'new') {
      const { property } = props.params;
      const { activeSelected } = props.budgetState;

      props.router.push(`/property/${property}/providers/new?active=${activeSelected}&concept=${ serviceId }`); 
    } else if (name === 'service_id') {
      setServiceId(value);
    }
  }

  const { open, onClose, providerState } = props;
  
  const providers = (providerState.providers?.result || [ ])
    .map(provider => ({
        value: provider.id,
        label: provider.name
    }));
  
  providers.push({
    value: 'new',
    label: 'Agregar proveedor'
  });

  return ( 
    <SimpleModal title='Registrar Pago de Servicio' open={open} onClose={onClose}>
      <Form
        loading={ props.budgetState.loading.POST_EXTRA_PROVIDER_PAYMENT?.status }
        onCancel={ props.onClose }
        onSubmit={ onSubmit }
        clearOnSubmitted
        changeInterceptor={ onFormChange }
        controls={[
          {
            name: 'service_id',
            label: 'Concepto/Servicio',
            type: 'concept',
            props: {
              required: true
            },
          },
          {
            name: 'provider_id',
            label: 'Provedor',
            type: 'select',
            props: {
              required: true,
              items: providers,
              disab: !serviceId
            },
          },
          {
            name: 'amount',
            label: 'Monto',
            props: {
              required: true,
              minimumValue: '0',
            },
            type: 'currency'
          },
          {
            name: 'payment_date',
            label: 'Fecha de Pago',
            type: 'date',
            props: {
              required: true,
              defaultValue: new Date().toISOString().substring(0, 10),
              InputLabelProps: {
                shrink: true
              }
            }
          },
          {
            name: 'payment_type',
            type: 'radio',
            props: {
              required: true,
              label: 'FORMA DE PAGO',
              row: true,
              defaultValue: 'CASH',
              options: paymentMethods
            }
          },
          {
            name: 'file',
            type: 'file',
            props: {
              label: 'EVIDENCIA (.docx, .pdf, .xlsx, .jpg, .png)',
              buttonProps: {
                startIcon: <ImageIcon />,
              },
              children: 'Adjuntar archivo'
            }
          },
        ]}
      />
    </SimpleModal>
  );
}

const mapDispatchToProps = dispatch => bindActionCreators({ ...Actions, ...ProviderActions }, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  budgetState: state.get('budgetState'),
  providerState: state.get('providerState')
});


export default connect(mapStateToProps, mapDispatchToProps)(ExtraordinaryPaymentModal);