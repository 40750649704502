
export const isLoadingGlobal = (state) => {
  const loadingLogin = state.get('loginState').loading;
  const loadingAccount = state.get('accountState').loading;
  const loadingBudget = state.get('budgetState').loading;
  const loadingProvider = state.get('providerState').loading;
  const loadingProperty = state.get('propertyState').loading;
  const loadingProfile = state.get('profileState').loading;
  const neighborState = state.get('neighborState').loading;
  const bookingState = state.get('bookingState').loading;
  
  const loading = {
    ...loadingLogin,
    ...loadingAccount,
    ...loadingBudget,
    ...loadingProvider,
    ...loadingProperty,
    ...loadingProfile,
    ...neighborState,
    ...bookingState
  };
  const loadingToArray = Object.values(loading);
  const loadingToFilter = loadingToArray.filter(item => item !== undefined);
  return loadingToFilter;
};

export const isLoading = (loadingState) => {
  if (!loadingState) return false;
  const filtered = loadingState.filter(item => item.status === true );
  if (!filtered) return false;
  return filtered.length > 0;
};


export const getLoading = loading => {
  return loading ? loading.status ? true: false :false
};