import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Fab from '@material-ui/core/Fab';
import Clear from '@material-ui/icons/Clear'

import './SimpleModal.scss';

class SimpleModal extends React.Component {
  render() {
    
    const { title, hideClose, onClose, open, children } = this.props;
    
    return (
      <Modal
        onClose={ onClose }
        open={ !!open }
        className='SimpleModal'
      >
        <div className='modal-card'>
          {
            title && (
              <div className='modal-head'>
                <h3>{ title }</h3>
              </div>
            )
          }
          {
            !hideClose && <Fab
              size='small'
              color='secondary'
              onClick={ onClose }
            >
              <Clear />
            </Fab>
          }
          <div className='modal-body'>
            { children }
          </div>
        </div>
      </Modal>
    );
  }
}

SimpleModal.propTypes = {
  children: PropTypes.element.isRequired,
  onClose: PropTypes.func.isRequired,
  hideClose: PropTypes.bool,
};

SimpleModal.defaultProps = {
  hideClose: false,
};

export default SimpleModal;
