import * as ACTION from 'containers/Neighbors/actionTypes';

const initialState = {
  loading: {},
  buildingSelected: '',
  neighbors: {},
  neighbor: {
    result: [],
  },
  balances: [],
  historyPays: [],
  contacts: {
    result: []
  }
};
const updateNeighborInfo = (action) => {
  const info = {};
  if (action.result && action.result.data) {
    const { result } = action.result.data;
    info.name = result.name;
    info.first_last_name = result.first_last_name;
    info.second_last_name = result.second_last_name;
    info.phone = result.phone;
    info.email = result.email;
  }
  return info;
};
const neighborReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.ADD_DATA_NEIGHBOR:
      return { ...state, ...action.data };


    case ACTION.LOADING_GET_NEIGHBORS:
      return { ...state, loading: { ...state.loading, GET_NEIGHBORS: { status: true } } };
    case ACTION.RECEIVE_GET_NEIGHBORS:
      return { ...state, loading: { ...state.loading, GET_NEIGHBORS: undefined }, neighbors: action.result.data };
    case ACTION.ERROR_GET_NEIGHBORS:
      return { ...state, loading: { ...state.loading, GET_NEIGHBORS: { status: false, detail: action.error.response } } };


    case ACTION.LOADING_GET_NEIGHBOR:
      return { ...state, loading: { ...state.loading, GET_NEIGHBOR: { status: true }, PUT_NEIGHBOR: undefined } };
    case ACTION.RECEIVE_GET_NEIGHBOR:
      return { ...state, loading: { ...state.loading, GET_NEIGHBOR: undefined }, neighbor: action.result.data };
    case ACTION.ERROR_GET_NEIGHBOR:
      return { ...state, loading: { ...state.loading, GET_NEIGHBOR: { status: false, detail: action.error.response } } };


    case ACTION.LOADING_PUT_NEIGHBOR:
      return { ...state, loading: { ...state.loading, PUT_NEIGHBOR: { status: true, complete: false } } };
    case ACTION.RECEIVE_PUT_NEIGHBOR: {
      const info = updateNeighborInfo(action);
      return { ...state, loading: { ...state.loading, PUT_NEIGHBOR: { status: undefined, complete: true } }, neighbor: { result: { ...state.neighbor.result, ...info } } };
    }
    case ACTION.ERROR_PUT_NEIGHBOR:
      return { ...state, loading: { ...state.loading, PUT_NEIGHBOR: { status: false, detail: action.error.response , complete: false } } };
    case ACTION.LOADING_SET_NEIGHBOR:
      return { ...state, loading: { ...state.loading, SET_NEIGHBOR: { status: true } } };
    case ACTION.RECEIVE_SET_NEIGHBOR:
      return { ...state, loading: { ...state.loading, SET_NEIGHBOR: undefined }, neighbors: [...state.neighbors, action.response.data] };
    case ACTION.ERROR_SET_NEIGHBOR:
      return { ...state, loading: { ...state.loading, SET_NEIGHBOR: { status: false, detail: action.error.response } } };


    case ACTION.LOADING_SET_NEIGHBOR_CONTACT:
      return { ...state, loading: { ...state.loading, SET_NEIGHBOR_CONTACT: { status: true } } };
    case ACTION.RECEIVE_SET_NEIGHBOR_CONTACT:
      return { ...state, loading: { ...state.loading, SET_NEIGHBOR_CONTACT: undefined } };
    case ACTION.ERROR_SET_NEIGHBOR_CONTACT:
      return { ...state, loading: { ...state.loading, SET_NEIGHBOR_CONTACT: { status: false, detail: action.error.response } } };

    case ACTION.LOADING_SEND_EMAIL_RESIDENTS:
      return { ...state, loading: { ...state.loading, SEND_EMAIL_RESIDENTS: { status: true } } };
    case ACTION.RECEIVE_SEND_EMAIL_RESIDENTS:
      return { ...state, loading: { ...state.loading, SEND_EMAIL_RESIDENTS: undefined } };
    case ACTION.ERROR_SEND_EMAIL_RESIDENTS:
      return { ...state, loading: { ...state.loading, SEND_EMAIL_RESIDENTS: { status: false, detail: action.error.response } } };

    case ACTION.LOADING_GET_NEIGHBOR_CONTACTS:
      return { ...state, loading: { ...state.loading, GET_NEIGHBOR_CONTACTS: { status: true } } };
    case ACTION.RECEIVE_GET_NEIGHBOR_CONTACTS:
      return { ...state, loading: { ...state.loading, GET_NEIGHBOR_CONTACTS: undefined }, contacts: action.result.data };
    case ACTION.ERROR_GET_NEIGHBOR_CONTACTS:
      return { ...state, loading: { ...state.loading, GET_NEIGHBOR_CONTACTS: { status: false, detail: action.error.response } } };


    case ACTION.LOADING_PUT_NEIGHBOR_CONTACT:
      return { ...state, loading: { ...state.loading, PUT_NEIGHBOR_CONTACT: { status: true } } };
    case ACTION.RECEIVE_PUT_NEIGHBOR_CONTACT:
      return { ...state, loading: { ...state.loading, PUT_NEIGHBOR_CONTACT: undefined } };
    case ACTION.ERROR_PUT_NEIGHBOR_CONTACT:
      return { ...state, loading: { ...state.loading, PUT_NEIGHBOR_CONTACT: { status: false, detail: action.error.response } } };


    case ACTION.LOADING_GET_NEIGHBOR_BALANCES:
      return { ...state, loading: { ...state.loading, GET_NEIGHBOR_BALANCES: { status: true } } };
    case ACTION.RECEIVE_GET_NEIGHBOR_BALANCES:
      return { ...state, loading: { ...state.loading, GET_NEIGHBOR_BALANCES: undefined }, balances: action.result.data.result || [] };
    case ACTION.ERROR_GET_NEIGHBOR_BALANCES:
      return { ...state, loading: { ...state.loading, GET_NEIGHBOR_BALANCES: { status: false, detail: action.error.response } } };


    case ACTION.LOADING_GET_NEIGHBOR_PAYMENTS:
      return { ...state, loading: { ...state.loading, GET_NEIGHBOR_PAYMENTS: { status: true } } };
    case ACTION.RECEIVE_GET_NEIGHBOR_PAYMENTS:
      return { ...state, loading: { ...state.loading, GET_NEIGHBOR_PAYMENTS: undefined }, historyPays: action.result.data.result || [] };
    case ACTION.ERROR_GET_NEIGHBOR_PAYMENTS:
      return { ...state, loading: { ...state.loading, GET_NEIGHBOR_PAYMENTS: { status: false, detail: action.error.response } } };

    default:
      return { ...state };
  }
};

export default neighborReducer;
