import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../actions';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';

import './TrialEditor.scss';

let waitTypying;

const TrialEditor = props => {

  const { adminId, value, subscriptionsState } = props;

  const { loading } = subscriptionsState;

  const [ weeks, setWeeks ] = useState(value);

  useEffect(_ => {
    if (weeks && value !== weeks) {
      clearTimeout(waitTypying);
  
      waitTypying = setTimeout(() => props.modifyTrial(adminId, Number(weeks)).then(props.getAdmins), 2000);
    }
  }, [ weeks ]);

  const onChange = e => setWeeks(e.target.value);

  const loadingStatus = loading.MODIFY_TRIAL?.status;

  const rowLoading = loading.MODIFY_TRIAL?.adminId;

  return (
    <div className='TrialEditor row align-center'>
      <FormControl fullWidth>
        <TextField
          variant='outlined'
          value={ weeks }
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">semanas</InputAdornment>,
            startAdornment: rowLoading === adminId && loadingStatus && <CircularProgress size={ 15 } />,
            inputProps: { min: 0 }
          }}
          onChange={ onChange }
          />
      </FormControl>
    </div>
  )
}

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);

const mapStateToProps = state => ({
  subscriptionsState: state.get('subscriptionsState')
});

export default connect(mapStateToProps, mapDispatchToProps) (TrialEditor);
