import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SpinnerConnected from 'components/SpinnerConnected';
import Button from 'components/Button';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import './Title.scss';

export class Tittle extends Component {

  handleGoBack = () => {
    const { property } = this.props;
    this.context.router.push(`/property/${property}/dashboard`);
  }

  render() {
    const { goBack, title, goBackText, hideIcon, validateProperties } = this.props;
    return (
      <div className='TitleFloat'>
        <div className='Title'>
          <div className='Title__back'>
            {
              validateProperties && 
              <Button color='secondary' icon='navigate_before' onClick={this.handleGoBack}> Dashboard </Button>
            }
          </div>
          <div className='Title__title'>
            <span>
              {title}
            </span>
          </div>

          <div className='Title__back_dash'>
              <Button color='secondary' icon='navigate_before' onClick={goBack}>{goBackText}</Button>
          </div>
          <div className='Title__title__info'>
          {
            !hideIcon
            ? <PopupState variant="popover" popupId="demo-popup-popover">
            {popupState => (

                <div>
                  <Button color='secondary' icon='error_outline' {...bindTrigger(popupState)} />
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <p style={{ margin: 16 }}>{this.props.helpMessage}</p>
                  </Popover>
                </div>
              )}
            </PopupState>
            :null
          }


          </div>
          <SpinnerConnected/>
        </div>
      </div>
    );
  }
}

Tittle.propTypes = {
  goBack: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  hideButton: PropTypes.bool,
  goBackText: PropTypes.string,
  hideIcon: PropTypes.bool
};
Tittle.contextTypes = {
  router: PropTypes.shape()
};

Tittle.defaultProps = {
  hideButton: false,
  goBackText: 'Regresar',
};

export default Tittle;
