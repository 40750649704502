import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './PrivacyNotice.scss';

const PrivacyNotice = () => {
    return (
      <React.Fragment>
        <Header />
        <div className="contenedor">
            <h1 className="title-privacy">Imobly</h1>
            <h4> Comprometido en proteger su privacidad</h4>
            <h4> Fecha de actualización: 29 de mayo 2020 </h4>

            <section>
                <p>
                La información personal que nos proporciona (datos personales y,
                en su caso, datos personales sensibles), será tratada conforme a
                lo establecido en este Aviso de Privacidad.
                </p>
                <p>
                La información personal que nos proporciona será utilizada Imobly
                para efectos de proporcionarle información concerniente a las
                Convocatorias de las Asambleas Ordinarias y Extraordinarias del
                Condominio, Pago de Servicios, Nuevos Proyectos, Cuotas de
                Mantenimiento, Circulares, Estados Financieros, Programas de
                Mantenimiento y Mejoramiento y en general todo lo relacionado con
                la Administracióndel Inmueble.
                </p>
                <p>
                Finalmente la información que nos proporciona podrá ser tratada en
                los casos que expresamente autoriza el art. 10 de la Ley Federal
                de Protección de Datos Personales en Posesión de Particulares.
                </p>
          </section>

          <section>
              <h2> A Datos Personales</h2>
              <p> Para los fines señalados en el presente Aviso de Privacidad, Imobly podrá recabar sus datos personales de diversas formas: </p> 
              <p> 1. Cuando la información es proporcionada directamente por usted por cualquier medio. </p> 
              <p> 2. Cuando obtenemos información a través de otros medios distintos a los antes señalados y que están permitidos por las leyes aplicables.Los datos personales obtenidos por los medios antes indicados, pueden ser: nombre, domicilio, correo electrónico, número telefónico y cualquier otro que se requiera conforme a las finalidades establecidas en el presente Aviso de Privacidad. </p>
          </section>

          <section>
                <h2> B. Uso de Datos Personales Sensibles.</h2>
                <p> Se consideran datos personales sensibles aquellos datos personales que afecten a la esfera más íntima de su titular, o cuya utilización indebida pueda dar origen a discriminación o conlleve un riesgo grave para éste. En particular, se consideran sensibles aquellos que puedan revelar aspectos como origen racial o étnico, estado de salud presente y futuro, información genética, creencias religiosas, filosóficas y morales, afiliación sindical, opiniones políticas, preferencias sexuales y dicha información sólo es recopilada por Imobly, de los Condóminos y/o Usuarios de los Condominios y podrá ser utilizada para las finalidades establecidas en el presente Aviso de Privacidad. </p> 
          </section>

          <section>
            <h2> C. Limitación al Uso o Divulgación de Información Personal.</h2>  
            <p>Usted podrá limitar en cualquier momento que a la información que proporciona a Imobly, se le dé el tratamiento señalado en el inciso B, mediante una solicitud que deberá ser presentada por escrito al siguiente correo electrónico admin@imobly.io Con la finalidad de proteger su confidencialidad, Imobly enviará la respuesta a su solicitud por escrito al domicilio o al correo electrónico que haya proporcionado en su escrito de solicitud, a elección de Imobly. </p> 
          </section>

          <section>
            <h2> D. Del Ejercicio de los Derechos de Acceso, Rectificación, Cancelación y Oposición. </h2> 
            <p>Dicho Softwarees propiedad y operada por: Imobly S.A. de C.V. (en adelante “Imobly”) con correoelectrónico: admin@imobly.io, los pagos que se realizan desde este software serán procesados através del gateway Openpay </p> 
          </section>

          <section>
            <h2> E. Control y Seguridad de información personal. </h2> 
            <p>Imobly se compromete a tomar las medidas necesarias para proteger la información recopilada, utilizando medidas y procedimientos de control y seguridad en el acceso, uso o divulgación de su información personal sin autorización.</p>
          </section>

          <section>
          <h2> F. Cambios a esta declaración de privacidad. </h2>  
            <p> Imobly podrá en cualquier momento actualizar esta declaración de privacidad. En el caso de que se produzcan cambios sustanciales a esta declaración, lo comunicaremos a través de una circular entregada a cada uno de los condóminos de manera física o electrónica o a través de una Asamblea Ordinaria o Extraordinaria. </p> 
            <p> Acepto que la información personal que proporciono a Imobly se trate conforme a lo establecido en este Aviso de Privacidad. </p> 
            <p>Me doy por enterado del contenido del Aviso de Privacidad.</p>
          </section>

          <section>
            <h2> AVISO DE PRIVACIDAD. </h2> 
            <p>Con fundamento en los artículos 15 y 16 de la Ley Federal de Protección de Datos Personales en Posesión de Particulares hacemos de su conocimiento que, Imobly Plataforma de software dedicada a la administración y operación de inmuebles, con domicilio en Av. Paseo de la Reforma #195 Colonia Cuauhtémoc, Delegación Cuauhtémoc, México D.F. Es responsable de recabar sus datos personales, del uso que se le dé a los mismos y de su protección.</p>
            <p> La información personal que nos proporciona será utilizada por Imobly para efectos de proporcionarle información concerniente a las Convocatorias de las Asambleas Ordinarias y Extraordinarias del Condominio, Pago de Servicios, Nuevos Proyectos, Cuotas de Mantenimiento, Circulares, Estados Financieros, Programas de Mantenimiento y Mejoramiento y en general todo lo relacionado con la Administración del Inmueble. </p> 
            <p>Finalmente la información que nos proporciona podrá ser tratada en los casos que expresamente autoriza el art. 10 de la Ley Federal de Protección de Datos Personales en Posesión de Particulares.</p>
          </section>

        </div>
        <Footer />
      </React.Fragment>
    );
}
 
export default PrivacyNotice;
