import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { ReactSVG } from "react-svg";
import * as Actions from "../../Property/propertyActions";
import { showIconAmenity } from "constants/amenityTypes";
import "./AmenityCounter.scss";

import checkIcon from "assets/img/check.svg";
import AddIcon from "@material-ui/icons/Add";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import RemoveIcon from "@material-ui/icons/Remove";

const AmenityCounter = props => {

  const updateAmenity = (amenityId, value) => {
    const { amenities } = props;

    const amenityIndex = amenities.findIndex(
      (amenity) => amenity.id === amenityId
    );

    amenities[amenityIndex].value = value;
    amenities[amenityIndex].checked = !!value;

    props.onChange(amenities[amenityIndex]);
  };

  const addAmenity = (amenity) => {
    const value = (amenity.value || 0) + 1;

    updateAmenity(amenity.id, value);
  };

  const subtractAmenity = (amenity) => {
    const value = (amenity.value || 1) - 1;

    updateAmenity(amenity.id, value);
  };

  return (
    <div className="AmenityCounter col">
      <div className="AmenityCounter__display row">
        { (props.amenities || []).map((amenity, index) => (
          <div className="AmenityCounter__display__option row" key={ index }>
            <div className={ `AmenityCounter__display__item col ${ amenity.checked && 'AmenityCounter__display__item__active' }` }>
              <div
                onClick={() => updateAmenity(amenity.id, 1)}
              >
                <label className="subtitle">{ amenity.name }</label>
                <img
                  alt="check"
                  src={ checkIcon }
                  className={ amenity.checked ? "AmenityCounter__display__item__check__visible" : "AmenityCounter__display__item__check" }
                />
                <ReactSVG
                  src={showIconAmenity(amenity.id).img}
                />
              </div>
              {
                amenity.checked && (
                  <Paper className="CustomInput">
                    <IconButton
                      aria-label="minus"
                      className="CustomInput__Button"
                      onClick={() => subtractAmenity(amenity)}
                    >
                      <RemoveIcon />
                    </IconButton>
                    <InputBase
                      value={amenity.value}
                      onChange={(e) =>
                        updateAmenity(amenity.id, e.target.value)
                      }
                      className="CustomInput__Input"
                      id="icon-menos"
                    />
                    <Divider orientation="vertical" />
                    <IconButton
                      aria-label="add"
                      className="CustomInput__Button"
                      onClick={() => addAmenity(amenity)}
                    >
                      <AddIcon />
                    </IconButton>
                  </Paper>
                )
              }
            </div>
          </div>
        )) }
      </div>
    </div>
  );
}

AmenityCounter.contextTypes = {
  router: PropTypes.shape(),
};

AmenityCounter.propTypes = {
  onChange: PropTypes.func.isRequired,
  amenities: PropTypes.array.isRequired
};

const mapDispatchToProps = (dispatch) => bindActionCreators(Actions, dispatch);
const mapStateToProps = (state) => ({
  loginState: state.get("loginState"),
  propertyState: state.get("propertyState"),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AmenityCounter);
