import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as Actions from '../loginActions';
import { FormattedMessage } from 'react-intl';

import SignUpForm from './SignUpForm';
import { EMAIL_REGEX, EXP_NAME, EXP_PASSWORD } from 'constants/globalRegex';

export class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      error: {}
    };
  }
  componentDidMount() {
    const {namep,emailp} = this.props;
    this.setState({name: namep, email: emailp });
  }


  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }

  goTo = (where) => () => {
    this.context.router.push(`/${where}`);
  }

  onChange = (event) => {
    const { error } = this.state;
    delete error[event.target.name];
    this.setState({ [event.target.name]: event.target.value, error });
  }

  hasErrors() {
    const { name, email, password, confirmPassword } = this.state;
    let error = {};
    let isError = false;

    if(!name || !EXP_NAME.test(name)){
      error['name'] = <FormattedMessage id='SignUp.invalidName' />;
      isError = true;
    }

    if(!email || !EMAIL_REGEX.test(email)){
      error['email'] = <FormattedMessage id='SignUp.invalidEmail' />;
      isError = true;
    }
    if(!password || !EXP_PASSWORD.test(password)){
      error['password'] = <FormattedMessage id='SignUp.invalidPassword' />;
      isError = true;
    }
    if(!confirmPassword || !EXP_PASSWORD.test(confirmPassword)){
      error['confirmPassword'] = <FormattedMessage id='SignUp.invalidPassword' />;
      isError = true;
    }
    if(password !== confirmPassword){
      error['confirmPassword'] = <FormattedMessage id='SignUp.notSamePassword' />;
      isError = true;
    }
    if (isError) {
      this.setState({ error })
    }
    return isError;
  }

  createUser() {
    const { name, email, password } = this.state;
    this.props.putRegister({ name, email, password }).then(() => {
      const { EDIT_REGISTER_SIGNUP } = this.props.loginState.loading;
      if(!EDIT_REGISTER_SIGNUP) {
        this.context.router.push('/code');
      }
    })
  }

  getUser() {
    const { name, email, password } = this.state;
    this.props.getRegister({ name, email, password }).then(()=>{
      const { REGISTER_SIGNUP } = this.props.loginState.loading;
      if(!REGISTER_SIGNUP){
        this.context.router.push('/code');
      }
    });
  }

  onSubmit = () => {
    const { email } = this.state;
    const { namep, emailp } = this.props;
    
    if (!this.hasErrors()) {
      this.props.addLoginData({ registro: email }).then(()=>{
        namep && emailp ? this.createUser()  : this.getUser();
      });
    }
  }

  responseGoogle = (response) => {
    this.props.getLoginGoogle({
      token: response.accessToken,
    });
  };

  responseFacebook = (response) => {
    this.props.getLoginFacebook({
      token: response.accessToken,
    });
  };

  render(){
    const { showPassword, password, name, email, confirmPassword, error } = this.state;
    const {namep, emailp} = this.props;
    return(
      <SignUpForm
        title={namep && emailp ? 'Register.titleAlt' : 'Register.title' }
        name={name}
        email={email}
        password={password}
        confirmPassword={confirmPassword}
        showPassword={showPassword}
        viewPassword={this.handleClickShowPassword}
        error={error}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
        responseGoogle={this.responseGoogle}
        responseFacebook={this.responseFacebook}
        goTo={this.goTo}
      />
    );
  }
}

SignUp.contextTypes = {
  router: PropTypes.shape()
};

SignUp.propTypes = {
  addLoginData: PropTypes.func,
  getRegister: PropTypes.func,
};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);

