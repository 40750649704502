import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { email as emailValidation, phoneNumber } from 'utils/validationsRules';
import * as Actions from '../neighborsActions';
import Card from 'components/Card';
import Button from 'components/Button';
import Input from 'components/Input';
import Default from 'assets/img/user-profile.svg';
import './NeighborEdit.scss';

export class NeighborEdit extends Component {
  constructor(props) {
    super(props);
    const {
      name,
      first_last_name,
      second_last_name,
      email,
      phone,
      id_client
    } = props.location.state.result;
    this.state = {
      name: this.nullIfBlank(name),
      first_last_name: this.nullIfBlank(first_last_name),
      second_last_name: this.nullIfBlank(second_last_name),
      email: this.nullIfBlank(email),
      phone: this.nullIfBlank(phone),
      id_client,
      errors: {
        name: !this.nullIfBlank(name),
        first_last_name: !this.nullIfBlank(first_last_name)
      }
    };
  }

  componentDidMount() {
    const { property, dwellingID } = this.props.params;
    if (dwellingID) {
      this.props.getNeighbor({
        pid: property,
        did: dwellingID
      });
    }
  }

  onChange = (event) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;
    switch (inputName) {
      case 'email':
        if (emailValidation(inputValue).validation) {
          this.setState(state => (
            { ...state, errors: { ...state.errors, email: false } }
          ));
        } else {
          this.setState(state => ({
            ...state,
            errors: { ...state.errors, email: true }
          }));
        }
        break;
      case 'phone':
        if (phoneNumber(inputValue).validation) {
          this.setState(state => (
            { ...state, errors: { ...state.errors, phone: false } }
          ));
        } else {
          this.setState(state => ({
            ...state,
            errors: { ...state.errors, phone: true }
          }));
        }
        break;
      case 'name':
      case 'first_last_name':
        this.setState(state => ({
          ...this.state,
          errors: { ...state.errors, [inputName]: inputValue.trim() === '' }
        }));
        break;
      default:
        break;
    }
    this.setState(state => ({ state, [inputName]: this.nullIfBlank(inputValue) }));
  }

  onPut = (dataToUp) => {
    const { property } = this.props.params;
    const { dwelling_id } = this.props.neighborState.neighbor.result;
    this.props.putNeighbor({
      idProperty: property,
      idDwelling: dwelling_id,
      dataResident: dataToUp
    });
  }
  onSubmit = () => {
    if (this.state.errors.email || this.state.errors.phone) {
      return;
    }
    this.onPut(this.state);
  }
  getTitle = (contactId, name, lastName, middleName) => {
    if (contactId !== '0') {
      const fullName = ` ${name || ''} ${lastName || ''} ${middleName || ''}`;
      return <FormattedMessage id='Neighbor.Edit.Title' values={{ name: fullName }} />;
    }
    return <FormattedMessage id='Neighbor.Edit.New' />;
  }
  showLoading = () => {
    const { PUT_NEIGHBOR } = this.props.neighborState.loading;
    if (PUT_NEIGHBOR && PUT_NEIGHBOR.complete) {
      this.goBack();
    }
    return PUT_NEIGHBOR ? PUT_NEIGHBOR.status : false;
  }
  goBack = () => {
    const { property, contactID, dwellingID } = this.props.params;
    this.context.router.push(`/property/${property}/contacts/${contactID}/dwellings/${dwellingID}`);
  }
  nullIfBlank = chars => (chars === null || typeof chars === 'undefined' || chars.trim() === '') ? null : chars;

  render() {
    const { contactID } = this.props.params;
    const {
      name,
      first_last_name,
      second_last_name,
      phone,
      email
    } = this.state;
    this.showLoading();
    return (
      <div className='NeighborEdit'>
        <div className='NeighborEdit__title'>
          {
            this.getTitle(contactID, name, first_last_name, second_last_name)
          }
        </div>
        <Card className='card-list SimpleCard'>
          <div className='NeighborEdit__container'>
            <div className='NeighborEdit__content'>
              <div className='NeighborEdit__content__image'>
                <div className='NeighborEdit__content__image__img'>
                  <img src={Default} alt='Neightbor' />
                </div>
              </div>
              <div className='NeighborEdit__content__main'>
                <div className='NeighborEdit__content__item'>
                  <Input
                    label='Nombre(s) del residente*'
                    fullWidth
                    onChange={this.onChange}
                    value={name}
                    name='name'
                    error={this.state.errors.name}
                  />
                </div>
                <div className='NeighborEdit__content__item'>
                  <Input
                    label='Apellido paterno*'
                    fullWidth
                    onChange={this.onChange}
                    error={this.state.errors.first_last_name}
                    value={first_last_name}
                    name='first_last_name'
                  />
                </div>
                <div className='NeighborEdit__content__item'>
                  <Input
                    error={this.state.errors.second_last_name}
                    label='Apellido materno'
                    fullWidth
                    onChange={this.onChange}
                    value={second_last_name}
                    name='second_last_name'
                  />
                </div>
                <div className='NeighborEdit__content__item'>
                  <Input
                    error={this.state.errors.phone}
                    label='Teléfono'
                    fullWidth
                    onChange={this.onChange}
                    value={phone}
                    type='number'
                    name='phone'
                  />
                </div>
                <div className='NeighborEdit__content__item'>
                  <Input
                    error={this.state.errors.email}
                    label='Correo'
                    fullWidth
                    onChange={this.onChange}
                    value={email}
                    name='email'
                  />
                </div>
              </div>
              <div className='NeighborEdit__content__options'>
                <Button color='secondary' variant='outlined' onClick={this.goBack}>Cancelar</Button>
                <Button
                  variant='contained'
                  disabled={
                    this.state.errors.email || this.state.errors.phone
                    || this.state.errors.name || this.state.errors.first_last_name
                  }
                  color='secondary'
                  onClick={this.onSubmit}
                >
                  Guardar
                </Button>
                {
                  this.showLoading() ?
                    <CircularProgress />
                    : null
                }
              </div>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

NeighborEdit.contextTypes = {
  router: PropTypes.instanceOf(Object)
};

NeighborEdit.defaultProps = {
  contactID: '0',
  params: null,
  loginState: null,
  getNeighbor: null,
  location: {},
  neighborState: null,
  putNeighbor: null
};
NeighborEdit.propTypes = {
  contactID: PropTypes.string,
  params: PropTypes.instanceOf(Object),
  loginState: PropTypes.instanceOf(Object),
  getNeighbor: PropTypes.func,
  location: PropTypes.instanceOf(Object),
  neighborState: PropTypes.instanceOf(Object),
  putNeighbor: PropTypes.func
};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  neighborState: state.get('neighborState'),
});

export default connect(mapStateToProps, mapDispatchToProps)(NeighborEdit);
