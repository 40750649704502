import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../regulationActions';

import Button from 'components/Button';
import Dropzone from '../Dropzone/Dropzone';

class ReportAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,
    };

    this.onFilesAdded = this.onFilesAdded.bind(this);
  }

  componentDidMount() {
    const { idProperty, tipe } = this.props;
    if (tipe) {
      this.props.getRegulations({ idProperty, tipe });
    }
  }

  onFilesAdded(files) {
    this.onHandleChange(files[0]);
    this.setState((prevState) => ({
      files: prevState.files.concat(files),
    }));
  }

  onChangeFile = (evt) => {
    const file = evt.target.files[0];
    const { idProperty, tipe } = this.props;
    let data = new FormData();
    data.append('name', file.name);
    data.append('bucket', 'api-images');
    data.append('type', 'report_admin');
    data.append('file', file);
    this.props
      .uploadRegulation({ idProperty, data })
      .then(() => {
        this.props.getRegulations({ idProperty, tipe });
      });
  };

  onHandleChange = (file) => {
    if (file) {
      const { idProperty, tipe } = this.props;
      let data = new FormData();
      data.append('name', file.name);
      data.append('bucket', 'api-images');
      data.append('type', 'report_admin');
      data.append('file', file);
      this.props
        .uploadRegulation({ idProperty, data })
        .then(() => {
          this.props.getRegulations({ idProperty, tipe });
        });
    }
  };

  render() {
    const { regulations } = this.props.propertyState;

    if (regulations.result !== null) {
        if (regulations.result.length > 0) {
          return (
            <div className='RegulationContainer'>
              <input
                style={{display: 'none' }}
                id="contained-button-file-report_admin"
                type="file"
                onChange={this.onChangeFile}
              />
              <label htmlFor="contained-button-file-report_admin">
                <Button variant="outlined" color='secondary' component="span">
                  Crear reporte de administración
                </Button>
              </label>
              <div className='RegulationTable'>
                <div className='RegulationTable__header'>
                  <div className='RegulationTable__header__tab'>
                    <span>Nombre de reporte de administración</span>
                  </div>
                  <div className='RegulationTable__header__tab date'>
                    <span>Fecha de creación</span>
                  </div>
                  <div className='RegulationTable__header__tab link'>
                    <span>Ver detalle</span>
                  </div>
                </div>
                {regulations.result.map((regulation, index) => (
                  <div key={`RegulationContainer${regulation}${index}`} className='RegulationTable__item'>
                    <div className='RegulationTable__item__section'>
                      <span>{regulation.name}</span>
                    </div>
                    <div className='RegulationTable__item__section date'>
                      <span>{regulation.created_at}</span>
                    </div>
                    <div className='RegulationTable__item__section link'>
                      <a href={regulation.url} target='_blank' rel="noreferrer">
                        <span>Ver reporte de administración</span>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        } else {
          return (
            <div className='Regulation'>
              <div className='Regulation_container'>
                <div className='Regulation__title'>Añade tus reportes de administración</div>
                <div className='Regulation__text'>Comparte un archivo Word o PDF con tus vecinos</div>
                <Dropzone
                  onFilesAdded={this.onFilesAdded}
                  disabled={this.state.uploading || this.state.successfullUploaded}
                />
              </div>
            </div>
          );
        }
      } else {
        return (
          <div className='Regulation'>
            <div className='Regulation_container'>
              <div className='Regulation__title'>Añade tus reportes de administración</div>
              <div className='Regulation__text'>Comparte un archivo Word o PDF con tus vecinos</div>
              <Dropzone
                onFilesAdded={this.onFilesAdded}
                disabled={this.state.uploading || this.state.successfullUploaded}
              />
            </div>
          </div>
        );
      }
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  propertyState: state.get('propertyState'),
});

export default connect(mapStateToProps, mapDispatchToProps) (ReportAdmin);
