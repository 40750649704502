import React, { Component } from 'react';
import notification from 'antd/lib/notification';

import './Report.scss';
import { Button } from 'components/Button/Button';

export class Report extends Component {
  sendReport = () => {
    this.props.setReportAdmin({
      data: { }
    }).then(() => {
      notification.open({
        message: 'Reporte',
        description: 'Se ha enviado correctamente.',
        type: 'success'
      });
    }).catch(() => {
      notification.open({
        message: 'Reporte',
        description: 'No se pudo realizar el envio.',
        type: 'error'
      });
    });
  }

  render() {
    return (
      <div className="Report">
        <div className="Report__container">
          <div className="Report__container__box">
            <h2>Reporte Administrador:</h2>

            <Button
              variant="outlined"
              color="secondary"
              onClick={this.sendReport}
            >
              Descargar
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Report;
