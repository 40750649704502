import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from 'containers/Budget/budgetActions';

import { FormattedMessage } from 'react-intl';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Popover } from 'antd';
import BalanceAvailable from 'containers/Wizard/Budget/Detail/BalanceAvailable' 

import Button from 'components/Button';

import { showIconAmenity } from 'constants/amenityTypes';
import { toMoneyFormat } from 'utils/format';

import './WizardHeader.scss';
export class WizardHeader extends Component {

  handleDetermineLabel = () => {
    const { showDeptos, numberDwellings, icon} = this.props;
    const homeLabel = numberDwellings > 1 ? 'casas' : 'casa';
    const deptoLabel = numberDwellings > 1 ? 'deptos.' : 'depto.';
    const selectLabel = icon === 'tower' ? deptoLabel : homeLabel;
    if (showDeptos) {
      return (
        <div className='heading-5 color-primary'>{numberDwellings} {selectLabel}</div>
      );
    }
    return null;
  }
  render() {
    const { name, icon, handleShowFound, showQuota, initial_balance, balance, onChange, onBlur, propertySelected, disabledEnter } = this.props;
    const direction = window.innerWidth;
    const { budgetsAndFees, budgetsAndFeesAmenities } = this.props.budgetState;
    return (
      <div className="WizardHeader row align-center space-between spacing">
        <div className="row">
          <ReactSVG
            className="img-properties flex-zero"
            src={showIconAmenity(icon).img}
            beforeInjection={(svg) => {
              svg.setAttribute("style", "width: 49px");
            }}
          />

          <div className="Header__titles">
            <div className="heading-3">
              {direction >= 1100 && name.length > 27
                ? name.substring(0, 27) + "..."
                : name}
            </div>
            {this.handleDetermineLabel()}
          </div>
        </div>

        {
          !!Object.keys(budgetsAndFeesAmenities).length && propertySelected?.type === 'amenity' && (
          <>
          <div className="col">
            <h5 className="title-budget row space-right">
              <FormattedMessage id="Budget.header.label.title" />
              <Popover content={<FormattedMessage id="Budget.header.tooltip" />} placement="right" className="justify" >
                <InfoOutlinedIcon />
              </Popover>
            </h5>
            <div className="row">
              <p className="body-text1">
                <FormattedMessage id="Budget.header.label.annual" />
              </p>
              <h5 className="space-label-monthly">
                $ {toMoneyFormat(budgetsAndFeesAmenities.annual_budget)}
              </h5>
            </div>
            <div className="row">
              <p className="body-text1">
                <FormattedMessage id="Budget.header.label.monthly" />
              </p>
              <h5 className="space-label-monthly">
                $ {toMoneyFormat(budgetsAndFeesAmenities.monthly_budget)}
              </h5>
              </div>
          </div>
          <BalanceAvailable 
              initial_balance={ initial_balance }
              balance={ balance }
              onChange={onChange}
              onBlur={onBlur}
              disabledEnter={disabledEnter}
           />
           </>
          )
        }

        {!!Object.keys(budgetsAndFees).length && showQuota && (
          <>
            <div className="col">
              <h5 className="title-budget row space-right">
                <FormattedMessage id="Budget.header.label.title" />
                <Popover content={<FormattedMessage id="Budget.header.tooltip" />} placement="right" className="justify" >
                  <InfoOutlinedIcon />
                </Popover>
              </h5>
              <div className="row">
                <p className="body-text1">
                  <FormattedMessage id="Budget.header.label.annual" />
                </p>
                <h5 className="space-label-monthly">
                  $ {toMoneyFormat(budgetsAndFees.annual_budget)}
                </h5>
              </div>
              <div className="row">
                <p className="body-text1">
                  <FormattedMessage id="Budget.header.label.monthly" />
                </p>
                <h5 className="space-label-monthly">
                  $ {toMoneyFormat(budgetsAndFees.monthly_budget)}
                </h5>
              </div>
            </div>
            
            <div className="col">
              <h5 className="title-budget row space-right">
                <FormattedMessage id="Budget.header.label.fee.title" />
                <Popover
                  content={<FormattedMessage id="Budget.header.tooltip.fees" />}
                  placement="right"
                >
                  <InfoOutlinedIcon />
                </Popover>
              </h5>
              <div className="row">
                <p className="body-text1">
                  <FormattedMessage id="Budget.header.label.suggested" />
                </p>
                <h5>$ {toMoneyFormat(budgetsAndFees.suggested_fee)}</h5>
              </div>
              <div className="row">
                <p className="body-text1">
                  <FormattedMessage id="Budget.header.label.funds" />
                </p>
                <h5 className="space-label-monthly">
                  $ {toMoneyFormat(budgetsAndFees.funds_fee)}
                </h5>
              </div>
            </div>
            <div className="col align-center">
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleShowFound}
              >
                {" "}
                + <FormattedMessage id="Button.add.money" />
              </Button>
            </div>
            <BalanceAvailable
              { ...this.props }
              initial_balance={initial_balance}
              onChange={onChange}
              onBlur={onBlur}
              disabledEnter={disabledEnter}
           />
          </>
        )}
      </div>
    );
  }
}

WizardHeader.defaultProps = {
  numberDwellings: 0,
  name: '',
  icon: '',
  showDeptos: false,
};

WizardHeader.propTypes = {
  numberDwellings: PropTypes.number,
  name: PropTypes.string,
  showDeptos: PropTypes.bool,
};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  budgetState: state.get('budgetState'),
  routerState: state.get('routing'),
});

export default connect(mapStateToProps, mapDispatchToProps) (WizardHeader);
