import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import MaxLengthTextField from '../MaxLengthTextField';
import { notification } from 'antd';
import Button from '../Button';
import Checkbox from '../Checkbox';
import RadioGroup from '../RadioGroup';
import FileButton from '../FileButton';
import DateRangePicker from '../DateRangePicker';
import FieldConcept from 'containers/Wizard/Budget/Detail/ConceptItem/FieldConcept';
import Select from '../Select';
import ConfirmationModal from './ConfirmationModal';
import { FormattedMessage } from 'react-intl';

import './Form.scss';

export const Form = props => {

  let defaultValues = { };

  const { title, controls, onCancel, submitDisabled, clearOnSubmitted, loading, changeInterceptor } = props;

  const [ formValue, setFormValue ] = useState({ });

  const [ attempts, setAttempts ] = useState(0);

  const [ confirmationModalVisibility, setConfirmationModalVisibility ] = useState(false);

  const submit = _ => {
    if (props.onSubmit) {
      (props.onSubmit({
        ...defaultValues,
        ...formValue
      }) || Promise.reject()).then((values) => {
        setConfirmationModalVisibility(false);
        
        props.notificationMessage && (
          notification.open({
            message: props.notificationMessage,
            type: 'success',
          })
        );
        
        if (clearOnSubmitted) {
          setAttempts(attempts + 1);
          setFormValue({ ...defaultValues,  ...values });
        }
      });
    }
  }
  const onSubmit = e => {
    e.preventDefault();
    
    if (props.needsUserConfirmation) {
      setConfirmationModalVisibility(true);
    } else {
      submit();
    }
  }

  const getComponentType = control => {
    switch (control.type) {
      case 'lengthCounter':
        return {
          component: MaxLengthTextField
        }
      case 'currency':
        return {
          component: CurrencyTextField,
          useCurrencyFormat: true,
          defaultProps: {
            currencySymbol: '$',
            outputFormat: 'number',
            inputProps: {
              autocomplete: 'off',
              form: {
                autocomplete: 'off',
              }
            }
          }
        }
      case 'file':
        return {
          component: FileButton
        }
      case 'checkbox':
        return {
          component: Checkbox,
          useToggleValue: true
        }
      case 'radio':
        return {
          component: RadioGroup,
          useEventTarget: true,
          defaultProps: {
            input: {
              name: control.name
            }
          }
        }
      case 'date':
        return {
          component: TextField,
          useEventTarget: true,
          defaultProps: {
            type: 'date',
            InputLabelProps: {
              shrink: true
            }
          }
        }
      
      case 'range':
        return {
          component: DateRangePicker
        }
      case 'select':
        return {
          component: Select,
          useEventTarget: true,
          defaultProps: {
            variant: 'outlined',
            labelProps: {
              shrink: true
            }
          }
        }
      case 'concept':
        return {
          component: FieldConcept,
          defaultProps: {
            toEdit: 'toValidate',
            asset_type: 'tools',
            onClick: _ => (e) =>  {
              onChange(control.name, e.key);
            },
            variant: 'outlined',
            value: formValue[control.name],
            labelProps: {
              shrink: true
            }
          }
        }
      default:
        return {
          component: TextField,
          useEventTarget: true,
          defaultProps: {
            InputLabelProps: {
              shrink: true
            }
          }
        }
    }
  }

  const onChange = (name, value) => {
    changeInterceptor && changeInterceptor(name, value);
    setFormValue({ ...defaultValues, ...formValue, [name]: value });
  }

  const handleEventTarget = e => onChange(e.target.name, e.target.value);

  const handleValue = name => value => onChange(name, value);

  const handleToggleValue = name => _ => onChange(name, !formValue[name]);

  const handleCurrencyFormat = e => onChange(e.target.name, (e.target.value || '').replaceAll(',', ''))

  const getOnChangeEvent = (controlType, control) => {
    if (controlType.useCurrencyFormat) {
      return handleCurrencyFormat;
    }
    if (controlType.useToggleValue) {
      return handleToggleValue(control.name);
    }
    if (controlType.useEventTarget) {
      return handleEventTarget;
    }
    return handleValue(control.name)
  }

  const getControl = control => {
    const controlType = getComponentType(control);
    const Control = controlType.component;

    if (control.type === 'checkbox') {
      defaultValues[control.name] = !!control.props?.defaultValue;
    } else {
      defaultValues[control.name] = control.props?.defaultValue;
    }
    
    const controlProps = {
      ...controlType.defaultProps,
      ...control.props
    }

    return <Control
      name={ control.name }
      label={ control.label }
      onChange={ getOnChangeEvent(controlType, control) }
      { ...controlProps }
    />
  }

  const getFormGrid = (item, index, inRow) => {
    if (Array.isArray(item)) {
      return (
        <div key={ index } className={ `row ${ item.length > 1 && 'spacing' }` }>
          { item.map((control, subIndex) => getFormGrid(control, subIndex, true)) }
        </div>
      );
    } else if (inRow) {
      return (
        <div key={ index } className='col'>
          { getControl(item) }
        </div>
      );
    }
    return (
      <div key={ index } className='row'>
        { getControl(item) }
      </div>
    );
  }

  const renderFormControls = _ => controls.map((control, index) => getFormGrid(control, `${ index }-${ attempts }`));
  
  return (
    <div className="Form col">
      { title && <p className='heading-5'>{ title }</p> }
      <form className='col' onSubmit={ onSubmit }>
        { renderFormControls() }
        <div className="row row-flex-end">
          { onCancel && (
            <Button disabled={ loading } variant="outlined" color="primary" onClick={ onCancel }>
              <FormattedMessage id='Button.cancel'/>
            </Button>
          )}
          {(
            <Button type="submit" disabled={ (submitDisabled && submitDisabled(formValue)) || loading } variant="contained" color="primary">
              { props.submitText || <FormattedMessage id='Button.save'/> }
            </Button>
          )}
        </div>
      </form>
      <ConfirmationModal open={ confirmationModalVisibility } { ...props } onClose={ _ => setConfirmationModalVisibility(false) } onAccept={ submit } values={{
        ...defaultValues,
        ...formValue
      }}/>
    </div>
  );
};

export default Form;
