import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from './loginActions';
import { getProfile } from '../Profile/ProfileActions';
import PropTypes from 'prop-types';

import Login from './Login/Login';
import Signup from './SigUp/SignUp';
import Remember from './Remember/Remember';
import Code from './Code/Code';
import Unauthorized from './Unauthorized/Unauthorized';
import Registered from './Unauthorized/Registered';
import WithoutPending from './Unauthorized/WithoutPending';
import WithPending from './Unauthorized/WithPending';
import Reset from './Unauthorized/Reset';
import Welcome from './Unauthorized/Welcome';
import RememberMe from './Unauthorized/RememberMe';
import CodeError from './Unauthorized/CodeError';
import ErrorSend from './Unauthorized/ErrorSend';

import './LoginPage.scss';

class LoginPage extends Component {
  componentDidMount(){
    this.handleRedirection();
  }

  componentWillUpdate(prevState){
    const before = prevState.loginState.Authorization;
    const next = this.props.loginState.Authorization;
    if(before != next){
      this.handleRedirection();
    }
  }

  handleRedirection = () => {
    const LOCAL_AUTH = localStorage.getItem('imobly');
    
    this.props.getProfile().then(() => {
      const { resident_last_view, resident_role } = this.props.profileState;

      if (LOCAL_AUTH && resident_role?.length) {
        if (resident_last_view !== 0) {
          this.props.router.push(`/Wizard/11/property/${ resident_last_view }`);
        } else {
          this.props.router.push('/Wizard/info/property');
        }
      }
    });
  }

  showView = (type, name, email) => {
    const { reset, remember } = this.props.loginState;
    const { NORMAL_LOGIN, REGISTER_SIGNUP, CODE_SIGNUP, REMEMBER_LOGIN, RESET_SIGNUP } = this.props.loginState.loading;

    if (remember) {
      return <RememberMe />;
    }
    if (reset && reset === 'ok') {
      return <Reset />;
    }
    if (RESET_SIGNUP && RESET_SIGNUP.detail && RESET_SIGNUP.detail.data.code === 41) {
      return <WithoutPending />;
    }
    if (NORMAL_LOGIN && NORMAL_LOGIN.detail && NORMAL_LOGIN.detail.data.code === 21) {
      return <Unauthorized />;
    }
    if (REGISTER_SIGNUP && REGISTER_SIGNUP.detail && REGISTER_SIGNUP.detail.data.code === 30) {
      return <WithPending />;
    }
    if (REGISTER_SIGNUP && REGISTER_SIGNUP.detail && REGISTER_SIGNUP.detail.data.code === 31) {
      return <Registered />;
    }
    if (REGISTER_SIGNUP && REGISTER_SIGNUP.detail && REGISTER_SIGNUP.detail.data.code === 32) {
      return <WithPending />;
    }
    if (NORMAL_LOGIN && NORMAL_LOGIN.detail && NORMAL_LOGIN.detail.data.code === 32) {
      return <WithPending />;
    }
    if (REMEMBER_LOGIN && REMEMBER_LOGIN.detail && REMEMBER_LOGIN.detail.data.code === 32) {
      return <WithPending />;
    }
    if (REMEMBER_LOGIN && REMEMBER_LOGIN.detail && REMEMBER_LOGIN.detail.data.code === 50) {
      return <ErrorSend />
    }
    if (CODE_SIGNUP && CODE_SIGNUP.detail && CODE_SIGNUP.detail.data.code === 43) {
      return <CodeError />
    }
    

    switch (type) {
      case 'signup':
        return <Signup
          namep={name}
          emailp={email}
        />;
      case 'remember':
        return <Remember />;
      case 'code':
        return <Code />;
      case 'welcome':
        return <Welcome />;
      default:
        return <Login  />;
    }
  }

  render() {
    const { type, name, email } = this.props.params;
    return (
      <div className='LoginPage'>
        <div className='LoginPage__containerForm'>
          <div className='LoginPage__containerForm__container'>
            { this.showView(type,name,email) }
          </div>
        </div>
      </div>
    );
  }
}

LoginPage.contextTypes = { router: PropTypes.shape() };
LoginPage.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({ ...Actions, getProfile }, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  profileState: state.get('profileState'),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
