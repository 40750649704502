import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Routes from 'constants/wizardRoutes';
import Info from './Info/Info';
import Address from './Address/Address';
import Build from './Build/Build';
import Budget from './Budget/Budget';
import Quota from './Quota/Quota';
import Init from './Account/Init/index';

class WizardPage extends Component {
  view = () => {
    const { params } = this.props;
    switch (params.id) {
      case Routes.WIZARD_ADDRESS:
        return (
          <Address params={params} />
        );
      case Routes.WIZARD_BUILD:
        return (
          <Build params={params} />
        );
      case Routes.WIZARD_BUDGET:
        return (
          <Budget params={params} />
        );
      case Routes.WIZARD_QUOTA:
        return (
          <Quota params={params} />
        );
      case Routes.WIZARD_ACCOUNT:
        return (
          <Init params={params} />
        );
      default:
        return (
          <Info params={params} />
        );
    }
  }

  render() {
    return this.view();
  }
}

WizardPage.propTypes = {
  params: PropTypes.shape([
    PropTypes.object,
  ]).isRequired
};

export default WizardPage;
