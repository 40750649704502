import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './TermsConditions.scss';
import TermsAndConditionsText from 'components/TermsAndConditionsText/TermsAndConditionsText';

const TermsConditions = () => {
    return ( 
        <React.Fragment>
        <Header />
            <TermsAndConditionsText />
            <Footer />
        </React.Fragment>
     );
}
 
export default TermsConditions;