import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { ReactSVG } from 'react-svg';
import { showIconAmenity } from 'constants/amenityTypes';

import './BudgetAsset.scss';

class BudgetAsset extends Component {
  render() {
    const { items, paymentDate, headTitle, icon, titleHidden } = this.props;

    return (
      <div className='BudgetAsset'>
        { !titleHidden && (
          <div className='BudgetAsset__header'>
              {
                icon && (
                  <div className='BudgetAsset__header__img'>
                    <ReactSVG src={showIconAmenity(icon).img_line} />
                  </div>
                )
              }          
              <div className='row space-between align-center'>
                <div className='heading-4 col'>{ headTitle }</div>
                { !!paymentDate && (
                  <div className='flex-zero col date'>
                    <label className='subtitle'>Fecha limite de pago</label>
                    <label className="heading-2">{ paymentDate } de cada mes</label>
                  </div>
                )}
              </div>
            </div>
        )}
        { items && <div className='BudgetAsset__titles'>
          {
            items.map((item, index) => {
              const { title, filter, onFilter, styleWidth } = item;
              return (
                <div className='BudgetAsset__titles__col' key={`BudgetAsset__titles__col${index}`} style={styleWidth}>
                  { title }
                  { filter && <IconButton onClick={onFilter}></IconButton> }
                </div>
              );
            })
          }
        </div> }
      </div>
    );
  }
}

BudgetAsset.defaultProps = {
  items: [],
  titleHidden: false,
};

export default BudgetAsset;
