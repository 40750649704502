import React, { useState } from 'react';
import { connect } from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import Dialog from 'components/Dialogs';
import * as Actions from '../../../../Property/propertyActions';
import { bindActionCreators } from 'redux';
import ImageIcon from '@material-ui/icons/Image';
import Form from 'components/Form';
import { injectIntl } from 'react-intl';
import paymentMethods from 'constants/paymentMethods';

import './PaymentForm.scss';

export const PaymentForm = props => {

  const { intl, budgetState : { actives, activeSelected }} = props;

  const currentDate = new Date().toISOString().substring(0, 10);

  const [ snackVisible, setSnackVisibility ] = useState(false);

  const { dwelling, onCancel } = props;

  const submitPayment = data => props.setPaymentResidents({
      did: dwelling.dwelling_id,
      data: {
        ...data,
        file: data.file?.file
      }
    })
    .then(props.onSuccess);

  const onSubmit = (values) => {
    const data = {
      ...values,
      amount: parseFloat(values.amount)
    };

    if (!data.amount) {
      setSnackVisibility(true);
    } else {
      return submitPayment(data);
    }
  };

  const toggleDialog = () => {
    setSnackVisibility(!snackVisible);
  }

  return (
    <>
      <Dialog
        title={ intl.formatMessage({ id: 'Modal.Resident.Title' }) }
        message={intl.formatMessage({ id: 'Modal.Resident.Message' }) }
        onClose={ toggleDialog }
        open={ snackVisible }
        onCancel={ toggleDialog }
      />
      <div className="PaymentForm">
        <Form
          title='Registra los datos de pago del residente'
          loading={ props.propertyState.loading.SET_PAYMENT_RESIDENTS?.status }
          needsUserConfirmation
          onCancel={ onCancel }
          onSubmit={ onSubmit }
          clearOnSubmitted
          notificationMessage='El pago ha sido registrado exitosamente'
          confirmationDetails={[
            {
              label: 'Nombre del residente',
              value: props.value.full_name
            },
            {
              label: 'Condominio',
              value: Object.values(actives?.result || { }).find(active => active.id === activeSelected)?.name
            },
            {
              label: 'Casa / Depto',
              value: props.value.number
            }
          ]}
          controls={[
            [
              {
                name: 'amount',
                label: intl.formatMessage({ id: 'Modal.Resident.Label.Amount' }),
                type: 'currency',
                props: {
                  required: true,
                  defaultValue: dwelling?.total,
                  minimumValue: '0',
                  InputProps: {
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }
                }
              },
              {
                name: 'date',
                label: intl.formatMessage({ id: 'Modal.Resident.Label.Date' }),
                type: 'date',
                props: {
                  required: true,
                  defaultValue: currentDate
                }
              },
            ],
            {
              name: 'reference',
              label: 'REFERENCIA'
            },
            {
              name: 'notes',
              label: 'NOTA',
              type: 'lengthCounter',
              props: {
                maxLength: 100
              }
            },
            {
              name: 'payment_type',
              type: 'radio',
              props: {
                label: 'FORMA DE PAGO',
                row: true,
                defaultValue: 'CASH',
                options: paymentMethods
              }
            },
            {
              name: 'file',
              type: 'file',
              hideFromConfirmation: true,
              props: {
                buttonProps: {
                  startIcon: <ImageIcon />,
                },
                children: 'Subir un comprobante'
              }
            },
            {
              name: 'to_send',
              type: 'checkbox',
              hideFromConfirmation: true,
              props: {
                label: intl.formatMessage({ id: 'Modal.Resident.Send' }),
                disabled: !dwelling.email
              }
            },
          ]}
        />
      </div>
    </>
  );
}

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);

const mapStateToProps = state => ({
  budgetState: state.get('budgetState'),
  propertyState: state.get('propertyState'),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PaymentForm));
