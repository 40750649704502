import React from 'react';

import './Status.scss';

const Status = props => {

  const value = (props.value || '').toLocaleLowerCase();
  
  const labels = {
    overdue: 'MOROSO',
    paid: 'AL CORRIENTE',
    advance: 'CON ANTICIPO',

    pending: 'PENDIENTE',
    in_progress: 'EN PROCESO',
    completed: 'COMPLETADO',
    requested: 'EN VALIDACIÓN',
    rejected: 'RECHAZADO',
    validated: 'VALIDADO',
  }

  const classMapper = {
    overdue: 'rejected-colors',
    paid: 'approved-colors',
    advance: 'payed-colors',

    pending: 'pending_payment-colors',
    in_progress: 'payed-colors',
    completed: 'approved-colors',
    requested: 'payed-colors',
    rejected: 'rejected-colors',
    validated: 'approved-colors',
  }
  
  return (
    <div className={ `Status ${ classMapper[value] }` }>
      { labels[value] }
    </div>
  )
}

export default Status;
