import React, { Component } from "react";
import { FormattedMessage } from 'react-intl';
import ColCard from "components/ColCard";
import Button from "components/Button";
import { toMoneyFormat } from "utils/format";

import "./SectionStatus.scss";

export class SectionStatus extends Component {

  constructor(props){
    super(props);
    this.state = {
      showModalTransfer: false
    }
  }

  render() {
    const {
      available_balance,
      totalFunds,
      goToDetailFunds,
      showDashAmenity,
      hadleOpenModalTransf,
    } = this.props;
    
    return (
      <div className='SectionStatus col'>
        <ColCard
          title={ <FormattedMessage id='SectionStatus.balance_title'/> }
          columns={ showDashAmenity ? [
            {
              label: <FormattedMessage id='SectionStatus.balance_available'/>,
              value: `$ ${ toMoneyFormat(available_balance || 0) }`
            }
          ] : [
            {
              label: <FormattedMessage id='SectionStatus.balance_available'/>,
              value: `$ ${toMoneyFormat(available_balance || 0)}`
            }, {
              label: <FormattedMessage id='SectionStatus.see_detail' />,
              value: `$ ${ toMoneyFormat(totalFunds || 0) }`,
              action: goToDetailFunds,
              actionLabel: 'Ver detalle'
            }
          ]}
        >
          <Button
            variant="outlined"
            color="secondary"
            onClick={hadleOpenModalTransf}
          >
            <FormattedMessage id='Button.transfer_founds' />
          </Button>
        </ColCard>
      </div>
    );
  }
}

export default SectionStatus;