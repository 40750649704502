import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import * as Actions from '../../neighborsActions';

import Input from 'components/Input';
import Dialogs from 'components/Dialogs';

import './NewContact.scss';
import Button from 'components/Button';
import { validator, phoneNumber, email, characters } from 'utils/validationsRules';

export class NewContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      relation: '',
      celphone: '',
      phone: '',
      contactEmail: '',
      error: {},
      dialog: {},
      disabledB: false,
    };
    this.bottomPage = null;
  }

  componentDidMount(){
    const { onEdit, onEditData } = this.props;
    if(onEdit !== 0 && onEditData && onEditData.name){
      this.setState({
        name: onEditData.name,
        relation: onEditData.relationship,
        phone: onEditData.phone_home,
        celphone: onEditData.phone_cel,
        contactEmail: onEditData.email,
      });
    }
    setTimeout(() => {
      this.bottomPage.scrollIntoView({ behavior: 'smooth', inline:'end'})
    }, 500);
  }

  handleOnChange = type => (event) => {
    let { error } = this.state;
    let compare = {};
    switch (type) {
      case 'phone': compare = phoneNumber(event.target.value); break;
      case 'text': compare = characters(event.target.value); break;
      case 'email': compare = email(event.target.value); break;
      default: return null;
    }
    error = validator(compare, error, event.target.name);
    this.setState({ [event.target.name]: event.target.value, error });
  }

  handleOnSubmit = () => {
    this.setState({disabledB: true});
    const { onEdit } = this.props;
    const { error, name, contactEmail, celphone,phone } = this.state;
    if (Object.values(error).length > 0) {
      this.setState({
        dialog: {
          title: <FormattedMessage id='NewContact.title_data_wrong' />,
          message: <FormattedMessage id='NewContact.message_dialog_wrong' />,
          show: true,
          textOnCancel: <FormattedMessage id='NewContact.close_dialog' />
        }
      });
      return;
    }
    if(!name || (!contactEmail && !celphone && !phone )) {
      this.setState({
        dialog: {
          title: <FormattedMessage id='NewContact.title_incomplete' />,
          message: <FormattedMessage id='NewContact.message_incomplete_dialog' />,
          show: true,
          textOnCancel: <FormattedMessage id='NewContact.close_dialog' />
        }
      });
      return;
    }
    if(onEdit !== 0){
      this.handleUpdateInfo();
      return;
    }
    this.handleSaveInfo();
  }

  handleSaveInfo = () => {
    const { name, relation, celphone, phone, contactEmail } = this.state;
    const { property, contactID } = this.props.params;
    this.props.setNeighborContact({
      idProperty: property,
      idResident: contactID,
      dataContact: {
        name: name,
        relationship: relation,
        phone_home: phone,
        phone_cel: celphone,
        email: contactEmail
      }
    }).then(()=>{
      this.props.onCancel();
      this.props.getNeighborContacts({
        idProperty: property,
        idResident: contactID
      });
    });
  }

  handleUpdateInfo = () => {
    const { onEdit } = this.props;
    const { name, relation, celphone, phone, contactEmail } = this.state;
    const { property, contactID } = this.props.params;
    this.props.putNeighborContact({
      idProperty: property,
      idResident: contactID,
      idContact: onEdit,
      dataContact: {
        "name": name,
        "relationship": relation,
        "phone_home": phone,
        "phone_cel": celphone,
        "email": contactEmail
      }
    }).then(()=>{
      this.props.onCancel();
      this.props.getNeighborContacts({
        idProperty: property,
        idResident: contactID
      });
    });
  }

  handleCloseDialog = () => {
    this.setState({ dialog: {}, disabledB:false });
  }

  render() {
    const { error, dialog, name, relation, celphone, phone, contactEmail,disabledB } = this.state;
    const { onCancel, onEdit } = this.props;
    return (
      <div className='NewContact'>
        <Dialogs
          title={dialog.title}
          message={dialog.message}
          onClose={this.handleCloseDialog}
          open={dialog.show}
          onCancel={this.handleCloseDialog}
          textCancel={dialog.textOnCancel}
        />
        <div className='NewContact__title'>
          {
            onEdit === 0
            ? <FormattedMessage id='NewContact.add_new_contact' />
            : null
          }
        </div>
        <div className='NewContact__body'>
          <div className='NewContact__body__container'>
          <p><FormattedMessage id='NewContact.add_contact_relation' /></p>
            <Input
              label={<FormattedMessage id='NewContact.label_name_contact' />}
              fullWidth
              className='NewContact__body__separator'
              name='name'
              value={name}
              error={!!error.name}
              helperText={error.name}
              onChange={this.handleOnChange('text')}
            />
            <Input
              label={<FormattedMessage id='NewContact.label_kinship' />}
              fullWidth
              className='NewContact__body__separator'
              name='relation'
              value={relation}
              error={!!error.relation}
              helperText={error.relation}
              onChange={this.handleOnChange('text')}
            />
            <Input
              label={<FormattedMessage id='NewContact.label_phone' />}
              fullWidth
              className='NewContact__body__separator'
              name='celphone'
              value={celphone}
              error={!!error.celphone}
              helperText={error.celphone}
              onChange={this.handleOnChange('phone')}
            />
            <Input
              label={<FormattedMessage id='NewContact.label_cell_phone' />}
              fullWidth
              className='NewContact__body__separator'
              name='phone'
              value={phone}
              error={!!error.phone}
              helperText={error.phone}
              onChange={this.handleOnChange('phone')}
            />
            <Input
              label={<FormattedMessage id='NewContact.label_email' />}
              fullWidth
              className='NewContact__body__separator'
              name='contactEmail'
              value={contactEmail}
              error={!!error.email}
              helperText={error.email}
              onChange={this.handleOnChange('email')}
            />
          </div>
        </div>
        <div className='NewContact__options'>
          <Button variant='outlined' color='secondary' onClick={onCancel}><FormattedMessage id='Button.cancel' /></Button>
          <Button variant='contained' color='secondary' onClick={this.handleOnSubmit} disabled={disabledB}><FormattedMessage id='Button.save' /></Button>
        </div>
        <div style={{ float: 'left', clear: 'both' }} ref={(el) => { this.bottomPage = el }}></div>
      </div>
    );
  }
}

NewContact.contextTypes = {
  router: PropTypes.shape()
};

NewContact.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  neighborState: state.get('neighborState'),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewContact);

