import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as Actions from '../../budgetActions';
import BudgetCollect from './BudgetCollect/BudgetCollect';
import BudgetCharged from './BudgetCharged/BudgetCharged';
import SecondaryTabView from 'components/SecondaryTabView';

export class BudgetIncome extends Component {
  constructor() {
    super();
  }

  handleChangeTab() {
    this.props.addData({
      concepts: {}
    });
  };

  render() {
    const { params } = this.props;

    return (
      <SecondaryTabView
        items={[
          {
            value: 1,
            label: 'Ingresos por cobrar',
            view: <BudgetCollect params={params} />
          },
          {
            value: 2,
            label: 'Ingresos cobrados',
            view: <BudgetCharged params={params} />
          },
        ]}
        onChange={ this.handleChangeTab.bind(this) }
      />
    );
  }
}

BudgetIncome.contextTypes = { 
  router: PropTypes.shape()
};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  budgetState: state.get('budgetState')
});

export default connect(mapStateToProps, mapDispatchToProps)(BudgetIncome);

