import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';

import './Checkbox.scss'

const styles = {
  root: {
    padding: 0,
    margin: 0,
  },
};

export const CheckBox = props => {
  
  const {
    label,
    classes,
    disabled
  } = props;

  const mProps = { ...props };

  mProps.disabled = mProps.disabled ? mProps.disabled.toString() :  null;

  return (
    <FormControlLabel
      classes={ classes }
      control={ <Checkbox classes={ classes } {...mProps}/> }
      label={ label }
      disabled={ disabled }
    />
  );
}

CheckBox.defaultProps = {
  disab: null,
  label: '',
  classes: null
};

CheckBox.propTypes = {
  label: PropTypes.string,
  disab: PropTypes.bool,
  classes: PropTypes.instanceOf(Object)
};

export default withStyles(styles)(CheckBox);
