import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import * as Actions from '../loginActions';
import stateWarning from 'assets/img/state-warning.svg';
import './styles.scss';

class Registered extends Component {
  clearError = () => {
    this.props.addLoginData({ loading: { ...this.props.loginState.loading, REGISTER_SIGNUP: undefined } });
    this.context.router.push(`/login`);
  }

  render() {
    return (
      <div className='Unauthorized'>
        <img src={stateWarning} alt='image ready' />
        <h2 className='Unauthorized__warning'>¡Cuenta antes registrada!</h2>
        <p>Ya éxiste una cuenta con esta misma dirección de correo electrónico.</p>
        <Button onClick={this.clearError} variant='contained' color='secondary'>Inicia sesión</Button>
      </div>
    );
  }
}

Registered.contextTypes = {
  router: PropTypes.shape()
};

Registered.propTypes = {
  loginState: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
});

export default connect(mapStateToProps, mapDispatchToProps)(Registered);

