import * as ACTION from './action-types';
import {
    rejectBookingService,
    approveBookingService,
    cancelBookingService,
    bookingPaymentService
} from '../../services/bookings-service';
 
import { getAmenityService } from '../../services/amenities-service';

export function rejectBooking(bookingId, reason) {
  return {
    types: [
      ACTION.LOADING_BOOKING_REJECTION,
      ACTION.RECEIVE_BOOKING_REJECTION,
      ACTION.ERROR_BOOKING_REJECTION,
    ],
    promise: rejectBookingService(bookingId, reason)
  };
}

export function approveBooking(bookingId) {
  return {
    types: [
      ACTION.LOADING_BOOKING_APPROVEMENT,
      ACTION.RECEIVE_BOOKING_APPROVEMENT,
      ACTION.ERROR_BOOKING_APPROVEMENT,
    ],
    promise: approveBookingService(bookingId)
  };
}

export function bookingPayment(bookingId, data) {
  return {
    types: [
      ACTION.LOADING_BOOKING_APPROVEMENT,
      ACTION.RECEIVE_BOOKING_APPROVEMENT,
      ACTION.ERROR_BOOKING_APPROVEMENT,
    ],
    promise: bookingPaymentService(bookingId, data)
  };
}

export function cancelBooking(bookingId, reason) {
  return {
    types: [
      ACTION.LOADING_BOOKING_CANCELLATION,
      ACTION.RECEIVE_BOOKING_CANCELLATION,
      ACTION.ERROR_BOOKING_CANCELLATION,
    ],
    promise: cancelBookingService(bookingId, reason)
  };
}

export function getAmenity(amenityId) {
  return {
    types: [
      ACTION.LOADING_GET_AMENITY,
      ACTION.RECEIVE_GET_AMENITY,
      ACTION.ERROR_GET_AMENITY,
    ],
    promise: getAmenityService(amenityId)
  };
}
