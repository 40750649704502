import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import * as Actions from '../../Budget/budgetActions';
import * as ProviderActions from '../../Providers/providerActions';


import Card from 'components/Card';
import TabView from 'components/TabView';

import Detail from '../../Wizard/Budget/Detail/Detail';
import Quotas from '../../Wizard/Quota/QuotaDetail/QuotaDetail';

import './BudgetEdit.scss';
import FundDetails from './FundDetails/FundDetails';

class BudgetEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSelected: '',
    };
  }

  componentDidMount() {
    const { property } = this.props.params;
    const { tab } = this.context.router.location.query;
    const { activeSelected } = this.props.budgetState;

    if (tab === 'funds') {
      this.setState({ tab: 3 });
    } else {
      this.props.getProviders({ idProperty: property });
    }
    this.props.getActives({ property });

    this.setState({
      activeSelected
    });
  }

  handleItems(){
    const { params } = this.props;
    const { tab } = this.context.router.location.query;
    const { activeSelected } = this.state;
    
    if (tab === 'funds') {
      return [
        {
          value: 3,
          label: 'Detalle de fondos',
          view: <FundDetails activeSelected={activeSelected} params={params}/>
        },
      ]
    } else {
      return [
        {
          value: 1,
          label: 'Presupuesto',
          view: (
            <Card>
              <Detail editBalance={ true } params={params} />
            </Card>
          )
        },
        {
          value: 2,
          label: 'Cuotas',
          view: (
            <Card>
              <Quotas params={params} />
            </Card>
          )
        }
      ]
    }
  }

  render() {
    const { tab } = this.state;
    const items = this.handleItems();   
    
    return (
      <div className='BudgetEdit'>
        <TabView
          selectedTab={ tab }
          items={ items }
        />
      </div>
    );
  }
}

BudgetEdit.contextTypes = { 
  router: PropTypes.shape(),
  activeSelected: PropTypes.string
};

BudgetEdit.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({ ...Actions, ...ProviderActions }, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  budgetState: state.get('budgetState'),
  profileState: state.get("profileState"),
  loading: state.get('budgetState').loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(BudgetEdit);
