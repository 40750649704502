
import * as ACTION from 'containers/Bookings/action-types';

const initialState = {
    loading: { }
};

const bookingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.LOADING_BOOKING_REJECTION:
      return { ...state, loading: { ...state.loading, BOOKING_REJECTION: { status: true } } };
    case ACTION.RECEIVE_BOOKING_REJECTION:
      return { ...state, loading: { ...state.loading, BOOKING_REJECTION: undefined } };
    case ACTION.ERROR_BOOKING_REJECTION:
      return { ...state, loading: { ...state.loading, BOOKING_REJECTION: { status: false, detail: action.error.response } } };

    case ACTION.LOADING_BOOKING_APPROVEMENT:
      return { ...state, loading: { ...state.loading, BOOKING_APPROVEMENT: { status: true } } };
    case ACTION.RECEIVE_BOOKING_APPROVEMENT:
      return { ...state, loading: { ...state.loading, BOOKING_APPROVEMENT: undefined } };
    case ACTION.ERROR_BOOKING_APPROVEMENT:
      return { ...state, loading: { ...state.loading, BOOKING_APPROVEMENT: { status: false, detail: action.error.response } } };

  case ACTION.LOADING_BOOKING_CANCELLATION:
    return { ...state, loading: { ...state.loading, BOOKING_CANCELLATION: { status: true } } };
  case ACTION.RECEIVE_BOOKING_CANCELLATION:
    return { ...state, loading: { ...state.loading, BOOKING_CANCELLATION: undefined } };
  case ACTION.ERROR_BOOKING_CANCELLATION:
    return { ...state, loading: { ...state.loading, BOOKING_CANCELLATION: { status: false, detail: action.error.response } } };
  
  case ACTION.LOADING_GET_AMENITY:
    return { ...state, loading: { ...state.loading, GET_AMENITY: { status: true } } };
  case ACTION.ERROR_GET_AMENITY:
    return { ...state, loading: { ...state.loading, GET_AMENITY: { status: false, detail: action.error.response } } };
  case ACTION.RECEIVE_GET_AMENITY:
    return { ...state, loading: { ...state.loading, GET_AMENITY: undefined }, amenity: action.result.data };

    default:
      return { ...state };
  }
};

export default bookingsReducer;
