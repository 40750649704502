import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../actions';
import { FormattedMessage } from 'react-intl';
import SimpleModal from 'components/SimpleModal';
import Spinner from '@material-ui/core/CircularProgress';
import Button from 'components/Button';
import { ReactSVG } from 'react-svg';

import IconRequestRejected from 'assets/img/icon_reserva_aceptada.svg';

import './ApproveModal.scss';

export const ApproveModal = (props) => {

    const {
        onClose,
        request,
        bookingState,
        open
    } = props;

    const {
        loading
    } = bookingState;

    const onSubmit = () => {
        const { booking_id } = request || { };

        if (booking_id && open) {
            props.approveBooking(booking_id).then(props.refresh);
        }
    };
    
    useEffect(onSubmit, [ request, open ]);

    return (
        <SimpleModal title={ <FormattedMessage id='ApproveModal.title'/> } { ...props }>
            <div className='ApproveModal'>
                {
                    loading.BOOKING_APPROVEMENTMENT?.status ? (
                        <div className='row row-centered'>
                            <Spinner></Spinner>
                        </div>
                    ) : (
                        <div className='col align-center'>
                            <div className='col'>
                                <ReactSVG
                                    className='icon row row-centered'
                                    src={ IconRequestRejected }
                                />
                                <label>
                                    <FormattedMessage id='ApproveModal.description'/>
                                </label>
                            </div>
                            <hr/>
                            <div className='row row-flex-end'>
                                <Button variant="contained" color='primary' onClick={ onClose }>
                                    <FormattedMessage id='ApproveModal.accept'/>
                                </Button>
                            </div>
                        </div>
                    )
                }
            </div>
        </SimpleModal>
    );
}

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);

const mapStateToProps = state => ({
  bookingState: state.get('bookingState'),
});

export default connect(mapStateToProps, mapDispatchToProps) (ApproveModal);