import React, { Component } from 'react';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import './BudgetConcept.scss';
import { toMoneyFormat } from 'utils/format';
import Pdf from 'assets/img/pdf.svg';
import { ReactSVG } from 'react-svg';

class BudgetConcept extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {
    const { items, block, disabledB } = this.props;
    return (
      <div className='theBudgetConcept' key={`theBudgetConcept${block}`}>
        {
          items.map((item, row) => {
            const {
              type, value, onChange, validate, onClick, checked, inputProps, refuse
            } = item;
            switch (type) {
              case 'text':
                return (
                  <div
                    className='theBudgetConcept__center'
                    key={`theBudgetConcept__center${block}${row + 1}`}>
                    {value}
                  </div>
                );
                case 'text_button':
                return (
                  <div className='theBudgetConcept__validate' key={`theBudgetConcept__validate${block}${row + 1}`}>
                    <Button
                      variant={validate ? 'contained' : 'outlined'}
                      color={validate  ? 'primary' : 'secondary'}
                      style={refuse ? {backgroundColor: '#9ea7aa'}:null}
                      onClick={onClick}
                      disabled={validate || disabledB || refuse}
                    >

                      {value}
                    </Button>
                  </div>
                );
              case 'money':
                return (
                  <div
                    className={ value >= 0 ? 'theBudgetConcept__money' : ' theBudgetConcept__money theBudgetConcept__money__negative' }
                    key={`theBudgetConcept__money${block}${row + 1}`}>
                    $ {toMoneyFormat(value)}
                  </div>
                );
              case 'image':
                return (
                  <div className='theBudgetConcept__center' key={`theBudgetConcept__center${block}${row + 1}`}>

                    {value}
                  </div>
                );
              case 'icon':
                  return (
                    <>
                      <div className='theBudgetConcept__center__pointer' key={`theBudgetConcept__center${block}${row + 1}`}>
                        { value !== '' ? <ReactSVG src={Pdf}
                          onClick={onClick}
                          /> : ' - '
                        }
                      </div>
                    </>
                  );
              case 'input':
                return (
                  <div className='theBudgetConcept__amount' key={`theBudgetConcept__amount${block}${row + 1}`}>
                    <input maxLength="6" value={value} onChange={onChange} {...inputProps}/>
                  </div>
                );
              case 'button':
                return (
                  <div className='theBudgetConcept__validate' key={`theBudgetConcept__validate${block}${row + 1}`}>
                    <Button
                      variant={validate ? 'contained' : 'outlined'}
                      color={validate  ? 'primary' : 'secondary'}
                      style={refuse ? {backgroundColor: '#9ea7aa'}:null}
                      onClick={onClick}
                      disabled={validate || disabledB || refuse}
                    >

                      {
                        validate
                        ? <aside>Validado</aside>
                        : 'Validar'
                      }
                    </Button>
                  </div>
                );
              case 'button_refuse':
                return (
                  <div className='theBudgetConcept__validate' key={`theBudgetConcept__validate${block}${row + 1}`}>
                    <Button
                      variant={refuse ? 'contained' : 'outlined'}
                      color={refuse ||disabledB ? 'primary' : 'secondary'}
                      style={validate ? {backgroundColor: '#9ea7aa'}:null}
                      onClick={onClick}
                      disabled={refuse || disabledB || validate}
                    >
                      {
                        refuse
                        ? <aside>Rechazado</aside>
                        : 'Rechazar'
                      }
                    </Button>
                  </div>
                );
              case 'status': {
                const json = { 0: 'Pendiente', 1: 'En proceso', 2: 'Completado', 3:'En validación', 4:'Rechazado', 5: 'Validado' };
                return (
                  <div
                    className={`theBudgetConcept__status theBudgetConcept__center theBudgetConcept__status__${value}`}
                    key={`theBudgetConcept__status${block}${row + 1}`}>
                    { json[value] }
                  </div>
                );
              }
              case 'check':
                return (
                  <div
                    className='theBudgetConcept__center'
                    key={`theBudgetConcept__center${block}${row + 1}`}>
                    <Checkbox checked={checked} onClick={onClick} {...inputProps} />
                  </div>
                );

                case 'button-register':
                  return(
                    <div className='theBudgetConcept__validate' key={`theBudgetConcept__validate${block}${row + 1}`}>
                      <Button
                        variant='outlined'
                        color='primary'
                        onClick={onClick}
                      >
                         Registrar
                      </Button>
                    </div>
                  );
              default:
                return null;
            }
          })
        }
      </div>
    );
  }
}

export default BudgetConcept;
