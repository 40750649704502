import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as Actions from '../../budgetActions';
import './FoundModal.scss';
import Icon from '@material-ui/core/Icon';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Input from 'components/Input';
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
import CurrencyInput from 'components/CurrencyInput/CurrencyInput';
import { FormControl, FormHelperText, FormLabel } from '@material-ui/core';

export class FoundModal extends Component {

  constructor(props) {
    super(props);
    const initialState = () => {
      return {
        error:{},
        name: '',
        total: 0,
        frequency: 'Mensual',
        duration: 1,
        type: 2,
        initial_balance: 0,
        disabledB: false,
        fid: '',
        label: 'MONTO OBJETIVO'
      };
    };
    this.state = initialState();
  }

  componentDidMount() {
    this.getDataEditFunds();
    setTimeout(() => this.bottomPage && this.bottomPage.scrollIntoView({ behavior: 'smooth'}), 1500);
  }

  getDataEditFunds(){
    const { dataEdit } = this.props;
    if (dataEdit){
      this.setState({
        name: dataEdit.name_found,
        total: dataEdit.target_amount,
        duration: dataEdit.duration,
        type: dataEdit.type,
        initial_balance: dataEdit.initial_balance,
        fid: dataEdit.id
      });
    }
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.disabledB = true
    let isError = false;
    const messageInitial = <FormattedMessage id='Modal.Found.Message' />;
    const { name, total, initial_balance } = event.target;

    const error = {};
    if (name.value === '') {
      isError = true;
      error.name = <FormattedMessage id='Modal.Found.Required' />;
    }
  
    const parseAmount = (amount) => Number((amount || '').replace(/,/g, ''));

    if (this.state.type === 1 && parseAmount(total.value) <= parseAmount(initial_balance.value)) {
        isError = true;
        error.total = messageInitial;
    }

    if (this.state.type === 1 && this.state.fid !== ''){
      const total_payments = this.handleGetTotals(this.state.fid);
      if (total_payments > this.state.total){
        isError = true;
        error.total = <FormattedMessage id='Modal.Found.Total' />;
      }
    }

    if (isError) {
      this.setState({ error });
      return;
    }

    let data = {
      frequency: this.state.frequency,
      name_found: this.state.name,
      target_amount: this.state.total,
      duration: this.state.duration,
      type: this.state.type,
      initial_balance: this.state.initial_balance,
      fid: this.state.fid,
      active: true
    }

    this.props.onSubmit(data);
  }

  handleChange = type => (e) => {
    if (type === 'number') {
      e.target.value = (e.target.value === '') ? 0 : e.target.value;
      e.target.value = (e.target.value < 0) ? Math.abs(e.target.value) : e.target.value;
      if (!isNaN(Number(e.target.value))) {
        this.setState({ [e.target.name]: Number(e.target.value), error: { ...this.state.error, [e.target.name]: undefined } });
      }
    } else {
      this.setState({ [e.target.name]: e.target.value, error: { ...this.state.error, [e.target.name]: undefined } });
    }
    return null;
  }

  handleGetTotals = fundID => {
    const { funds_transfer } = this.props.budgetState;
    if (funds_transfer && funds_transfer.result) {
      const total = funds_transfer.result.find(item => item.fund_id === fundID);
      return total.total;
    }
    return [];
  };

  renderCurrencyInput = (name, value, label, error, onChange) => {
    return (
      <FormControl  error={!!error}>
        <FormLabel className="MuiInputLabel-animated MuiInputLabel-shrink">
          {label}
        </FormLabel>
        <CurrencyInput
          type='text'
          name={name}
          disableUnderline={false}
          value={value}
          className="input_modal"
          onChange={onChange}
          onBlur={() => {}}
        />
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    );
  }

  render() {
    const durationOptions = [
      {
        value: 1,
        label: '1 Mes',
      },
      {
        value: 2,
        label: '2 Meses',
      },
      {
        value: 3,
        label: '3 Meses',
      },
      {
        value: 4,
        label: '4 Meses',
      },
      {
        value: 5,
        label: '5 Meses',
      },
      {
        value: 6,
        label: '6 Meses',
      },
      {
        value: 7,
        label: '7 Meses',
      },
      {
        value: 8,
        label: '8 Meses',
      },
      {
        value: 9,
        label: '9 Meses',
      },
      {
        value: 10,
        label: '10 Meses',
      },
      {
        value: 11,
        label: '11 Meses',
      },
      {
        value: 12,
        label: '1 Año',
      },
      {
        value: 24,
        label: '2 Años',
      },
    ];
    const { dataEdit } = this.props;
    return(
      <div className='FoundModal'>
        <div className='modal'>

            <div className='head'>
              <h3>{dataEdit ? <FormattedMessage id='Modal.Found.title.edit' /> : <FormattedMessage id='Modal.Found.title.add' />}</h3>
              <Icon onClick={() => this.props.closeModal()}>close</Icon>
            </div>

            <div className='body'>
              <div className='message-found body-text1'>
                <div  className='color-gray'>
                  <FormattedMessage id='Modal.Found.Title' />
                </div>
                <div className='color-black'>
                  <FormattedMessage id='Modal.Found.Title.mount'  />
                </div>
              </div>
              <form onSubmit={this.onSubmit.bind(this)} className="form">
                <TextField
                  id="standard-select-currency"
                  select
                  name='type'
                  label={<FormattedMessage id='Modal.Found.label.type.fund' />}
                  value={this.state.type}
                  onChange={this.handleChange('number')}
                  margin="normal"
                >
                  <MenuItem key="1" value="1" className="body-text1">
                    <FormattedMessage id='Modal.Found.objective' />
                  </MenuItem>
                  <MenuItem key="2" value="2">
                    <FormattedMessage id='Modal.Found.Recurrent' />
                  </MenuItem>
                </TextField>
                <Input
                  label={<FormattedMessage id='Modal.Found.label.name.fund' />}
                  type='text'
                  name='name'
                  value={this.state.name}
                  className="input_modal"
                  inputProps={{
                    maxLength: 35,
                  }}
                  error={!!this.state.error.name}
                  helperText={this.state.error.name}
                  onChange={this.handleChange()}
                  />
                  {this.renderCurrencyInput(
                    'initial_balance',
                    this.state.initial_balance,
                    <FormattedMessage id='Modal.Found.label.balance' />,
                    this.state.error.initial_balance,
                    this.handleChange('number')
                  )}
                  {this.renderCurrencyInput(
                    'total',
                    this.state.total,
                    this.state.type === 1 ? this.state.label : 'Monto Recurente',
                    this.state.error.total,
                    this.handleChange('number')
                  )}
                  {
                    this.state.type == 1 ?
                    <Input
                      disabled
                      label={<FormattedMessage id='Modal.Found.label.frequency' />}
                      type='text'
                      name='frequency'
                      className="input_modal"
                      value={this.state.frequency}
                    />
                    : null
                  }
                  {
                    this.state.type== 1 ?
                    <TextField
                      id="standard-select-currency"
                      select
                      name='duration'
                      label={<FormattedMessage id='Modal.Found.label.duration' />}
                      value={this.state.duration}
                      onChange={this.handleChange('number')}
                      margin="normal"
                    >
                      {durationOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    :null
                  }
                <div className="div-button">
                  <Button type='submit' variant='contained' color='secondary' className="button_modal">{dataEdit ? 'Validar' :'Crear fondo'}</Button>
                </div>
              </form>
              <div style={{ float: 'left', clear: 'both' }} ref={(el) => { this.bottomPage = el }}></div>
            </div>
        </div>
      </div>
    );
  }
}

FoundModal.contextTypes = {
  router: PropTypes.shape()
};

FoundModal.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);

const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  budgetState: state.get('budgetState')
});

export default connect(mapStateToProps, mapDispatchToProps)(FoundModal);
