import * as ACTION from 'containers/Budget/actionTypes';

const initialState = {
  loading: false,
  activeSelected: null,
  actives: {
    result: []
  },
  assets: {
    result: []
  },
  funds: {
    result: []
  },
  services: {
    result: []
  },
  concepts: {},
  conceptsAmount: {},
  periods: [],
  budgetsAndFees: {},
  budgetsAndFeesAmenities: {},
  conceptsToPay: {},
  conceptsToCash: {},
  openPayResponse: {},
  quotaSelected: '',
  quotas: {
    result: []
  },
  quota: {
    fee: 0,
    fee_moratorium_tax: 3,
    fee_frecuency_type_id: 1,
    fee_limit_day: 10,
  },
  expenses: {},
  transfer: []
};

const changeActiveFormat = (state, data) => {
  const result = {};
  let { activeSelected } = state;
  
  (data?.result || []).forEach((active) => {
    result[active.id] = active;
    activeSelected = activeSelected || active.id;
  });
  return { data: { ...data, result }, activeSelected };
};

const changeActiveFormatUpdate = (data) => {
  const object = {};
  if (data && data.result) {
    data.result.forEach((item) => {
      object[item.id] = item;
    });
  }
  return { ...data, result: object };
};

const changeConceptsFormat = (data) => {
  const object = {};
  (data || []).forEach((item) => {
    object[item.asset_id] = object[item.asset_id] || { };
    object[item.asset_id][item.id] = item;
  });
  return object;
};

const changeConceptsFormatList = (data, key) => {
  const object = {};
  if (data && data.result) {
    data.result.forEach((item) => {
      const def = item.id || item[key];
      if (!object[def]) { object[def] = {}; }
      object[def] = item;
    });
  }
  return object;
};

const handleGeneralAmount = ({ data, state }) => {
  const { dwellings } = data.result;
  const array = [];
  let count = 0;
  let modeFee = 0;
  dwellings.forEach((item) => {
    if (array.indexOf(item.fee_total) === -1) {
      array.push(item.fee_total);
    }
  });
  array.forEach((amount) => {
    const num = dwellings.filter(item => item.fee_total === amount).length;
    if (num > count) {
      count = num;
      modeFee = amount;
    }
  });
  return { quotas: data, quota: { ...state, fee: modeFee } };
};

const conceptAmountsAreValid = (concepts) => {
  const conceptsAmount = { };
  (concepts || []).map(concept => {
    conceptsAmount[concept.id] = concept;
    return 0;
  });
  const amountsAreValid = !Object.values(conceptsAmount).find(concept => concept.amount <= 0 );
  return { 
    amountsAreValid,
    conceptsAmount
  };
}

const handleDwellingData = ({ action, state }) => {
  let { quotas } = state;
  
  quotas.result.dwellings = quotas.result.dwellings.map(dwelling => {
    if(isNaN(action.data.moratorium_tax) || Number(action.data.moratorium_tax) <= 0) {
      action.data.moratorium_tax = dwelling.fee_moratorium_tax;
    }

    if(action.data.fee_limit_day <= 0 ) {
      action.data.fee_limit_day = dwelling.fee_limit_day;
    }

    if(typeof action.data.is_valid !== 'boolean') {
      action.data.is_valid = dwelling.is_valid;
    }

    if (!action.dwellingID || action.dwellingID === dwelling.id) {
      dwelling = {
        ...dwelling,
        ...action.data
      }
    }
    return dwelling;
  });

  return { quotas };
};

const budgetReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.ADD_DATA_BUDGET:
      return { ...state, ...action.data };
    case ACTION.CLEAR_BUDGET:
      return { ...initialState };
    case ACTION.CLEAR_BUDGET_CONCEPTS:
      return { ...state, concepts: {} };
    case ACTION.LOADING_GET_ACTIVES:
      return { ...state, loading: { ...state.loading, GET_ACTIVES: { status: true } } };
    case ACTION.RECEIVE_GET_ACTIVES: {
      const { data, activeSelected } = changeActiveFormat(state, action.result.data);
      return {
        ...state, loading: { ...state.loading, GET_ACTIVES: undefined }, actives: data, activeSelected
      };
    }
    case ACTION.ERROR_GET_ACTIVES:
      return { ...state, loading: { ...state.loading, GET_ACTIVES: { status: false, detail: action.error.response } } };
    case ACTION.RECEIVE_GET_ACTIVES_UPDATE: {
      return { ...state, loading: { ...state.loading, GET_ACTIVES: undefined }, actives: changeActiveFormatUpdate(action.result.data) };
    }
    case ACTION.LOADING_GET_ASSETS:
      return { ...state, loading: { ...state.loading, GET_ASSETS: { status: true } } };
    case ACTION.RECEIVE_GET_ASSETS:
      return { ...state, loading: { ...state.loading, GET_ASSETS: undefined }, assets: action.result.data };
    case ACTION.ERROR_GET_ASSETS:
      return { ...state, loading: { ...state.loading, GET_ASSETS: { status: false, detail: action.error.response } } };
    case ACTION.LOADING_GET_CONCEPTS:
      return { ...state, loading: { ...state.loading, GET_CONCEPTS: { status: true } } };
    case ACTION.RECIVED_GET_CONCEPTS:
      const concepts = changeConceptsFormat(action.result.data.result)
      return { ...state, loading: { ...state.loading, GET_CONCEPTS: undefined }, concepts, ...conceptAmountsAreValid(action.result.data.result) };
    case ACTION.ERROR_GET_CONCEPTS:
      return { ...state, loading: { ...state.loading, GET_CONCEPTS: { status: false, detail: action.error.response } } };

    case ACTION.LOADING_GET_PERIODS:
      return { ...state, loading: { ...state.loading, GET_PERIODS: { status: true } } };
    case ACTION.RECIVED_GET_PERIODS:
      return { ...state, loading: { ...state.loading, GET_PERIODS: undefined }, periods: action.result.data };
    case ACTION.ERROR_GET_PERIODS:
      return { ...state, loading: { ...state.loading, GET_PERIODS: { status: false, detail: action.error.response } } };
    
    case ACTION.LOADING_GET_BUDGETS_FEES:
      return { ...state, loading: { ...state.loading, GET_BUDGET_FEES: { status: true } } };
    case ACTION.RECIVED_GET_BUDGETS_FEES:
      return { ...state, loading: { ...state.loading, GET_BUDGET_FEES: undefined }, budgetsAndFees: action.result.data };
    case ACTION.ERROR_GET_BUDGETS_FEES:
      return { ...state, loading: { ...state.loading, GET_BUDGET_FEES: { status: false, detail: action.error.response } } };
      
    case ACTION.LOADING_GET_BUDGETS_FEES_AMENITIES:
      return { ...state, loading: { ...state.loading, GET_BUDGET_FEES_AMENITIES: { status: true } } };
    case ACTION.RECIVED_GET_BUDGETS_FEES_AMENITIES:
      return { ...state, loading: { ...state.loading, GET_BUDGET_FEES_AMENITIES: undefined }, budgetsAndFeesAmenities: action.result.data };
    case ACTION.ERROR_GET_BUDGETS_FEES_AMENITIES:
      return { ...state, loading: { ...state.loading, GET_BUDGET_FEES_AMENITIES: { status: false, detail: action.error.response } } };
      
      
    case ACTION.LOADING_SET_CONCEPT:
      return { ...state, loading: { ...state.loading, SET_CONCEPT: { status: true } } };
    case ACTION.RECIVED_SET_CONCEPT:
      return { ...state, loading: { ...state.loading, SET_CONCEPT: undefined } };
    case ACTION.ERROR_SET_CONCEPT:
      return { ...state, loading: { ...state.loading, SET_CONCEPT: { status: false, detail: action.error.response } } };
    case ACTION.UPDATE_CONCEPT:
    return { ...state, ...conceptAmountsAreValid([action.conceptData]) };
    case ACTION.CLEAR_UPDATE_CONCEPT:
      const { conceptsAmount } = state;
      delete conceptsAmount[action.id];
      return { ...state, conceptsAmount };
    case ACTION.LOADING_PUT_CONCEPT:
      return { ...state, loading: { ...state.loading, PUT_CONCEPT: { status: true } } };
    case ACTION.RECIVED_PUT_CONCEPT:
      return { ...state, loading: { ...state.loading, PUT_CONCEPT: undefined } };
    case ACTION.ERROR_PUT_CONCEPT:
      return { ...state, loading: { ...state.loading, PUT_CONCEPT: { status: false, detail: action.error.response } } };
      
    case ACTION.LOADING_POST_SERVICE:
      return { ...state, loading: { ...state.loading, POST_SERVICE: { status: true } } };
    case ACTION.RECIVED_POST_SERVICE:
      return { ...state, loading: { ...state.loading, POST_SERVICE: undefined }, last_created_service: action.result.data.id };
    case ACTION.ERROR_POST_SERVICE:
      return { ...state, loading: { ...state.loading, POST_SERVICE: { status: false, detail: action.error.response } } };

    case ACTION.LOADING_GET_CATALOG_SERVICES:
      return { ...state, loading: { ...state.loading, GET_CATALOG_SERVICES: { status: true } } };
    case ACTION.RECIVED_GET_CATALOG_SERVICES:
      return { ...state, loading: { ...state.loading, GET_CATALOG_SERVICES: undefined }, services: action.result.data };
    case ACTION.ERROR_GET_CATALOG_SERVICES:
      return { ...state, loading: { ...state.loading, GET_CATALOG_SERVICES: { status: false, detail: action.error.response } } };
    case ACTION.LOADING_DELETE_CONCEPT:
      return { ...state, loading: { ...state.loading, DELETE_CONCEPT: { status: true } } };
    case ACTION.RECIVED_DELETE_CONCEPT:
      return { ...state, loading: { ...state.loading, DELETE_CONCEPT: undefined } };
    case ACTION.ERROR_DELETE_CONCEPT:
      return { ...state, loading: { ...state.loading, DELETE_CONCEPT: { status: false, detail: action.error.response } } };
    case ACTION.LOADING_GET_QUOTAS:
      return { ...state, loading: { ...state.loading, GET_QUOTAS: { status: true } } };

    case ACTION.LOADING_PUT_FEE:
      return { ...state, loading: { ...state.loading, PUT_QUOTAS: { status: true } } };
    case ACTION.RECEIVE_PUT_FEE:
        return { ...state, loading: { ...state.loading, PUT_QUOTAS: undefined } };
    case ACTION.ERROR_PUT_FEE:
        return { ...state, loading: { ...state.loading, PUT_QUOTAS: { status: false, detail: action.error.response } } };

        case ACTION.LOADING_SET_FEE:
      return { ...state, loading: { ...state.loading, SET_QUOTAS: { status: true } } };
    case ACTION.RECEIVE_SET_FEE:
        return { ...state, loading: { ...state.loading, SET_QUOTAS: undefined } };
    case ACTION.ERROR_SET_FEE:
        return { ...state, loading: { ...state.loading, SET_QUOTAS: { status: false, detail: action.error.response } } };
    case ACTION.RECEIVE_GET_QUOTAS:
      return { ...state, loading: { ...state.loading, GET_QUOTAS: undefined }, ...handleGeneralAmount({ data: action.result.data, state: state.quota }) };
    case ACTION.ERROR_GET_QUOTAS:
      return { ...state, loading: { ...state.loading, GET_QUOTAS: { status: false, detail: action.error.response } } };
    case ACTION.ADD_DWELLING_DATA:
      return { ...state, ...handleDwellingData({ action: action.data, state: state }) };
    case ACTION.LOADING_CREATE_POCAHONTAS:
      return { ...state, loading: { ...state.loading, CREATE_POCAHONTAS: { status: true } } };
    case ACTION.RECEIVE_CREATE_POCAHONTAS:
      return { ...state, loading: { ...state.loading, CREATE_POCAHONTAS: undefined }, pocahontas: action.result };
    case ACTION.ERROR_CREATE_POCAHONTAS:
      return { ...state, loading: { ...state.loading, CREATE_POCAHONTAS: { status: false, detail: action.error.response } } };

    case ACTION.LOADING_GET_EXPENSES:
      return { ...state, loading: { ...state.loading, GET_EXPENSES: { status: true } }, expenses: { } };
    case ACTION.RECEIVE_GET_EXPENSES:
      return { ...state, loading: { ...state.loading, GET_EXPENSES: undefined }, expenses: action.result.data };
    case ACTION.ERROR_GET_EXPENSES:
      return { ...state, loading: { ...state.loading, GET_EXPENSES: { status: false, detail: action.error.response } } };


    case ACTION.LOADING_GET_CONCEPTS_TO_VALIDATE:
      return { ...state, loading: { ...state.loading, GET_CONCEPTS_TO_VALIDATE: { status: true } } };
    case ACTION.RECEIVE_GET_CONCEPTS_TO_VALIDATE:
      return { ...state, loading: { ...state.loading, GET_CONCEPTS_TO_VALIDATE: undefined }, conceptsToValidate: changeConceptsFormat(action.result.data.payments) };
    case ACTION.ERROR_GET_CONCEPTS_TO_VALIDATE:
      return { ...state, loading: { ...state.loading, GET_CONCEPTS_TO_VALIDATE: { status: false, detail: action.error.response } } };


    case ACTION.LOADING_SET_PROVIDER_PAY:
      return { ...state, loading: { ...state.loading, SET_PROVIDER_PAY: { status: true } } };
    case ACTION.RECEIVE_SET_PROVIDER_PAY:
      return { ...state, loading: { ...state.loading, SET_PROVIDER_PAY: undefined } };
    case ACTION.ERROR_SET_PROVIDER_PAY:
      return { ...state, loading: { ...state.loading, SET_PROVIDER_PAY: { status: false, detail: action.error.response } } };

    
    case ACTION.LOADING_GET_TRANSFERFUND:
      return { ...state, loading: { ...state.loading, GET_TRANSFERFUND: { status: true } } };
    case ACTION.RECEIVE_GET_TRANSFERFUND:
      return { ...state, loading: { ...state.loading, GET_TRANSFERFUND: undefined }, transfer: action.result.data.result};
    case ACTION.ERROR_GET_TRANSFERFUND:
      return { ...state, loading: { ...state.loading, GET_TRANSFERFUND: { status: false, transfer: action.error.response } } };

    case ACTION.LOADING_GET_PROVIDER_PAYMENT_PENDING:
      return { ...state, loading: { ...state.loading, GET_PROVIDER_PAYMENT_PENDING: { status: true } } };
    case ACTION.RECEIVE_GET_PROVIDER_PAYMENT_PENDING:
      return { ...state, loading: { ...state.loading, GET_PROVIDER_PAYMENT_PENDING: undefined }, conceptsPendingProviderPayment: changeConceptsFormat(action.result.data.result) };
    case ACTION.ERROR_GET_PROVIDER_PAYMENT_PENDING:
      return { ...state, loading: { ...state.loading, GET_PROVIDER_PAYMENT_PENDING: { status: false, detail: action.error.response } } };


    case ACTION.LOADING_PUT_PROVIDER_PAYMENTS_PENDINGS:
      return { ...state, loading: { ...state.loading, PUT_PROVIDER_PAYMENTS_PENDINGS: { status: true } } };
    case ACTION.RECEIVE_PUT_PROVIDER_PAYMENTS_PENDINGS:
      return { ...state, loading: { ...state.loading, PUT_PROVIDER_PAYMENTS_PENDINGS: undefined }, openPayResponse: action.result.data.result };
    case ACTION.ERROR_PUT_PROVIDER_PAYMENTS_PENDINGS:
      return { ...state, loading: { ...state.loading, PUT_PROVIDER_PAYMENTS_PENDINGS: { status: false, detail: action.error.response } } };

    case ACTION.LOADING_POST_EXTRA_PROVIDER_PAYMENT:
      return { ...state, loading: { ...state.loading, POST_EXTRA_PROVIDER_PAYMENT: { status: true } } };
    case ACTION.RECEIVE_POST_EXTRA_PROVIDER_PAYMENT:
      return { ...state, loading: { ...state.loading, POST_EXTRA_PROVIDER_PAYMENT: undefined } };
    case ACTION.ERROR_POST_EXTRA_PROVIDER_PAYMENT:
      return { ...state, loading: { ...state.loading, POST_EXTRA_PROVIDER_PAYMENT: { status: false, detail: action.error.response } } };

    case ACTION.LOADING_PUT_PROVIDER_PAYMENTS:
      return { ...state, loading: { ...state.loading, PUT_PROVIDER_PAYMENTS: { status: true } } };
    case ACTION.RECEIVE_PUT_PROVIDER_PAYMENTS:
      return { ...state, loading: { ...state.loading, PUT_PROVIDER_PAYMENTS: undefined }, openPayResponse: action.result.data.result };
    case ACTION.ERROR_PUT_PROVIDER_PAYMENTS:
      return { ...state, loading: { ...state.loading, PUT_PROVIDER_PAYMENTS: { status: false, detail: action.error.response } } };

    case ACTION.LOADING_GET_INCOMES_CHARGED:
      return { ...state, loading: { ...state.loading, GET_INCOMES_CHARGED: { status: true } } };
    case ACTION.RECEIVE_GET_INCOMES_CHARGED:
      return { ...state, loading: { ...state.loading, GET_INCOMES_CHARGED: undefined }, incomeConcepts: action.result.data.result };
    case ACTION.ERROR_GET_INCOMES_CHARGED:
      return { ...state, loading: { ...state.loading, GET_INCOMES_CHARGED: { status: false, detail: action.error.response } } };


    case ACTION.LOADING_GET_INCOMES_CHARGERED:
      return { ...state, loading: { ...state.loading, GET_INCOMES_CHARGERED: { status: true } } };
    case ACTION.RECEIVE_GET_INCOMES_CHARGERED:
      return { ...state, loading: { ...state.loading, GET_INCOMES_CHARGERED: undefined }, conceptsToCollect: changeConceptsFormatList(action.result.data, 'id_payment') };
    case ACTION.ERROR_GET_INCOMES_CHARGERED:
      return { ...state, loading: { ...state.loading, GET_INCOMES_CHARGERED: { status: false, detail: action.error.response } } };


    case ACTION.LOADING_PUT_INITIAL_BALANCE:
      return { ...state, loading: { ...state.loading, PUT_INITIAL_BALANCE: { status: true } } };
    case ACTION.RECEIVE_PUT_INITIAL_BALANCE:
      return { ...state, loading: { ...state.loading, PUT_INITIAL_BALANCE: undefined } };
    case ACTION.ERROR_PUT_INITIAL_BALANCE:
      return { ...state, loading: { ...state.loading, PUT_INITIAL_BALANCE: { status: false, detail: action.error.response } } };


    case ACTION.LOADING_POST_PAYMENT:
      return { ...state, loading: { ...state.loading, SENDING_PAYMENT: { status: true } } };
    case ACTION.RECEIVE_SPEI_PAYMENT:
      return { ...state, loading: { ...state.loading, SENDING_PAYMENT: undefined }, payInfo: action.result.data.result };
    case ACTION.RECEIVE_PAYMENT:
      return { ...state, loading: { ...state.loading, SENDING_PAYMENT: undefined }, payInfo: action.result.data.result };
    case ACTION.ERROR_POST_PAYMENT:
      return { ...state, loading: { ...state.loading, SENDING_PAYMENT: { status: false, detail: action.error.response } } };


    case ACTION.LOADING_SET_CODE_TO_PAY:
      return { ...state, loading: { ...state.loading, SET_CODE_TO_PAY: { status: true } } };
    case ACTION.RECEIVE_SET_CODE_TO_PAY:
      return { ...state, loading: { ...state.loading, SET_CODE_TO_PAY: undefined } };
    case ACTION.ERROR_SET_CODE_TO_PAY:
      return { ...state, loading: { ...state.loading, SET_CODE_TO_PAY: { status: false, detail: action.error.response } } };
    case ACTION.LOADING_SET_FUND_TRANSFER:
      return { ...state, loading: { ...state.loading, SET_FUND_TRANSFER: { status: true } } };
    case ACTION.RECEIVE_SET_FUND_TRANSFER:
      return { ...state, loading: { ...state.loading, SET_FUND_TRANSFER: undefined } };
    case ACTION.ERROR_SET_FUND_TRANSFER:
      return { ...state, loading: { ...state.loading, SET_FUND_TRANSFER: { status: false, detail: action.error.response } } };

    case ACTION.LOADING_PUT_FUND_TRANSFER:
      return { ...state, loading: { ...state.loading, PUT_FUND_TRANSFER: { status: true } } };
    case ACTION.RECEIVE_PUT_FUND_TRANSFER:
      return { ...state, loading: { ...state.loading, PUT_FUND_TRANSFER: undefined } };
    case ACTION.ERROR_PUT_FUND_TRANSFER:
      return { ...state, loading: { ...state.loading, PUT_FUND_TRANSFER: { status: false, detail: action.error.response } } };
    case ACTION.LOADING_GET_FUNDS_TRANSFER:
      return { ...state, loading: { ...state.loading, GET_FUNDS_TRANSFER: { status: true } } };
    case ACTION.RECEIVE_GET_FUNDS_TRANSFER:
      return { ...state, loading: { ...state.loading, GET_FUNDS_TRANSFER: undefined }, funds_transfer: action.result.data };
    case ACTION.ERROR_GET_FUNDS_TRANSFER:
      return { ...state, loading: { ...state.loading, GET_FUNDS_TRANSFER: { status: false, detail: action.error.response } } };

    case ACTION.LOADING_GET_FUNDS_TRANSFER_ACCESS:
      return { ...state, loading: { ...state.loading, GET_FUNDS_TRANSFER_ACCESS: { status: true } } };
    case ACTION.RECEIVE_GET_FUNDS_TRANSFER_ACCESS:
      return { ...state, loading: { ...state.loading, GET_FUNDS_TRANSFER_ACCESS: undefined }, funds_transfer_access: action.result.data };
    case ACTION.ERROR_GET_FUNDS_TRANSFER_ACCESS:
      return { ...state, loading: { ...state.loading, GET_FUNDS_TRANSFER_ACCESS: { status: false, detail: action.error.response } } };
    case ACTION.LOADING_GET_FUNDS_ICOME:
      return { ...state, loading: { ...state.loading, GET_FUNDS_ICOME: { status: true } } };
    case ACTION.RECEIVE_GET_FUNDS_ICOME:
      return { ...state, loading: { ...state.loading, GET_FUNDS_ICOME: undefined }, funds_icome: action.result.data };
    case ACTION.ERROR_GET_FUNDS_ICOME:
      return { ...state, loading: { ...state.loading, GET_FUNDS_TRANSFER: { status: false, detail: action.error.response } } };

    case ACTION.LOADING_PUT_NEIGHBOR_PAYMENTS:
      return { ...state, loading: { ...state.loading, PUT_NEIGHBOR_PAYMENTS: { status: true } } };
    case ACTION.RECEIVE_PUT_NEIGHBOR_PAYMENTS:
      return { ...state, loading: { ...state.loading, PUT_NEIGHBOR_PAYMENTS: undefined }};
    case ACTION.ERROR_PUT_NEIGHBOR_PAYMENTS:
      return { ...state, loading: { ...state.loading, PUT_NEIGHBOR_PAYMENTS: { status: false, detail: action.error.response } } };

    case ACTION.LOADING_GET_NEIGHBOR_PAYMENTS:
      return { ...state, loading: { ...state.loading, GET_NEIGHBOR_PAYMENTS: { status: true } } };
    case ACTION.RECEIVE_GET_NEIGHBOR_PAYMENTS:
      return { ...state, loading: { ...state.loading, GET_NEIGHBOR_PAYMENTS: undefined }, neighborPayments: action.result.data };
    case ACTION.ERROR_GET_NEIGHBOR_PAYMENTS:
      return { ...state, loading: { ...state.loading, GET_NEIGHBOR_PAYMENTS: { status: false, detail: action.error.response } } };

    case ACTION.LOADING_GET_FUNDS:
      return { ...state, loading: { ...state.loading, GET_FUNDS: { status: true } } };
    case ACTION.RECEIVE_GET_FUNDS:
      return { ...state, loading: { ...state.loading, GET_FUNDS: undefined }, funds: action.result.data };
    case ACTION.ERROR_GET_FUNDS:
      return { ...state, loading: { ...state.loading, GET_FUNDS: { status: false, detail: action.error.response } } };

    case ACTION.LOADING_PUT_FUNDS:
      return { ...state, loading: { ...state.loading, PUT_FUNDS: { status: true } } };
    case ACTION.RECEIVE_PUT_FUNDS:
      return { ...state, loading: { ...state.loading, PUT_FUNDS: undefined }, fund: action.result.data };
    case ACTION.ERROR_PUT_FUNDS:
      return { ...state, loading: { ...state.loading, PUT_FUNDS: { status: false, detail: action.error.response } } };
    case ACTION.LOADING_DELETE_FUNDS:
      return { ...state, loading: { ...state.loading, DELETE_FUNDS: { status: true } } };
    case ACTION.RECEIVE_DELETE_FUNDS:
      return { ...state, loading: { ...state.loading, DELETE_FUNDS: undefined } };
    case ACTION.ERROR_DELETE_FUNDS:
      return { ...state, loading: { ...state.loading, DELETE_FUNDS: { status: false, detail: action.error.response } } };
    case ACTION.LOADING_GET_ACCESS:
      return { ...state, loading: { ...state.loading, GET_ACCESS: { status: true } } };
    case ACTION.RECEIVE_GET_ACCESS:
      return { ...state, loading: { ...state.loading, GET_ACCESS: undefined }, access: action.result.data };
    case ACTION.ERROR_GET_ACCESS:
      return { ...state, loading: { ...state.loading, GET_ACCESS: { status: false, detail: action.error.response } } };
    case ACTION.LOADING_SET_ACCESS:
      return { ...state, loading: { ...state.loading, SET_ACCESS: { status: true } } };
    case ACTION.RECEIVE_SET_ACCESS:
      return { ...state, loading: { ...state.loading, SET_ACCESS: undefined }, access: action.result.data };
    case ACTION.ERROR_SET_ACCESS:
      return { ...state, loading: { ...state.loading, SET_ACCESS: { status: false, detail: action.error.response } } };
    case ACTION.LOADING_PUT_ACCESS:
      return { ...state, loading: { ...state.loading, PUT_ACCESS: { status: true } } };
    case ACTION.RECEIVE_PUT_ACCESS:
      return { ...state, loading: { ...state.loading, PUT_ACCESS: undefined }, access: action.result.data };
    case ACTION.ERROR_PUT_ACCESS:
      return { ...state, loading: { ...state.loading, PUT_ACCESS: { status: false, detail: action.error.response } } };
    case ACTION.LOADING_DELETE_ACCESS:
      return { ...state, loading: { ...state.loading, DELETE_ACCESS: { status: true } } };
    case ACTION.RECEIVE_DELETE_ACCESS:
      return { ...state, loading: { ...state.loading, DELETE_ACCESS: undefined }, access: action.result.data };
    case ACTION.ERROR_DELETE_ACCESS:
      return { ...state, loading: { ...state.loading, DELETE_ACCESS: { status: false, detail: action.error.response } } };
    case ACTION.LOADING_GET_REPORT:
      return { ...state, loading: { ...state.loading, GET_REPORT: { status: true } } };
    case ACTION.RECEIVE_GET_REPORT:
      return { ...state, loading: { ...state.loading, GET_REPORT: undefined } };
    case ACTION.ERROR_GET_REPORT:
      return { ...state, loading: { ...state.loading, GET_REPORT: { status: false, detail: action.error.response } } };
    case ACTION.LOADING_GET_DWELLING_ACCOUNT:
      return { ...state, loading: { ...state.loading, GET_DWELLING_ACCOUNT: { status: true }}}
    case ACTION.RECEIVE_GET_DWELLING_ACCOUNT:
      return { ...state, loading: { ...state.loading, GET_DWELLING_ACCOUNT: undefined }, dwellingAccount: { ...state.dwellingAccount, [action.param]: action.result.data.result } }
    case ACTION.ERROR_GET_DWELLING_ACCOUNT:
      return { ...state, loading: { ...state.loading, GET_DWELLING_ACCOUNT: { status: false, detail: action.error.response }}}
    
    case ACTION.LOADING_ADJUST_BALANCE:
      return { ...state, loading: { ...state.loading, ADJUST_BALANCE: { status: true } } };
    case ACTION.RECEIVE_ADJUST_BALANCE:
        return { ...state, loading: { ...state.loading, ADJUST_BALANCE: undefined } };
    case ACTION.ERROR_ADJUST_BALANCE:
        return { ...state, loading: { ...state.loading, ADJUST_BALANCE: { status: false, detail: action.error.response } } };

    default:
      return { ...state };
  }
};

export default budgetReducer;
