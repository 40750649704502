import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LinearProgress from "@material-ui/core/LinearProgress";
import { isLoadingGlobal, isLoading } from '../../utils/loader';
import './Loader.scss';

class SpinnerConnected extends Component {
  render() {
    const { loaderState } = this.props;
    const loadingToProcess = isLoadingGlobal(loaderState);
    const loading = isLoading(loadingToProcess);
    return (
      <div className="loader-connected">
        {
          loading && <LinearProgress className="linear"/>
        }
      </div>
    )
  }
}

SpinnerConnected.propTypes = {
  loaderState: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired
};

const mapStateToProps = state => ({
  loaderState: state,
});

export default connect(mapStateToProps)(SpinnerConnected);

