
import React from 'react';
import '@babel/polyfill';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Map } from 'immutable';
import { MuiThemeProvider } from '@material-ui/core/styles';
import createStore from './store';
import Routes from './routes';
import Theme from './theme';
import './general.scss';
import 'react-quill/dist/quill.snow.css'; 
import Intl from './components/Intl';

const store = createStore(Map(window.REDUX__STATE));

ReactDOM.render(
  <Provider store={store}>
    <Intl>
      <MuiThemeProvider theme={Theme}>
        <Routes store={store} />
      </MuiThemeProvider>
    </Intl>
  </Provider>,
  document.getElementById('root')
);
