import React, { Component } from 'react';
import { ReactSVG } from 'react-svg';

import Button from 'components/Button';

import { showIconAmenity } from 'constants/amenityTypes';
import './PropertiesConfigurationItem.scss';

class PropertiesConfigurationItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { icon, name, onDelete } = this.props;
    return (
      <div className='PropertiesConfigurationItem'>
        <div className='PropertiesConfigurationItem__image'>
          <ReactSVG src={showIconAmenity(icon).img} alt='Inmueble icon' />
        </div>
        <div className='PropertiesConfigurationItem__name'>
          {name}
        </div>
        <div className='PropertiesConfigurationItem__options'>
          <Button color='secondary' variant='outlined' onClick={onDelete}>Eliminar</Button>
        </div>
      </div>
    );
  }
}

export default PropertiesConfigurationItem;
