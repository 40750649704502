import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { browserHistory } from 'react-router';
import { notification } from 'antd';
import { FormattedMessage } from 'react-intl';
import sha512 from 'js-sha512';

import Profile from 'assets/img/profileX.png';
import Icon from 'components/Icon';
import Input from 'components/Input';
import { EXP_PASSWORD } from 'constants/globalRegex';
import * as profileActions from '../../../Profile/ProfileActions';
import * as Actions from '../../../Login/loginActions';
import { roleProf } from '../RoleProfile';
import './ChangePassword.scss';

export class ChangePassword extends Component {

  constructor(){
    super();
      this.state = { 
        roleProfileState: '',
        passwordActual: '',
        password: '',
        confirmPassword: '',
        error: {}
      }
  }

  componentDidMount(){
    this.props.getProfile();
  }

  componentWillReceiveProps(nextProps){
    let error = {};
    let isError = false;
    if(nextProps.profileState.resident_last_view > 0){
      
        const { resident_role } = nextProps.profileState;
        let role = roleProf(resident_role);
        this.setState({
          roleProfileState: role
        });
      
      if(nextProps.loginState.loading.CHANGE_PASSWORD && nextProps.loginState.loading.CHANGE_PASSWORD.status === false){
        this.evaluateError('error');
        error['passwordActual'] = <FormattedMessage id='Password.current' />
        isError = true;
        if (isError){
          this.setState({ error });
        }
      }
      if(nextProps.loginState.restore === false){
        this.evaluateError('success');
      }
    }
  }

  componentWillUnmount(){
    this.props.clearDataLogin();
  }

  onChange = (event) => {
    const { error } = this.state;
    delete error[event.target.name];
    this.setState({ [event.target.name]: event.target.value, error });
  }
  
  onSubmit = () => {
    const { password, confirmPassword, passwordActual } = this.state;
    let error = {};
    let isError = false;

    if(passwordActual === ''){
      error['passwordActual'] = <FormattedMessage id='Password.required' />;
      isError = true;
    }

    if(!password || !EXP_PASSWORD.test(password)){
      error['password'] = <FormattedMessage id='Password.rules' />;
      isError = true;
    }
    if(!confirmPassword || !EXP_PASSWORD.test(confirmPassword)){
      error['confirmPassword'] = <FormattedMessage id='Password.rules' />;
      isError = true;
    }
    if(password !== confirmPassword){
      error['confirmPassword'] = <FormattedMessage id='Password.different' />;
      isError = true;
    }
    if (isError) {
      this.setState({ error })
      return;
    }
    
    this.props.putChangePassword({
      email: this.props.profileState.resident_email,
      password: sha512(passwordActual).toUpperCase(),
      new_password: sha512(password).toUpperCase()
    });
  }

  goBack = () => {
    const { property } = this.props.params;
    browserHistory.push(`/property/${property}/profile/configuration`);
  }

  evaluateError = (type) => {
    if(type === 'success' ){
      notification[type]({
        message: 'Tu contraseña ha sido cambiada exitosamente.',
      });
      this.goBack();
    }
    if(type === 'error'){
      notification[type]({
        message: 'Contraseña incorrecta',
      });
    }
  }

  render(){
    const {
      resident_last_view,
      resident_name,
      resident_email,
      resident_phone
    } = this.props.profileState;
    const { roleProfileState, error, password, confirmPassword, passwordActual } = this.state;
        return (
          <>
            {resident_last_view > 0 && (
              <div>
                <div className="ChangePassword_goback" onClick={this.goBack}>
                  <Icon icon="arrow_back" color="secondary" />
                  <label>Regresar</label>
                </div>
                <div  className='containterChangePassword'>
                <div className="ChangePassword">
                  <div className="ChangePassword__display">
                    <img
                      className="ChangePassword__display__img"
                      src={Profile}
                      alt="Profile"
                    />
                    <div className="ChangePassword__display__description">
                      <h2 className="heading-2">{resident_name}</h2>
                      <p className="subtitle-2">{roleProfileState}</p>
                    </div>
                  </div>
                  <div className="ChangePassword__dataProfile">
                    <div className='ChangePassword__dataProfile__information'>
                      <div>
                        <p className="subtitle"><FormattedMessage id='Password.label_name' /></p>
                        <p className='body-text3'>{resident_name}</p>
                      </div>
                      <div>
                        <p className="subtitle"><FormattedMessage id='Password.label_phone' /></p>
                        <p className='body-text3'>
                          {resident_phone !== "" ? resident_phone : "No indicado"}
                        </p>
                      </div>
                      <div>
                        <p className="subtitle"><FormattedMessage id='Password.label_email' /></p>
                        <p className='body-text3'>{resident_email}</p>
                      </div>
                    </div>
                    <div className='ChangePassword__dataProfile__text'>
                      <h4 className='ChangePassword__dataProfile__text__title4'>Cambio de contraseña</h4>
                      <p className='body-text1'>
                        Por favor, ingresa tu contraseña actual y genera una
                        nueva. Recuerda que debe tener al menos 8 caracteres y
                        al menos un número y una letra.
                      </p>
                    </div>
                    <div className='ChangePassword__dataProfile__inputs'>
                      <Input 
                          fullWidth
                          label={<FormattedMessage id='Password.label1' />}
                          name='passwordActual'
                          type='password'
                          className="subtitle-2 "
                          InputLabelProps={{shrink: true}}
                          value={passwordActual}
                          error={!!error.passwordActual}
                          onChange={this.onChange}
                          helperText={error.passwordActual}
                        />
                    </div>
                    <div className='ChangePassword__dataProfile__inputs'>
                      <Input 
                        fullWidth
                        label={<FormattedMessage id='Password.label2' />}
                        name='password'
                        type='password'
                        className="subtitle-2 "
                        InputLabelProps={{shrink: true}}
                        value={password}
                        error={!!error.password}
                        onChange={this.onChange}
                        helperText={error.password}
                      />
                    </div>
                    <div className='ChangePassword__dataProfile__inputs'>
                      <Input 
                          fullWidth
                          label={<FormattedMessage id='Password.label3' />}
                          name='confirmPassword'
                          type='password'
                          className="subtitle-2 "
                          InputLabelProps={{shrink: true}}
                          value={confirmPassword}
                          error={!!error.confirmPassword}
                          onChange={this.onChange}
                          helperText={error.confirmPassword}
                        />
                    </div>
                    <div className='ChangePassword__dataProfile__buttons'>
                      <button className='ChangePassword__dataProfile__buttons__cancel' type='button' onClick={this.goBack} >Cancelar</button>
                      <button className='ChangePassword__dataProfile__buttons__change' type='button' onClick={this.onSubmit} >Cambiar contraseña</button>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            )}
          </>
        );
  }
}
const mapDispatchToProps = dispatch => bindActionCreators({
  ...Actions,
  ...profileActions
}, dispatch);

const mapStateToProps = state => ({
  profileState: state.get('profileState'),
  loginState: state.get('loginState'),
});

export default connect(mapStateToProps, mapDispatchToProps) (ChangePassword);
