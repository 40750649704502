import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ColCard from 'components/ColCard';
import Button from 'components/Button';
import BuildingDto from 'assets/logos/depto_casa.svg';
import Mail from 'assets/img/icon-email2.svg';
import Smartphone from 'assets/img/icon-smartphone.svg';
import Building from 'assets/logos/edificio.svg';
import Tower from 'assets/logos/casa.svg';
import './NeighborPanel.scss';

const unDefined = 'No indicado';

/* eslint-disable camelcase */
class NeighborPanel extends Component {
  showNeighborName = () => {
    const { name, first_last_name, second_last_name } = this.props;
    let fullName = '';
    if (name) {
      fullName += `${name} `;
    }
    if (first_last_name) {
      fullName += `${first_last_name} `;
    }
    if (second_last_name) {
      fullName += `${second_last_name} `;
    }
    return fullName !== '' ? fullName : unDefined;
  }

  render() {
    const {
      goToEdit,
      email,
      phone,
      building_number,
      building_name,
      tree_type
    } = this.props;
    const neighborName = this.showNeighborName();
    return (
      <div className='NeighborPanel row'>
        <ColCard
          className='card-list'
          columns={[
            {
              view: (
                <div className='col subtitle'>
                  <label className='name'>{ neighborName }</label>
                  <Button color='primary' onClick={ goToEdit }>{neighborName !== unDefined ? 'Editar' : 'Crear'} Residente</Button>
                </div>
              )
            }, {
              view: (
                <div className='row align-center'>
                  <img src={Building} alt='building' />
                  <div className='col subtitle'>
                    <label>Edificio</label>
                    {building_name || unDefined}
                  </div>
                </div>
              )
            }, {
              view: (
                <div className='row align-center'>
                  <img src={tree_type === 1 ? BuildingDto : Tower} alt='Location' />
                  <div className='col subtitle'>
                    <label>Dpto/Casa</label>
                    {building_number || unDefined}
                  </div>
                </div>
              )
            }, {
              view: (
                <div className='row align-center'>
                  <img src={Mail} alt='Email' />
                  <div className='col subtitle'>
                    <label>Correo Electronico</label>
                    {email || unDefined}
                  </div>
                </div>
              )
            }, {
              view: (
                <div className='row align-center'>
                  <img src={Smartphone} alt='Smartphone' />
                  <div className='col subtitle'>
                    <label>Teléfono</label>
                    {phone || unDefined}
                  </div>
                </div>
              )
            }
          ]}
        />
      </div>
    );
  }
}

NeighborPanel.defaultProps = {
  goToEdit: null,
  name: undefined,
  first_last_name: undefined,
  second_last_name: undefined,
  email: unDefined,
  phone: unDefined,
};
NeighborPanel.propTypes = {
  goToEdit: PropTypes.func,
  name: PropTypes.string,
  first_last_name: PropTypes.string,
  second_last_name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
};
export default NeighborPanel;
