import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { ReactSVG } from 'react-svg';
import * as Actions from '../../Property/propertyActions';
import { showIconAmenity } from 'constants/amenityTypes';

import Chain from 'assets/logos/icon_link.svg';
import ChainHover from 'assets/logos/chain_hover.svg';
import Edit from 'assets/logos/edit.svg';
import Delete from 'assets/logos/delete-button.svg';

import './BuildListItem.scss';

export class BuildListItem extends React.Component {
  constructor(){
    super();
    this.state = {
      anchorEl: null,
    }
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleGetImage = (type) => {
    const json = { 1: 'tower', 2: 'home' };
    return json[type];
  }

  handleGetType = (type, count) => {
    const json = count > 1 ? { 1: 'Deptos', 2: 'Casas' } : { 1: 'Depto', 2: 'Casa'};
    return json[type];
  }

  handleShowAccess = () => {
    const { amenities } = this.props.propertyState;
    const { access } = this.props.propertyState;

    return (access[this.props.id] || []).map((item, index) => {
      const amenity = (amenities || []).find(reg => reg.amenity_id === item);
      const amenity_type_id = amenity.type_id;
      const name = amenity.name;
      const amenityIcon = showIconAmenity(amenity_type_id);

      return (
        <div key={ index } className='BuildListItem__access'>
          <ReactSVG src={amenityIcon.img_small} />
          { name }
        </div>
      )
    });
  }

  handleDeleteBuild = id => () => {
    const { onDelete } = this.props;
    this.handleClose();
    onDelete(id);
  }

  render() {
    const { amenities } = this.props.propertyState;
    const { id, name, dwelling_number, type_id, selected, hide, goTo, onAdd } = this.props;

    return (
      <div className={`BuildListItem ${ selected && 'BuildListItem__selected' } ${ hide && 'hidden' }` }>
        <div className='BuildListItem__container'>
          <div className='BuildListItem__container__condominiums'>
            <div className='BuildListItem__container__condominiums__svg'>
              <ReactSVG src={showIconAmenity(this.handleGetImage(type_id)).img_small} />
            </div>
            <div className='BuildListItem__container__condominiums__dptos' >
              <p className='body-text1'>{ name }</p>
              <p className='body-text1'>{ `${dwelling_number} ${this.handleGetType(type_id, dwelling_number)}` }</p>
            </div>
          </div>
          <div className='BuildListItem__container__options'>
            {
              !!(amenities || []).length && (
                <div className='BuildListItem__container__options__icon'>
                  {
                    !selected ? <ReactSVG src={ Chain } onClick={() => onAdd(id, name)} /> : <ReactSVG src={ ChainHover }/>
                  }
                </div>
              )
            }
            <div className='BuildListItem__container__options__icon'>
                <ReactSVG src={ Edit } onClick={ goTo(type_id, id) }/>
            </div>
            <div className='BuildListItem__container__options__icon'>
                <ReactSVG src={ Delete } onClick={ this.handleDeleteBuild(id) }/>
            </div>
          </div>
        </div>
        <div className='BuildListItem__item__access'>
            { this.handleShowAccess() }
        </div>
      </div>
    );
  }
}

BuildListItem.contextTypes = {
  router: PropTypes.shape()
};

BuildListItem.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  propertyState: state.get('propertyState')
});

export default connect(mapStateToProps, mapDispatchToProps)(BuildListItem);
