import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionsAccount from '../../../Accounts/accountActions';
import './PayError.scss';
import images from 'assets/img';
import Button from 'components/Button/Button';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

export class PayError extends Component {

  goMyAccount = () => {
    const { property } = this.props.params;
    this.context.router.push(`/property/${property}/profile/configuration?tab=accountimobly`);
  }

  render() {

    return (
      <>
        <div className="PayError card">
          <img src={images.payment.error} alt="Error en pago" className="PayError__icono"/>
          <h2 className="heading-2">
            <FormattedMessage id='Payment.Error.Title'/>
          </h2>
          <div className='PayError__text'>
            <p className="regular-text">
              <FormattedMessage id='Payment.Error.Description'/>
            </p>
            <p className="regular-text">
              <FormattedMessage id='Payment.Error.Description2'/>
              <span className='pay-error-email'><FormattedMessage id='email_support'/></span>
            </p>
          </div>

          <div className="PayError__content">
            <Button variant="contained" color="primary" onClick={this.goMyAccount}>
              <FormattedMessage id='Payment.Error.Retry'/>
            </Button>
          </div>
        </div>
      </>
    )
  }
}

PayError.contextTypes = {
  router: PropTypes.shape()
}

const mapDispatchToProps = dispatch => bindActionCreators({ ...actionsAccount}, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  accountState: state.get('accountState')
});


export default connect(mapStateToProps, mapDispatchToProps)(PayError);
