import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as Actions from "../../budgetActions";
import BudgetHistory from "./BudgetHistory/BudgetHistory";
import BudgetPending from "./BudgetPending/BudgetPending";
import BudgetProgrammed from "./BudgetProgrammed/BudgetProgrammed";
import SecondaryTabView from "components/SecondaryTabView";
import ExtraordinaryPaymentModal from "./ExtraordinaryPaymentModal";

const BudgetEntry = props => {

  const [ paymentModalOpen, setPaymentModalOpen ] = useState(false);

  const { params } = props;

  const handleChangeTab = () => {
    props
      .addData({
        concepts: {},
        conceptsToPay:{},
        conceptsToCash:{}
      });
  };

  return (
    <div>
      <SecondaryTabView
        items={[
          {
            value: 1,
            label: "Conceptos programados",
            view: <BudgetProgrammed params={params} />
          }, {
            value: 2,
            label: "Pagos pendientes",
            view: <BudgetPending params={params} />
          }, {
            value: 3,
            label: "Histórico de pagos",
            view: <BudgetHistory params={params} />
          }
        ]}
        button={{
          children: 'Pago extraordinario',
          onClick: _ => setPaymentModalOpen(!paymentModalOpen)
        }}
        onChange={ handleChangeTab }
      />
      <ExtraordinaryPaymentModal
        open={ paymentModalOpen }
        onClose={ _ => setPaymentModalOpen(!paymentModalOpen) }
        { ...props }
      />
    </div>
  );
}

BudgetEntry.contextTypes = {
  router: PropTypes.shape()
};

BudgetEntry.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  budgetState: state.get("budgetState")
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BudgetEntry);
