import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as Actions from '../../../../Budget/budgetActions';
import { defaultDateFormat } from 'constants/dates';
import FieldConcept from './FieldConcept';
import FieldProvider from './FieldProvider';
import FieldPeriodicity from './FieldPeriodicity';
import FieldRemember from './FieldRemember';
import FieldDelete from './FieldDelete';
import FieldNextPayment from './FieldNextPayment';
import ConceptPopover from '../ConceptPopover';
import CurrencyInput from 'components/CurrencyInput/CurrencyInput';
import './ConceptItem.scss';

export class BudgetConceptItem extends Component {
  state = {
    anchorEl: null,
    top: 0,
    left:0,
    isService: false
  };
  componentDidMount(){
    this.props.getPeriods();
    this.updateAmount();
  }

  componentDidUpdate(nextProps) {
    if(nextProps.concept.amount !== this.props.concept.amount) {
      this.updateAmount();
    }
    if(nextProps.budgetState.activeSelected !== this.props.budgetState.activeSelected){
      this.mapActivesSelected();
    }
  }

  updateAmount() {
    this.setState({
      amount: this.props.concept.amount ? this.props.concept.amount : 0
    });
  }

  mapActivesSelected = () => {

    const { actives, activeSelected } = this.props.budgetState;

    if(!!activeSelected.length) {
      const objectActives = Object.values(actives.result);
      const propertySelected = objectActives.find(active => active.id === activeSelected);
      if(propertySelected.type === 'amenity'){
        const amenity_id = propertySelected?.amenities[0].id;
        this.props.getBudgetsAndFeesAmenities({amenity_id});
        return;
      }

      this.getBudgetsAndFees(propertySelected.building_id);
    }
  }

  getBudgetsAndFees = (bid) => {
    this.props.getBudgetsAndFees({ bid })
  }

  closePopover = () => {
    this.setState({ anchorEl: null, type: null });
  }

  handleServiceNameChange = (serviceName) =>  {
    const { id : cid, asset_id } = this.props.concept;
    
    const conceptData = this.props.budgetState.concepts[asset_id][cid];
    conceptData.name = serviceName;

    this.props.putConcept({
      conceptData,
      cid
    });
  }

  handleClickProvider = (event) => {
    const { id : cid, asset_id } = this.props.concept;
    
    const conceptData = this.props.budgetState.concepts[asset_id][cid];
    conceptData.provider_id = Number(event.target.value);

    this.props.putConcept({ conceptData, cid });
  }

  handleChangeAmount = ({ target }) => {
    const { value } = target;
    const amount =  Number(value) || 0;

    this.setState({ amount});
  }

  handleOnBlurAmount = () => {
    const { property } = this.props.params;
    const { id: cid, asset_id } = this.props.concept;
    const { amount } = this.state;

    const conceptData = this.props.budgetState.concepts[asset_id][cid];
    conceptData.amount = Number(amount);
    
    this.props.putConcept({
      conceptData,
      cid
    }).then(() => {
      this.props.getActivesWithoutSelected({
        property
      });
      this.mapActivesSelected();
      this.props.updateConcept(conceptData);
    });
  };

  handleChangeRemember = () => {
    const { id: cid, asset_id } = this.props.concept;

    const conceptData = this.props.budgetState.concepts[asset_id][cid];
    conceptData.reminder = !conceptData.reminder;

    this.props.putConcept({
      conceptData,
      cid
    });
  }  
  
  onChangeNextPayment = ({ target }) => {
    const { id: cid, asset_id } = this.props.concept;
    if(target.value){
      const next_payment_date = target.value;
      const conceptData = this.props.budgetState.concepts[asset_id][cid];
      conceptData.next_payment_date = moment(next_payment_date).format(defaultDateFormat);
      this.props.putConcept({
        conceptData,
        cid
      });
    }
  }

  onChangePeriodicity = ({ target }) => {
    const { id : cid, asset_id } = this.props.concept;

    if(target.value){
      const period_type_id = target.value;
      const conceptData = this.props.budgetState.concepts[asset_id][cid];
      conceptData.period_type_id = period_type_id;
      this.props.putConcept({
        conceptData,
        cid
      }).then(() => { 
        this.mapActivesSelected();
      })
    }
  }

  handleDeleteConcept = () => {
    const { property } = this.props.params;
    const { id : cid, asset_id, active_id } = this.props.concept;

    this.props.deleteConcept({
      property,
      active: active_id,
      cid
    }).then(()=>{
      const { concepts } = this.props.budgetState;
      delete concepts[asset_id][cid];
      this.props.addData({ concepts });
    }).then(() => { 
      this.mapActivesSelected();
      this.props.clearUpdateConcept(cid);
    })
  }

  addProvider = (idConcept) => () => {
    const { property, id } = this.props.params;
    const { activeSelected } = this.props.budgetState;
    const urlBase = `/Wizard/${id}/property/${property}`;
    const urlSecondary = `/property/${property}/budget/edition`;
    const url = id ? urlBase : urlSecondary;
    this.context.router.push(`/property/${property}/providers/new?back="${url}"&active=${activeSelected}&concept=${idConcept}`);
  }

  render() {
    const { providers } = this.props.providerState;
    const { anchorEl, type, amount } = this.state;
    const { name, provider_id, reminder, period_type_id, id, status, next_payment_date } = this.props.concept;
    const { asset_type } = this.props;
    const { periods } = this.props.budgetState;
    
    return (
      <div>
        <div className='BudgetConceptItem'>
          <div className={status!=='toValidate'? 'BudgetConceptItem__col disable':'BudgetConceptItem__col'}>
            <FieldConcept
              addDefault={ true }
              enableNewConcept={ true }
              value={name}
              asset_type={asset_type}
              onChange={this.handleServiceNameChange}
              toEdit={status}
            />
          </div>
          <div className='BudgetConceptItem__col'>
            <FieldProvider
              providersList={providers.result || []}
              providerId={provider_id}
              onChangeValue={this.handleClickProvider}
              addProvider={this.addProvider(id)}
            />
          </div>
          <div className='BudgetConceptItem__col'>
            <FieldPeriodicity
              value={period_type_id}
              onChangePeriodicity={this.onChangePeriodicity}
              periods={periods}
            />
          </div>
          <div className='BudgetConceptItem__col'>
            <FieldNextPayment
              next_payment_date={next_payment_date}
              onChangeNextPayment={this.onChangeNextPayment}
            />
          </div>
          <div className='BudgetConceptItem__col'>
            <CurrencyInput
              value={amount}
              onChange={this.handleChangeAmount}
              onBlur={this.handleOnBlurAmount}
              disabled={status!=='toValidate'}
            />
          </div>
          <div className='BudgetConceptItem__remember'>
            <FieldRemember
              onChange={this.handleChangeRemember}
              checked={reminder}
              disab={status!=='toValidate'}
            />
          </div>
          <div className='BudgetConceptItem__delete'>
            <FieldDelete onClick={this.handleDeleteConcept} disab={status!=='toValidate'}/>
          </div>
          <div style={{ float: 'left', clear: 'both' }} ref={(el) => { this.bottomPage = el }}></div>
        </div>
        <ConceptPopover 
          closePopover={this.closePopover} 
          anchorEl={anchorEl} 
          top={this.state.top}
          left={this.state.left}
          type={type} 
          data={{ ...this.props.concept, params:this.props.params }}
          isService={this.state.isService}
        />
        
      </div>
    );
  }
}

BudgetConceptItem.contextTypes = {
  router: PropTypes.object
};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  budgetState: state.get('budgetState'),
  providerState: state.get('providerState'),
});

export default connect(mapStateToProps, mapDispatchToProps)(BudgetConceptItem);

