import React, { Component } from 'react';

import SimpleModal from 'components/SimpleModal';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import './ModalInvitations.scss';
export class ModalInvitations extends Component {
  render() {
    const { open, onClose, goTo, sendInvitations, loading, name } = this.props;
    
    return (
      <SimpleModal
        title='Invitación App Imobly'
        open={open}
        onClose={onClose}
      >
        <div className='ModalInvitations'>
          <div className='ModalInvitations__body'>
            Para que los residentes de {name} puedan ver sus saldos actualizados envía 
            una invitación para que <br />
            descarguen la <a href={goTo} target='_blank'>App Imobly</a>
          </div>
          <div className='ModalInvitations__options'>
            {
              !loading
              ? <Button variant='contained' color='secondary' onClick={sendInvitations}>
                  Envíar invitación
                </Button>
              : <Spinner loading />
            }
          </div>
        </div>
      </SimpleModal>
    );
  }
}

export default ModalInvitations;
