import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import localeData from 'constants/languages.json';

// addLocaleData([...en, ...es, ...fr]);

const language = 'es';
const languageWithoutRegionCode = (language ? language.toLowerCase().split(/[_-]+/)[0] : 0);
const messages = localeData[languageWithoutRegionCode] || localeData[language] || localeData.es;


export class Intl extends Component {
  render() {
    return (
      <IntlProvider
        locale={language}
        defaultLocale={language}
        messages={messages}
      >
        {this.props.children}
      </IntlProvider>
    );
  }
}

export default Intl;
