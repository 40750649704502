import React, { useState, useEffect } from "react";
import RequestsList from "../RequestsList/RequestsList";
import { Card } from "antd";
import MonthCalendar from '../MonthCalendar';
import WeekCalendar from '../WeekCalendar';

import "./Body.scss";

export const Body = (props) => {

  const [ mode, setMode ] = useState('week');
  
  const [ selectedDate, setSelectedDate ] = useState({ });

  const [ selectedRequest, setSelectedRequest ] = useState();

  const [ lastRefresh, setLastRefresh ] = useState();

  useEffect(() => {
    props.refresh();
  }, [ lastRefresh ])

  return (
    <>
      <div className="Body col">
        <div className="row">
          <Card className="col left-column">
            <MonthCalendar
              amenityId={ props.amenityId }
              lastRefresh={ lastRefresh }
              onChange={ setSelectedDate }
              selectedRequest={ selectedRequest }
              mode={ mode }
            />
            <RequestsList
              amenityId={props.amenityId}
              lastRefresh={ lastRefresh }
              goToRequest={ request => {
                setSelectedRequest(request);
              }}
            />
          </Card>
          <Card className="col">
            <WeekCalendar
              amenityId={props.amenityId}
              onModeChange={ setMode }
              date={ selectedDate }
              selectedRequest={ selectedRequest }
              requests={ selectedDate.events }
              mode={ mode }
              refresh={ setLastRefresh }
            />
          </Card>
        </div>
      </div>
    </>
  );
};

export default Body;
