import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './StepperWizardItem.scss';
import Text from 'components/Text';

class StepperWizardItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { active, number } = this.props;
    return (
      <div className={ `StepperWizardItem ${ active ? 'active' : '' }` }>
        <div className={active ? 'StepperWizardItem__number StepperWizardItem__number__active' : 'StepperWizardItem__number'}>
          <span>{number}</span>
        </div>
        <div className='StepperWizardItem__text'>
          <Text variant={active ? 'body2' : 'caption'} id={`Layout.Wizard.Step.${number}`} onlyformat={false} />
        </div>
      </div>
    );
  }
}
StepperWizardItem.defaultProps = {
  last: false,
  active: false,
};

StepperWizardItem.propTypes = {
  last: PropTypes.bool,
  active: PropTypes.bool,
  number: PropTypes.number.isRequired,
};

export default StepperWizardItem;
