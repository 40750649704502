import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import Profile from 'assets/img/profileX.png';
import './RequestItem.scss';

export const RequestItem = (props) => {
  const { title, building_name, full_name, start_date, end_date, status, dwelling_number } = props.item;
    return (
      <>
        <div onClick={() => props.onClick({
            ...props.item,
            currentTime: moment().toDate()
          }) } className="RequestItem col">
          <div className="list-display row">
            <img className="display-img" src={Profile} />
            <div className="display-data">
              <h5 className="space">{full_name}</h5>
              <p className="body-text3">{ building_name }, { dwelling_number !== '' ? dwelling_number : '---' }</p>
            </div>
          </div>
          <div className="data">
            <p className="body-text3">
              {(title !== '' ? title : '---')}
              <br />
              { moment(start_date).format('LL') }
              <br />
              { moment(start_date).format('HH:mm') } - { moment(end_date).format('HH:mm') } hrs
            </p>
          </div>
          <div className={ `status ${ status.toLocaleLowerCase() }-colors subtitle-2` }>
            <FormattedMessage id={ `Status.${ props.item.status }` }/>
          </div>
        </div>
      </>
    );
}
 
export default RequestItem;