import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import * as Actions from '../../Budget/budgetActions';
import * as ProviderActions from '../../Providers/providerActions';
import { getProperties } from '../../Property/propertyActions';

import Card from 'components/Card';
import BudgetDetail from './Detail/Detail';

import './Budget.scss';
import Title from '../components/Title/Title';

class Budget extends Component {
  componentDidMount() {
    const { property } = this.props.params;
    
    this.props.getProperties();
    this.props.getProviders({ idProperty: property });
    this.getActives();
    this.bottomPage.scrollIntoView();
  }

  selectDefaultActive() {
    const { actives } = this.props.budgetState;
    
    this.props.addData({
      activeSelected: Object.keys(actives?.result || { })[0],
      concepts: {}
    });
  }

  getActives() {
    const { property } = this.props.params;

    this.props.getActives({ property }).then(() => this.selectDefaultActive());
  }

  goBack = () => {
    const { property, id } = this.props.params;
    if (property) {
      this.context.router.push(`/Wizard/${Number(id) - 10}/property/${property}`);
      return;
    }
    this.context.router.push(`/Wizard/${Number(id) - 10}/property`);
  }

  render() {
    const { resident_last_view } = this.props.profileState;

    return (
      <div className='Budget col'>
        <div style={{ float: 'left', clear: 'both' }} ref={(el) => { this.bottomPage = el }}></div>   

        <Title
          title='Presupuesto'
          helpMessage='Añade manualmente a tus proveedores con los datos de sus servicios y se creará automáticamente un presupuesto mensual y anual.'
          goBack={this.goBack}
          property={ resident_last_view || null }
        />
        <div className='Budget__body'>
          <Card>
            <BudgetDetail params={this.props.params}/>
          </Card>
        </div>
      </div>
    );
  }
}


Budget.contextTypes = {
  router: PropTypes.shape()
};

Budget.propTypes = {
  params: PropTypes.instanceOf(Object),
  loginState: PropTypes.instanceOf(Object),
  budgetState: PropTypes.instanceOf(Object),
  profileState: PropTypes.instanceOf(Object),
  getProperties: PropTypes.func,
  getProviders: PropTypes.func,
  getActives: PropTypes.func
};

Budget.defaultProps = {
  params: null,
  loginState: null,
  budgetState: null,
  profileState: null,
  getProperties: null,
  getProviders: null,
  getActives: null
};

const mapDispatchToProps = dispatch => bindActionCreators({ ...Actions, ...ProviderActions, getProperties }, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  budgetState: state.get('budgetState'),
  profileState: state.get('profileState'),
});

export default connect(mapStateToProps, mapDispatchToProps)(Budget);
