import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import * as Actions from '../loginActions';
import CodeForm from './CodeForm';
import { EMAIL_REGEX, EMAIL_REGEX_2 } from 'constants/globalRegex';
import { FormattedMessage } from 'react-intl';

export class Code extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      codeError: ''
    };
  }

  onChange = (event) => {
    this.setState({ emailError: undefined });
    this.props.addLoginData({ registro: event.target.value });
  }

  onChangeCode = (event) => {
    this.setState({ code: (event.target.value).replace(/\s/g, ''), codeError: '' });
  }

  resendCode = () => {
    const { registro } = this.props.loginState;
    if (!registro || !EMAIL_REGEX.test(registro)) {
      this.setState({ emailError: <FormattedMessage id="Message.Login.Code"/> });
      return;
    }
    this.props.getReset(registro);
  }

  goTo = where => () => {
    this.props.addLoginData({ registro: '', restore: true });
    this.context.router.push(`/${where}`);
  }

  onSubmit = () => {
    const { code } = this.state;
    const { restore, registro } = this.props.loginState;
    let isError = false;
    const codd = code.replace(/_/g, '');
    if (codd.length !== 5) {
      this.setState({ codeError: <FormattedMessage id="Message.Login.Code.invalid"/> });
      isError = true;
    }
    if (restore && !EMAIL_REGEX_2.test(registro)) {
      this.setState({ emailError: <FormattedMessage id="Message.Login.Email.invalid"/> });
      isError = true;
    }
    if (isError) {
      return;
    }
    this.props.getCode(code, registro).then(() => {
      const { CODE_SIGNUP } = this.props.loginState.loading;
      if (!CODE_SIGNUP) {
        this.context.router.push('/welcome');
      }
    });
  }

  render() {
    const { code, codeError } = this.state;
    const { restore, registro } = this.props.loginState;
    return (
      <CodeForm
        registro={registro}
        isRestore={restore}
        code={code}
        onChangeCode={this.onChangeCode}
        goTo={this.goTo}
        onSubmit={this.onSubmit}
        codeError={codeError}
        resendCode={this.resendCode}
      />
    );
  }
}

Code.contextTypes = {
  router: PropTypes.shape()
};

Code.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
});

export default connect(mapStateToProps, mapDispatchToProps)(Code);

