import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { toMoneyFormat } from '../../../../utils/format';
import './QuotaFotter.scss';

class QuotaFotter extends Component {
  render() {
    const { total_quotas, total_budget } = this.props;
    return (
      <div className='QuotaFotter'>
        <div className='QuotaFotter__row'>
          <div className='QuotaFotter__row__label'>Total de cuotas</div>
          <div className='QuotaFotter__row__amount'>${toMoneyFormat(total_quotas)}</div> 
        </div>
        <div className='QuotaFotter__row'>
          <div className='QuotaFotter__row__label'>Total presupuestado</div>
          <div className='QuotaFotter__row__amount'>${toMoneyFormat(total_budget)}</div>
        </div>
      </div>
    );
  }
}

QuotaFotter.propTypes = {
  total_quotas: PropTypes.number.isRequired,
  total_budget: PropTypes.number.isRequired,
};

export default QuotaFotter;
