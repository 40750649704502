import React, { Component } from 'react';
import './PanelBlank.scss';

class PanelBlank extends Component {
  render() {
    const { children } = this.props;
    return (
      <div className='PanelBlank'>
        {children}
      </div>
    );
  }
}

export default PanelBlank;
