import {
  BANCK_ACCOUNT_REGEX,
  EMAIL_REGEX_2,
  NUMBER_REGEX,
  PHONE_NUMBER_REGEX,
  RFC_REGEX
} from 'constants/globalRegex';

export const banksAccounts = value =>
  ((value && BANCK_ACCOUNT_REGEX.test(value)) || !value
    ? { validation: true }
    : { validation: false, description: 'Clabe interbancaria incorrecta' }
  );

export const number = value =>
  ((value && NUMBER_REGEX.test(value)) || !value
    ? { validation: true }
    : { validation: false, description: 'El campo solo acepta numericos.' }
  );

export const RFC = value =>
  ((value && RFC_REGEX.test(value)) || !value
    ? { validation: true }
    : { validation: false, description: 'El formato de la RFC es incorrecto.' }
  );

export const email = value =>
  ((value && EMAIL_REGEX_2.test(value)) || !value
    ? { validation: true }
    : { validation: false, description: 'Dirección de email invalida' }
  );

export const characters = value =>
  ((value && /^[a-zA-Z\s\u00C0-\u017F\_\-\,\.\']*$/i.test(value)) || !value
    ? { validation: true }
    : { validation: false, description: 'El campo no permite entradas numericas.' }
  );

export const phoneNumber = value =>
  ((value && PHONE_NUMBER_REGEX.test(value)) || !value
    ? { validation: true }
    : { validation: false, description: 'Numero de teléfono invalido, al menos deben ser 10 digitos' }
  );

export const validator = (compare, errorStack, keyErrorStack) => {
  const error = errorStack;
  if (compare.validation === false) {
    error[keyErrorStack] = compare.description;
  } else {
    delete error[keyErrorStack];
  }
  return error;
};

