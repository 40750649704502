import React, { useState, useEffect } from 'react';
import WeekCalendar from 'react-week-calendar';
import Event from './Event';
import './Agenda.scss';
import { Radio } from 'antd';
import 'react-week-calendar/dist/style.css';

export const WeeklyCalendar = props => {

  const [ mode, setMode ] = useState(props.mode);

  const viewOptions = [
    { label: 'DÍA', value: 'day' },
    { label: 'SEMANA', value: 'week' }
  ];

  const onRadioChange = ({ target }) => {
    setMode(target.value);
  }

  const addLastDayInterval = (interval, intervals) => {
    const startOfLastPart = interval.end.clone().startOf('day');
    if (startOfLastPart.diff(interval.end)) {
      intervals.push({
        ...interval,
        start: interval.end.clone().startOf('day')
      })
    }
  }

  const handleDifferentDayInterval = (interval, intervals) => {
    const start = interval.start.clone();
    while (!start.isSame(interval.end, 'day')) {
      intervals.push({
        ...interval,
        start: start.clone(),
        end: start.clone().endOf('day')
      })
      start.add(1, 'day').startOf('day');
    }
    addLastDayInterval(interval, intervals);
  }

  const mapIntervals = () => {
    const intervals = [];
    (props.selectedIntervals || [ ]).map(interval => {
      if (interval.start.isSame(interval.end, 'day')) {
        intervals.push(interval);
      } else {
        handleDifferentDayInterval(interval, intervals);
      }
    });
    return intervals;
  }

  useEffect(_ => {
    mode && props.onModeChange && props.onModeChange(mode);
  }, [ mode ])

  useEffect(() => {
    setTimeout(() =>{
      const weekCalendar = document.getElementsByClassName('weekCalendar__content')[0];
      const event = document.querySelector('.Event.selected') || document.querySelector('.Event');
      
      if (event) {
        const top = Number(window.getComputedStyle(event.parentElement).getPropertyValue('top').replace('px', ''));
        
        weekCalendar.scrollTo({
          top
        })
      }
    }, 200)
  }, [
    props.selectedIntervals
  ])

  return (
    <div className={ `WeeklyCalendar ${ mode }` }>
      <div className='row space-between'>
        { mode === 'week' && (
          <h2>
            Semana del { props.date?.start?.format('DD') } al { props.date?.end?.format('DD [de] MMMM YYYY') }
          </h2>
        )}
        { mode === 'day' && (
          <h2>
            { props.date?.start?.format('LL') }
          </h2>
        )}
        <Radio.Group
          defaultValue={ mode }
          onChange={ onRadioChange }
          buttonStyle="solid">
          { viewOptions.map(
              (option, key) => <Radio.Button key={ key } value={ option.value }>{ option.label }</Radio.Button>
            )
          }
        </Radio.Group>
      </div>
      <WeekCalendar
        { ...props }
        firstDay={ props.date?.start }
        useModal={ false }
        numberOfDays={ mode === 'day' ? 1 : 7 }
        scaleUnit={ 60 }
        dayFormat='ddd DD'
        cellHeight={ 78 }
        eventComponent={ eventProps => <Event { ...props } { ...eventProps } /> }
        selectedIntervals={mapIntervals()}
      />
    </div>
  )
};

export default WeeklyCalendar;
