import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as Actions from "../../../Budget/budgetActions";
import * as propertyActions from "../../../Property/propertyActions";
import Card from "components/Card";
import Table from "components/Table";
import moment from 'moment';

import "./FundDetails.scss";

export class FundDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { };
  }

  componentDidMount() {
    const { property } = this.props.params;
    const { activeSelected } = this.props;

    if(!activeSelected) { 
      this.goBack();
      return;
    }
    this.props
      .getActives({
        property
      })
      .then(() => {
        this.handleUpdateData(activeSelected);
      });
  }

  handleUpdateData = active => {
    const { property } = this.props.params;
    
    this.props
      .getAssets({
        property,
        active
      })
      .then(this.props.getFunds({ pid: property, aid: active }));
  };
  
  goBack = () => {
    const { property } = this.props.params;
    this.context.router.push(`/property/${property}/dashboard`);
  }

  render() {
    const { funds, loading } = this.props.budgetState;

    return (
      <Card>
        <Table
          columns={[
            {
              headerName: 'Nombre',
              field: 'name_found'
            },
            {
              headerName: 'Tipo',
              field: 'type',
              render: value => value.type === 1 ? 'Objetivo': 'Recurrente'
            }, {
              headerName: 'Duración',
              field: 'duration',
              render: value => moment.duration(moment().add(value.duration, 'month').diff(moment())).humanize().replace('un', '1')
            },{
              headerName: 'Monto recurrente',
              field: 'type',
              currency: true,
              render: value => value.type === 2 ? value.target_amount : 'N/A'
            },{
              headerName: 'Monto objetivo',
              field: 'type',
              currency: true,
              render: value => value.type === 1 ? value.target_amount : 'N/A'
            },{
              headerName: 'Saldo',
              field: 'balance',
              currency: true
            }
          ]}
          tableProps={{
            stickyHeader: true
          }}
          loading={ loading.GET_FUNDS?.status }
          rows={ funds?.result }
        />
      </Card>
    );
  }
}

FundDetails.contextTypes = {
  router: PropTypes.shape(),
  activeSelected: PropTypes.string
};

FundDetails.propTypes = {};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...Actions, ...propertyActions }, dispatch);
const mapStateToProps = state => ({
  budgetState: state.get("budgetState")
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FundDetails);
