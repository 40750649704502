import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import * as Actions from '../../Property/propertyActions';

import Card from 'components/Card';
import Form from './Form/Form';
import Accordion from './Accordion/Accordion';
import Button from 'components/Button';
import Dialogs from 'components/Dialogs';

import './AddTowerPage.scss';
import Tittle from '../components/Title/Title';
import { INPUT_TYPE_DATE_START_ADMIN, INPUT_TYPE_NAME } from 'constants/typeInput';
import { FormattedMessage } from 'react-intl';

let renderAccordion = null;

export class AddHomeTowerPage extends Component {

  constructor() {
    super();
    this.state = {
      warningGlobalDate: false,
      warningTitle: '',
      warningMessage: '',
      showFocus: true,
      dwellingsLength: 1,
      blockButton: false,
      error: {},
      buildingName: ''
    };
  }

  componentDidMount() {
    clearTimeout(renderAccordion);
    const { property, towerID } = this.props.params;
    if (towerID) {
      this.props.getBuilding({
        idProperty: property,
        idBuilding: towerID
      });
      this.props.getDwellings({
        idProperty: property,
        idBuilding: towerID
      });
    }

    if (!towerID) {
      const name = this.props.propertyState.new.name;
      const { build, buildings } = this.props.propertyState;
      const { data } = build;
      if (!data['0']) {
        this.props.addPropertyData({
          build: {
            ...build,
            name: !buildings.result || buildings.length === 0 ? name : 'Edificio',
          }
        }).then(() => {
          this.handleAddAccordion(1, true);
        });
      }
      this.showFocus();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { name } = prevProps.propertyState.build;
    const nextName  = this.props.propertyState.build.name;
    if(name !== nextName){
      this.setState({ buildingName: nextName });
    }
  }


  componentWillUnmount() {
    this.props.unmountBuild();
  }

  handleAddAccordion = (numAccordions, isFirst) => {
    const { build } = this.props.propertyState;
    let num = Object.getOwnPropertyNames(build.data).length;
    for (let i = 0; i < numAccordions; i += 1) {
      const initialDwellingDefault = 1;
      const newInitial = (i + (isFirst ? 1 : 2)) * 100;
      build.data[num] = {
        initial: newInitial + initialDwellingDefault,
        content: this.handleAddDwellings(newInitial, initialDwellingDefault, num),
      };
      num += 1;
    }
    this.props.addPropertyData({
      build: {
        ...build,
        data: build.data,
      }
    });
  }

  handleRemoveAccordion = (numAccordions) => {
    const { build } = this.props.propertyState;
    let num = Object.getOwnPropertyNames(build.data).length;
    for (let i = 0; i < numAccordions; i += 1) {
      num -= 1;
      delete build.data[num];
    }
    this.props.addPropertyData({
      build: {
        ...build,
        data: build.data,
      }
    });
  }

  handleAddDwellings = (initialNumber, numberDwelling, floorOfDwellings) => {
    const { build } = this.props.propertyState;
    const content = [];
    for (let i = 0; i < numberDwelling; i++) {
      content.push({
        dwelling_id: 0,
        dwelling_name: build.name,
        dwelling_number: `${initialNumber + (i + 1)}`,
        dwelling_admin_date: build.date_start_admin,
        dwelling_floor: `${floorOfDwellings}`,
        dwelling_amount: 0,
        resident_id: 0,
        resident_name: '',
        resident_last_name: '',
        resident_sec_last_name: '',
        resident_email: '',
        resident_phone: ''
      });
    }
    return content;
  }

  handleChangeDataAccordion = (event) => {
    const newRows = event.target.value === '' ? 1 : event.target.value;
    if (!isNaN(Number(event.target.value))) {
      this.props.addPropertyData({
        build: {
          ...this.props.propertyState.build,
          floors: (event.target.value === '') ? 0 : parseInt(event.target.value)
        }
      });
      const { data } = this.props.propertyState.build;
      const oldRows = Object.getOwnPropertyNames(data).length;
      clearTimeout(renderAccordion);
      renderAccordion = setTimeout(() => {
        if (newRows === '') {
          this.props.addPropertyData({ build: { ...this.props.propertyState.build, floors: 1 } });
          return null;
        }
        if (newRows > oldRows) {
          const rows = newRows - oldRows;
          this.handleAddAccordion(rows, false);
          if (newRows === 1) {
            this.props.addPropertyData({ build: { ...this.props.propertyState.build, floors: 1 } });
          }
          return null;
        }
        if (newRows < oldRows) {
          const rows = oldRows - newRows;
          this.handleRemoveAccordion(rows);
          if (newRows === 1) {
            this.props.addPropertyData({ build: { ...this.props.propertyState.build, floors: 1 } });
          }
          return null;
        }
        if (newRows === oldRows) {
          this.props.addPropertyData({ build: { ...this.props.propertyState.build, floors: 1 } });
          return null;
        }
      }, 1000);
    }
  }

  showFocus = () => {
    setTimeout(() => {
      this.setState({ showFocus: false });
    }, 2000);
  }

  handleChange = (event) => {
    const { build } = this.props.propertyState;
    this.props.addPropertyData({ build: { ...build, [event.target.name]: event.target.value } });
  };

  handleCloseWarn = () => {
    this.setState({ warningShow: false });
  }

  handleChangeDateAll = (value) => () => {
    const { build } = this.props.propertyState;
    const { data, edited } = build;
    Object.values(data).forEach((item, index) => {
      if (!edited[index]) { edited[index] = []; }
      item.content.forEach((row, line) => {
        if (edited[index].indexOf(line) === -1) {
          edited[index].push(line);
        }
        data[index].content[line].dwelling_admin_date = value;
      });
    });
    this.props.addPropertyData({
      build: {
        ...build,
        data,
        edited,
        date_start_admin: value
      }
    }).then(() => {
      this.setState({ warningShow: false });
    });
  }

  handleDetermineAction = (event) => {
    this.setState({ buildingName: event.target.value })
    this.props.propertyState.build.name = event.target.value;
    if (event.target.name === INPUT_TYPE_NAME) {
      if (event.target.value === '') {
        this.setState({ error: { name: <FormattedMessage id="Message.Input.Name"/> }, buildingName: event.target.value });
        return;
      }
      this.setState({ buildingName: event.target.value, error: {} })
    }
    if (event.target.name === INPUT_TYPE_DATE_START_ADMIN) {
      this.setState({
        warningShow: true,
        warningTitle: 'Fecha de administración',
        warningMessage: '¿Deseas cambiar la fecha de administración?, cambiara la fecha de toda la unidad.',
        warningSuccess: this.handleChangeDateAll(event.target.value)
      });
      return null;
    }
    this.handleChange(event);
  }


  handleSet = (buildingData) => {
    const { property } = this.props.params;
    this.props.setBuilding({
      idProperty: property,
      buildingData
    }).then(() => {
      const idBuilding = this.props.propertyState.build.idBuilding;
      const dwellingsData = [];
      const { data } = this.props.propertyState.build;
      const dwellings = Object.getOwnPropertyNames(data).length;
      for (let i = 0; i < dwellings; i += 1) {
        dwellingsData.push(...data[i].content);
      }
      this.props.setDwellings({
        idProperty: property,
        idBuilding,
        dwellingsData
      }).then(() => {
        this.goBack();
      });
    });
  }

  handlePut = (buildingData) => {
    const { property, towerID } = this.props.params;
    this.props.putBuilding({
      idProperty: property,
      idBuilding: towerID,
      buildingData
    }).then(() => {
      const { edited } = this.props.propertyState.build;
      if (edited) {
        for (const i in edited) {
          edited[i].forEach((key) => {
            const dwellingData = this.props.propertyState.build.data[i].content[key];
            this.props.putDwelling({
              idProperty: property,
              idBuilding: towerID,
              dwellingData
            });
          });
        }
      }
      this.goBack();
    });
  }

  handleGetDwellings = () => {
    const { data } = this.props.propertyState.build;
    const dwellings = Object.getOwnPropertyNames(data).length;
    let dwelling_number = 0;
    for (let i = 0; i < dwellings; i += 1) {
      dwelling_number += data[i].content.length;
    }
    return dwelling_number;
  }

  onDeleteDwelling = (idDwelling, idResident, callBack) => () => {
    this.setState({
      warningShow: true,
      warningTitle: 'Eliminar departamento.',
      warningMessage: 'Estas seguro de eliminar este departamento. La acción no es reversible.',
      warningSuccess: this.handleDeleteDwelling(idDwelling, idResident),
    });
    callBack();
  }

  handleDeleteDwelling = (idDwelling, idResident) => () => {
    const { property, towerID } = this.props.params;
    this.props.delDwellings({
      idProperty: property,
      idBuilding: towerID,
      idDwelling,
      idResident
    }).then(() => {

      this.props.getDwellings({
        idProperty: property,
        idBuilding: towerID
      }).then(() => {

        this.setState({
          warningShow: false,
        });
      });
    });

  }

  onDeleteFloor = (floor) => () => {
    this.setState({
      warningShow: true,
      warningTitle: 'Eliminar Piso.',
      warningMessage: 'Estas seguro de eliminar el piso completo. La acción no es reversible.',
      warningSuccess: this.handleDeleteFloor(floor),
    });
  }

  handleDeleteFloor = (floor) => () => {
    const { property, towerID } = this.props.params;
    const { content } = this.props.propertyState.build.data[floor];
    const promises = [];
    content.forEach(dwelling => {
      promises.push(this.props.delDwellings({
        idProperty: property,
        idBuilding: towerID,
        idDwelling: dwelling.dwelling_id,
        idResident: dwelling.resident_id
      }));
    });
    Promise.all(promises).then(() => {
      this.props.getDwellings({
        idProperty: property,
        idBuilding: towerID
      }).then(() => {
        this.setState({
          warningShow: false,
        });
      });
    });
  }

  handleSetFloors = () => {
    const { build } = this.props.propertyState;
    const { floors, date_start_admin } = build;
    const { property, towerID } = this.props.params;
    const dwelling = [{
      "dwelling_id": 0,
      "dwelling_name": 'Depto',
      "dwelling_number": `${(floors + 1) * 100 + 1}`,
      "dwelling_admin_date": date_start_admin,
      "dwelling_floor": `${(floors)}`,
      "resident_id": 0,
      resident_name: '',
      resident_last_name: '',
      resident_sec_last_name: '',
      resident_email: '',
      resident_phone: '',
    }];
    this.props.setDwellings({
      idProperty: property,
      idBuilding: towerID,
      dwellingsData: dwelling
    }).then(() => {
      this.props.putBuilding({
        idProperty: property,
        idBuilding: towerID,
        buildingData: {
          name: build.name,
          date_start_admin: build.date_start_admin,
          dwelling_number: this.handleGetDwellings(),
          floors: floors + 1,
        }
      }).then(() => {
        this.props.getBuilding({
          idProperty: property,
          idBuilding: towerID
        });
      });
      this.props.getDwellings({
        idProperty: property,
        idBuilding: towerID
      });
    });
  }


  handleVerifyContent = () => {
    const { error } = this.props.propertyState.build;
    if (error && error.length > 0) {
      this.setState({
        warningShow: true,
        warningTitle: 'Información incorrecta.',
        warningMessage: 'Datos incompletos o erroneos, verifique información proporcionada.'
      });
      return false;
    }
    return true;
  }

  onSubmit = () => {
    const { name, date_start_admin, data } = this.props.propertyState.build;
    if (this.state.buildingName === '') {
      this.setState({ error: { name: <FormattedMessage id="Message.Input.Name"/> } })
      return
    }
    if (!this.handleVerifyContent()) { return null; }
    const { towerID } = this.props.params;
    const buildingData = {
      name: name,
      date_start_admin: date_start_admin,
      floors: Object.getOwnPropertyNames(data).length,
      dwelling_number: this.handleGetDwellings(),
      type_id: 1
    }
    if (towerID) {
      this.handlePut(buildingData);
      return null;
    }
    this.handleSet(buildingData);
    this.blockButton = true;
    return null;
  }

  goBack = () => {
    this.context.router.push(`/Wizard/${this.props.params.id}/property/${this.props.params.property}`);
  }

  render() {
    const { warningShow, warningTitle, warningMessage, warningSuccess, showFocus, dwellingsLength, buildingName } = this.state;
    const { params } = this.props;
    const { name, date_start_admin, floors, data } = this.props.propertyState.build;

    return (
      <div className='AddTowerPage'>
        <Dialogs
          title={warningTitle}
          message={warningMessage}
          onClose={this.handleCloseWarn}
          open={warningShow}
          onSuccess={warningSuccess}
          onCancel={this.handleCloseWarn}
        />

        <Tittle
          title='Agregar edificio'
          goBack={this.goBack}
          hideIcon={true}
        />
        <div className='AddHomePage__label'>
          Escribe el número de pisos y registra los datos de cada residente por vivienda
        </div>
        <div className='AddTowerPage__body'>
          <div className='AddTowerPage__body__add'>
            <Card style={{ width: '250px' }}>
              <Form
                handleChangeDataAccordion={this.handleChangeDataAccordion}
                onChange={this.handleDetermineAction}
                params={params}
                name={params.towerID ? name : buildingName}
                date_start_admin={date_start_admin}
                floors={floors}
                showFocus={showFocus}
                error={this.state.error}
                handleSetFloors={this.handleSetFloors}
              />
            </Card>
          </div>
          <div className='AddTowerPage__body__pane'>
            {
              (Object.getOwnPropertyNames(data).length > 0)
                ? <div>
                  {
                    Object.keys(data).map((item) => {
                      return (
                        <Accordion
                          handleChangeDataRows={dwellingRows => this.setState({ dwellingsLength: dwellingRows })}
                          key={`Acordion${item}`}
                          idRow={item}
                          params={params}
                          showFocus={showFocus}
                          onDeleteDwelling={this.onDeleteDwelling}
                          onDeleteFloor={this.onDeleteFloor}
                        />
                      );
                    })
                  }
                  <div className='AddTowerPage__options'>
                    {floors === 0 || !dwellingsLength ?
                      <Button variant='contained' color='secondary' disabled type='button'>{floors === 0 ? 'Ingresa el número de pisos.' : 'Ingresa el número de departamentos'}</Button>
                      :
                      <Button variant='contained' color='secondary' disabled={this.blockButton} onClick={this.onSubmit} type='button'>¡Listo!</Button>
                    }

                  </div>
                </div>
                : 'Indique número de pisos para visualizar.'
            }
          </div>
        </div>
      </div>
    );
  }
}

AddHomeTowerPage.contextTypes = {
  router: PropTypes.shape()
};

AddHomeTowerPage.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  propertyState: state.get('propertyState')
});

export default connect(mapStateToProps, mapDispatchToProps)(AddHomeTowerPage);
