import React, { useEffect } from 'react';
import Table from 'components/Table';
import SecondaryTabView from 'components/SecondaryTabView';
import PaymentForm from '../PaymentForm';
import SurchargeForm from '../SurchargeForm';
import DiscountForm from '../DiscountForm';
import ErrorIcon from '@material-ui/icons/Error';
import VariableConceptCurrencyInput from 'components/VariableConceptCurrencyInput/VariableConceptCurrencyInput';

import './BudgetRowDetail.scss';

const BudgetRowDetail = props => {
    const { value, budgetState } =  props;
    const { property } = props.params;
    const { dwellingAccount, loading } = budgetState;
    const { activeSelected } = props.budgetState;

    const fetchData = _ => {
        const params = {
            property,
            active: activeSelected,
            pid: property,
            aid: activeSelected,
            did: value.dwelling_id
        };
        
        props.getDwellingAccount(value.dwelling_id);
        props.getFunds(params);
        props.getNeighborPayments(params);
        props.getIncomesCharged(params);
        props.getExpenses(activeSelected);
        props.getActivesWithoutSelected(params);
    }

    const updateDetail = () => {
        fetchData();
        props.onClose();
    }

    const mapNeighborPayments = () => {
        const { neighborPayments } = props.budgetState;

        return (neighborPayments?.result || []).filter(item => item.type === 3);
    }

    useEffect(fetchData, [ ]);

    let dwellingConcepts =  (dwellingAccount || { })[value.dwelling_id] || [];
    const dwellingAdvance = dwellingConcepts.find(item => item.concept.indexOf('Anticipo') > -1) || {};
    dwellingConcepts = dwellingConcepts.filter(item => item.concept.indexOf('Anticipo') === -1);
    return (
        <div className='BudgetRowDetail col'>
            <div className='row'>
                <div className='col'>
                    <h5>Pagos requeridos</h5>
                    <Table
                        columns={[
                            {
                                headerName: 'Conceptos fijos',
                                field: 'concept'
                            },
                            {
                                headerName: '',
                                field: ''
                            },
                            {
                                headerName: 'Cuota mensual',
                                field: 'total_fee',
                                currency: true,
                                useCurrencyColors: true,
                                reverseNumber: true,
                                cellProps: {
                                    align: "right"
                                }
                            },
                            {
                                headerName: 'Saldo',
                                field: 'balance',
                                currency: true,
                                useCurrencyColors: true,
                                cellProps: {
                                    align: "right"
                                }
                            }
                        ]}
                        loading={ loading.GET_DWELLING_ACCOUNT?.status }
                        rows={dwellingConcepts}
                    />
                    <Table
                        columns={[
                            {
                                headerName: 'Gastos de vecinos',
                                field: 'name_found'
                            },
                            {
                                headerName: 'Registro de couta',
                                field: 'amount',
                                component: VariableConceptCurrencyInput,
                                props: {
                                    value: 'amount',
                                    fid: 'fund_id',
                                    did: value.dwelling_id,
                                    onChange: fetchData
                                },
                                cellProps: {
                                    align: "right"
                                }
                            },
                            {
                                headerName: 'Cuota mensual',
                                field: 'balance',
                                currency: true,
                                useCurrencyColors: true,
                                reverseNumber: true,
                                cellProps: {
                                    align: "right"
                                }
                            },
                            {
                                headerName: '',
                                field: ''
                            }
                        ]}
                        loading={ loading.GET_NEIGHBOR_PAYMENTS?.status }
                        rows={ mapNeighborPayments() }
                    />
                    <Table
                        columns={[
                            {
                                headerName: 'Otros conceptos',
                                field: 'concept'
                            },
                            {
                                headerName: '',
                                field: ''
                            },
                            {
                                headerName: '',
                                field: ''
                            },
                            {
                                headerName: 'Saldo',
                                field: 'balance',
                                currency: true,
                                useCurrencyColors: true,
                                cellProps: {
                                    align: "right"
                                }
                            }
                        ]}
                        loading={ loading.GET_DWELLING_ACCOUNT?.status }
                        rows={ [ dwellingAdvance ] }
                    />
                </div>
                <div className='col'>
                    <h5>Registrar operación</h5>
                    <SecondaryTabView
                        items={[
                            {
                                value: 1,
                                label: "Pago",
                                view: <PaymentForm
                                    dwelling={ value }
                                    onSuccess={ updateDetail }
                                    { ...props }
                                />
                            }
                        ]}
                    />
                </div>
            </div>
            <div className='message-box row'>
                <ErrorIcon/>
                <p>Al momento de realizar un registro de pago a todos los conceptos, el sistema distribuirá la cantidad registrada en el siguiente orden de prioridad: Intereses, Recargos, Cuota de mantenimiento y Cuotas de fondos.</p>
            </div>
        </div>
    );
}

export default BudgetRowDetail;
