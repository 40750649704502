import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../Budget/budgetActions';
import Card from 'components/Card';

import RelationsAmenityItem from './RelationsAmenityItem';

import { toMoneyFormat } from '../../../../utils/format';
import './RelationsAmenity.scss';

class RelationsAmenity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetail: false
    };
  }


  render() {
    const { amenities, isFund, showDetail } = this.props;
    const long = amenities.length;
    const { budgetsAndFees } = this.props.budgetState;

    return (
      <div className={ showDetail ? 'RelationsAmenity__flexCol':'RelationsAmenity'}>
        {
          showDetail ?
            <div className='RelationsAmenity__amenities'>
              {
                amenities && amenities.length > 0 && !isFund
                  ? amenities.map((item, index) => {
                    return (
                      <RelationsAmenityItem
                        key={`QuotaAmenity${index}`}
                        isEnd={long === (index + 1)}
                        concept_name={item.concept_name || ''}
                        icon_type={item.icon_type || ''}
                        suggested_fee={item.suggested_fee || 0}
                        monthly_fee={item.monthly_fee || 0}
                      />
                    );
                  })
                  : null
              }
              {
                amenities && amenities.length > 0 && isFund
                  ? amenities.map((item, index) => {
                    return (
                      <RelationsAmenityItem
                        key={`QuotaAmenity${index}`}
                        isEnd={long === (index + 1)}
                        concept_name={item.name_found || ''}
                        icon_type={'fund'}
                        suggested_fee={item.amount || 0}
                        isFund={isFund}
                      />
                    );
                  })
                  : null
              }
            </div>
            : null
        }
        <div className='RelationsAmenity__resume'>
          { this.state.showDetail ? <span>=</span> : null }
          <Card>
            <div className='RelationsAmenity__resume__title'>{isFund ? 'Cuota Extraordinaria*' : 'Cuota sugerida mensual*'}</div>
            <div className='RelationsAmenity__resume__number'>$ {toMoneyFormat(isFund ? budgetsAndFees.funds_fee : budgetsAndFees.suggested_fee)}
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);

const mapStateToProps = state => ({
  budgetState: state.get('budgetState')
});


export default connect(mapStateToProps, mapDispatchToProps) (RelationsAmenity);
