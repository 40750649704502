import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as Actions from '../../../budgetActions';
import BudgetAsset from '../../../components/BudgetAsset/BudgetAsset';
import BudgetConcept from '../../../components/BudgetConcept/BudgetConcept';

import { simpleDate, toMoneyFormat } from 'utils/format';
import './BudgetHistory.scss';

class BudgetHistory extends Component {
  componentDidMount() {
    const { activeSelected } = this.props.budgetState;
    this.handleUpdateData(activeSelected);
  }

  componentDidUpdate(prevProps){
    const activeSelectedBefore = prevProps.budgetState.activeSelected;
    const activeSelectedNext = this.props.budgetState.activeSelected;
    if(activeSelectedBefore !== activeSelectedNext){
      const { activeSelected } = this.props.budgetState;
      this.handleUpdateData(activeSelected);
    }
  }

  handleUpdateData = (active) => {
    const { property } = this.props.params;
    this.props.getAssets({
      property,
      active
    }).then(()=>{
      this.props.getProviderPaymentPending({
        property,
        active
      });
    });
  }

  handleOpen = (url) => () =>{
    window.open(url, "_blank")
  }

  handleCreateConceptAsset = (asset) => {
    const { conceptsPendingProviderPayment, loading } = this.props.budgetState;
    const { GET_PROVIDER_PAYMENT_PENDING } = loading;
    const data = Object.values(conceptsPendingProviderPayment[asset]);
    const filter = data.filter((item)=>item.status === 'Completed');
    if(filter.length === 0){
      return (
        <div className='BudgetPending__none'>
          {
            GET_PROVIDER_PAYMENT_PENDING
            ? 'Buscando elementos...'
            : 'Ningún concepto encontrado' 
          }
        </div>
      );
    }
    const conceptsRows = filter.map((item) => {
      return (
        <BudgetConcept
          items={[
            {
              type: 'text',
              value: item.service_name,
            },{
              type: 'image',
              image: null,
              value: item.provider_name
            },{
              type: 'text',
              value: simpleDate(item.service_date)
            },{
              type: 'input',
              value: toMoneyFormat(item.amount),
              inputProps: {
                disabled: true,
              }
            },{
              type: 'icon',
              value: item.receipt_url,
              onClick: this.handleOpen(item.receipt_url),
            }
          ]}
          options={[{ value: 1, label: 'hola', onClick: () => {}}]}
        />
      );
    })
    return conceptsRows;
  }
  
  handleCreateAssetSection = () => {
    const { conceptsPendingProviderPayment, assets } = this.props.budgetState;
    const assetSection = Object.getOwnPropertyNames(conceptsPendingProviderPayment).map((asset)=>{
      const infoAsset = assets.result.filter((item)=>item.id === asset);
      if (infoAsset && infoAsset[0]){
        return this.handleCreateConceptAsset(asset);
      }
    });
    return assetSection;
  }

  render() {
    const { GET_PROVIDER_PAYMENT_PENDING } = this.props.budgetState.loading;
    const { conceptsPendingProviderPayment } = this.props.budgetState;
    
    return(
      <div className='BudgetPending'>
        <div className='BudgetHistory__date'>
        </div>
        <BudgetAsset
          icon={ 12 }
          titleHidden
          items={[
            {
              title: 'Concepto',
              filter: false,
              onFilter: () => {},
              styleWidth: { width: '16.6%' }
            }, {
              title: 'Proveedor',
              filter: false,
              onFilter: () => {},
              styleWidth: { width: '16.6%' }
            }, {
              title: 'Fecha de Pago',
              filter: false,
              onFilter: () => {},
              styleWidth: { width: '16.6%' }
            }, {
              title: 'Monto',
              onFilter: () => {},
              styleWidth: { width: '16.6%' }
            }, {
              title: 'Recibo',
              filter: false,
              styleWidth: { width: '16.6%' },
            }
          ]}
        />
        {
          Object.values(conceptsPendingProviderPayment || { }).length === 0
          ? <div className='BudgetPending__none'>
            {
              GET_PROVIDER_PAYMENT_PENDING
              ? 'Buscando elementos...'
              : 'Ningún concepto encontrado' 
            }
            </div>
          : this.handleCreateAssetSection()
        }
        
      </div>
    );
  }
}

BudgetHistory.contextTypes = { 
  router: PropTypes.shape()
};

BudgetHistory.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  budgetState: state.get('budgetState')
});

export default connect(mapStateToProps, mapDispatchToProps)(BudgetHistory);