import React, { Component } from 'react';
import ColCard from 'components/ColCard';
import { toMoneyFormat } from 'utils/format';
import Progress from 'antd/lib/progress';

import './SectionIncome.scss';

class SectionIncome extends Component {
  render() {
    const currentMonth = new Date();
    const options = { year: 'numeric', month: 'long' };
    let { charged, pending_charged } = this.props;

    charged = charged || 0;
    pending_charged = pending_charged || 0;
    
    let percentageIncome = 0;
    
    if (charged > 0 && pending_charged > 0) {
      percentageIncome =  (charged / pending_charged ) * 100;
    }
    
    return (
      <div className='SectionIncome col'>
        <ColCard
          title={`Ingresos de ${ currentMonth.toLocaleDateString('es-ES', options) }`}
          columns={[
            {
              label: 'Cobrados',
              value: `$ ${ toMoneyFormat(charged) }`
            },
            {
              label: 'Objetivo',
              value: `$ ${ toMoneyFormat(pending_charged) }`
            }
          ]}
          preappend
        >
          <Progress
            strokeColor={{
              from: "#7894fe",
            }}
            percent={parseInt(percentageIncome.toFixed(0))}
          />
        </ColCard>
      </div>
    );
  }
}

export default SectionIncome;
