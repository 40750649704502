import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import SimpleModal from 'components/SimpleModal';
import Button from 'components/Button';

class ModalHomePage extends Component {
  render() {
    const { show, onClose, goTo } = this.props;
    const { result } = this.props.propertyState.buildings;
    const filterHomes = result && result.length > 0 ? result.filter(building => building.type_id === 2) : [];
    
    return (
      <SimpleModal
        title='Agregar casas a:'
        open={show}
        onClose={onClose}>
          <div>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
              {
                filterHomes.map((item)=>{
                  return(
                    <Button 
                      key={item.id}
                      variant='outlined'
                      color='secondary'
                      style={{ width: '300px', marginBottom: '15px' }}
                      onClick={goTo(item.id)}>
                      {item.name.toUpperCase()}
                    </Button>
                  );
                })
              }
              <Button
                variant='contained'
                color='secondary'
                style={{ width: '300px' }}
                onClick={onClose}>
                NUEVO CONJUNTO
              </Button>
            </div>
          </div>
      </SimpleModal>
    );
  }
}

ModalHomePage.contextTypes = {
  router: PropTypes.shape()
};

ModalHomePage.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
const mapStateToProps = state => ({
  propertyState: state.get('propertyState')
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalHomePage);
