import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import PopoverBuilder from './PopoverBuilder';
import './Popover.scss';

const styles = theme => ({
  typography: {
    margin: theme.spacing(2),
  }
});

class SimplePopover extends React.Component {
  handleOpen = (e) => {
    const { valid, open } = this.props;
    if (!valid) {
      open(e);
    }
  };
  render() {
    const {
      children,
      anchorEl,
      close,
      anchorLb,
      noButtonClose,
      top,
      left,
      anchorOrigin,
      transformOrigin
    } = this.props;
    const content = (
      <div className='Popover'>
        {
          !noButtonClose
            ? <button className='Popover__close' onClick={close}><i className='material-icons'>close</i></button>
            : null
        }
        {children}
      </div>);
    const popoverBuilder = new PopoverBuilder()
      .setOpen(Boolean(anchorEl))
      .setAnchorEl(anchorEl)
      .setTop(top)
      .setLeft(left)
      .setOnClose(close)
      .setAnchorOrigin(anchorOrigin)
      .setTransformOrigin(transformOrigin)
      .setContent(content);
    return (
      <div style={{ width: '100%' }}>
        <div role='button' tabIndex='-1' onKeyDown={this.handleOpen} onClick={this.handleOpen} className='Popover__label'>{anchorLb}</div>
        {
          popoverBuilder.build()
        }
      </div>
    );
  }
}

SimplePopover.defaultProps = {
  noButtonClose: false,
  close: null,
  open: null,
  anchorOrigin: {
    vertical: 'center',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'center',
    horizontal: 'left',
  },
  anchorEl: null,
  valid: false,
  top: 0,
  left: 0,
  anchorLb: null,
  children: null
};

SimplePopover.propTypes = {
  close: PropTypes.func,
  open: PropTypes.func,
  anchorEl: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  noButtonClose: PropTypes.bool,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string
  }),
  transformOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string
  }),
  valid: PropTypes.bool,
  top: PropTypes.number,
  left: PropTypes.number,
  anchorLb: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.object])
};

export default withStyles(styles)(SimplePopover);

