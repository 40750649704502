import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import MuiSelect from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import './Select.scss';

export const Select = props => {

  const { helperText, error, label, items, disab, labelProps } = props;

  return (
    <FormControl fullWidth error={error} disabled={disab} className='Select' > 
      { !!label && <InputLabel { ...labelProps }>{label}</InputLabel> }
      <MuiSelect
        input={ <Input /> }
        { ...props }
      >
        { (items || [ ]).map((item, index) => <MenuItem key={ index } value={item.value}>{item.label}</MenuItem>) }
      </MuiSelect>
      { !!helperText && <FormHelperText>{helperText}</FormHelperText> }
    </FormControl>
  );
}

Select.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string.isRequired
};

Select.defaultProps = {
  items: [{}],
  label: ''
};

export default Select;
