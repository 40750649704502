import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import * as Actions from '../../Property/propertyActions';
import * as budgetActions from '../../Budget/budgetActions';

import Text from 'components/Text';
import Button from 'components/Button';
import Card from 'components/Card';
import Dialogs from 'components/Dialogs';

import Title from '../components/Title/Title';
import BuildList from './BuildList';
import AmenityList from './AmenityList';

import SectionCard from './subcomponents/SectionCard';
import MasiveCard from './subcomponents/MasiveCard';

import common from 'assets/img/amenities/027-property-copy.png';
import home from 'assets/img/amenities/023-semi-detached-copy.png';
import tower from 'assets/img/amenities/011-office-building-copy.png';
import './Build.scss';

export class Build extends Component {
	constructor () {
		super();
		this.state = {
			showMasiveCharge: false,
		}
	}

	componentDidMount(){
		const { property } = this.props.params;
		this.props.getProperty({
			property
		});
		this.props.getBuildings({
			idProperty: property
		}).then(()=>{
			const { result } = this.props.propertyState.buildings;
			if(result===null){return;}
			for (let i = 0; i < result.length; i += 1) {
				this.props.getAmenitiesAccess({
					idProperty: property,
					idBuilding: result[i].id,
				});
			}
		});
		this.props.getAmenities({
			idProperty: property
		});
	}

	goTo = () => {
		const { property, id } = this.props.params;
		this.context.router.push(`/wizard/${Number(id) + 10}/property/${property}`);
	}

	goBack = () => {
		const { property, id } = this.props.params;
		if(property){
			this.context.router.push(`/Wizard/${id - 10}/property/${property}?back`);
			return;
		}
		this.context.router.push(`/Wizard/${id - 10}/property`);
	}

	goTowerEdit = () => {
		const { id, property } = this.props.params;
		this.context.router.push(`/Wizard/${id}/property/${property}/Tower`);
	}

	goHomeEdit = () => {
		const { id, property } = this.props.params;
		this.context.router.push(`/Wizard/${id}/property/${property}/Home`);
	}

	goAmenityEdit = () => {
		const { id, property } = this.props.params;
		this.props.getAmenities({
			idProperty: property
		});
		this.context.router.push(`/Wizard/${id}/property/${property}/Amenity`);
	}

	handleShowMasive = () => {
		this.setState({ showMasiveCharge: true });
	}

	handleCloseMasive = () => {
		this.props.addPropertyData({ masiveCharge: undefined });
		this.setState({ showMasiveCharge: false });
	}

	downloadFile = () => {
		this.props.downloadMassiveTemplate();
	}

	uploadFile = (file) => {
		const { property } = this.props.params;
		this.props.uploadMassiveTemplate({
			idProperty: property,
			data: { file_base_64: file }
		});
	}

	handleCloseDialog = () => {
    	this.setState({ showDialog: false });
	}

	messageDialogA(type){
		let messageDialog = '';
		if(type === 'amenities') {
		messageDialog = <FormattedMessage id='Build.message.amenities' />
		} else { messageDialog = <FormattedMessage id='Build.message.buildings' />  }
		this.setState({
			showDialog: true,
			dialogTitle: <FormattedMessage id='Build.dialog.title' />,
			dialogMessage: messageDialog,
			dialogTextSuccess: <FormattedMessage id='Button.continue' />,
			dialogTextCancel: <FormattedMessage id='Button.cancel' />,
		});
	}

	savePropertyStatus() {
        const { name, address } = this.props.propertyState.new;
		const { property: idProperty, id: step } = this.props.params;
		const property = {
			step: parseInt(step),
			status_id: 21,
			name,
			address,
		}

        return this.props.putProperty({
          	idProperty,
			property
		})
	}

	handleVerifyAccess = () => {
		this.savePropertyStatus().then(() => {
			const { amenities } = this.props.propertyState;
			const access = Object.values(this.props.propertyState.access).filter(item => item !== null);

			this.amenitiesHasBuildings(amenities, access);
			
			if(!amenities.length) {
			 	this.goTo();
			}
		});
	}

  amenitiesHasBuildings (amenities, access){
	let arrayIdAmenities = [];
	let arrayIdAccess = [];
	let isEqual =  false;	
	amenities.forEach(amenity => {
		arrayIdAmenities.push(amenity.amenity_id);
	});

	access.forEach(access_item => {
		access_item.forEach(item_id => {
			arrayIdAccess.push(item_id)
		});
	});
	for(let i =0; i < arrayIdAmenities.length;i++){
		isEqual = false;
		for(let j =0; j < arrayIdAccess.length;j++){
			if(arrayIdAmenities[i] === arrayIdAccess[j]){ isEqual = true; break; }
		}
		if(!isEqual){ this.messageDialogA('amenities');break; }
	}
	if(isEqual){this.goTo();}
  }

  render() {
		const { params } = this.props;
		const { resident_last_view } = this.props.profileState;
		const { name } = this.props.propertyState.new;
		const { editAmenities, bindName, bindWho } = this.props.propertyState.behaviorScreen;
		const { masiveCharge, amenities, buildings } = this.props.propertyState;
		const { showMasiveCharge, showDialog, dialogTitle, dialogMessage, dialogTextSuccess, dialogTextCancel } = this.state;
    return(
			<div className='Build'>
				<Dialogs
					title={dialogTitle}
					message={dialogMessage}
					onClose={this.handleCloseDialog}
					open={showDialog}
					onCancel={this.handleCloseDialog}
					textSuccess={dialogTextSuccess}
					textCancel={dialogTextCancel}
				/>

				{
					buildings && buildings.result && buildings.result.length > 0 ?
					<Title
						title={`Construir`}
						helpMessage='Si tus condominios comparten alguna área común selecciona el botón de  “+ áreas comunes”, después de agregarlas da click en  “vincular áreas comunes” en el inmueble que deseas agregarlo. '
						goBack={showMasiveCharge ? this.handleCloseMasive : this.goBack}
					    property={resident_last_view ? resident_last_view: null}

					/>
					:<Title
					title={`Construir`}
					helpMessage='Agrega la configuración de tus edificios o conjunto de casas'
					goBack={showMasiveCharge ? this.handleCloseMasive : this.goBack}
					property={null}

				/>
				}
				<div className='Build__container'>

					{
						!showMasiveCharge
						? <div className='Build__container__panel'>
								<SectionCard
									goTo={this.goTowerEdit}
									image={tower}
									buttonText='Build.tower'
								/>
								<SectionCard
									goTo={this.goHomeEdit}
									image={home}
									buttonText='Build.home'
								/>
								{buildings && buildings.result && buildings.result.length > 0 ?
									<SectionCard
										goTo={this.goAmenityEdit}
										image={common}
										buttonText='Build.common'
									/>
								:null}
							</div>
						: <MasiveCard
								onCancel={this.handleCloseMasive}
								onDownload={this.downloadFile}
								onSubmit={this.uploadFile}
								isLoaded={masiveCharge}
							/>
					}
					<Text variant='body2' id='Build.text.buil' className='Build__text' />
					<div className='Build__container__card'>
						<div className='Build__container__card__col'>
							<Card>
								<div className='Build__container__card__col__int'>
									<div className='Build__container__card__col__int__title'>
										<label>Conjuntos {name}</label>
									</div>
									<BuildList params={params} />
								</div>
							</Card>
						</div>
						{
							amenities && amenities.length > 0
							? <div className='Build__container__card__col'>
									<Card>
										<div className='Build__container__card__col__int'>
											<div className='Build__container__card__col__int__title'>
												<label>Amenidades en {bindName || name}</label>
											</div>
											<AmenityList params={params}/>
										</div>
									</Card>
								</div>
							: null
						}
					</div>
					{
						!bindWho && !editAmenities && buildings?.result && (
							<div className='Build__container__button'>
								<Button variant='contained' color='secondary' onClick={this.handleVerifyAccess}>Continuar</Button>
							</div>
						)
					}
				</div>

			</div>
    );
  }
}


Build.contextTypes = {
  router: PropTypes.shape()
};

Build.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({ ...Actions, ...budgetActions }, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
	propertyState: state.get('propertyState'),
	budgetState: state.get('budgetState'),
	profileState: state.get('profileState'),

});

export default connect(mapStateToProps, mapDispatchToProps)(Build);
