import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import * as Actions from '../../../Budget/budgetActions';
import * as actionsAccount from '../../../Accounts/accountActions';
import { getProfile, putProfile } from '../../../Profile/ProfileActions';
import { setInvitations, getProperty, putProperty } from '../../../Property/propertyActions';
import { FormattedMessage } from 'react-intl';
import Title from '../../components/Title/Title';

import ActiveCard from './ActiveCard';
import ModalInt from './ModalInt';
import './Init.scss';

import image from 'assets/img/imagen-cuenta.png';

export class InitAccount extends Component {

  constructor() {
    super();
    this.state = {
      showModal: false,
      typeRegister: false
    };
  }

	savePropertyStatus() {
    const { name, address } = this.props.propertyState.new;
    const { property: idProperty, id: step } = this.props.params;
    const property = {
      step: parseInt(step),
      status_id: 21,
      name,
      address,
    }

    return this.props.putProperty({
      idProperty,
      property
    })
  }

  componentDidMount() {
    const { property } = this.props.params;
    this.props.getProperty({ property });
    this.props.getAccounts({ property });
    this.props.getActives({ property });
    this.props.getCosts({ property });
  }

  goBack = () => {
    const { property, id } = this.props.params;
    this.context.router.push(`/Wizard/${(Number(id) - 10)}/property/${property}`);
  }

  handleShowModal = () => {
    this.setState({ showModal: true });
  }

  handleCloseModal = () => {
    this.setState({ showModal: false });
  }

  handleRegisterOn = () => {
    this.setState({ typeRegister : true });
  }

  handleRegisterOff = () => {
    this.setState({ typeRegister: false });
  }

  handleGoTo = async () => {
    const { property } = this.props.params;
    await this.handleActivePocahontas();
    await this.handleRegisterProperty();

    this.savePropertyStatus().then(() => this.context.router.push(`/property/${property}/dashboard`));
  }

  handleGoToInvitations = async () => {
    const { property: idProperty } = this.props.params;
    
    await this.props.setInvitations({
      idProperty
    }).then(this.handleGoTo.bind(this));
  }

  handleActivePocahontas = () => new Promise((resolve) => {
    const { property } = this.props.params;
    this.props.createPocahontasRegistry({
      property
    }).then(()=>{
      resolve();
    });
  });

  handleRegisterProperty = () => {
    const { property } = this.props.params;
    this.props.putProfile({
      profileData:{ resident_last_view: parseInt(property) }
    }).then(()=>{
      this.props.getProfile();
    });
  }

  render() {
    const { resident_last_view } = this.props.profileState;
    const { showModal } = this.state;
    const { result } = this.props.budgetState.actives;
    const { SEND_INVITATIONS } = this.props.propertyState.loading;
    const { CREATE_POCAHONTAS } = this.props.budgetState.loading;
    const { name } = this.props.propertyState.new;
    const numberOfActives = Object.values(result).length;
    const maxMediumSize = 6;
    const useLargeSize = numberOfActives > maxMediumSize;
    return (
      <div className='InitAccount'>
        <Title
          loading={true}
          title='Cuenta'
          goBack={this.goBack}
          hideIcon={true}
          property={resident_last_view ? resident_last_view: null}
        />
        <div className='InitAccount__container'>
          <div className='InitAccount__card'>
            <div className='InitAccount__card__margin'>
              <div className='InitAccount__image'>
                <img src={image} alt='image' style={{height: '30%',width:'30%'}} />
              </div>
              <div className='InitAccount__title'><FormattedMessage id='InitAccount.title.build' /> {name}!</div>
              <div className='InitAccount__text'>
                <FormattedMessage id='InitAccount.subtitle.text' />
              </div>
              <div className='InitAccount__cards'>
                {
                  result ?
                  Object.values(result).map((item)=>{
                    return(
                      <ActiveCard
                        key={`InitAccount${item.id}`}
                        formatMini={useLargeSize}
                        iconType={item.icon}
                        name={item.name}
                        bank='BANORTE'
                        reference={item.reference}
                        company={item.agreement_number}
                        initial_balance={item.initial_balance}
                      />
                    );
                  })
                  : null
                }
              </div>
              <div className='InitAccount__amount'>
                <strong>
                  <FormattedMessage id='InitAccount.text.amount' />
                </strong>
                <div className="InitAccount__amount__container-register">
                  <div className="InitAccount__amount__container-register__automatico">
                      <h3 className="title-regist"> <FormattedMessage id='InitAccount.title.register.automatic' /></h3>
                      <ul>
                        <li><FormattedMessage id='InitAccount.automatic.list.money' /></li>
                        <li><FormattedMessage id='InitAccount.automatic.list.admin' /></li>
                        <li><FormattedMessage id='InitAccount.automatic.list.register' /></li>
                      </ul>
                      <button type="button" disabled className="buttnon-register" ><FormattedMessage id='Button.soon' /></button>
                  </div>
                  <div className="InitAccount__amount__container-register__manual">
                      <h3 className="title-regist"> <FormattedMessage id='InitAccount.title.register.manual' /></h3>
                      <ul>
                        <li><FormattedMessage id='InitAccount.manual.list.money' /></li>
                        <li><FormattedMessage id='InitAccount.manual.list.admin' /></li>
                        <li><FormattedMessage id='InitAccount.manual.list.register' /></li>
                      </ul>
                      <button type="button" className="buttnon-register manual" onClick={this.handleShowModal} ><FormattedMessage id='Button.manual' /></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalInt
          open={showModal}
          onClose={this.handleCloseModal}
          goTo={this.handleGoTo}
          goToInvite={this.handleGoToInvitations}
          isLoading={SEND_INVITATIONS && SEND_INVITATIONS.status === true || CREATE_POCAHONTAS && CREATE_POCAHONTAS.status === true}
        />
      </div>
    );
  }
}

InitAccount.contextTypes = { 
  router: PropTypes.shape()
};

InitAccount.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({ ...Actions, ...actionsAccount, setInvitations, getProfile, putProfile, getProperty, putProperty }, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  budgetState: state.get('budgetState'),
  propertyState: state.get('propertyState'),
  profileState: state.get('profileState'),
  accountState: state.get('accountState')
});

export default connect(mapStateToProps, mapDispatchToProps)(InitAccount);

