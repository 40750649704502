import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import MButton from '@material-ui/core/Button';
import './Button.scss';

export const Button = (props) => {
  const { className, children, icon } = props;

  return (
    <MButton {...props} className={`ButtonComponent ${className}`}>
      {
        icon ? (
          <div className='ButtonDefault'>
            <Icon>{ icon }</Icon>
            { children }
          </div>
        ) : (
          children
        )
      }
    </MButton>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string
};

Button.defaultProps = {
  children: null,
  className: '',
  icon: ''
};

export default Button;
