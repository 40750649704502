import React from 'react';
import { Popover } from 'antd';
import './Event.scss';
import 'react-week-calendar/dist/style.css';

const Event = props => {
    const { start, end, title, status, selected, detailComponent } = props;
    return (
        <Popover
            placement='left'
            trigger='click'
            content={ detailComponent(props) }
        >
            <div className={ `Event ${ status.toLocaleLowerCase() }-colors bordered ${ selected && 'selected' }` }>
                <label>{ title }</label>
                <label>{ `${ start.format('HH:mm') } - ${ end.format('HH:mm') }` }</label>
            </div>
        </Popover>
    )
};

export default Event;
