import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import * as Actions from '../providerActions';

import Input from 'components/Input';
import Button from 'components/Button';

import { validator, characters, phoneNumber, email, RFC, number, banksAccounts } from 'utils/validationsRules';
import Dialog from 'components/Dialogs';
import './AddProviderForm.scss';

class AddProviderForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      disabledB: false,
      title: 'Campos requeridos'
    };
  }

  onChange = type => (event) => {
    const news = this.props.providerState.new;
    let { error } = this.state;
    let compare = {};
    switch (type) {
      case 'banks': compare = banksAccounts(event.target.value); break;
      case 'RFC': compare = RFC(event.target.value); break;
      case 'number': compare = number(event.target.value); break;
      case 'phone': compare = phoneNumber(event.target.value); break;
      case 'text': compare = characters(event.target.value); break;
      case 'email': compare = email(event.target.value); break;
      default: return null;
    }
    error = validator(compare, error, event.target.name);
    this.props.addProviderData({
      new: { 
        ...news,
        [event.target.name]: event.target.value 
      }
    }).then(()=>{
      this.setState({ error });
    });
  }


  accountLabel = (type) => {
    const json = {
      0: 'Número de cuenta',
      1: 'Clabe interbancaria',
      2: 'Número de tarjeta',
      3: 'Tu CLABE Interbancaria'
    };
    return json[type] ||  'Ninguno';
  }

  onSubmit = (event) => {
    const { error } = this.state;
    const { name, phone } = this.props.providerState.new;
    if(name === '' || phone === '') { 
      error['name'] = 'Campo requerido'     
      this.setState({ error, disabledB: true });
      return
    }

    if(phone === '') { 
      error['phone'] = 'Campo requerido'
      this.setState({ error, disabledB: true })
      return
    }
  
    if(error.name || error.phone) { 
        this.setState({disabledB: true ,title: 'Verifica que los datos sean corectos'});
        return;
    } else {
      this.props.onSubmit(event);
    }
  }

  onCloseDialog = () => {
    this.setState({ disabledB: false });
  }

  render() {
    
    const { error, disabledB, title } = this.state;
    const { onCancel } = this.props;
    const { name, phone, rfc, email, account_type, account_number } = this.props.providerState.new;
    return (
      <>
        <Dialog
          title={title}
          message=' Nombre de Proveedor y/o Teléfono '
          onClose={this.onCloseDialog}
          open={disabledB}
          onCancel={this.onCloseDialog}
        />
      <form className='AddProviderForm'>
        <div className='AddProviderForm__item'>
          <Input
            label='Nombre de Proveedor*'
            name='name'
            onChange={this.onChange('text')}
            value={name}
            fullWidth
            error={!!error.name}
            helperText={error.name}
            inputProps={{
              maxLength: 35,
            }}
          />
        </div>
        <div className='AddProviderForm__item'>
          <Input
            label='Teléfono*'
            name='phone'
            onChange={this.onChange('phone')}
            value={phone}
            fullWidth
            error={!!error.phone}
            helperText={error.phone}
            inputProps={{
              maxLength: 15,
            }}
          />
        </div>
        <div className='AddProviderForm__item'>
          <Input
            label='Mail destinatario (opcional)'
            name='email'
            onChange={this.onChange('email')}
            value={email}
            fullWidth
            error={!!error.email}
            helperText={error.email}
          />
        </div>
        
        <div className='AddProviderForm__item AddProviderForm__options'>
          <Button color='secondary' variant='outlined' onClick={onCancel} style={{ width: '150px', marginRight: '20px' }}>Cancelar</Button>
          <Button color='secondary' variant='contained' onClick={this.onSubmit} style={{ width: '150px' }} disabled={this.disabledB}>Guardar</Button>
        </div>
      </form>
      </>
    );
  }
}

AddProviderForm.contextTypes = {
  router: PropTypes.shape()
};
AddProviderForm.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  providerState: state.get('providerState'),
  loginState: state.get('loginState')
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProviderForm);
