import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';

class Text extends Component {
  
  clean = (props) => {
    return Object.keys(props).reduce((obj, key) => {
      if (key !== 'caption' && key !== 'onlyformat') {
        obj[key] = props[key];
      }
      return obj;
    }, {});
  }

  render() {
    const {
      className, id, children, onlyformat
    } = this.props;
    if (onlyformat) {
      return (<FormattedMessage id={id}>{children}</FormattedMessage>);
    }
    const propsF = this.clean(this.props);
    return (
      <Typography {...propsF} className={`Text ${className}`}>
        {
          id !== 'Foreing'
          ? <FormattedMessage id={id}>{children}</FormattedMessage>
          : children
        }
      </Typography>
    );
  }
}

Text.propTypes = {
  onlyformat: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  children: PropTypes.node
};

Text.defaultProps = {
  onlyformat: false,
  className: '',
  children: null,
};

export default Text;
