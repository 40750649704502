import React from 'react';
import { connect } from 'react-redux';
import Form from 'components/Form'
import * as Actions from '../../../../Property/propertyActions';
import InputAdornment from '@material-ui/core/InputAdornment';
import ImageIcon from '@material-ui/icons/Image';
import { bindActionCreators } from 'redux';

import './SurchargeForm.scss';

export const SurchargeForm = props => {
  
  const { onCancel, dwelling, budgetState : { actives, activeSelected } } = props;

  const onSubmit = data => props.setSurcharge({
      did: dwelling.dwelling_id,
      data: {
        ...data,
        amount: Number(data.amount),
        file: data.file?.file
      }
    })
    .then(props.onSuccess);

  return (
    <div className='SurchargeForm'>
      <Form
        title='Registra los datos de recargo al residente'
        loading={ props.propertyState.loading.SET_SURCHARGE?.status }
        needsUserConfirmation
        onCancel={ onCancel }
        onSubmit={ onSubmit }
        clearOnSubmitted
        notificationMessage='El recargo ha sido registrado exitosamente'
        confirmationDetails={[
          {
            label: 'Nombre del residente',
            value: props.value.full_name
          },
          {
            label: 'Condominio',
            value: Object.values(actives?.result || { }).find(active => active.id === activeSelected)?.name
          },
          {
            label: 'Casa / Depto',
            value: props.value.number
          }
        ]}
        controls={[
          [
            {
              name: 'title',
              label: 'RECARGO',
              props: {
                required: true
              }
            },
            {
              name: 'amount',
              label: 'MONTO',
              type: 'currency',
              props: {
                required: true,
                minimumValue: '0',
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }
              }
            }
          ],
          {
            name: 'description',
            label: 'MOTIVO DE RECARGO',
            type: 'lengthCounter',
            props: {
              maxLength: 100
            }
          },
          {
            name: 'file',
            type: 'file',
            hideFromConfirmation: true,
            props: {
              label: 'Puedes agregar una imagen de evidencia para justificar el recargo.',
              buttonProps: {
                startIcon: <ImageIcon />,
              },
              children: 'Agregar imagen'
            }
          }
        ]}
      />
    </div>
  );
}

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);

const mapStateToProps = state => ({
  budgetState: state.get('budgetState'),
  propertyState: state.get('propertyState'),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurchargeForm);
