import React, { useState } from 'react';
import CurrencyInput from 'components/CurrencyInput/CurrencyInput';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from 'containers/Budget/budgetActions';
import './VariableConceptCurrencyInput.scss';

const VariableConceptCurrencyInput = ({did, fid, putNeighborPayments, value, onChange}) => {
  const [amount, setAmount] = useState();
  const handleOnChange = (event) => {
    setAmount(event?.target?.value);
  }
  const handleOnBlur = () => {
    if (!isNaN(amount)) {
      putNeighborPayments({
        did,
        fid,
        data: {
          total: Number(amount)
        }
      }).then(() => onChange());
    }
  }
  return (
    <CurrencyInput value={value} onChange={handleOnChange} onBlur={handleOnBlur} />
  );
};


const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  
});

export default connect(mapStateToProps, mapDispatchToProps)(VariableConceptCurrencyInput);
