import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../Button';
import './Dialogs.scss';

class Dialogs extends React.Component {
  render() {
    const {
      title, message, onClose, open, onSuccess, onCancel, textSuccess, textCancel, haveLink, toEdit, messageB
    } = this.props;
    return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {messageB === '' ? '' : <b className="alert-dialog-description-b"> {messageB} </b>}
            {message}
            { haveLink ? 
              <div className='alert-dialog-link' onClick={toEdit}>
                  Editar Presupuestos
              </div>:null
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} variant={!onSuccess ? 'contained' : 'text' } color='secondary'>
            {!onSuccess ? 'Aceptar' : textCancel}
          </Button>
          {
            onSuccess
          ? <Button onClick={onSuccess} variant='contained' color='secondary' autoFocus>{textSuccess}</Button>
            : null
          }

        </DialogActions>
      </Dialog>
    );
  }
}

Dialogs.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  toEdit: PropTypes.func,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  textSuccess: PropTypes.string,
  textCancel: PropTypes.string,
  haveLink: PropTypes.bool,
  messageB: PropTypes.string
};

Dialogs.defaultProps = {
  onSuccess: null,
  haveLink: false,
  toEdit: null,
  open: false,
  message: '',
  title: '',
  textSuccess: 'Aceptar',
  textCancel: 'Cancelar',
  messageB: ''
};

export default Dialogs;
