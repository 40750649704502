import http from 'constants/config';

export const getProvidersN = ({ idProperty }) => http.get(
  `v1/properties/${idProperty}/providers`
);

export const getProviderN = ({ idProvider }) => http.get(
  `v1/providers/${idProvider}`
);

export const setProviderN = ({ dataProvider }) => http.post(
  'v1/providers',
  JSON.stringify(dataProvider)
);

export const putProviderN = ({ idProvider, dataProvider }) => http.put(
  `v1/providers/${idProvider}`,
  JSON.stringify(dataProvider)
);

export const getProviderDetailN = ({ idProperty, idProvider }) => http.get(
  `v1/properties/${idProperty}/providers/${idProvider}/detail`
);
export const getProviderActiveDetailN = ({ idProperty, idProvider }) => http.get(
  `v1/properties/${idProperty}/providers/${idProvider}/actives`
);

export const getProviderActiveConceptsN = ({ idProperty, idProvider, idActive, idAsset }) => http.get(
  `v1/properties/${idProperty}/provider/${idProvider}/actives/${idActive}/detail/${idAsset}`
);
