import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';
import './QuotaItem.scss';
import Checkbox from 'components/Checkbox';
import CurrencyInput from 'components/CurrencyInput/CurrencyInput';

export class QuotaItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      balance: props.dwellingBalance,
      valid: false,
      cuota: props.dwellingAmount,
    };
  }
  validateNumber = (e) =>{
    return  e.target.value === '' | e.target.value === '-'
  }

  onChange = (e, stateName) => {
    const value = this.validateNumber(e) ? e.target.value : parseFloat(e.target.value);

    if (this.validateNumber(e)) {
      this.setState({ [stateName]: value });
    } else {
      this.setState({ [stateName]: isNaN(value) ? 0 : value });
    }
  }

  onBlurCuota = () => {
    const { cuota } = this.state;
    if (cuota === '') {
      this.setState({ cuota: 0 });
      this.props.onChangeCuota({ target: { cuota: 0 } });
      return null;
    }
    this.props.onChangeCuota({ target: { value: parseFloat(cuota) } });
  }

  onBlurBalance = () => {
    const { balance } = this.state;
    if (balance === '') {
      this.setState({ balance: 0 });
      this.props.onChangeBalance({ target: { value: 0 } });
      return null;
    }
    this.props.onChangeBalance({ target: { value: parseFloat(balance) } });
  }

  onFocusBalance = (e) => {
    const value = e.target.value;
    value === '0' && this.setState({ balance: '' });
  }

  onFocusCuota = (e) => {
    const valueShare = e.target.value;
    valueShare === '0' && this.setState({ cuota: '' });
  }

  render() {
    const {
      dwellingNumber,
      residentName,
      isLast,
      onChangecheck,
      valid,
      validTableCourt,
      loading
    } = this.props;
    const { balance, cuota } = this.state;
    const invalidInput = valid || validTableCourt || loading
    const invalidCheck =  validTableCourt || loading
    return (
      <React.Fragment>
      <div className={!isLast ? 'QuotaItem' : 'QuotaItem QuotaItem__last'}>
        <div className='QuotaItem__item__number'>{dwellingNumber}</div>
        <div className='QuotaItem__item__name'>{residentName}</div>
        <div className='QuotaItem__item__balance'>
          <div className={invalidInput ? 'inavlid-input-balance ': 'QuotaItem__item__fee__input'}>
            <CurrencyInput
              value={balance}
              onChange={e => this.onChange(e, 'balance')}
              onBlur={this.onBlurBalance}
              onFocus={this.onFocusBalance}
              disabled={invalidInput}
            />
            <div className={
                `QuotaItem__item__fee__input__arrow 
                ${balance >= 0 
                  ? 'QuotaItem__item__fee__input__arrow__up' 
                  : 'QuotaItem__item__fee__input__arrow__down'}`
                }>
              <Icon icon='arrow_downward' />
            </div>
          </div>
        </div>

        <div className='QuotaItem__item__fee'>
          <div className= {invalidCheck ? 'inavlid-input-balance' : 'QuotaItem__item__fee__input'} >
            <CurrencyInput
              value={cuota}
              onChange={e => this.onChange(e, 'cuota')}
              onBlur={this.onBlurCuota}
              onFocus={this.onFocusCuota}
              disabled={invalidCheck}
            />
          </div>
        </div>
        <div className='QuotaItem__item__number'>
          <Checkbox
            checked={valid}
            onChange={invalidCheck ? null : onChangecheck}
              styles={{
                margin: 0,
                padding: 0,
              }}
            />
        </div>

      </div>
      </React.Fragment>
    );
  }
}

QuotaItem.defaultProps = {
  dwellingNumber: 'Sin número',
  residentName: 'Sin nombre',
  dwellingInteres: 0,
  dwellingBalance: 0,
  isLast: false,
  onChangeBalance: () => {}
};

QuotaItem.propTypes = {
  dwellingNumber: PropTypes.any,
  residentName: PropTypes.string,
  dwellingBalance: PropTypes.number,
  dwellingInteres: PropTypes.number,
  isLast: PropTypes.bool,
  onChangeBalance: PropTypes.func,
};

export default QuotaItem;
