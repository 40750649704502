import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MaterialIcon from '@material-ui/core/Icon';

class Icon extends Component {
  render() {
    const { icon, className } = this.props;
    return (
      <MaterialIcon {...this.props} className={`IconComponent ${className}`}>{icon}</MaterialIcon>
    );
  }
}

Icon.defaultProps = {
  className: '',
};

Icon.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string
};

export default Icon;
