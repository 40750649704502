import React from 'react';
import logoFooter from 'assets/logos/logo-imobly.svg';
import { ReactSVG } from 'react-svg';

import './Footer.scss';


const Footer = () => {
    return (
      <footer className="rectangle">
        <div className="tex1">
            <ReactSVG src={logoFooter} />
            <p>© Imobly 2020</p>
            <p>Preguntas frecuentes</p>
            <p>Términos y condiciones</p>
            <p>Aviso de privacidad</p>
        </div>
        <div className="tex2"></div>
        <div className="tex3"></div>
        <div className="tex4"></div>
      </footer>
    );
}
 
export default Footer;  