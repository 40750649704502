import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import * as BudgetActions from '../../../../Budget/budgetActions';
import { OutlinedInput } from '@material-ui/core';

let conceptTimeoutReference;

class FieldConcepts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ''
    };
  }
  componentDidMount() {
    let { result : assets } = this.props.budgetState.assets;
    const activeSelected = this.props.budgetState.actives.result[this.props.budgetState.activeSelected];
    let types;
    if (activeSelected?.type === 'building') {
      let array = [];
      if (assets) {
        assets.map(asset => array.push(asset.type));
        types = array.join(',');
      }
    } else {
      types = activeSelected?.icon;
    }
    this.setState({
      value: this.props.value
    })
  }

  handleOnChange = (event) => {
    clearTimeout(conceptTimeoutReference);
    conceptTimeoutReference = setTimeout(() => {
      this.props.onChange(this.state.value);
    }, 2000);
    this.setState({
      value: event.target.value
    });
  }

  render() {
    const {value} = this.state;
    const { label, labelProps } = this.props;
    return (
      <div className='FieldConcept'>
        { !!label && <InputLabel { ...labelProps }>{ label }</InputLabel> }
        <OutlinedInput
          value={value}
          onChange={this.handleOnChange.bind(this)}
        />
      </div>
    );
  }
}

FieldConcepts.defaultProps = {
  toEdit: ''
};

FieldConcepts.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  budgetState: PropTypes.shape([]).isRequired,
  toEdit: PropTypes.string
};

const mapDispatchToProps = dispatch => bindActionCreators({ ...BudgetActions }, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  budgetState: state.get('budgetState'),
});

export default connect(mapStateToProps, mapDispatchToProps)(FieldConcepts);

