import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { notification } from 'antd';
import SimpleModal from 'components/SimpleModal';
import TemplateFonts from 'components/TemplateFonts/TemplateFonts';
import SelectAnimated from 'components/SelectAnimated/SelectAnimated';
import Button from 'components/Button/Button';

import './ModalComposeMessage.scss';

export class ModalComposeMessage extends Component {
  constructor() {
    super();
    this.state = {
      body: '',
      emails: [],
      subject: '',
      recipients: [],
    };
  }

  componentDidUpdate(nextProps){
    this.clearRecipients(nextProps);
  }

  componentDidMount() {
    if (this.props.sendTo) {
      this.setState({
        emails: [
          this.props.sendTo
        ]
      })
    }
  }

  handleChange = (type) => (value) => {
    this.setState({
      [type]: value
    });
  };

  handleInputChange = () => (input) => {
    this.setState({
      [input.target.name]: input.target.value
    });
  };

  composeEmail() {
    let { body, emails, subject } = this.state;

    return {
      recipients: emails.map(recipient => recipient.email),
      subject,
      body,
      notification_type: 'email',
    };
  }

  onSubmit = async () => {
    this.props.onClose();
    
    await this.props.sendEmailResidents(this.composeEmail());

    const { SEND_EMAIL_RESIDENTS } =  this.props.loadingNeighbor;
    
    if(!SEND_EMAIL_RESIDENTS?.detail) {
      notification.open({
        message: 'El email',
        description: 'Se envio correctamente',
        type: 'success',
      });
      this.clearState();
    }
  };

  clearState = () => {
    this.setState({
      body: '',
      emails: [],
      subject: '',
    });
  }

  clearRecipients = (nextProps) => {
    if(nextProps.recipients !== this.props.recipients) {
      let { recipients } = this.props;
      this.setState({
        recipients: recipients.filter(recipient => recipient.email),
      });
    }
  };

  validate() {
    const { emails, body, subject } = this.state;

    return  !emails?.length || !body?.length || !subject?.length;
  }
  render() {
    const { onClose } = this.props;
    const { recipients, emails, body } = this.state;
    
    return (
      <>
        <SimpleModal title={ <FormattedMessage id='Modal.Compose.Message.title'/> } { ...this.props }>
          <div className='ModalComposeMessage'>
            <div className='ModalComposeMessage__container'>
              <div className='ModalComposeMessage__container__row'></div>
              { !this.props.sendTo && (
                <div className='ModalComposeMessage__container__for'>
                  <p className='body-text1 space'><FormattedMessage id='Modal.Compose.Message.recipients' /></p>
                  <SelectAnimated
                    array={ recipients }
                    onChange={ this.handleChange('emails') }
                    emails={ emails }
                    title={ <FormattedMessage id='Modal.Compose.Message.title_select' /> }
                  />
                </div>
              )}
              <div className='ModalComposeMessage__container__subject'>
                <p className='body-text1'><FormattedMessage id='Modal.Compose.Message.subject' /></p>
                <input 
                type='text' 
                name='subject' 
                className='ModalComposeMessage__container__subject__input' 
                onChange={ this.handleInputChange() }
                />
              </div>
              <div className='ModalComposeMessage__container__message'>
                <TemplateFonts
                  textHtml={ body }
                  onChange={ this.handleChange('body') }
                />
              </div>
              <div
                className='ModalComposeMessage__container__buttons'
                align='center'
              >
                <div className='ModalComposeMessage__container__buttons__one'>
                  <Button
                    variant='outlined'
                    color='secondary'
                    onClick={ onClose }
                  >
                    <FormattedMessage id='Button.discard' />
                  </Button>
                </div>
                <div className='ModalComposeMessage__container__buttons__two'>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={ this.onSubmit }
                    disabled={ this.validate() }
                  >
                   <FormattedMessage id='Button.send' />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </SimpleModal>
      </>
    );
  }
}

export default ModalComposeMessage;
