import React, { Component } from 'react';
import TableProperties from './TableProperties';
import QuantitiesPayService from './QuantitiesPayService';

import Icon from 'components/Icon';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { toMoneyFormat } from 'utils/format';
import { browserHistory } from 'react-router';
import * as actionsAccount from '../../../Accounts/accountActions';
import './PayServiceImobly.scss';
import IframeFiserv from './IframeFiserv';

export class PayServiceImobly extends Component {

  componentDidMount() {
    const { property } = this.props.params;
    const { Authorization } = this.props.loginState;
    this.props.getAccountImobly({ Authorization, property });
  }

  goBack = () => {
      const { property } = this.props.params;
      browserHistory.push(`/property/${property}/profile/configuration?tab=accountimobly`);
  }
  render() {
    const { accountImobly } = this.props.accountState;
    const { property } = this.props.params;
    return (
     <>
     { accountImobly.result ?
      <div className='PayServiceImobly'>
        <div className='PayServiceImobly__goback' onClick={this.goBack}>
            <Icon icon='arrow_back' color='secondary' />
            <label className='body-text1 account_imobly'><FormattedMessage id='goBack'/></label>
        </div>
        <div className='PayServiceImobly__title'>
          <h2><FormattedMessage id='Payment.Service.Imobly.title_payment_service_imobly'/></h2>
          <p className='body-text1 space-pay'>
           <FormattedMessage id='Payment.Service.Imobly.rate_imobly'/> ${toMoneyFormat(accountImobly.result.imobly_price)}  <FormattedMessage id='Payment.Service.Imobly.iva_habitation_unit'/>
          </p>
        </div>
        <div className='PayServiceImobly__content'>
          <div className='PayServiceImobly__content__summary'>
            <h4><FormattedMessage id='Payment.Service.Imobly.title_summary_payment_monthly'/></h4>
            <div className='PayServiceImobly__content__summary__properties'>
              <TableProperties properties={accountImobly.result.properties} />
            </div>
            <div className='PayServiceImobly__content__summary__quantities'>
              <QuantitiesPayService result={accountImobly.result} />
            </div>
          </div>
          <div className='PayServiceImobly__content__payment'>
                <h4><FormattedMessage id='Payment.Service.Imobly.title_payment_subscription'/></h4>
                <div className='PayServiceImobly__content__payment__description'>
                  <p className="body-text1"><FormattedMessage id='Payment.Service.Imobly.paragraph.subscription_monthly_1'/></p>
                  <p className="body-text1"><FormattedMessage id='Payment.Service.Imobly.paragraph.subscription_monthly_2'/></p>
                  <p className="body-text1"><FormattedMessage id='Payment.Service.Imobly.paragraph.subscription_monthly_3'/> <span className='account_imobly'> Cuenta Imobly.</span></p>
                </div>
                <IframeFiserv
                  oid={accountImobly.result.oid}
                  hashExtended={accountImobly.result.hashExtended}
                  stored_id={accountImobly.result.stored_id}
                  hash={accountImobly.result.hash}
                  total_payment={accountImobly.result.total_payment}
                  currency={accountImobly.result.currency}
                  txndatetime={accountImobly.result.txndatetime}
                  property={property}
                  recurringInstallmentCount={accountImobly.result.recurringInstallmentCount}
                  recurringInstallmentPeriod={accountImobly.result.recurringInstallmentPeriod}
                  recurringInstallmentFrequency={accountImobly.result.recurringInstallmentFrequency}
                  recurringComments={accountImobly.result.recurringComments}
                  ponumber={accountImobly.result.ponumber} 
                 />
          </div>
        </div>
      </div>
      : null
     }</>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ ...actionsAccount}, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  accountState: state.get('accountState')
});


export default connect(mapStateToProps, mapDispatchToProps)(PayServiceImobly);
