import React, {Component} from 'react';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

export class SelectAnimated extends Component { 
  render(){
    const { array, onChange, emails, title } = this.props;
    return(
      <>
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          isMulti={true}
          options={array}
          getOptionValue={(options) => options.id_client}
          getOptionLabel={(options) => options.email}
          onChange={onChange}
          value={emails}
          placeholder={title}
        />
      </>
    )
  }
}
export default SelectAnimated;
