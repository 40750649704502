import * as ACTION from './actionTypes';
import { getProfileN, putProfileN, setReportAdminN } from '../../services/configurationService';

export function addData(data) {
  return {
    type: ACTION.ADD_DATA_PROFILE,
    data
  };
}

export function getProfile() {
  return {
    types: [
      ACTION.LOADING_GET_PROFILE,
      ACTION.RECEIVE_GET_PROFILE,
      ACTION.ERROR_GET_PROFILE,
    ],
    promise: getProfileN()
  };
}
export function putProfile({ profileData }) {
  return {
    types: [
      ACTION.LOADING_PUT_PROFILE,
      ACTION.RECEIVE_PUT_PROFILE,
      ACTION.ERROR_PUT_PROFILE,
    ],
    promise: putProfileN({ profileData })
  };
}

export function setReportAdmin({ data }) {
  return {
    types: [
      ACTION.LOADING_SET_REPORT,
      ACTION.RECEIVE_SET_REPORT,
      ACTION.ERROR_SET_REPORT,
    ],
    promise: setReportAdminN({ data })
  };
}
