import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import { toMoneyFormat } from 'utils/format';
import * as actionsAccount from '../../Accounts/accountActions';
import { postCreateSuscriptionN } from '../../../services/accountsService';
import * as neighborsActions from '../../Neighbors/neighborsActions';
import ModalComposeMessage from '../../Neighbors/Neighbors/ModalComposeMessage';
import Button from 'components/Button';
import ModalCancelPayment from './ModalCancelPayment';
import './AccountImobly.scss';

export class AccountImobly extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            messageSuccess: false
        };
    }

    componentDidMount(){
        const { property } = this.props.params;
        this.props.getAccountImobly({property});
    }

    termsAndAvise = (value) => {
        this.props.changeStateTab({value: value});
    }

    paymentService = () => {
        postCreateSuscriptionN()
            .then((res) => {
                window.location.replace(res.data);
            })
    }

    cancelPaymentService = () => {
        const { property } = this.props.params;
        this.props.putCancelRecurringPayment()
          .then((res) => {
            this.props.getAccountImobly({property});
              this.setState({ messageSuccess:  true })
          })
    }

    handleOpenDialog = () => {
        this.setState({ open: true });
    }

    handleCloseDialog = () => {
        this.setState({ open: false });
    }

    toggleMessageModal() {
        this.setState({ composeMessageOpen: !this.state.composeMessageOpen });
    }

    render(){
        let dateActivateAccount;
        let deadline_dateN;
        let effective_dateN;
        let cancelButton = false;
        const { accountImobly } = this.props.accountState
        if(accountImobly.result) {
            const {member_since, deadline_date, effective_date, recurrent_payment:{ Bool, Valid }} = accountImobly.result;
            let miliseconds = new Date(member_since);
            dateActivateAccount = moment(miliseconds).format('LL');
            deadline_dateN = moment(new Date(deadline_date)).format('LL');
            effective_dateN = moment(new Date(effective_date)).format('LL');
            cancelButton = (Bool === true && Valid === true);
        }
        const { open, messageSuccess } = this.state;
        return(
            <>
            { accountImobly.result ?
                <div className='AccountImobly'>
                <div className='AccountImobly__description'>
                    <h3><FormattedMessage id='AccountImobly.account_imobly_title'/></h3>
                    <div className='AccountImobly__description__date'>
                        <p className='subtitle'><FormattedMessage id='AccountImobly.date_activation_title'/></p>
                        <p className='body-text1'>{dateActivateAccount}</p>
                    </div>
                    <div className='AccountImobly__description__type'>
                        <p className='subtitle'><FormattedMessage id='AccountImobly.type_administration_title'/></p>
                        <p className='body-text1'>{accountImobly.result.administration_type}</p>
                    </div>
                    <div className='AccountImobly__description__properties'>
                        <p className='subtitle'><FormattedMessage id='AccountImobly.property_registered'/></p>
                        {
                            accountImobly.result.properties.map(res => (
                              <div key={`AccountImobly__description__properties${res.property_id}`}  className="AccountImobly__description__properties__prop">
                                  <p className='body-text1'> {res.name}</p>
                              </div>
                          ))
                        }
                    </div>
                </div>
                <div className='AccountImobly__supscripton border'>
                    <ModalCancelPayment
                        open={open}
                        loading={messageSuccess}
                        onClose={this.handleCloseDialog}
                        onCancelPayment={this.cancelPaymentService}
                        serviceDate={ deadline_dateN }
                    />
                    <h3><FormattedMessage id='AccountImobly.subscription_imobly_title'/></h3>
                    <div className='AccountImobly__supscripton__validity'>
                        <p className='subtitle'><FormattedMessage id='AccountImobly.validity_service_title'/></p>
                        <p className='body-text1'><FormattedMessage id='AccountImobly.subscription_validity_text'/>{deadline_dateN}. <br />
                        <FormattedMessage id='AccountImobly.make_payment_text'/>
                         {effective_dateN} 
                         <FormattedMessage id='AccountImobly.renew_payment_text'/>
                         </p>
                    </div>
                    <div className='AccountImobly__supscripton__service'>
                        <p className='subtitle AccountImobly__supscripton__service__title'><FormattedMessage id='AccountImobly.service_fee_title'/></p>
                        <div className='AccountImobly__supscripton__service__text'>
                            <p className='body-text1'>$ {toMoneyFormat(accountImobly.result.total_payment)} por {accountImobly.result.housing_units} unidades habitacionales.</p>
                        </div>

                        <div className="AccountImobly__supscripton__service__buttons">
                            {  cancelButton ? 
                                <Button variant="outlined" color="primary" onClick={this.handleOpenDialog}><FormattedMessage id='Button.cancel_automatic'/></Button> 
                               :
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.paymentService}
                                >
                                    <FormattedMessage id='Button.make_payment'/>
                                </Button>
                            }
                        </div>

                    </div>

                    <div className='AccountImobly__supscripton__support'>
                        <h3><FormattedMessage id='AccountImobly.support_contact_title'/></h3>
                        <div className='AccountImobly__supscripton__support__text' >
                            <div className='AccountImobly__supscripton__support__text__question'>
                                <p className='body-text1'>
                                    <FormattedMessage id='AccountImobly.comments_questions_text'/>
                                    <br />
                                    <FormattedMessage id='AccountImobly.email_text'/>
                                </p>
                            </div>
                            <button className='AccountImobly__supscripton__support__text__button' onClick={ this.toggleMessageModal.bind(this) }><FormattedMessage id='AccountImobly.comments_button'/></button>
                        </div>
                    </div>
                    <div className='AccountImobly__supscripton__terms'>
                        <h3><FormattedMessage id='AccountImobly.terms_privacy_title'/></h3>
                        <p className='body-text1'><FormattedMessage id='AccountImobly.doubts_query_text'/> <a href="#" onClick={ () => this.termsAndAvise(2)} ><FormattedMessage id='AccountImobly.terms_conditions_href'/></a>   y  nuestro <a href="#" onClick={ () => this.termsAndAvise(3) }><FormattedMessage id='AccountImobly.notice_privacy_text'/></a>.</p>
                    </div>
                </div>
                <ModalComposeMessage 
                    open={ this.state.composeMessageOpen }
                    onClose={ this.toggleMessageModal.bind(this)  }
                    recipients={[{
                        id_client: 1,
                        email: 'soporte@imobly.io'
                    }]}
                    sendTo={{
                        id_client: 1,
                        email: 'soporte@imobly.io'
                    }}
                    sendEmailResidents={this.props.sendEmailResidents}
                    loadingNeighbor={this.props.loadingNeighbor}
                />
            </div>
            : null}
            </>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ ...neighborsActions, ...actionsAccount }, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  accountState: state.get('accountState'),
  loadingNeighbor: state.get('neighborState').loading
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountImobly);
