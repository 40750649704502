import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../Property/propertyActions';
import * as ProfileActions from '../../Profile/ProfileActions';
import Dialog from 'components/Dialogs';

import PropertiesConfigurationItem from './PropertiesConfigurationItem';

import './PropertiesConfiguration.scss';

class PropertiesConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: {}
    };
  }

  handleOnDelete = (id) => () => {
    this.setState({
      dialog: {
        idProperty: id,
        title: 'Elimiar propiedad.',
        message: 'Esta seguro de continuar con la eliminación de la propiedad. Esta acción es irreversible.',
      }
    });
  }

  handleOnEdit = (id) => () => {
    this.context.router.push(`/Wizard/21/property/${id}`);
  }

  handleCloseDialog = () => {
    this.setState({ dialog: {} });
  }

  onDelete = () => {
    const { idProperty } = this.state.dialog;
    this.props.delProperty({
      property: idProperty
    }).then(()=>{
      this.props.getProfile();
      this.props.getProperties().then(() => {
        const { properties } = this.props.propertyState;
        if(Object.values(properties).length === 0){
          this.context.router.push(`/Wizard/info/property`);
        }
        this.setState({ dialog: {} });
      });
    });
  }

  render() {
    const { dialog } = this.state;
    const { properties } = this.props.propertyState;
    return (
      <div className='PropertiesConfiguration'>
        <Dialog
          title={dialog.title}
          message={dialog.message}
          onClose={this.handleCloseDialog}
          open={!!dialog.title}
          onSuccess={this.onDelete}
          onCancel={this.handleCloseDialog}
          textSuccess='Continuar'
          textCancel='Cancelar'
        />
        <div className='PropertiesConfiguration__container'>
          {
            Object.values(properties).map((item, index) => (
                <PropertiesConfigurationItem
                  key={ index }
                  icon='tower'
                  name={item.name}
                  onDelete={this.handleOnDelete(item.id)}
                  onEdit={this.handleOnEdit(item.id)}
                />
              )
            )
          }
        </div>
      </div>
    );
  }
}

PropertiesConfiguration.contextTypes = {
  router: PropTypes.shape()
};

PropertiesConfiguration.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({ ...Actions, ...ProfileActions }, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  propertyState: state.get('propertyState')
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesConfiguration);
