import React, { Component } from 'react';

import TabView from 'components/TabView';
import Card from 'components/Card';

import Regulation from './Regulation/Regulation';
import Assembly from './Assembly/Assembly';

import './RegulationPage.scss';
import ReportAdmin from './ReportAdmin/ReportAdmin';
import Advertisements from './Advertisements/Advertisements';

class RegulationPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { property } = this.props.params;

    return (
      <div className='RegulationPage col animate__animated animate__fadeIn'>
        <h2>Documentos</h2>
        <Card>
          <TabView
            items={[
              { value: 0, label: 'Reglamentos', view: <Regulation idProperty={property} tipe='regulation' /> },
              { value: 1, label: 'Asambleas', view: <Assembly idProperty={property} tipe='assembly' /> },
              { value: 2, label: 'Reportes de administración', view: <ReportAdmin idProperty={property} tipe='report_admin' /> },
              { value: 3, label: 'Anuncios', view: <Advertisements idProperty={property} tipe='advertisements' /> }
            ]}
          />
        </Card>
      </div>
    );
  }
}

export default RegulationPage;
