import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Title from '../components/Title/Title';
import AddressAutocomplete from './AddressAutocomplete';
import AddressMap from './AddressMap';
import Text from 'components/Text';
import Card from 'components/Card';
import Button from 'components/Button';

import * as Actions from '../../Property/propertyActions';
import { putProfile, getProfile } from '../../Profile/ProfileActions';
import './Address.scss';
import { CircularProgress } from '@material-ui/core';


export class Address extends Component {
  constructor(props){
    super(props);
    this.state = {
      error: {},
      blockButton: false,
      validateProperties: false
    };
  }

  componentDidMount() {
    const { property } = this.props.params;
    const { properties } = this.props.propertyState;

    if (property){
      this.props.getProperty({ property }).then(this.restoreSession.bind(this));
    }
    this.hasPropertiesUser(properties);
  }

  componentWillUnmount() {
    this.props.addPropertyData({
      new: {
        id: null,
        name: '',
        address: '',
      }
    });
  }

  restoreSession() {
    const { step } = this.props.propertyState.new;
    const { property } = this.props.params;
    const { search } = window.location;

    if (step === 51) {
      this.context.router.push(`/property/${ property }/dashboard`);
    } else if (search.indexOf('back') === -1 && step > 0) {
      this.context.router.push(`/Wizard/${ step + 10 }/property/${ property }`);
    }
  }

  handleRegisterProperty = (property) => {
    this.props.putProfile({
      profileData:{ resident_last_view: Number(property) }
    }).then(()=>{
      this.props.getProfile();
    });
  }

  goBack = () => {
    const { property } = this.props.params;
    property ? this.context.router.push(`/wizard/info/property/${property}`) : this.context.router.push(`/wizard/info/property`);
  }

  onSubmit = () => {
    if (!this.validate()) {
      this.blockButton = true;
      
      if (this.props.params.property){
        const { name, address } = this.props.propertyState.new;
        const property = {
          "name": name,
          "step": parseInt(this.props.params.id),
          "status_id": 21,
          "address":{
            "latitude": address.latitude,
            "longitude": address.longitude,
            "description": address.description,
          }
        }
        const id = this.props.params.property;
        this.props.putProperty({
          idProperty: id,
          property
        }).then(()=>{
          this.handleRegisterProperty(id);
          this.context.router.push(`/Wizard/21/property/${id}`);
          this.blockButton = false;
        });
      }

      if (!this.props.params.property){
        const { name, address } = this.props.propertyState.new;
        const property = {
          "name": name,
          "address":{
            "latitude": address.latitude,
            "longitude": address.longitude,
            "description": address.description,
            
          }
        }
        this.props.setProperty({ 
          property
        }).then(()=>{
          const { SET_PROPERTY } = this.props.propertyState.loading;
          this.blockButton = false;          
          if(!SET_PROPERTY){
            const id = this.props.propertyState.new.id;
            this.handleRegisterProperty(id);
            this.context.router.push(`/Wizard/21/property/${id}`);
          }
          
          
        });
      }
      
    }
    
  }

  validate = () => {
    let isError = false;
    const error = {};
    const validationMessage = 'Campo requerido';
    const { address, name } = this.props.propertyState.new;
    if (address.description === '') { 
      isError = true;
      error.description = validationMessage;
    }
    if (!name) {
      isError = true;
      error.name = validationMessage;
    }
    this.setState({ error });
    return isError;
  };
  
  hasPropertiesUser (properties){
    if (JSON.stringify(properties) !== '{}') {
      this.setState({
        validateProperties: true
      })
    } else {
      this.setState({
        validateProperties: false
      })
    }
  }

  render() {
    const { error, validateProperties } = this.state;
    const { resident_last_view } = this.props.profileState;
    
    return(
      <div className='Address'>
        <Title
          title={<Text variant='body1' id='Address.title' />}
          helpMessage='La ubicación debe estar ingresada correctamente para poder localizar tu propiedad en el mapa.'
          goBack={this.goBack}
          goBackText='ver tutorial'
					property={resident_last_view ? resident_last_view: null}
          validateProperties={validateProperties}
        />
        <Card style={{
          maxWidth: '630px',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
          padding: '20px 30px',
          marginBottom: '40px',
          boxSizing: 'border-box'
        }}>
          <AddressAutocomplete
            error={error}
            params={this.props.params}
          />
          <AddressMap />
          
          <div className='Address__options'>
              {
                this.blockButton 
                ? <CircularProgress/>
                : <Button 
                variant='contained'
                color='secondary'
                onClick={this.onSubmit}>
                <Text onlyformat id='Button.continue' />
              </Button>
              }
          </div>
                    
        </Card>
      </div>
    );
  }
}

Address.contextTypes = {
  router: PropTypes.shape()
};

Address.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({ ...Actions, putProfile, getProfile }, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  propertyState: state.get('propertyState'),
	profileState: state.get('profileState'),

});

export default connect(mapStateToProps, mapDispatchToProps)(Address);
