import React, { useState } from 'react';
import { connect } from 'react-redux';
import Form from 'components/Form'
import * as Actions from '../../../../Property/propertyActions';
import InputAdornment from '@material-ui/core/InputAdornment';
import ImageIcon from '@material-ui/icons/Image';
import { toMoneyFormat } from 'utils/format';

import { bindActionCreators } from 'redux';

import './DiscountForm.scss';

export const DiscountForm = props => {

  const { value, budgetState } =  props;

  const { dwellingAccount } = budgetState;
  
  const concepts = ((dwellingAccount || { })[value.dwelling_id] || [ ])
    .filter(concept => concept.balance < 0)
    .map(concept => ({
        value: concept,
        label: concept.concept
    }));

  const { onCancel, dwelling, budgetState : { actives, activeSelected } } = props;

  const [ selectedConcept, setSelectedConcept ] = useState({
    balance: 0
  });

  const [ discountAmount, setDiscountAmount ] = useState(0);

  const onSubmit = data => props.setDiscount({
      did: dwelling.dwelling_id,
      data: {
        ...data,
        amount: Number(data.amount),
        concept: JSON.stringify(data.concept),
        file: data.file?.file
      }
    })
    .then(props.onSuccess);
  
  const maxDiscountAmount = selectedConcept.balance * -1;
  const amountExcedsMaxDiscountAmount =  maxDiscountAmount < discountAmount;

  return (
    <div className='DiscountForm'>
      <Form
        title='Registra los datos para aplicar un descuento al residente'
        loading={ props.propertyState.loading.SET_DISCOUNT?.status }
        needsUserConfirmation
        onCancel={ onCancel }
        onSubmit={ onSubmit }
        clearOnSubmitted
        notificationMessage='El descuento ha sido registrado exitosamente'
        changeInterceptor={(name, controlValue) => {
          if (name === 'concept') {
            setSelectedConcept(controlValue);
          }
          if (name === 'amount') {
            setDiscountAmount(Number(controlValue || 0));
          }
        }}
        confirmationDetails={[
          {
            label: 'Nombre del residente',
            value: props.value.full_name
          },
          {
            label: 'Condominio',
            value: Object.values(actives?.result || { }).find(active => active.id === activeSelected)?.name
          },
          {
            label: 'Casa / Depto',
            value: props.value.number
          }
        ]}
        controls={[
          [
            {
              name: 'title',
              label: 'DESCUENTO',
              props: {
                required: true
              }
            },
            {
              name: 'concept',
              label: 'CONCEPTO',
              type: 'select',
              displayKey: 'concept',
              props: {
                required: true,
                items: concepts,
                disabled: !concepts.length
              }
            }
          ],
          [
            {
              name: 'amount',
              label: 'MONTO',
              type: 'currency',
              props: {
                disabled: !selectedConcept.balance,
                required: true,
                minimumValue: '0',
                error: amountExcedsMaxDiscountAmount,
                helperText: amountExcedsMaxDiscountAmount && `El monto máximo es de ${ toMoneyFormat(maxDiscountAmount) }`,
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }
              }
            },
            {
              name: 'reference',
              label: 'REFERENCIA'
            }
          ],
          {
            name: 'description',
            label: 'MOTIVO DE DESCUENTO',
            type: 'lengthCounter',
            props: {
              maxLength: 100
            }
          },
          {
            name: 'file',
            type: 'file',
            hideFromConfirmation: true,
            props: {
              label: 'Puedes agregar una imagen de evidencia para justificar el descuento',
              buttonProps: {
                startIcon: <ImageIcon />,
              },
              children: 'Agregar imagen'
            }
          }
        ]}
      />
    </div>
  );
}

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);

const mapStateToProps = state => ({
  budgetState: state.get('budgetState'),
  propertyState: state.get('propertyState'),
});

export default connect(mapStateToProps, mapDispatchToProps)(DiscountForm);
