import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import * as Actions from '../../../Property/propertyActions';

import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Text from 'components/Text';
import './Table.scss';
import {
  EMAIL_REGEX,
  PHONE_NUMBER_REGEX,
} from 'constants/globalRegex';

export class TableAccordion extends Component {
  constructor() {
    super();
    this.state = {
      anchor: {},
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.bottomPage?.scrollIntoView({ behavior: 'smooth' });
    }, 1500);
  }

  handleOpenOptions = (key) => (event) => {
    const { anchor } = this.state;
    anchor[key] = event.currentTarget;
    this.setState({ anchor });
  };

  handleCloseOptions = () => {
    this.setState({ anchor: {} });
  };

  handleValidatorErrors = (row, indexX, indexY, value) => {
    const { error } = this.props.propertyState.build;

    const letter = `${row}${indexX}${indexY}`;
    const index = error.indexOf(letter);
    const exist = error.includes(letter);

    switch (indexX) {
      case 'resident_name':
      case 'resident_last_name':
      case 'resident_sec_last_name':
          if (!/^[a-zA-Z\s\u00C0-\u017F\_\-\,\.\']*$/i.test(value)) {
            if (!exist) {
              error.push(letter);
            }
          } else {
            if (exist) {
              error.splice(index, 1);
            }
          }
        break;
      case 'resident_phone':
        if (!PHONE_NUMBER_REGEX.test(value) && value !== '') {
          if (!exist) {
            error.push(letter);
          }
        } else {
          if (exist) {
            error.splice(index, 1);
          }
        }
        break;
      case 'resident_email':
        if (!EMAIL_REGEX.test(value) && value !== '') {
          if (!exist) {
            error.push(letter);
          }
        } else {
          if (exist) {
            error.splice(index, 1);
          }
        }
        break;
      default:
        return error;
    }

    return error;
  };

  handleValidatorEdition = (row, indexY) => {
    const edited = this.props.propertyState.build.edited;
    const newContent = this.props.propertyState.build.data[row].content;
    if (newContent[indexY].dwelling_id > 0) {
      if (!edited[row]) {
        edited[row] = [];
      }
      if (edited[row].indexOf(indexY) === -1) {
        edited[row].push(indexY);
      }
    }
    return edited;
  };

  onChange = (params, event) => {
    const { row } = this.props;
    const { build } = this.props.propertyState;
    const newContent = build.data[row].content;

    const edited = this.handleValidatorEdition(row, params.indexY);
    const error = this.handleValidatorErrors(
      row,
      params.indexX,
      params.indexY,
      event.target.value
    );

    newContent[params.indexY][params.indexX] = event.target.value;

    this.props.addPropertyData({
      build: {
        ...build,
        data: {
          ...build.data,
          [row]: {
            ...build.data[row],
            content: newContent,
          },
        },
        edited,
        error,
      },
    });
  };

  render() {
    const { error } = this.props.propertyState.build;
    const { content, row, toEdit, onDelete, showFocus } = this.props;
    const { anchor } = this.state;

    return (
      <div className='TableAccordion'>
        <div className='TableAccordion__header'>
          <div className='TableAccordion__header__section1'>
            <Text variant='caption' id='Home.table.section1' />
          </div>
          <div className='TableAccordion__header__section2'>
            <Text variant='caption' id='Home.table.section2' />
          </div>
        </div>
        <div className='TableAccordion__headers'>
          <div>Número</div>
          <div className='TableAccordion__popover'>Fecha de ocupación</div>
          <div>Nombre</div>
          <div>Apellido paterno</div>
          <div>Apellido materno</div>
          <div>Correo</div>
          <div>Teléfono</div>
        </div>
        <div className='TableAccordion__body'>
          {content.map((items, indexY) => {
            const determine = () => {
              const focus =
                showFocus && !toEdit ? `TableAccordion__show` : null;
              const select = Boolean(anchor[indexY])
                ? 'TableAccordion__row Table__row__selected'
                : 'TableAccordion__row';
              return `${focus} ${select}`;
            };
            return (
              <div key={`${row}${indexY}`} className={determine()}>
                <div>
                  <input
                    type='text'
                    className='TableAccordion__input TableAccordion__center'
                    onChange={(event) =>
                      this.onChange(
                        { indexX: 'dwelling_number', indexY },
                        event
                      )
                    }
                    value={items.dwelling_number}
                    maxLength='5'
                  />
                </div>
                <div>
                  <input
                    type='date'
                    className='TableAccordion__input'
                    onChange={(event) =>
                      this.onChange(
                        { indexX: 'dwelling_admin_date', indexY },
                        event
                      )
                    }
                    value={items.dwelling_admin_date}
                  />
                </div>
                <div
                  style={{
                    borderLeftWidth: 1,
                    borderLeftStyle: 'solid',
                    borderLeftColor: '#dcd8d8',
                  }}
                >
                  <input
                    type='text'
                    placeholder='Nombre'
                    className={
                      error && error.includes(`${row}resident_name${indexY}`)
                        ? 'TableAccordion__input TableAccordion__input__error'
                        : 'TableAccordion__input'
                    }
                    onChange={(event) =>
                      this.onChange({ indexX: 'resident_name', indexY }, event)
                    }
                    value={items.resident_name}
                    maxLength='35'
                  />
                </div>
                <div>
                  <input
                    type='text'
                    placeholder='Apellido Paterno'
                    className={
                      error &&
                      error.includes(`${row}resident_last_name${indexY}`)
                        ? 'TableAccordion__input TableAccordion__input__error'
                        : 'TableAccordion__input'
                    }
                    onChange={(event) =>
                      this.onChange(
                        { indexX: 'resident_last_name', indexY },
                        event
                      )
                    }
                    value={items.resident_last_name}
                    maxLength='35'
                  />
                </div>
                <div>
                  <input
                    type='text'
                    placeholder='Apellido Materno'
                    className={
                      error &&
                      error.includes(`${row}resident_sec_last_name${indexY}`)
                        ? 'TableAccordion__input TableAccordion__input__error'
                        : 'TableAccordion__input'
                    }
                    onChange={(event) =>
                      this.onChange(
                        { indexX: 'resident_sec_last_name', indexY },
                        event
                      )
                    }
                    value={items.resident_sec_last_name}
                    maxLength='35'
                  />
                </div>
                <div>
                  <input
                    type='email'
                    placeholder='Correo'
                    className={
                      error && error.includes(`${row}resident_email${indexY}`)
                        ? 'TableAccordion__input TableAccordion__input__error'
                        : 'TableAccordion__input'
                    }
                    onChange={(event) =>
                      this.onChange({ indexX: 'resident_email', indexY }, event)
                    }
                    value={items.resident_email}
                    maxLength='35'
                  />
                </div>
                <div>
                  <input
                    type='tel'
                    placeholder='Teléfono'
                    className={
                      error && error.includes(`${row}resident_phone${indexY}`)
                        ? 'TableAccordion__input TableAccordion__input__error'
                        : 'TableAccordion__input'
                    }
                    onChange={(event) =>
                      this.onChange({ indexX: 'resident_phone', indexY }, event)
                    }
                    value={items.resident_phone}
                    maxLength='13'
                  />
                  <div
                    style={{ float: 'left', clear: 'both' }}
                    ref={(el) => {
                      this.bottomPage = el;
                    }}
                  ></div>

                  {toEdit ? (
                    <IconButton onClick={this.handleOpenOptions(indexY)}>
                      <MoreVertIcon />
                    </IconButton>
                  ) : null}
                  <Menu
                    anchorEl={anchor[indexY]}
                    open={Boolean(anchor[indexY])}
                    onClose={this.handleCloseOptions}
                    PaperProps={{ style: { width: 200 } }}
                  >
                    <MenuItem
                      onClick={onDelete(
                        items.dwelling_id,
                        items.resident_id,
                        this.handleCloseOptions
                      )}
                    >
                      Eliminar
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

TableAccordion.contextTypes = {
  router: PropTypes.shape(),
};

TableAccordion.propTypes = {};

const mapDispatchToProps = (dispatch) => bindActionCreators(Actions, dispatch);
const mapStateToProps = (state) => ({
  loginState: state.get('loginState'),
  propertyState: state.get('propertyState'),
});

export default connect(mapStateToProps, mapDispatchToProps)(TableAccordion);
