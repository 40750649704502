import React, { Component } from 'react';
import { ReactSVG } from 'react-svg';

import { showIconAmenity } from 'constants/amenityTypes';
import { toMoneyFormat } from 'utils/format';
import './DrawerItem.scss';

class DrawerItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      active, budgetBuilding, onClick, icon, quota, showAmount
    } = this.props;
    const { name } = budgetBuilding;
    return (
      <div className='DrawerItem' onClick={onClick}>
        <div className={ active ? 'DrawerItem__view DrawerItem__view__on' : 'DrawerItem__view' }>
          <ReactSVG
            src={ showIconAmenity(icon).img }
            className={active ? 'DrawerItem__svgOn' : 'DrawerItem__svg'}
            beforeInjection={(svg) => {
              svg.setAttribute('style', 'width: 40px')
            }}
          />
          <div className='DrawerItem__text'>{name}</div>
          {
            showAmount
            ? <div className='DrawerItem__number'>${toMoneyFormat(quota)} cuota</div>
            : null
          }          
        </div>
      </div>
    );
  }
}

DrawerItem.defaultProps = {
  name: 'Sin nombre',
  quota: 0,
};

export default DrawerItem;
