import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as Actions from '../loginActions';
import { getProfile } from '../../Profile/ProfileActions';
import { FormattedMessage } from 'react-intl';
import LoginForm from './LoginForm';
import { EMAIL_REGEX } from 'constants/globalRegex';

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: props.loginState.registro || '',
      password: '',
      error: {}
    };
  }

  onFailure = (error) => {
    
  };

  hasErrors() {
    const { user, password } = this.state;
    const error = {};
    let isError = false;
    if (!user || !EMAIL_REGEX.test(user)) {
      error.user = <FormattedMessage id='LoginPage.invalidEmail' />;
      isError = true;
    }
    if (!password) {
      error.password = <FormattedMessage id='LoginPage.invalidPassword' />;
      isError = true;
    }
    if (isError) {
      this.setState({ error });
    }
    return isError;
  }

  onSubmit = () => {
    if (!this.hasErrors()) {
      const { user, password } = this.state;
      this.props.getLoginNormal({
        email: user,
        password
      }).then(()=>{
        this.props.addLoginData({ registro: user });
      });
    }
  }

  onChange = (event) => {
    const { error } = this.state;
    delete error[event.target.name];
    this.setState({ [event.target.name]: event.target.value, error });
  }

  responseGoogle = (response) => {
    this.props.getLoginGoogle({
      token: response.accessToken,
    });
  };

  responseFacebook = (response) => {
    this.props.getLoginFacebook({
      token: response.accessToken,
    });
  };

  goTo = where => () => {
    this.context.router.push(`/${where}`);
  }

  render() {
    const { user, password, error } = this.state;
    return (
      <LoginForm
        user={user}
        password={password}
        error={error}
        versionName={'1.0.0'}
        onFailure={this.onFailure}
        onSubmit={this.onSubmit}
        onChange={this.onChange}
        responseGoogle={this.responseGoogle}
        responseFacebook={this.responseFacebook}
        goTo={this.goTo}
      />
    );
  }
}

Login.contextTypes = {
  router: PropTypes.shape()
};
Login.propTypes = {
  getLoginNormal: PropTypes.func.isRequired,
  getLoginGoogle: PropTypes.func.isRequired,
  getLoginFacebook: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({ ...Actions, getProfile }, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState')
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
