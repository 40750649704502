import { combineReducers } from 'redux-immutable';
import { routerReducer as routing } from 'react-router-redux';

import loginState from './loginReducer';
import profileState from './profileReducer';
import propertyState from './propertyReducer';
import budgetState from './budgetReducer';
import providerState from './providerReducer';
import accountState from './accountsReducer';
import neighborState from './neighborReducer';
import bookingState from './bookingsReducer';
import subscriptionsState from './subscriptionsReducer';

const rootReducer = combineReducers({
  accountState,
  budgetState,
  providerState,
  propertyState,
  neighborState,
  profileState,
  loginState,
  bookingState,
  routing,
  subscriptionsState,
});

export default rootReducer;
