import * as ACTION from 'containers/Profile/actionTypes';

const initialState = {
  loadingPage: {},
  error: {},
  resident_email: '',
  resident_last_name: '',
  resident_name: '',
  resident_phone: '',
  resident_sec_last_name: '',
  resident_notifications: false,
  resident_role: [],
  resident_id: 0,
  resident_last_view: 0,
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.ADD_DATA_PROFILE:
      return { ...state, ...action.data };

    case ACTION.LOADING_GET_PROFILE:
      return {
        ...state,
        loadingPage: { ...state.loadingPage, GET_PROFILE: true },
        error: { ...state.error, GET_PROFILE: undefined }
      };
    case ACTION.RECEIVE_GET_PROFILE:
      return {
        ...state,
        loadingPage: { ...state.loadingPage, GET_PROFILE: undefined },
        ...action.result.data.result,
        error: { ...state.errors, GET_PROFILE: undefined }
      };
    case ACTION.ERROR_GET_PROFILE:
      return {
        ...state,
        loadingPage: { ...state.loadingPage, GET_PROFILE: undefined },
        error: { ...state.error, GET_PROFILE: action.error.response }
      };

    case ACTION.LOG_OUT:
      return initialState;

    default:
      return { ...state };
  }
};

export default profileReducer;
