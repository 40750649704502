import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import * as Actions from '../../../budgetActions';
import Status from 'components/Status';
import BudgetAsset from '../../../components/BudgetAsset/BudgetAsset';
import Dialog from 'components/Dialogs';
import Table from 'components/Table';
import { toMoneyFormat } from '../../../../../utils/format';
import './BudgetPending.scss';
import ModalPaymentService from '../ModalPaymentService/ModalPaymentService';
import BudgetPendingModal from './BudgetPendingModal';


export class BudgetPending extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConceptsValidation: false,
      code: '',
      codeError: '',
      showSnack: false,
      insufficient: false,
      showModalPay: false,
      payment: {},
      conceptsEdit: {}
    };
  }

  componentDidMount() {
    const { activeSelected } = this.props.budgetState;
    this.handleUpdateData(activeSelected);
    this.props.addData({ conceptsToPay: {}, conceptsToCash: {} });
  }

  componentDidUpdate(prevProps) {
    const activeSelectedBefore = prevProps.budgetState.activeSelected;
    const activeSelectedNext = this.props.budgetState.activeSelected;
    if (activeSelectedBefore !== activeSelectedNext) {
      const { activeSelected } = this.props.budgetState;
      this.handleUpdateData(activeSelected);
      this.props.addData({ conceptsToPay: {}, conceptsToCash: {} });
    }
  }

  handleUpdateData = (active) => {
    const { property } = this.props.params;
    this.props.getAssets({
      property,
      active
    }).then(() => {
      this.props.getProviderPaymentPending({
        property,
        active
      });
    });
  }

  goToEdit = () => {
    const { property } = this.props.params;
    this.context.router.push(`/property/${property}/budget/edition`);
  }

  handleCalcAmountToPay = () => {
    const { conceptsToPay } = this.props.budgetState;
    let toPay = 0;
    Object.values(conceptsToPay).forEach(item => {
      toPay += item.amount;
    });
    return toPay;
  }

  handleShowValidationConcepts = topay => () => {
    const { property } = this.props.params;
    const { activeSelected, expenses } = this.props.budgetState;
    if (topay <= expenses.balance) {
      this.props.setCodeToPay({ property, active: activeSelected });
      this.setState({ showConceptsValidation: true, code: '' });
    } else {
      this.setState({ insufficient: true });
    }
  }

  handleCreateAssetSection = () => {
    const { conceptsPendingProviderPayment, assets, loading } = this.props.budgetState;
    return Object.getOwnPropertyNames(conceptsPendingProviderPayment || { }).map((asset, index) => {
      const infoAsset = (assets?.result || []).find(item => item.id === asset) || { };

      return (
        <div className="BudgetPending" key={ index }>
          <BudgetAsset
            icon={ infoAsset.type }
            headTitle={ infoAsset.name }
          />
          <Table
            columns={[
              {
                headerName: 'Concepto',
                field: 'service_name'
              },
              {
                headerName: 'Proveedor',
                field: 'provider_name'
              }, {
                headerName: 'Fecha de ejecución',
                field: 'service_date',
                date: true
              },{
                headerName: 'Status',
                component: Status,
                props: {
                  value: 'status'
                },
                cellProps: {
                  align: 'center'
                }
              },
              {
                headerName: 'Monto',
                field: 'amount',
                currency: true
              },
              {
                cellProps: {
                  align: 'right'
                },
                button: 'Registrar',
                onClick: this.hadleOpenModalPay.bind(this)
              }
            ]}
            tableProps={{
              stickyHeader: true
            }}
            loading={ loading.GET_PROVIDER_PAYMENT_PENDING?.status || loading.GET_ASSETS?.status }
            rows={ Object.values(conceptsPendingProviderPayment[asset]).filter(item => item.status !== 'Completed') }
          />
        </div>
      );
    });
  }

  onCloseDialog = () => {
    this.setState({ showSnack: false });
  }

  handleCloseModal = () => {
    this.props.addData({
      openPayResponse: {}
    });
    this.setState({ insufficient: false });
  }

  hadleOpenModalPay(item) {
    this.setState({ showModalPay: true, payment: item });
  }

  hadleCloseModalPay() {
    this.setState({ showModalPay: false });
  }

  render() {
    const {
      showSnack, showModalPay, payment
    } = this.state;
    const { conceptsPendingProviderPayment } = this.props.budgetState;
    const toPay = this.handleCalcAmountToPay();
    const { property } = this.props.params;

    return (
      <div className="BudgetPending">

        <ModalPaymentService
          open={showModalPay}
          onClose={this.hadleCloseModalPay.bind(this)}
          payment={payment}
          property={property}
        />
        <BudgetPendingModal
          conceptsToCash={conceptsPendingProviderPayment}
          insufficient={this.state.insufficient}
          close={this.handleCloseModal}
        />
        { this.handleCreateAssetSection() }
        {
          toPay > 0 && (
            <div className="BudgetPending__total">
              <div className="BudgetPending__total__title">
                Total
              </div>
              <div className="BudgetPending__total__amount">
                $ {toMoneyFormat(toPay)}
              </div>
              <div className="BudgetPending__total__opt">
                <Button variant="contained" color="secondary" onClick={this.handleShowValidationConcepts(toPay)}>Pagar</Button>
              </div>
            </div>
          )
        }
        {
          <Dialog
            title="¡Proveedor no seleccionado!"
            message="Tu concepto no tiene un proveedor asignado, asígnalo en la sección de"
            onClose={this.onCloseDialog}
            open={showSnack}
            onCancel={this.onCloseDialog}
            haveLink
            toEdit={this.goToEdit}
          />
        }
      </div>
    );
  }
}

BudgetPending.contextTypes = {
  router: PropTypes.shape()
};

BudgetPending.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  budgetState: state.get('budgetState')
});

export default connect(mapStateToProps, mapDispatchToProps)(BudgetPending);
