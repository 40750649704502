import Popover from '@material-ui/core/Popover';
import React from 'react';

const anchorPosition = 'anchorPosition';
const anchorEl = 'anchorEl';

class PopoverBuilder {
  constructor() {
    this.properties = {};
  }
  setTop = (top) => {
    this.top = top;
    return this;
  }
  setLeft = (left) => {
    this.left = left;
    return this;
  }
  setOpen = (open) => {
    this.properties = { ...this.properties, open };
    return this;
  }
  setAnchorEl = (anchorEle) => {
    this.anchorEl = anchorEle;
    return this;
  }
  setOnClose = (onClose) => {
    this.properties = { ...this.properties, onClose };
    return this;
  }
  setAnchorOrigin = (anchorOrigin) => {
    this.properties = { ...this.properties, anchorOrigin };
    return this;
  }
  setTransformOrigin = (transformOrigin) => {
    this.properties = { ...this.properties, transformOrigin };
    return this;
  }

  setContent = (content) => {
    this.content = content;
    return this;
  }

  build = () => {
    this.properties.anchorEl = this.top > 0 && this.left > 0 ? null : this.anchorEl;
    this.properties.anchorReference = this.top > 0 && this.left > 0 ? anchorPosition : anchorEl;
    if (this.properties.anchorReference === anchorPosition) {
      this.properties.anchorPosition = { top: this.top, left: this.left };
    }
    return (
      <Popover {...this.properties} >
        {this.content};
      </Popover>);
  }
}

export default PopoverBuilder;
