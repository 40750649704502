import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import BudgetAsset from '../../../components/BudgetAsset/BudgetAsset';
import Table from 'components/Table';
import Status from 'components/Status';
import BudgetRowDetail from '../BudgetRowDetail';

import * as Actions from '../../../budgetActions';


import './BudgetCollect.scss';

export class BudgetCollect extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { activeSelected } = this.props.budgetState;
    this.handleUpdateData(activeSelected);
  }

  componentDidUpdate(prevProps){
    const activeSelectedBefore = prevProps.budgetState.activeSelected;
    const activeSelectedNext = this.props.budgetState.activeSelected;
    if(activeSelectedBefore !== activeSelectedNext){
      const { activeSelected } = this.props.budgetState;
      this.handleUpdateData(activeSelected);
    }
  }

  componentWillUnmount(){
    this.props.clearDataConcepts();
  }

  handleUpdateData = (active) => {
    const { property } = this.props.params;
    this.props.clearDataConcepts();
    this.props.getIncomesCharged({
      property,
      active
    });
  }

  render() {
    
    let paymentDate;
    
    const { incomeConcepts, loading } = this.props.budgetState;

    Object.values(incomeConcepts || { }).forEach(
      concept => paymentDate = concept.payment_date
    );
   
    return (
      <div className='BudgetCollect'>
        <BudgetAsset
          headTitle='Ingresos de cuotas de mantenimiento'
          paymentDate={ paymentDate }
        />
        <Table
          columns={[
            {
              headerName: 'Casa/Depto.',
              field: 'number'
            },
            {
              headerName: 'Nombre del Residente',
              field: 'full_name',
              placeholder: 'No encontrado'
            }, {
              headerName: 'Cuota mensual',
              field: 'monthly_fee',
              currency: true,
              useCurrencyColors: true,
              reverseNumber: true

            },{
              headerName: 'Saldo',
              field: 'balance',
              currency: true,
              useCurrencyColors: true
            },{
              headerName: 'Pendiente de pago',
              field: 'total',
              currency: true,
              useCurrencyColors: true,
              reverseNumber: true
            },
            {
              headerName: 'Status',
              component: Status,
              props: {
                value: 'status'
              },
              cellProps: {
                align: 'center'
              }
            },
            {
              button: 'Registrar',
              toggleButton: true,
              cellProps: {
                align: 'right'
              }
            }
          ]}
          tableProps={{
            stickyHeader: true
          }}
          detailComponent={ BudgetRowDetail }
          loading={ loading.GET_INCOMES_CHARGED?.status }
          rows={ incomeConcepts }
          { ...this.props }
        />
      </div>
    );
  }
}

BudgetCollect.contextTypes = {
  router: PropTypes.instanceOf(Object)
};

BudgetCollect.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  budgetState: state.get('budgetState')
});

export default connect(mapStateToProps, mapDispatchToProps)(BudgetCollect);
