import * as ACTION from './actionTypes';

import {
  getProvidersN,
  getProviderN,
  setProviderN,
  putProviderN,
  getProviderDetailN,
  getProviderActiveDetailN,
  getProviderActiveConceptsN,
} from '../../services/providerService';

export function addProviderData(data) {
  return {
    type: ACTION.ADD_PROVIDERS_DATA,
    data
  };
}

export function clearDataProvider() {
  return {
    type: ACTION.CLEAR_DATA_PROVIDER
  };
}

export function getProviders({ idProperty }) {
  return {
    types: [
      ACTION.LOADING_GET_PROVIDERS,
      ACTION.RECEIVE_GET_PROVIDERS,
      ACTION.ERROR_GET_PROVIDERS,
    ],
    promise: getProvidersN({ idProperty }),
  };
}

export function setProvider({ dataProvider }) {
  return {
    types: [
      ACTION.LOADING_SET_PROVIDER,
      ACTION.RECEIVE_SET_PROVIDER,
      ACTION.ERROR_SET_PROVIDER,
    ],
    promise: setProviderN({ dataProvider }),
  };
}

export function getProvider({ idProvider }) {
  return {
    types: [
      ACTION.LOADING_GET_PROVIDER,
      ACTION.RECEIVE_GET_PROVIDER,
      ACTION.ERROR_GET_PROVIDER,
    ],
    promise: getProviderN({ idProvider }),
  };
}

export function putProvider({ idProvider, dataProvider }) {
  return {
    types: [
      ACTION.LOADING_PUT_PROVIDER,
      ACTION.RECEIVE_PUT_PROVIDER,
      ACTION.ERROR_PUT_PROVIDER,
    ],
    promise: putProviderN({ idProvider, dataProvider }),
  };
}

// -----------------

export function getProviderDetail({ idProperty, idProvider }) {
  return {
    types: [
      ACTION.LOADING_GET_PROVIDER_DETAIL,
      ACTION.RECEIVE_GET_PROVIDER_DETAIL,
      ACTION.ERROR_GET_PROVIDER_DETAIL,
    ],
    promise: getProviderDetailN({ idProperty, idProvider }),
  };
}
export function getProviderActiveDetail({ idProperty, idProvider }) {
  return {
    types: [
      ACTION.LOADING_GET_PROVIDER_ACTIVE_DETAIL,
      ACTION.RECEIVE_GET_PROVIDER_ACTIVE_DETAIL,
      ACTION.ERROR_GET_PROVIDER_ACTIVE_DETAIL,
    ],
    promise: getProviderActiveDetailN({ idProperty, idProvider }),
  };
}
export function getProviderActiveConcepts({ idProperty, idProvider, idActive, idAsset }) {
  return {
    types: [
      ACTION.LOADING_GET_PROVIDER_ACTIVE_CONCEPTS,
      ACTION.RECEIVE_GET_PROVIDER_ACTIVE_CONCEPTS,
      ACTION.ERROR_GET_PROVIDER_ACTIVE_CONCEPTS,
    ],
    promise: getProviderActiveConceptsN({ idProperty, idProvider, idActive, idAsset }),
    param: { idAsset }
  };
}

