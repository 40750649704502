import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import { bindActionCreators } from "redux";
import { FormattedMessage } from 'react-intl';
import * as propertyActions from "../../Property/propertyActions";
import RequestItem from "../RequestItem";
import BookingsNotFound from 'assets/img/bookings-not-found.svg'
import "./RequestsList.scss";

export const RequestsList = (props) => {

  const orderByOptions = [
    { label: <FormattedMessage id="RequestsList.label.orderby" />, disabled: true },
    { label: <FormattedMessage id="RequestsList.label.more.recent" />, value: "start_date:asc" },
    { label: <FormattedMessage id="RequestsList.label.less.recent" />, value: "start_date:desc" },
  ];

  const didMount = useRef(false);
  const [ page, setPage ] = useState(1);
  const [ bookings, setBookings ] = useState([]);
  const [ orderBy, setOrderBy ] = useState(orderByOptions[1].value);

  const { loading } = props.propertyState;

  const concatPage = () => {
    setBookings(bookings.concat(props.propertyState.bookings || []));
  }

  const getBookings = () => {
    const { amenityId: amenity_id } = props;

    props.getBookings({
      page,
      limit: 10,
      amenity_id,
      orderBy
    });
  };

  const resetList = async () => {
   await setPage(1);
   await setBookings([]);
   getBookings();
  }

  useEffect(concatPage, [props.propertyState.bookings]);

  useEffect(() => {
    if (didMount.current) {
      resetList();
    } else {
      didMount.current = true;
    }
  }, [props.amenityId, props.lastRefresh, orderBy]);

  useEffect(getBookings, [ page ]);

  const onEndOfPage = () => {
    setPage(page + 1);
  };

  const onScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    const scrollBottom = scrollHeight - scrollTop === clientHeight;
    if (scrollBottom) {
      onEndOfPage();
    }
  };

  const orderByOnChange = ({target}) => {
    const { value } = target;
    setOrderBy(value);
  };

  const selectDisabled = !bookings.length;
  
  return (
    <>
      <div className="RequestsList">
        <div className="row head">
          <h4>
            <FormattedMessage id="RequestsList.label.requests" />
          </h4>
          <Select value={orderBy} className="select body-text3" onChange={orderByOnChange} disabled={selectDisabled}>
            {orderByOptions.map((option, index) => (
              <MenuItem value={option.value} key={index} disabled={option.disabled}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="scroll-list" onScroll={onScroll}>
          {!!bookings.length || loading.GET_PAGINATED_BOOKINGS?.status ? (
            bookings.map((request, index) => (
              <RequestItem onClick={ props.goToRequest } item={request} key={index} />
            ))
          ) : (
            <div className="col bookings-not-found">
              <img src={BookingsNotFound} />
              <h5>
                <FormattedMessage id="RequestsList.label.isEmpty" />
              </h5>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(propertyActions, dispatch);
const mapStateToProps = (state) => ({
  propertyState: state.get("propertyState"),
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestsList);
