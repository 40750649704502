import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Text from 'components/Text';

import './SectionCard.scss';

class SectionCard extends Component {
  render() {
    const { goTo, image, buttonText } = this.props;
    return (
      <div className='SectionCard'>
        <div className='SectionCard__cube' onClick={goTo}>
          <div className='SectionCard__cube__containerIcon'>
            <img src={image} className='SectionCard__cube__containerIcon__icon' alt='sectionIcon' />
          </div>
          <div className='SectionCard__cube__containerText'>
            <span>+</span>
            <Text onlyformat id={buttonText} />
          </div>
        </div>
      </div>
    );
  }
}

SectionCard.propTypes = {
  goTo: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export default SectionCard;
