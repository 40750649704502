import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import { addLoginData } from '../loginActions';
import stateReady from 'assets/img/state-warning.svg';
import './styles.scss';

class WithoutPending extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  clearError = () => {
    this.props.dispatch(addLoginData({ loading: {} })).then(()=>{
      this.context.router.push(`/login`);
    });
  }

  render() {
    return (
      <div className='Unauthorized'>
        <img src={stateReady} alt='image ready' />
        <h2 className='Unauthorized__error'>¡Código no enviado!</h2>
        <p>Acción cancelada porque el correo indicado no tiene validación pendiente. Verifique.</p>
        <Button onClick={this.clearError} variant='contained' color='secondary'>Iniciar sesión</Button>
      </div>
    );
  }
}

WithoutPending.contextTypes = {
  router: PropTypes.shape()
};

WithoutPending.propTypes = {
  loginState: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired
};

export default connect(state => ({ 
  loginState: state.get('loginState')
}))(WithoutPending);

