import React from 'react';
import PropTypes from 'prop-types';
import { Router, Route, browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import LoginPage from './containers/Login/LoginPage';
import ProfilePage from './containers/Profile/ProfilePage';
import Property from './containers/Property/PropertiesPage';
import Layout from './containers/Layout/Layout';
import WizardSwitch from './containers/Wizard/WizardPage';
import AddHomePage from './containers/Wizard/AddHome/AddHomePage';
import AddTowerPage from './containers/Wizard/AddTower/AddTowerPage';
import AddAmenityPage from './containers/Wizard/AddAmenity/AddAmenityPage';
import Providers from './containers/Providers/Providers/Providers';
import AddProvider from './containers/Providers/AddProvider/AddProvider';
import DashboardPage from './containers/Budget/Budget/DashboardPage';
import BudgetEdit from './containers/Budget/BudgetEdit/BudgetEdit';
import Neighbors from './containers/Neighbors/Neighbors/Neighbors';
import NeighborDetail from './containers/Neighbors/NeighborDetail/NeighborDetail';
import NeighborEdit from './containers/Neighbors/NeighborEdit/NeighborEdit';
import RegulationPage from './containers/Regulation/RegulationPage';
import TermsConditions from './public/termsConditions/TermsConditions';
import PrivacyNotice from './public/privacy/PrivacyNotice';
import PayServiceImobly from './containers/Profile/AccountImobly/PayServiceImobly/PayServiceImobly';
import PaySuccess from './containers/Profile/AccountImobly/PayServiceImobly/PaySuccess';
import PayError from './containers/Profile/AccountImobly/PayServiceImobly/PayError';
import ChangePassword from './containers/Profile/ConfigProfile/ChangePassword/ChangePassword';
import Bookings from './containers/Bookings/Bookings';

import Subscriptions from './containers/Subscriptions';

const Routes = (props = {}) => {
  let history = browserHistory;
  if (props.store) {
    history = syncHistoryWithStore(browserHistory, props.store, {
      selectLocationState(state) {
        if (typeof state.get('routing').toJS === 'function') {
          return state.get('routing').toJS();
        }
        return state.get('routing');
      }
    });
  }
  return (
    <Router history={history}>
      <Route component={Layout}>
        <Route exact path='properties' component={Property} />
        <Route exact path='subscriptions' component={ Subscriptions }/>
        <Route exact path='wizard/:id'>
          <Route exact path='property/:property/Amenity(/:amenityId)' component={AddAmenityPage} />
          <Route exact path='property/:property/Tower(/:towerID)' component={AddTowerPage} />
          <Route exact path='property/:property/Home(/:homeID)' component={AddHomePage} />
          <Route exact path='property(/:property)' component={WizardSwitch} />
          <Route exact path='property/:property/account/:accountTab' component={WizardSwitch} />
        </Route>
        <Route exact path='property(/:property)'>
          <Route exact path='providers' component={Providers} />
          <Route exact path='providers/new' component={AddProvider} />
          <Route exact path='providers/:providerID/edit' component={AddProvider} />
          <Route exact path='dashboard' component={DashboardPage} />
          <Route exact path='bookings' component={Bookings} />
          <Route exact path='budget/edition' component={BudgetEdit} />
          <Route exact path='contacts' component={Neighbors} />
          <Route exact path='contacts/:contactID/dwellings/:dwellingID' component={NeighborDetail} />
          <Route exact path='contacts/:contactID/dwellings/:dwellingID/edit' component={NeighborEdit} />
          <Route exact path='profile/configuration' component={ProfilePage} />
          <Route exact path='profile/resetPassword' component={ChangePassword} />
          <Route exact path='profile/payment' component={PayServiceImobly} />
          <Route exact path='profile/payment/pay-success' component={PaySuccess} />
          <Route exact path='profile/payment/pay-error' component={PayError} />
          <Route exact path='regulation' component={RegulationPage} />
        </Route>
      </Route>
      <Route exact path='/terms-and-conditions' component={TermsConditions} />
      <Route exact path='/privacy-notice' component={PrivacyNotice} />
      <Route exact path='/:type/:name/:email' component={LoginPage} />
      <Route exact path='/:type' component={LoginPage} />
      <Route
        path='/'
        component={() => {
        window.location.href = 'landing.html';
        return null;
      }}
      />

    </Router>
  );
};

Routes.propTypes = {
  store: PropTypes.shape().isRequired,
};
Routes.defaultProps = {};

export default Routes;
