import * as ACTION from 'containers/Providers/actionTypes';

const initialState = {
  loading: {},
  new: {
    name: '',
    url_image: null,
    phone: '',
    rfc: '',
    email: '',
    account_type: 1,
    account_number: '',
  },
  providers: {},
  providerInfo: {},
  providerActives: {
    activeSelected: '',
    result: [],
  },
  providerConcepts: {},
  conceptsSelected: {},
};

const handleFormatActives = (response) => {
  if (response.result && response.result[0]) {
    return { activeSelected: response.result[0].id, ...response };
  }
  return { ...response };
};

const getConceptsSelected = (data, idAsset) => {
  const concepts = {};
  if (data && data.result) {
    data.result.forEach((item) => {
      concepts[item.concept_id] = {
        amount: item.amount,
        id: item.concept_id,
        idAsset
      };
    });
  }
  return concepts;
};

const providerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.ADD_PROVIDERS_DATA:
      return { ...state, ...action.data };
    case ACTION.CLEAR_DATA_PROVIDER:
      return { ...state, new: {} };

    // ------------------------ GET PROVIDERS ------------------------ //
    case ACTION.LOADING_GET_PROVIDERS:
      return { ...state, loading: { ...state.loading, GET_PROVIDERS: { status: true } } };
    case ACTION.RECEIVE_GET_PROVIDERS:
      return { ...state, loading: { ...state.loading, GET_PROVIDERS: undefined }, providers: action.result.data };
    case ACTION.ERROR_GET_PROVIDERS:
      return { ...state, loading: { ...state.loading, GET_PROVIDERS: { status: false, detail: action.error.response } } };

    // ------------------------ SET PROVIDER ------------------------ //
    case ACTION.LOADING_SET_PROVIDER:
      return { ...state, loading: { ...state.loading, GET_PROVIDER: { status: true } } };
    case ACTION.RECEIVE_SET_PROVIDER:
      return { ...state, loading: { ...state.loading, GET_PROVIDER: undefined }, new: initialState.new, lastProviderCreated: action.result.data.result };
    case ACTION.ERROR_SET_PROVIDER:
      return { ...state, loading: { ...state.loading, GET_PROVIDER: { status: false, detail: action.error.response } } };

    case ACTION.LOADING_GET_PROVIDER:
      return { ...state, loadingPage: true, error: undefined };
    case ACTION.RECEIVE_GET_PROVIDER:
      return { ...state, loadingPage: true, new: action.result.data.result };
    case ACTION.ERROR_GET_PROVIDER:
      return { ...state, loadingPage: true, error: action.error };

    case ACTION.LOADING_PUT_PROVIDER:
      return { ...state, loadingPage: true, error: undefined };
    case ACTION.RECEIVE_PUT_PROVIDER:
      return { ...state, loadingPage: false, new: initialState.new };
    case ACTION.ERROR_PUT_PROVIDER:
      return { ...state, loadingPage: false, error: action.error };

    // ------------------------ GET PROVIDER DETAIL ------------------------ //
    case ACTION.LOADING_GET_PROVIDER_DETAIL:
      return { ...state, loading: { ...state.loading, GET_PROVIDER_DETAIL: { status: true } } };
    case ACTION.RECEIVE_GET_PROVIDER_DETAIL:
      return { ...state, loading: { ...state.loading, GET_PROVIDER_DETAIL: undefined }, providerInfo: action.result.data };
    case ACTION.ERROR_GET_PROVIDER_DETAIL:
      return { ...state, loading: { ...state.loading, GET_PROVIDER_DETAIL: { status: false, detail: action.error.response } } };

    // ------------------------ GET PROVIDER ACTIVE DETAIL ------------------------ //
    case ACTION.LOADING_GET_PROVIDER_ACTIVE_DETAIL:
      return { ...state, loading: { ...state.loading, GET_PROVIDER_ACTIVE_DETAIL: { status: true } } };
    case ACTION.RECEIVE_GET_PROVIDER_ACTIVE_DETAIL:
      return { ...state, loading: { ...state.loading, GET_PROVIDER_ACTIVE_DETAIL: undefined }, providerActives: handleFormatActives(action.result.data) };
    case ACTION.ERROR_GET_PROVIDER_ACTIVE_DETAIL:
      return { ...state, loading: { ...state.loading, GET_PROVIDER_ACTIVE_DETAIL: { status: false, detail: action.error.response } } };

    // ------------------------ GET PROVIDER ACTIVE CONCEPTS ------------------------ //
    case ACTION.LOADING_GET_PROVIDER_ACTIVE_CONCEPTS:
      return { ...state, loading: { ...state.loading, GET_PROVIDER_ACTIVE_CONCEPTS: { status: true } } };
    case ACTION.RECEIVE_GET_PROVIDER_ACTIVE_CONCEPTS:
      return {
 ...state, loading: { ...state.loading, GET_PROVIDER_ACTIVE_CONCEPTS: undefined }, providerConcepts: { ...state.providerConcepts, [action.param.idAsset]: action.result.data }, conceptsSelected: { ...state.conceptsSelected, ...getConceptsSelected(action.result.data, action.param.idAsset) } 
};
    case ACTION.ERROR_GET_PROVIDER_ACTIVE_CONCEPTS:
      return { ...state, loading: { ...state.loading, GET_PROVIDER_ACTIVE_CONCEPTS: { status: false, detail: action.error.response } } };

    default:
      return { ...state };
  }
};

export default providerReducer;
