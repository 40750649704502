import http from 'constants/config';
import environments from 'constants/environments';

export const getBudgetAllN = ({ property }) => http.get(
  `v1/properties/${property}/budgets`
);

export const getActivesN = ({ property }) => http.get(
  `v2/properties/by/id/${ property }/actives`
);

export const getAssetsN = ({ property, active }) => http.get(
  `v1/properties/${property}/actives/${active}/assets`
);


export const getPeriodsN = () => http.get(`v2/budgets/periods`);

export const getBudgetsAndFeesN = ({ bid }) => http.get(`v2/budgets/by-building/${bid}`);

export const getBudgetsAndFeesAmenitiesN = ({ amenity_id }) => http.get(`v2/budgets/by-amenity/${amenity_id}`);

export const getConceptsN = ({ property, active, page, limit }) => http.get(
  `v1/properties/${ property }/actives/${ active }/assets_concepts`, { params: { page, limit }  }
);

export const setConceptN = ({ conceptData }) => http.post(`v2/concepts`, conceptData);

export const putConceptN = ({ conceptData, cid }) => http.put(`v2/concepts/by/id/${ cid }`, conceptData);

export const createServiceN = (name, asset_type) => http.post(`v2/catalogs/services`, { name, asset_type });

export const deleteConceptN = ({ cid }) => http.delete(`v2/concepts/by/id/${ cid }`);

export const setRemindersN = ({ propertyID, data }) => http.post(
  `v1/properties/${propertyID}/concepts/reminders`,
  JSON.stringify(data)
);

export const getCatalogServicesN = ({ types }) => http.get(
  `v1/catalogs/services?asset_type=${types}`
);

export const getQuotaDetailN = ({ idBuilding }) => http.get(
  `v1/buildings/${idBuilding}/fees`
);

export const putDweelingBalanceN = ({ dataBalance, idProperty, idBuilding }) => http.put(
  `v1/properties/${idProperty}/buildings/${idBuilding}/dwellings_balance`,
  JSON.stringify(dataBalance)
);

export const putFeeDweelingN = ({ fees }) => http.put(
  `v1/fees`,
  JSON.stringify(fees)
);

export const createPocahontasRegistryN = ({ property }) => http.get(
  `v1/apipocahontas/property/${property}`
);

export const getExpensesN = active => http.get(
  `v2/budgets/by-active/${active}`
);

export const getConceptsToValidateN = ({ active }) => http.get(
  `v2/concepts-payments/by/active/${active}`
);

export const setProviderPayN = ({ dataConcept, concept_id }) => http.patch(
  `v2/concepts-payments/by/id/${ concept_id }/validate`,
  JSON.stringify(dataConcept)
);

export const setTransferFundN =({ dataTransfer }) => http.post(
  `/v2/transfers`,
  JSON.stringify(dataTransfer)
);

export const getTransferFundN =({ property, active }) => http.get(
  `v2/properties/by/id/${ property }/actives/${ active }/transfer`
);

export const getProviderPaymentPendingN = ({ property, active }) => http.get(
  `/v2/providers-payments/by/id/${ property }/actives/${ active }`
);

export const postExtraProviderPaymentN = (active, data) => {

  const formData = new FormData();

  Object.keys(data).forEach(key => data[key] && formData.append(key, data[key]));

  return http.post(
    `/v2/providers-payments/extra/by-active/${ active }`,
    formData,
    {
      headers: {'Content-Type': 'multipart/form-data' }
    }
  )
}

export const putProviderPaymentsPendingsN = ({ data }) => http.put(
  `v2/providers-payments/by/id/${ data.payment_id }`,
  JSON.stringify(data)
);


export const putProviderPaymentsN = ({ property, active, payment, status, reason }) => http.put(
  `v1/properties/${property}/actives/${active}/providers_payments/${payment}`,
  JSON.stringify({ status, reason })
);

export const getIncomesChargedN = ({ property, active }) => http.get(
  `v2/actives/by/id/${ active }/incomes`
);

export const getIncomesChargeredN = ({ property, active }) => http.get(
  `v1/properties/${property}/actives/${active}/income_charged`
);

export const putInitialBalanceN = ({ property, active, balance }) => http.put(
  `v2/actives/by/id/${active}`,
  JSON.stringify(balance)
);

export const setCodeToPayN = ({ property, active }) => http.post(
  `v1/properties/${property}/actives/${active}/code_created`,
  JSON.stringify()
);

export const setFundTransferN = ({ pid, data }) => http.post(
  `v1/properties/${pid}/funds_transfer`,
  JSON.stringify(data)
);
export const putFundTransferN = ({ pid, ftid, data }) => http.put(
  `v1/properties/${pid}/funds_transfer/${ftid}`,
  JSON.stringify(data)
);

export const getFundTransferN = ({ pid, aid }) => http.get(
  `v1/properties/${pid}/actives/${aid}/funds_transfer`
);

export const getFundTransferAccessN = ({ pid, aid, type }) => http.get(
  `v1/properties/${pid}/actives/${aid}/funds_transfer/${type}`
);

export const getFundIcomeN = ({ pid, aid }) => http.get(
  `v1/properties/${pid}/actives/${aid}/funds_icome`
);

export const setFundN = ({ data }) => http.post(
  `v2/funds`,
  JSON.stringify(data)
);

export const getFundsN = ({ pid, aid }) => http.get(
  // `v1/properties/${pid}/actives/${aid}/funds`
  `/v2/properties/by/id/${ pid }/actives/${ aid }/funds`
);

export const getNeighborPaymentsN = ({ did }) => http.get(
  `v2/payments/by/dwelling/${did}/neighbor-payments`
);

export const putNeighborPaymentsN = ({ did, fid, data }) => http.put(
  `/v2/payments/by/dwelling/${did}/neighbor-payments/${fid}`,
  JSON.stringify(data)
);

export const putFundsN = ({ fid, data }) => http.put(
  `v2/funds/by/id/${ fid }`,
  JSON.stringify(data)
);

export const putVariableFundN = ({ did, fid, data }) => http.put(
  `v2/payments/by/dwelling/${did}/funds/${ fid }`,
  JSON.stringify(data)
);

export const deleteFundsN = ({ pid, aid, fid }) => http.delete(
  `v1/properties/${pid}/actives/${aid}/funds/${fid}`
);

export const getAccessN = () => http.get(
  `v1/access`
);

export const setAccessN = ({ pid, data }) => http.post(
  `v1/properties/${pid}/access`,
  JSON.stringify(data)
);

export const putAccessN = ({ pid, data }) => http.put(
  `v1/properties/${pid}/access`,
  JSON.stringify(data)
);

export const deleteAccessN = ({ pid, cid }) => http.delete(
  `v1/properties/${pid}/clients/${cid}/access`
);

export const getReportN = ({ pid, aid,  data }) => http.get(
  `v1/properties/${pid}/actives/${aid}/report`,
  JSON.stringify(data)
);

export const getResidentReportURL = ( pid, data) => {
  window.open(`${ environments.apiUrl }v2/properties/by/id/${ pid }/report-residents?initial_date=${ data.initial_date }&final_date=${ data.final_date }`, "_self");
};

export const getActiveReportURL = (pid, data) => {
  window.open(`${ environments.apiUrl }v2/properties/by/id/${ pid }/report-actives?initial_date=${ data.initial_date }&final_date=${ data.final_date }`, "_self");
};

export const adjustBalanceN = (active, data) => {

  const formData = new FormData();

  Object.keys(data).forEach(key => data[key] && formData.append(key, data[key]));

  return http.post(
    `/v2/actives/by/id/${ active }/adjustment-balance`,
    formData,
    {
      headers: {'Content-Type': 'multipart/form-data' }
    }
  )
}