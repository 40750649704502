import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import * as Actions from '../../Property/propertyActions';

import Button from 'components/Button';
import AmenityListItem from './AmenityListItem';
import ModalAmenity from './ModalAmenity';
import './AmenityList.scss';

class AmenityList extends Component {
  constructor() {
    super();
    this.state={
      showModalAmenity: false,
      itemToDelete: 0,
    }
  }

  handleAmenityEdit = () => {
		const { behaviorScreen } = this.props.propertyState;
		this.props.addPropertyData({
			behaviorScreen: { ...behaviorScreen, editAmenities: !behaviorScreen.editAmenities }
    });
  }

  handleBindClose = () => {
    const { property } = this.props.params;
    const { behaviorScreen } = this.props.propertyState;
    this.props.addPropertyData({
      behaviorScreen: {
        ...behaviorScreen,
        bindOptions: false,
        bindName: null,
        bindWho: null,
        bindItems: [],
        editAmenities: false,
      }
    }).then(()=>{
      const { result } = this.props.propertyState.buildings;
      if(result === null){ return; }
      for (let i = 0; i < result.length; i += 1) {
        this.props.getAmenitiesAccess({
          idProperty: property,
          idBuilding: result[i].id
        });
      }
    });
  }

  onSubmit = (event) => {
    event.preventDefault();
    const { property } = this.props.params;
    const { behaviorScreen } = this.props.propertyState;

    this.props.putAmenitiesAccess({
      idProperty: property,
      idBuilding: behaviorScreen.bindWho,
      dataAccess: { access: behaviorScreen.bindItems }
    }).then(()=>{
      this.props.addPropertyData({
        behaviorScreen: {
          ...this.props.propertyState.behaviorScreen,
          bindOptions: false,
          bindWho: null,
        }
      }).then(()=>{
        const { result } = this.props.propertyState.buildings;
        if(result === null){ return; }
        for (let i = 0; i < result.length; i += 1) {
          this.props.getAmenitiesAccess({
            idProperty: property,
            idBuilding: result[i].id
          });
        }
        this.props.addPropertyData({
          behaviorScreen: {
            ...this.props.propertyState.behaviorScreen,
            bindOptions: false,
            bindName: null,
            bindWho: null,
            bindItems: [],
            editAmenities: false,
          }
        });
      });
    });
  }

  handleChecked = (event) => {
    const { behaviorScreen } = this.props.propertyState;
    let { bindItems } = behaviorScreen;
    if(event.target.checked){
      bindItems.push(parseInt(event.target.value));
      this.props.addPropertyData({
        behaviorScreen: { ...behaviorScreen, bindItems: bindItems.sort() }
      });
      return;
    }
    if(!event.target.checked){
      const index = bindItems.indexOf(parseInt(event.target.value));
      bindItems.splice(index, 1);
      this.props.addPropertyData({
        behaviorScreen: { ...behaviorScreen, bindItems: bindItems.sort() }
      });
      return;
    }
  }

  goToEditAmenity = (amenityId) => {
		const { id, property } = this.props.params;
		this.props.getAmenities({
			idProperty: property
		});
		this.context.router.push(`/Wizard/${id}/property/${property}/Amenity/${ amenityId }`);
	}

  openModal = (amenityID) => () => {
    this.setState({ showModalAmenity: true, itemToDelete: amenityID });
  }

  closeModal = () => {
    this.setState({ showModalAmenity: false, itemToDelete: 0 });
  }

  deleteAmenity = () => {
    const { itemToDelete } = this.state;
    const { params } = this.props;
    this.props.deleteAmenity({
      idProperty: params.property,
      idAmenity: itemToDelete
    }).then(()=>{
      const { result } = this.props.propertyState.buildings;
      if(!result){ return; }
      for (let i = 0; i < result.length; i += 1) {
        this.props.getAmenitiesAccess({
          idProperty: params.property,
          idBuilding: result[i].id
        });
      }
      this.props.getAmenities({
        idProperty: params.property
      });
      this.closeModal();
    });
  }

  render() {
    const { params, propertyState } = this.props;
    const { amenities } = propertyState;
    const { bindOptions, bindItems, editAmenities } = this.props.propertyState.behaviorScreen;
    const { showModalAmenity } = this.state;
    return (
      <div className='AmenityList'>
        <ModalAmenity
          open={showModalAmenity}
          onClose={this.closeModal}
          onDelete={this.deleteAmenity}
        />
        <div className='AmenityList__items'>
          {
            amenities ? amenities.map((item,index) => 
              <AmenityListItem
                key={ index }
                {...item}
                params={params}
                bindOptions={bindOptions}
                editAmenities={editAmenities}
                goTo={this.goToEditAmenity}
                handleChecked={this.handleChecked}
                deleteItem={this.openModal}
                checked={bindItems.indexOf(item.amenity_id) !== -1}
              />
            ) : <div className='AmenityList__items__default'>Aún no tienes áreas comunes </div>
          }
        </div>
        {
          bindOptions ?
          <div className='AmenityList__buttons'>
            <div className='AmenityList__col2'>
              <Button variant='outlined' color='secondary' className='AmenityList__col2__cancel' onClick={this.handleBindClose}>CANCELAR</Button>
            </div>
            <div className='AmenityList__col2'>
              <Button type='button' variant='contained' color='secondary' className='AmenityList__col2__save' onClick={this.onSubmit}>GUARDAR</Button>
            </div>
          </div>
          : ''
        }
      </div>
    );
  }
}


AmenityList.contextTypes = {
  router: PropTypes.shape()
};

AmenityList.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  propertyState: state.get('propertyState')
});

export default connect(mapStateToProps, mapDispatchToProps)(AmenityList);
