import React, { Component } from 'react';
import ColCard from 'components/ColCard';
import { toMoneyFormat } from 'utils/format';
import Progress from 'antd/lib/progress';

import './SectionExpenditure.scss';

class SectionExpenditure extends Component {
  state = {
    expanded: false
  };
  toggle() {
    this.setState({
      expanded: !this.state.expanded
    })
  }
  render() {
    const currentMonth = new Date();
    const options = { year: 'numeric', month: 'long' };
    let { paid, pending_toPay, estimated_budget } = this.props;

    paid = paid || 0;
    pending_toPay = pending_toPay || 0;
    estimated_budget = estimated_budget || 0;
    
    const long = ((paid * 100) / estimated_budget) || 0;

    return (
      <div className='SectionExpenditure col'>
        <ColCard
          title={ `Gasto mensual presupuestado ${ currentMonth.toLocaleDateString('es-ES', options) }` }
          columns={[
            {
              label: 'Gasto presupuestado',
              value: `$ ${ toMoneyFormat(estimated_budget) }`
            }, {
              view: (
                <div className="graph row">
                  <div className="labels col">
                    <div className='row-flex-start'>
                      <label className="row align-center subtitle-2">
                        <div className="CirclePay"></div>
                        Pagado
                      </label>
                      <span className="heading-3">$ {toMoneyFormat(paid)}</span>
                    </div>
                    <br />
                    <div  className='row-flex-start'>
                      <label className="row align-center subtitle-2">
                        <div className="CirclePay2"></div>
                        Por pagar
                      </label>
                      <span className="heading-3">$ {toMoneyFormat(pending_toPay)}</span>
                    </div>
                  </div>
                  <div className='col row-centered align-center'>
                    <Progress
                      percent={parseInt(long.toFixed(0)) }
                      type="circle"
                      size="big"
                      strokeColor={{
                        "0%": "#84e7d5",
                      }}
                    />
                  </div>
                </div>
              )
            }
          ]}
        >
        </ColCard>
      </div>
    );
  }
}

export default SectionExpenditure;
