import http from 'constants/config';

export const setInvitationsN = ({ idProperty }) => http.put(
  `v1/properties/${idProperty}/clients/invitations`,
  JSON.stringify({})
);

export const downloadFileN = () => http.get(
  'v1/importer/format',
  {
    responseType: 'blob',
  }
);

export const uploadFileN = ({ idProperty, data }) => http.post(
  `v1/properties/${idProperty}/bulkload`,
  JSON.stringify(data)
);

// ******************************** PROPERTY ******************************** //

export const getPropertiesN = () => http.get('v1/properties');

export const getPropertyN = ({ property }) => http.get(`v1/properties/${property}`);

export const setPropertyN = ({ property }) => http.post(
  'v1/properties',
  JSON.stringify(property)
);

export const putPropertyN = ({ idProperty, property }) => http.put(
  `v1/properties/${idProperty}`,
  JSON.stringify(property)
);

export const delPropertyN = ({ property }) => http.delete(`v1/properties/${property}`);

// ******************************** BUILDING ******************************** //

export const getBuildingsN = ({ idProperty }) => http.get(`v1/properties/${idProperty}/buildings`);

export const getBuildingN = ({ idProperty, idBuilding }) => http.get(`v1/properties/${idProperty}/buildings/${idBuilding}`);

export const setBuildingN = ({ idProperty, buildingData }) => http.post(
  `v1/properties/${idProperty}/buildings`,
  JSON.stringify(buildingData)
);

export const putBuildingN = ({ idProperty, idBuilding, buildingData }) => http.put(
  `v1/properties/${idProperty}/buildings/${idBuilding}`,
  JSON.stringify(buildingData)
);

export const delBuildingN = ({ idProperty, idBuilding }) => http.delete(`v1/properties/${idProperty}/buildings/${idBuilding}`);

// ******************************** DWELLING ******************************** //

export const getDwellingsN = ({ idProperty, idBuilding }) => http.get(`v1/properties/${idProperty}/buildings/${idBuilding}/dwellings`);

export const setDwellingsN = ({ idProperty, idBuilding, dwellingsData }) => http.post(
  `v1/properties/${idProperty}/buildings/${idBuilding}/dwellings`,
  JSON.stringify(dwellingsData)
);

export const putDwellingN = ({ idProperty, idBuilding, dwellingData }) => http.put(
  `v1/properties/${idProperty}/buildings/${idBuilding}/dwellings`,
  JSON.stringify(dwellingData)
);

export const delDwellingsN = ({
  idProperty, idBuilding, idDwelling, idResident
}) => http.delete(`v1/properties/${idProperty}/buildings/${idBuilding}/dwellings/${idDwelling}/residents/${idResident}`);


// ******************************** AMENITY ******************************** //

export const getCatalogAmenitiesN = () => http.get('v1/catalogs/amenities');

export const setAmenityN = ({ propertyId, amenity }) => http.post(
  `v1/properties/${propertyId}/amenities`,
  JSON.stringify(amenity)
);

export const getAmenityN = ({ idProperty, idAmenity }) => http.get(`v1/properties/${idProperty}/amenities/${idAmenity}`);

export const putAmenityN = ({ idProperty, idAmenity, amenityData }) => http.put(
  `v1/properties/${idProperty}/amenities/${idAmenity}`,
  JSON.stringify(amenityData)
);

export const deleteAmenityN = ({ idAmenity }) => http.delete(`v2/amenities/${idAmenity}`);

export const getAmenitiesN = ({ idProperty }) => http.get(`v2/amenities`, {params: {property_id: idProperty}});

export const getBookingsN = params => http.get(`V2/bookings`, { params });

export const rejectBookingN = ({ id }) => http.patch(`V2/bookings/${ id }/reject`);

export const getAmenitiesAccessN = ({ idProperty, idBuilding }) => http.get(`v1/properties/${idProperty}/buildings/${idBuilding}/amenitiesaccess`);

export const putAmenitiesAccessN = ({ idProperty, idBuilding, dataAccess }) => http.put(
  `v1/properties/${idProperty}/buildings/${idBuilding}/amenitiesaccess`,
  JSON.stringify(dataAccess)
);

export const uploadRegulationN = ({ idProperty, data }) => http.post(
  `v1/properties/${idProperty}/regulations`,
  data
);

export const createAnAmenitiesN = ({ data }) => http.post(
  'v1/amenities',
  JSON.stringify(data)
);

export const createAnAmenitiesBulkN = ({ data }) => http.post(
  'v2/amenities-bulk',
  JSON.stringify({ data })
);

export const updateAmenitiesBulkN = ({ data }) => http.put(
  'v2/amenities-bulk',
  JSON.stringify({ data })
);

export const getRegulationsN = ({ idProperty, tipe }) => http.get(`v1/properties/${idProperty}/regulations?type=${tipe}`);

export const getFounds = ({ pid, bid }) => http.get(`v1/properties/${pid}/buildings/${bid}/extrafee`);

export const setPaymentResidentN = ({ did, data }) => {

  const formData = new FormData();

  Object.keys(data).forEach(key => {
    if (typeof data[key] === 'boolean') {
      formData.append(key, String(data[key]))
    } else {
      data[key] && formData.append(key, data[key])
    }
  });

  return http.post(
    `v2/payments/by/dwelling/${ did }`,
    formData,
    {
      headers: {'Content-Type': 'multipart/form-data' }
    }
  );
}

export const setSurchargeN = ({ did, data }) => {
  
  const formData = new FormData();

  Object.keys(data).forEach(key => data[key] && formData.append(key, data[key]));

  return http.post(
    `v2/payments/by/dwelling/${ did }/surcharge`,
    formData,
    {
      headers: {'Content-Type': 'multipart/form-data' }
    }
  );
}

export const setDiscountN = ({ did, data }) => {
  
  const formData = new FormData();

  Object.keys(data).forEach(key => data[key] && formData.append(key, data[key]));

  return http.post(
    `v2/payments/by/dwelling/${ did }/discount`,
    formData,
    {
      headers: {'Content-Type': 'multipart/form-data' }
    }
  );
}