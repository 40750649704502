import React, { Component } from 'react';

import BudgetTabItem from './BudgetTabItem';

import './BudgetTab.scss';

class BudgetTab extends Component {
  render() {
    const { items, value, onChange } = this.props;
    return (
      <div className='BudgetTab'>
        {
          items.map(section => (
            <div className='BudgetTabSection' key={section.title}>
              <div className='BudgetTabSection__title'>
                <div className='BudgetTabSection__title__title'>{section.title}</div>
              </div>
              <div className='BudgetTabSection__tabs'>
                {
                  section.content.map(item => (
                    <BudgetTabItem
                      key={`BudgetTabItem${section.title}${item.value}`}
                      {...item}
                      active={item.value === value}
                      onChange={onChange(item.value)}
                    />
                  ))
                }
              </div>
            </div>
          ))
        }
      </div>
    );
  }
}

export default BudgetTab;
