import React, { Component } from 'react';

import './HistoryHeader.scss';

class HistoryHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div className='HistoryHeader'>
        <div className='HistoryHeader__col'>Fecha de pago</div>
        <div className='HistoryHeader__col'>Pago</div>
        <div className='HistoryHeader__col'>Tipo de pago</div>
        <div className='HistoryHeader__img'>Recibo de Pago</div>
      </div>
    );
  }
}

export default HistoryHeader;
