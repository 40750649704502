import React, { Component } from 'react';
import { ReactSVG } from 'react-svg';
import { FormattedMessage } from 'react-intl';

import Mail from 'assets/img/icon-email2.svg';
import Smartphone from 'assets/img/icon-smartphone.svg';
import Telephone from 'assets/img/icon-telephone.svg';
import People from 'assets/img/icon-people.svg';

import './RelationItem.scss';

class RelationItem extends Component {
  render() {
    const { name, relationship, phone_home, phone_cel, email, onEdit } = this.props;
    return (
      <div className='RelationItem'>
        <div className='RelationItem__image'>
          <img src={People} alt='Neighbor' />
        </div>
        <div className='RelationItem__data'>
          <div className='RelationItem__data__name'>
            <div className='RelationItem__data__name__name'>
              <label>{name}</label>
              {relationship ? <span>- {relationship}</span> : null}
            </div>
            <div className='RelationItem__data__name__edit' onClick={onEdit}>
              editar
            </div>
          </div>
          <div className='RelationItem__data__info'>
            {
              phone_cel ?
              <div className='RelationItem__data__info__date'>
                <div className='RelationItem__data__info__date__icon'>
                  <ReactSVG src={Smartphone} />
                </div>
                <div className='RelationItem__data__info__date__info'>
                  <label><FormattedMessage id='NewContact.label_cell_phone' /></label>
                  <span>{phone_cel}</span>
                </div>
              </div>
              :null
            }
           {
             phone_home ?
             <div className='RelationItem__data__info__date'>
                <div className='RelationItem__data__info__date__icon'>
                  <ReactSVG src={Telephone} />
                </div>
                <div className='RelationItem__data__info__date__info'>
                  <label><FormattedMessage id='NewContact.label_phone' /></label>
                  <span>{phone_home}</span>
                </div>
              </div>
              :null
            }
            {
              email ?
              <div className='RelationItem__data__info__date'>
                <div className='RelationItem__data__info__date__icon'>
                  <ReactSVG src={Mail} />
                </div>
                <div className='RelationItem__data__info__date__info'>
                  <label><FormattedMessage id='NewContact.label_email'/></label>
                  <span>{email}</span>
                </div>
              </div>
              : null
            }
          </div>
        </div>
      </div>
    );
  }
}

export default RelationItem;
