import tower from 'assets/img/amenities/011-office-building-copy.svg';
import towerSmall from 'assets/logos/icon_mini_edificio.svg';

import home from 'assets/img/amenities/house-icon.svg';
import homeSmall from 'assets/logos/icon_mini_casas.svg';

import laundry from 'assets/img/amenities/lavanderia.svg';
import laundryLine from 'assets/img/amenities/laundry-line.svg';
import laundrySmall from 'assets/img/amenities/laundry_small.svg';

import pool from 'assets/img/amenities/alberca.svg';
import poolLine from 'assets/img/amenities/pool-line.svg';
import poolSmall from 'assets/img/amenities/pool_small.svg';

import games from 'assets/img/amenities/ludoteca.svg';
import gamesLine from 'assets/img/amenities/games-line.svg';
import gamesSmall from 'assets/img/amenities/games_small.svg';

import gym from 'assets/img/amenities/gym.svg';
import gymLine from 'assets/img/amenities/gym-line.svg';
import gymSmall from 'assets/img/amenities/gym_small.svg';

import playground from 'assets/img/amenities/pelota.svg';
import playgroundLine from 'assets/img/amenities/playground-line.svg';
import playgroundSmall from 'assets/img/amenities/ball_small.svg';

import green from 'assets/img/amenities/arbol.svg';
import greenLine from 'assets/img/amenities/green-line.svg';
import greenSmall from 'assets/img/amenities/small_tree.svg';

import business from 'assets/img/amenities/whiteboard.svg';
import businessLine from 'assets/img/amenities/business-line.svg';
import businessSmall from 'assets/img/amenities/room_busi_small.svg';

import surveillance from 'assets/img/amenities/policeman.svg';
import surveillanceLine from 'assets/img/amenities/surveillance_line.svg';
import surveillanceSmall from 'assets/img/amenities/surve_small.svg';

import room from 'assets/img/amenities/room.svg';
import roomLine from 'assets/img/amenities/room-line.svg';
import roomSmall from 'assets/img/amenities/room_small.svg';

import lighting from 'assets/img/amenities/alumbrado.svg';
import lightingLine from 'assets/img/amenities/lighting-line.svg';
import lightingSmall from 'assets/img/amenities/light_small.svg';

import elevator from 'assets/img/amenities/lift.svg';
import elevatorLine from 'assets/img/amenities/elevator-line.svg';
import elevatorSmall from 'assets/img/amenities/elevator_small.svg'

import tennis from 'assets/img/amenities/ping-pong.svg';
import tennisLine from 'assets/img/amenities/tennis-line.svg';
import tennisSmall from 'assets/img/amenities/tenis_small.svg';

import roofgarden from 'assets/img/amenities/chairs.svg';
import roofgardenLine from 'assets/img/amenities/roofgarden-line.svg';
import roofgardenSmall from 'assets/img/amenities/roof_small.svg';

import parking from 'assets/img/amenities/estacionamiento.svg';
import parkingLine from 'assets/img/amenities/parking-line.svg';
import parkingSmall from 'assets/img/amenities/parking_small.svg';

import coffee from 'assets/img/amenities/coffee.svg';
import coffeeLine from 'assets/img/amenities/coffee-line.svg';

import restaurant from 'assets/img/amenities/restaurant.svg';
import restaurantLine from 'assets/img/amenities/restaurant-line.svg';

import other from 'assets/img/amenities/star.svg';
import otherLine from 'assets/img/amenities/other-line.svg';
import otherSmall from 'assets/img/amenities/other_small.svg';

import access from 'assets/img/amenities/acceso.svg';
import accessLine from 'assets/img/amenities/acceos.svg';
import accessSmall from 'assets/img/amenities/small_access.svg';

import water from 'assets/img/amenities/water.svg';
import waterLine from 'assets/img/amenities/water-line.svg';

import tools from 'assets/img/amenities/tools.svg';
import toolsLine from 'assets/img/amenities/tools-line.svg';

import coinFund from 'assets/img/amenities/coin-fondo.svg';
//* icons sub menu
import poolSubMenu from 'assets/img/amenities/icons-submenu/icon_mini_alberca_resting.svg';
import laundrySubMenu from 'assets/img/amenities/icons-submenu/icon_mini_lavanderia_resting.svg';
import gameSubMenu from 'assets/img/amenities/icons-submenu/icon_mini_ludoteca_resting.svg';
import playgroundSubMenu from 'assets/img/amenities/icons-submenu/icon_mini_basket_resting.svg';
import greenSubMenu from 'assets/img/amenities/icons-submenu/icon_mini_areaverde_resting.svg';
import businessSubMenu from 'assets/img/amenities/icons-submenu/icon_mini_negocios_resting.svg';
import accessSubMenu from 'assets/img/amenities/icons-submenu/icon_mini_acceso_resting.svg';
import roomSubMenu from 'assets/img/amenities/icons-submenu/icon_mini_salon_resting.svg';
import lightingSubMenu from 'assets/img/amenities/icons-submenu/icon_mini_alumbrado_resting.svg';
import surveillanceSubMenu from 'assets/img/amenities/icons-submenu/icon_mini_vigilancia_resting.svg';
import elevatorSubMenu from 'assets/img/amenities/icons-submenu/icon_mini_elevador_resting.svg';
import tennisSubMenu from 'assets/img/amenities/icons-submenu/icon_mini_tennis_resting.svg';
import roofGardenSubMenu from 'assets/img/amenities/icons-submenu/icon_mini_roofgarden_resting.svg';
import parkingSubMenu from 'assets/img/amenities/icons-submenu/icon_mini_estacionamiento_resting.svg';
import restaurantSubMenu from 'assets/img/amenities/icons-submenu/icon_mini_restaurante_resting.svg';
import waterSubMenu from 'assets/img/amenities/icons-submenu/icon_mini_agua_resting.svg';
import otherSubMenu from 'assets/img/amenities/icons-submenu/icon_mini_otros_resting.svg';
import towerSubMenu from 'assets/img/amenities/icons-submenu/icon_mini_edificio_resting.svg';
import houseSubMenu from 'assets/img/amenities/icons-submenu/icon_mini_casas_resting.svg';
import toolsSubMenu from 'assets/img/amenities/icons-submenu/icon_mini_mantenimiento_resting.svg';
import coinSubMenu from 'assets/img/amenities/icons-submenu/icon_mini_fondos_resting.svg';
import gymSubMenu from 'assets/img/amenities/icons-submenu/icon_mini_gym_resting.svg';

export const showIconAmenity = (type) => {
  switch (type) {
    case 1: case '1': case 'LAVANDERIA':
      return {
        img: laundry,
        img_line: laundryLine,
        img_small: laundrySmall,
        img_sub_menu: laundrySubMenu,
        name: 'Lavandería'
      };
    case 2: case '2': case 'ALBERCA':
      return {
        img: pool,
        img_line: poolLine,
        img_small: poolSmall,
        img_sub_menu: poolSubMenu,
        name: 'Piscina'
      };
    case 3: case '3':
      return {
        img: games,
        img_line: gamesLine,
        img_small: gamesSmall,
        img_sub_menu: gameSubMenu,
        name: 'Area de juegos'
      };
    case 4: case '4': case 'GYM':
      return {
        img: gym,
        img_line: gymLine,
        img_small: gymSmall,
        img_sub_menu: gymSubMenu,
        name: 'Gimnasio'
      };
    case 5: case '5': case 'CANCHA':
      return {
        img: playground,
        img_line: playgroundLine,
        img_small: playgroundSmall,
        img_sub_menu: playgroundSubMenu,
        name: 'Cancha de basket'
      };
    case 6: case '6': case 'AREA VERDE':
      return {
        img: green,
        img_line: greenLine,
        img_small: greenSmall,
        img_sub_menu: greenSubMenu,
        name: 'Área verde'
      };
    case 7: case '7': case 'SALA DE NEGOCIOS':
      return {
        img: business,
        img_line: businessLine,
        img_small: businessSmall,
        img_sub_menu: businessSubMenu,
        name: 'Sala de negocios'
      };
    case 8: case '8': case 'ACCESO':
      return {
        img: access,
        img_line: accessLine,
        img_small: accessSmall,
        img_sub_menu: accessSubMenu,
        name: 'Acceso'
      };
    case 9: case '9': case 'SALON':
      return {
        img: room,
        img_line: roomLine,
        img_small: roomSmall,
        img_sub_menu: roomSubMenu,
        name: 'Salón de eventos',
      };
    case 10: case '10': case 'ALUMBRADO':
      return {
        img: lighting,
        img_line: lightingLine,
        img_small: lightingSmall,
        img_sub_menu: lightingSubMenu,
        name: 'Alumbrado'
      };
    case 11: case '11': case 'VIGILANCIA':
      return {
        img: surveillance,
        img_line: surveillanceLine,
        img_small: surveillanceSmall,
        img_sub_menu: surveillanceSubMenu,
        name: 'Vigilancia'
      };
    case 12: case '12': case 'ELEVADOR':
      return {
        img: elevator,
        img_line: elevatorLine,
        img_small: elevatorSmall,
        img_sub_menu: elevatorSubMenu,
        name: 'Elevador'
      };
    case 13: case '13': case 'CANCHA DE TENIS':
      return {
        img: tennis,
        img_line: tennisLine,
        img_small: tennisSmall,
        img_sub_menu: tennisSubMenu,
        name: 'Cancha de tenis'
      };
    case 14: case '14': case 'ROOF GARDEN':
      return {
        img: roofgarden,
        img_line: roofgardenLine,
        img_small: roofgardenSmall,
        img_sub_menu: roofGardenSubMenu,
        name: 'Roof Garden'
      };
    case 16: case '16': case 'ESTACIONAMIENTO':
      return {
        img: parking,
        img_line: parkingLine,
        img_small: parkingSmall,
        img_sub_menu: parkingSubMenu,
        name: 'Estacionamiento'
      };
    case 17: case '17':
      return {
        img: coffee,
        img_line: coffeeLine,
        img_small: coffeeLine,
        img_sub_menu: coffeeLine,
        name: 'Cafeteria'
      };
    case 18: case '18':
      return {
        img: restaurant,
        img_line: restaurantLine,
        img_small: restaurantLine,
        img_sub_menu: restaurantSubMenu,
        name: 'Restaurante'
      };
      case 19: case '19':
      return {
        img: water,
        img_line: waterLine,
        img_small: waterLine,
        img_sub_menu: waterSubMenu,
        name: 'Restaurante'
      };
    case 99: case '99':
      return {
        img: other,
        img_line: otherLine,
        img_small: otherSmall,
        img_sub_menu: otherSubMenu,
        name: 'Otro'
      };
    case 'tower':
      return {
        img: tower,
        img_line: tower,
        img_small: towerSmall,
        img_sub_menu: towerSubMenu,
        name: 'Edificio'
      };
    case 'home':
      return {
        img: home,
        img_line: home,
        img_small: homeSmall,
        img_sub_menu: houseSubMenu,
        name: 'Casa'
      };
    case 'tools':
      return {
        img: tools,
        img_line: toolsLine,
        img_sub_menu: toolsSubMenu,
        name: 'Mantenimiento general'
      };
    case'fund':
      return{
        img: coinFund,
        img_line: coinFund,
        img_sub_menu: coinSubMenu,
        name: 'fondo'
      };
    default:
      return {
        img: other,
        img_line: otherLine,
        img_sub_menu: otherSubMenu,
        name: 'Otro'
      };
  }
};
