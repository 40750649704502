import http from 'constants/config';

// ********************* CONFIGURATION ********************* //

export const getProfileN = () => http.get('v1/clients/admin/profile');

export const setReportAdminN = ({ data }) => http.post(
  'v1/reports/super-admin',
  JSON.stringify(data)
);

export const putProfileN = ({ profileData }) => http.put(
  'v1/clients/admin/profile',
  JSON.stringify(profileData)
);
