import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";

import * as Actions from "../../../../Profile/ProfileActions";
import * as BudgetActions from "../../../../Budget/budgetActions";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

import Button from "components/Button";
import Icon from "components/Icon";

import "./ItemProperties.scss";
import { ReactSVG } from "react-svg";
import { showIconAmenity } from "constants/amenityTypes";

export class ItemProperties extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null
    };
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleChange = property => () => {
    this.props
      .putProfile({
        profileData: { resident_last_view: parseInt(property) }
      })
      .then(() => window.location.reload());

    this.setState({ anchorEl: null });
    
    this.context.router.push(`/property/${property}/dashboard`);
  };

  handleCreateProperty = () => {
    const { properties } = this.props.propertyState;
    const items = Object.values(properties);
    const long = items.length;
    if (long > 0) {
      this.context.router.push(`/Wizard/11/property`);
      return;
    }
    this.context.router.push(`/Wizard/info/property`);
  };

  handleLogOut = () => {
    localStorage.clear();
    this.context.router.push('/login');
  };

  handlerName = (width, name, div) => {
    name = name || '';
    return name.length >= width / div
      ? name.substring(0, width / div) + " ..."
      : name;
  };

  render() {
    const { anchorEl } = this.state;
    const { properties } = this.props.propertyState;
    const { resident_last_view, resident_role } = this.props.profileState;
    const items = Object.values(properties);
    const filter = items.filter(item => item.id === resident_last_view);
    const long = items.length;
    const width = window.innerWidth;
    return (
      <div className="ItemProperties">
        <Button onClick={this.handleClick}>
          <div className="ItemProperties__button">
            <div className="icon-container">
              <ReactSVG
                src={showIconAmenity("tower").img}
              />
            </div>
            <div className="ItemProperties__button__label">
              {filter && filter[0]
                ? this.handlerName(width, filter[0].name, 20)
                : "No seleccionada"}
            </div>
            <Icon icon="keyboard_arrow_down" color="secondary" />
          </div>
        </Button>
        <Menu
          id="render-props-menu"
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={this.handleClose}
        >
          <div className="ItemProperties__title">Propiedades</div>
          {long > 0
            ? items.map(item => {
                return (
                  <MenuItem
                    key={`MenuItem${item.id}`}
                    onClick={this.handleChange(item.id)}
                    selected={ item.id === resident_last_view }
                  >
                    <ListItemIcon>
                      <ReactSVG
                        className="ItemProperties__item__svg"
                        src={showIconAmenity("tower").img}
                        svgStyle={{ width: 30 }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      className="ItemProperties__item__list"
                      inset
                      primary={this.handlerName(width, item.name, 17)}
                      inset
                    />
                  </MenuItem>
                );
              })
            : null}
          <Divider />
          {
            resident_role !== 3 ?
            <MenuItem key="uniq1" onClick={this.handleCreateProperty}>
              Nueva propiedad
            </MenuItem> :null
          }
          <MenuItem key="uniq2" onClick={this.handleLogOut}>
            Cerrar sesión
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

ItemProperties.contextTypes = {
  router: PropTypes.shape()
};
ItemProperties.propTypes = {};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...Actions, ...BudgetActions }, dispatch);
const mapStateToProps = state => ({
  loginState: state.get("loginState"),
  profileState: state.get("profileState"),
  budgetState: state.get("budgetState"),
  propertyState: state.get("propertyState")
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemProperties);
