import React, { Component } from 'react';
import { ReactSVG } from 'react-svg';
import Proptypes from 'prop-types';

import SimpleModal from 'components/SimpleModal';
import Button from 'components/Button';
import Circle from 'assets/logos/Ok.svg'
import './ModalTransferSuccess.scss';

export class ModalTransferSuccess extends Component {
    render() {
        const {openModSuc,  closeModSuc } =  this.props;
        
        return (
            <>
                    <SimpleModal title='Transferir saldos entre cuentas' open={openModSuc} onClose={closeModSuc}>
                        <div className="ModalTransferSuccess">
                            <div className="ModalTransferSuccess__circle" >
                            <ReactSVG src={Circle} alt='Success' />
                            </div>
                            <div className="ModalTransferSuccess__transf" >La transferencia se ha hecho correctamente</div>
                            <div className="ModalTransferSuccess__button-paymen">
                                    <div className="ModalTransferSuccess__button-paymen__botton-save" onClick={closeModSuc}>
                                        <Button type="submit" variant="contained" color="secondary">
                                            Aceptar
                                        </Button>
                                    </div>
                            </div>
                        </div>
                    </SimpleModal>
                
            </>
        );
    }
}

ModalTransferSuccess.propTypes = { 
    openModSuc: Proptypes.bool,
    closeModSuc: Proptypes.func
}

export default ModalTransferSuccess;



