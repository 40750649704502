import React from 'react';
import Card from '../Card';
import Button from '../Button';
import './ColCard.scss';

export const ColCard = props => {

  const {
    title,
    columns,
    children,
    preappend
  } = props;

  return (
    <div className="ColCard col">
      <Card className='col'>
        { !!title && <h4>{ title }</h4> }
        { preappend && children }
        <div className="sections row space-between align-center">
          { columns.map((column, key) => (
            <div className="col" key={ key }>
              { column.view }
              { column.label && <label className="subtitle-2">{ column.label }</label> }
              { column.value && <span className="heading-3">{ column.value }</span> }
              { column.action && (
                <Button
                  className="subtitle-2"
                  color="secondary"
                  onClick={ column.action }
                >
                  { column.actionLabel }
                </Button>
              )}
            </div>
          ))}
        </div>
        { !preappend && children }
      </Card>
    </div>
  );
};

export default ColCard;
