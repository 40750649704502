import React, { useState } from 'react';
import MaterialTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import Row from './Row';

import './Table.scss';

const Table = props => {

  const [ toggledRow, setToggledRow ] = useState();

  let { columns, rows, loading, tableProps } = props;

  columns = columns || [];

  rows = (Array.isArray(rows) ? rows : Object.values(rows || { }));

  return (
      <div className='Table'>
        <TableContainer>
          <MaterialTable { ...tableProps }>
            <TableHead>
              <TableRow>
                  {columns.map((column, columnKey) => (
                    <TableCell
                      { ...column.cellProps }
                      alt={ `${ String(column.field || column.headerName || column.button || '').toLocaleLowerCase() }-head` }
                      key={ columnKey }
                    >{ column.headerName }</TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              { loading && (
                <TableRow>
                  <TableCell className='loading-container' colSpan={ (columns || []).length }>
                    <LinearProgress color='secondary'/>
                  </TableCell>
                </TableRow>
              )}
              { (rows || []).map((rowValue, rowIndex) => (
                  <Row
                    key={ rowIndex }
                    { ...props }
                    index={ rowIndex }
                    toggledRow={ toggledRow }
                    onToggle={ setToggledRow }
                    value={ rowValue }
                  />
                )
              )}
            </TableBody>
          </MaterialTable>
        </TableContainer>
      </div>
  );
}

export default Table;
