import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { showIconAmenity } from 'constants/amenityTypes';
import { toMoneyFormat } from '../../../../utils/format';
import './ActiveCard.scss';

export class ActiveCard extends Component {
  render() {
    const { iconType, name, initial_balance, formatMini } = this.props;
    const theClassName = `ActiveCard${formatMini ? 'Mini' : ''}`;
    return (
      <div className={theClassName}>
        <div className={`${theClassName}__row`}>
          <div className={`${theClassName}__row__image`}>
            <ReactSVG className={`${theClassName}__row__image__svg`} src={showIconAmenity(iconType).img} alt='Icon Type' />
          </div>
          <div className={`${theClassName}__row__text`}>
            <div className={`${theClassName}__row__text__title`}>
              {name}
            </div>
            <div className={`${theClassName}__row__text__data`}>
              <span>Saldo inicial: </span>
              <span className={`${theClassName}__row__text__data_balance`}> $ {toMoneyFormat(initial_balance)}</span>
            </div>            
          </div>
        </div>
      </div>
    );
  }
}

ActiveCard.propTypes = {
  iconType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  formatMini: PropTypes.bool.isRequired,
  initial_balance: PropTypes.number.isRequired,
};

export default ActiveCard;
