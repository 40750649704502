import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import { ReactSVG } from 'react-svg';

import IconEdit from 'assets/img/icon_edit.svg';

const styles = theme => ({
  button: {
    width: 30,
    height: 30,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const FieldEdit = (props) => {
  const { classes, onClick,disab } = props;
  return (
    <IconButton className={classes.button} onClick={onClick} disabled={disab}>
      <ReactSVG
        src={IconEdit}
        svgStyle={{ height: 20 }}
      />
    </IconButton>
  );
};

FieldEdit.propTypes = {
  classes: PropTypes.objectOf([
    PropTypes.object
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(FieldEdit);
