import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as Actions from '../../../../Budget/budgetActions';
import FieldProvider from './FieldProvider';
import FieldPeriodicity from './FieldPeriodicity';
import FieldDelete from './FieldDelete';
import { OutlinedInput } from '@material-ui/core';
import './ConceptItem.scss';

let waitFormName;

export class BudgetConceptItem extends Component {
  state = {
    name: ''
  };
  componentDidMount(){
    this.updateName();
  }

  componentDidUpdate(nextProps) {
    if(nextProps.concept.name_found !== this.props.concept.name_found) {
      this.updateName();
    }
  }

  updateName() {
    this.setState({
      name: this.props.concept.name_found || ''
    });
  }

  getBudgetsAndFees = (bid) => {
    this.props.getBudgetsAndFees({ bid })
  }

  handleOnChangeName = () => (e) =>  {
    const {target : { value }} = e;

    this.setState({ name: value });

    clearTimeout(waitFormName);

    waitFormName = setTimeout(() => {
      const { property } = this.props.params;
      const { activeSelected } = this.props.budgetState;
      const { id: fid } = this.props.concept;
  
      if(value) {
        this.props.putFunds({
          pid: property,
          aid: activeSelected,
          fid: fid,
          data: {
            type: 3,
            name_found: value,
            active: true,
          }
        }).then(this.updateTable.bind(this));
      }
    }, 2000);
  }

  updateTable = () => {
    const { property } = this.props.params;
    const { activeSelected } = this.props.budgetState;
    this.props.getFunds({
      pid: property,
      aid: activeSelected
    });
  }

  handleClickProvider = (event) => {
    const { id } = this.props.concept;
    const { property } = this.props.params;
    const { activeSelected } = this.props.budgetState;
    const { id: fid } = this.props.concept;
    
    this.props.putFunds({
      pid: property,
      aid: activeSelected,
      fid: fid,
      data: {
        id,
        type: 3,
        provider_id: Number(event.target.value),
        active: true,
      }
    }).then(this.updateTable.bind(this));
  }

  onChangePeriodicity = ({ target }) => {
    const { property } = this.props.params;
    const { activeSelected } = this.props.budgetState;
    const { id: fid } = this.props.concept;

    if(target.value){
      this.props.putFunds({
        pid: property,
        aid: activeSelected,
        fid: fid,
        data: {
          frequency: target.value,
          active: true,
        }
      }).then(this.updateTable.bind(this));
    }
  }

  render() {
    const { onDelete } = this.props;
    const { providers } = this.props.providerState;
    const { provider_id, frequency, id } = this.props.concept;
    
    return (
      <div>
        <div className='BudgetConceptItem'>
          <div className={status!=='toValidate'? 'BudgetConceptItem__col col-3 disable':'BudgetConceptItem__col col-3'}>
            <OutlinedInput
              value={this.state.name}
              onChange={this.handleOnChangeName()}
            />
          </div>
          <div className='BudgetConceptItem__col col-3'>
            <FieldProvider
              providersList={providers.result || []}
              providerId={provider_id}
              onChangeValue={this.handleClickProvider}
            />
          </div>
          <div className='BudgetConceptItem__col col-3'>
            <FieldPeriodicity
              value={frequency}
              onChangePeriodicity={this.onChangePeriodicity}
              periods={[{
                period_id: 'Mensual',
                name: 'Mensual'
              }]}
            />
          </div>
          <div className='BudgetConceptItem__delete'>
            <FieldDelete onClick={onDelete({
              id: this.props.concept.id,
              type: 3,
              active:false
            })} />
          </div>
          <div style={{ float: 'left', clear: 'both' }} ref={(el) => { this.bottomPage = el }}></div>
        </div>
      </div>
    );
  }
}

BudgetConceptItem.contextTypes = {
  router: PropTypes.object
};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  budgetState: state.get('budgetState'),
  providerState: state.get('providerState'),
});

export default connect(mapStateToProps, mapDispatchToProps)(BudgetConceptItem);

