import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as Actions from '../providerActions';
import Card from 'components/Card';
import Button from 'components/Button';
import Table from 'components/Table';

import './Providers.scss';

class Providers extends Component {
  componentDidMount(){
    const { property } = this.props.params;
    this.props.getProviders({
      idProperty: property
    });
  }

  goToNew = () => {
    const { property } = this.props.params;
    this.context.router.push(`/property/${property}/providers/new`);
  }

  goToDetail(row) {
    const { id } = row;
    const { property } = this.props.params;
    this.context.router.push(`/property/${property}/providers/${id}/edit`);
  }

  render() {
    const { providers, loading } = this.props.providerState;
    
    return (
      <div className='Providers animate__animated animate__fadeIn'>
        <div className='title row space-between flex-zero'>
          <div className='heading-2 self-center'>Proveedores</div>
          <Button
            variant='outlined'
            color='secondary'
            onClick={this.goToNew}
            title='Proveedor'>
            + Proveedor
          </Button>
        </div>
        <div>
          <Card>
            <Table
              columns={[
                {
                  headerName: 'Nombre',
                  field: 'name',
                  placeholder: 'No indicado'
                },
                {
                  headerName: 'Email',
                  field: 'email',
                  placeholder: 'No indicado'
                },
                {
                  headerName: 'Teléfono',
                  field: 'phone',
                  placeholder: 'No indicado'
                },
                {
                  button: 'Editar',
                  cellProps: {
                    align: 'right'
                  },
                  onClick: this.goToDetail.bind(this)
                }
              ]}
              loading={ loading.GET_PROVIDERS?.status }
              rows={ providers?.result }
            />
          </Card>
        </div>
      </div>
    );
  }
}

Providers.contextTypes = {
  router: PropTypes.shape()
};
Providers.propTypes = {
  property: PropTypes.number,
  resident_last_view: PropTypes.number
};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  providerState: state.get('providerState'),
  loginState: state.get('loginState'),
  profileState: state.get("profileState"),
  loading: state.get('providerState').loading
});

export default connect(mapStateToProps, mapDispatchToProps)(Providers);
