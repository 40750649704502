import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SimpleModal from 'components/SimpleModal';
import Button from 'components/Button';

import './ModalAmenity.scss';

class ModalAmenity extends Component {
  render() {
    const { open, onClose, onDelete } = this.props;
    
    return (
      <SimpleModal title='¿Estás seguro que deseas eliminar esta área comun?' open={open} onClose={onClose}>
        <div className='ModalAmenity'>
          <div className='ModalAmenity__inline'>
            <div className='ModalAmenity__item'>
              <Button variant='outlined' color='secondary' onClick={onClose}>Cancelar</Button>
            </div>
            <div className='ModalAmenity__item'>
              <Button variant='contained' color='secondary' onClick={onDelete}>Continuar</Button>
            </div>
          </div>
        </div>
      </SimpleModal>
    );
  }
}

ModalAmenity.contextTypes = {};

ModalAmenity.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ModalAmenity;
