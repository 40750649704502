import React, { useRef } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Button from '../Button';
import { ReactSVG } from 'react-svg';
import { toMoneyFormat, simpleDate } from '../../utils/format';

const Row = props => {

    const open = props.toggledRow === props.index;

    const detailRef = useRef(null);
    const toggleDetail = (index) => {
        props.onToggle(index || props.index);
        setTimeout(() => {
            detailRef?.current && detailRef.current.scrollIntoView();
        }, 500);
    }

    const renderComponent = (column, row) => {
        const dynamicProps = {};

        Object.keys(column.props).forEach(propName => {
            dynamicProps[propName] = row[column.props[propName]] || column.props[propName];
        })

        return <column.component { ...dynamicProps }/>;
    }

    const renderButton = (column, row) => (
        <Button
            variant='outlined'
            className='action-button'
            color='primary'
            onClick={() => column.toggleButton ? toggleDetail() : column.onClick(row)}
            disabled={ column.disabled && column.disabled(row) }
            { ...column.props }
        >
            { typeof column.button === 'string' ? column.button : column.button(row) }
        </Button>
    );

    const renderSVG = (column, row) => (
        <ReactSVG
            src={ column.svg }
            { ...column.props }
            onClick={() => column.onClick && column.onClick(row)}
        />
    );

    const renderInput = (column, row, defaultValue) => (
        <OutlinedInput
            defaultValue={ defaultValue }
            variant="outlined"
            disabled={ column.disabled && column.disabled(row) }
            onInput={e => column.onChange && column.onChange(row, e)}
            { ...column.inputProps }
        />
    );

    const renderCell = (column, row) => {
        if (column.component) {
            return renderComponent(column, row);
        }
        if (column.button) {
            return renderButton(column, row);
        }
        if (column.svg) {
            return renderSVG(column, row);
        }

        let cellValue = row[column.field];

        if (column.reverseNumber && !isNaN(cellValue)) {
            cellValue = cellValue * -1;
        }
        
        cellValue = String(typeof cellValue !== 'undefined' ? cellValue : '').trim();

        if (column.input) {
            return renderInput(column, row, cellValue);
        }

        if (cellValue) {
            if (column.render) {
                cellValue = column.render(row);
            }
            if (column.currency && !isNaN(cellValue)) {
                let color = '';
                if (column.useCurrencyColors) {
                    if (cellValue < 0) {
                        color = 'negative';
                    } else if (cellValue >= 0) {
                        color = 'positive';
                    }
                }
                return (
                    <span className={color}>
                        {`$ ${ toMoneyFormat(cellValue) }`}
                    </span>
                );
            }
            if (column.date) {
                return simpleDate(cellValue);
            }
            return cellValue;
        }
        
        return <span className='placeholder'>{ column.placeholder }</span>;
    }

    const { detailComponent : DetailComponent, value } = props;

    const columns = props.columns || [];

    const collapseRow = _ => toggleDetail(-1);

    return (
        <>
            <TableRow className={ `${ open && 'detail-outline'}` }>
                { columns.map((column, columnKey) => (
                    <TableCell
                        alt={ `${ String(column.field || column.headerName || column.button || '').toLocaleLowerCase() }-body` }
                        { ...column.cellProps }
                        key={ columnKey }
                    >{ renderCell(column, value) }</TableCell>
                ))}
            </TableRow>
            { !!DetailComponent && <TableRow className={ `detail-component ${ !open && 'collapsed'}` }>
                <TableCell colSpan={ columns.length }>
                    <Collapse ref={ detailRef } in={ open } timeout="auto" unmountOnExit>
                        <DetailComponent onClose={ collapseRow } onCancel={ collapseRow } { ...props }/>
                    </Collapse>
                </TableCell>
            </TableRow> }
        </>
    );
}

export default Row;
