import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from './propertyActions';

import Button from 'components/Button';
import Text from 'components/Text';

import background from 'assets/img/img-sn-prop.svg';

import './PropertiesPage.scss';

export class PropertiesPage extends Component {

  componentDidUpdate(prevProps) {
    const before = prevProps.profileState.resident_last_view;
    const next = this.props.profileState.resident_last_view;

    if(next !== before || next !== 0) {
      this.context.router.replace(`/property/${ next }/dashboard`);
    }
  }

  handeClick = where => () => {
    this.context.router.push(`/${where}`);
  }

  render() {
    const { resident_last_view } = this.props.profileState;
    return (
      <div className='PropertiesPage'>
        <div className='PropertiesPage__image'>
          <img src={background} alt='PropertiesPageImage' className='img-class' />
        </div>
        {
          resident_last_view === 0
          ? <div className='PropertiesPage__options'>
              <div className='PropertiesPage__text'>
                <Text variant='title' id='Properties.List.PropertiesPage' />
              </div>
              <div className='PropertiesPage__button'>
                <Button
                  variant='contained'
                  color='secondary'
                  className='PropertiesPage__button__button'
                  onClick={this.handeClick('Wizard/info/property')}>
                  <Text id='Button.property.create' onlyformat />
                </Button>
              </div>
            </div>
          : null
        }
      </div>
    );
  }
}

PropertiesPage.contextTypes = {
  router: PropTypes.shape({})
};

PropertiesPage.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  profileState: state.get('profileState')
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesPage);
