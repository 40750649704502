import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import './AddressMap.scss';

const google = window.google

export class AddressMap extends Component {
  componentDidMount() {
    const { latitude, longitude } = this.props.propertyState.new.address;
    if (!latitude || !longitude) {
      new google.maps.Map(document.getElementById('map'), {
        center: { lat: 19.3901783, lng: -99.1928924 },
        zoom: 4
      });
      return;
    }
    this.printMarker();
  }

  shouldComponentUpdate(nextProps) {
    const latitudeOld = this.props.propertyState.new.address.latitude;
    const longitudeOld = this.props.propertyState.new.address.longitude;
    const latitudeNew = nextProps.propertyState.new.address.latitude;
    const longitudeNew = nextProps.propertyState.new.address.longitude;
    return (latitudeOld !== latitudeNew || longitudeOld !== longitudeNew);
  }

  componentDidUpdate() {
    this.printMarker();
  }

  printMarker = () => {
    const { latitude, longitude } = this.props.propertyState.new.address;
    if (latitude) {
      const map = new google.maps.Map(document.getElementById('map'), {
        center: { lat: latitude, lng: longitude },
        zoom: 17
      });
      new google.maps.Marker({
        position: {
          lat: latitude,
          lng: longitude
        },
        map,
        title: 'Hello World!'
      });
    }
  }

  render() {
    return (
      <div id='map' className='map' />
    );
  }
}


AddressMap.contextTypes = {};
AddressMap.propTypes = {
  propertyState: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
const mapStateToProps = state => ({
  propertyState: state.get('propertyState')
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressMap);
