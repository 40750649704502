import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PDF from 'assets/img/icon-pdf.svg';

import './HistoryItem.scss';
import { toMoneyFormat } from 'utils/format';


class HistoryItem extends Component {

  handleOpen = (url) => () =>{
    window.open(url, "_blank")
  }

  render() {
    const {
      isLast, payment_date, amount, payment_type, url_receipt
    } = this.props;
    
    return (
      <div className={!isLast ? 'HistoryItem' : 'HistoryItem HistoryItem__last'}>
        <div className='HistoryItem__col'>{payment_date}</div>
        <div className='HistoryItem__col'>$ {toMoneyFormat(amount)}</div>
        <div className='HistoryItem__col'>{payment_type || 'No indicado'}</div>
        <div className='HistoryItem__img'>
          {
            url_receipt !== '' ? 
              <img src={PDF} alt='PDF' onClick={this.handleOpen(url_receipt)} />
            : ' - '
          }
        </div>
        
      </div>
    );
  }
}

HistoryItem.defaultProps = {
  isLast: false,
  payment_date: new Date(),
  amount: 0,
  balance: 0,
};

HistoryItem.propTypes = {
  isLast: PropTypes.bool,
  payment_date: PropTypes.string,
  amount: PropTypes.number,
  balance: PropTypes.number,
};

export default HistoryItem;
