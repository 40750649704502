import React, { Component } from 'react';

import './GraphicProcrastination.scss';
import Coming from 'assets/img/coming_soon.png';

class GraphicProcrastination extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className='Graphic'>
        <div className='Graphic__detail'>
          <img
            className="Graphic__detail__img"
            src={Coming}
            alt="coming_soon"
          />
        </div>
      </div>
    );
  }
}

export default GraphicProcrastination;
