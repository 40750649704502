import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as propertyActions from '../../Property/propertyActions';
import Calendar from "components/Calendar";
import moment from 'moment';
import { defaultDateFormat } from 'constants/dates';

export const MonthCalendar = props => {

    const [ bookings, setBookings ] = useState();

    const [ activeMonth, setActiveMonth ] = useState(moment());

    const getBookings = () => {
        const { amenityId: amenity_id } = props;
        
        props.getBookingsByDate({
            start_date: activeMonth.startOf('month').toDate(),
            end_date: activeMonth.endOf('month').toDate(),
            amenity_id
        });
    }

    const mapBookings = () => {
        const { month_bookings } = props.propertyState;
        const requests = { ...bookings } || { };
        
        (month_bookings || []).map(item => {
            const date = moment(item.start_date).format(defaultDateFormat);
            
            requests[date] = (requests[date] || []).filter(request => {
                const bookingHasNotChanged = request.booking_id === item.booking_id && request.status === item.status;
                
                return bookingHasNotChanged || request.booking_id !== item.booking_id;
            });

            const alreadyExists = requests[date].find(request => request.booking_id === item.booking_id && request.status === item.status);

            if (!alreadyExists && item.status !== 'CANCELLED') {
                requests[date].push(item);
            }
        });

        setBookings(requests)
    }

    const clearBookings = () => {
        setBookings([]);
    }

    const loadRequest = () => {
        setActiveMonth(moment(props.selectedRequest?.start_date));
    }

    useEffect(clearBookings, [ props.amenityId ]);

    useEffect(getBookings, [ props.amenityId, props.lastRefresh, activeMonth ]);

    useEffect(loadRequest, [ props.selectedRequest ]);

    useEffect(mapBookings, [ props.propertyState.lastBookingsUpdate ]);

    return (
        <>
            <Calendar
                onChange={ value => props.onChange(value) }
                mode={ props.mode }
                events={ bookings }
                selectedDate={ props.selectedRequest?.start_date }
                calendarProps={{
                    activeMonth,
                    onMonthChange: date => {
                        setActiveMonth(moment(date))
                    }
                }}
            />
        </>
    );
}

const mapDispatchToProps = dispatch => bindActionCreators(propertyActions, dispatch);
const mapStateToProps = state => ({
    propertyState: state.get("propertyState"),
});

export default connect(mapStateToProps, mapDispatchToProps) (MonthCalendar);