import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as Actions from '../../Property/propertyActions';
import Button from 'components/Button';
import Card from 'components/Card';
import images from 'assets/img';
import * as Routes from 'constants/wizardRoutes';
import { FormattedMessage } from 'react-intl';
import './Info.scss';

export class Info extends Component {
  constructor(props){
    super(props);
    this.state = {
      rows: {
        1: {
          image: images.wizard.carousel[0],
          text: <FormattedMessage id="Info.Carousel.slide1"/>
        },
        2: {
          image: images.wizard.carousel[1],
          text: <FormattedMessage id="Info.Carousel.slide2"/>
        },
        3: {
          image: images.wizard.carousel[2],
          text: <FormattedMessage id="Info.Carousel.slide3"/>
        },
        4: {
          image: images.wizard.carousel[3],
          text: <FormattedMessage id="Info.Carousel.slide4"/>
        },
        5: {
          image: images.wizard.carousel[4],
          text: <FormattedMessage id="Info.Carousel.slide5"/>
        },
      },
      activeStep: 1
    }
  }

  componentDidMount() {
    this.props.addPropertyData({
      new: {
        id: null,
        name: '',
        address: '',
      }
    });
  }

  handleGoTo = () => {
    (this.props.params.property) ? 
      this.context.router.push(`/Wizard/${ Routes.WIZARD_ADDRESS }/property/${this.props.params.property}`) :
      this.context.router.push(`/Wizard/${ Routes.WIZARD_ADDRESS }/property`);
  }

  handleGoBack = () => {
    this.context.router.push(`/properties`);
  }

  handleNext = (totalSteps) => () => {
    this.setState((prevState) => {
      if ((prevState.activeStep + 1) < totalSteps) {
        return ({
          activeStep: prevState.activeStep + 1,
        });
      }
      return ({
        activeStep: 1,
      });
    });
  };

  handleBack = (totalSteps) => () => {
    this.setState((prevState) => {
      if (prevState.activeStep === 0) {
        return ({
          activeStep: totalSteps - 1,
        });
      }
      return ({
        activeStep: prevState.activeStep - 1,
      });
    });
  };

  handleStepChange = (activeStep) => {
    this.setState({ activeStep });
  };

  render() {
    const { rows, activeStep } = this.state;
    return (
      <div className='Info'>
        <img src={images.wizard.header} alt='tutorial' className='Info__header' />
        <img src={images.wizard.logo} alt='logo' className='Info__logo' onClick={this.handleGoBack} />
        {
          activeStep !== 5 && (
            <div className='Info__out'>
              <Button variant='text' color='primary' onClick={this.handleGoTo}>
                <FormattedMessage id="Info.skip"/>
              </Button>
            </div> 
          )
        }
        <div className='Info__headerMob' />
        <div className='Info__container'>
          <div className='Info__container__title'>
            <FormattedMessage id={ activeStep === 1 ? "Info.welcome" : 'Info.tutorial' }/>
          </div>
          <div className='Info__container__text'>
            <FormattedMessage id={ "Info.description" }/>
          </div>
          <div className='Info__container__card'>
            <Card>
              <div className='Info__container__card__body'>
                <div className='Info__container__card__body__text'>
                  <div className='Info__container__card__body__text__number'>
                    {activeStep}
                  </div>
                  <div className='Info__container__card__body__text__text'>
                    {rows[activeStep].text}
                  </div>
                </div>
                <img src={rows[activeStep].image} alt='carousel' />
                <div className='Info__container__card__body__point'>
                  {
                    Object.values(rows).map((item, index)=>{
                      return(
                        <span  key={`${index + 1}`} className={
                          activeStep === index + 1
                          ? 'Info__container__card__body__point__item Info__container__card__body__point__item__active'
                          : 'Info__container__card__body__point__item'
                        }/>
                      );
                    })
                  }
                </div>
              </div>
            </Card>
          </div>
          <div className={`Info__container__options ${ activeStep === 1 ? 'next' : '' }`}>
            {
              activeStep !== 1 && activeStep !== 5 && (
                <Button className="before_tuto" color='secondary' onClick={this.handleBack(6)}>
                  <FormattedMessage id={ "Info.previousButton" }/>
                </Button>
              )
            }
            {
              activeStep !== 5 && (
                <Button className="next_tuto" color='secondary' onClick={this.handleNext(6)}>
                  <FormattedMessage id={ "Info.nextButton" }/>
                </Button>
              )
            }
          </div>
          {
            activeStep === 5 && (
              <div className='Info__container__options2'>
                <Button className="before_tuto" color='secondary' onClick={this.handleBack(6)}>
                  <FormattedMessage id={ "Info.back.button" }/>
                </Button>
                <Button color='secondary' variant='contained' onClick={this.handleGoTo}>
                  <FormattedMessage id={ "Info.startButton" }/>
                </Button>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

Info.contextTypes = {
  router: PropTypes.shape()
};

Info.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  propertyState: state.get('propertyState')
});

export default connect(mapStateToProps, mapDispatchToProps)(Info);