import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionsAccount from '../../../Accounts/accountActions';
import images from 'assets/img';
import './PaySuccess.scss';
import Button from 'components/Button/Button';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { dateFormatString, toMoneyFormat } from 'utils/format';
import PropTypes from 'prop-types';

export class PaySuccess extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount(){
    const { property } = this.props.params;
    this.props.getAccountImobly({property});
  }

  goMyAccount = () => {
    const { property } = this.props.params;
    this.context.router.push(`/property/${property}/profile/configuration?tab=accountimobly`);
  }

  goPrint = () => {
    window.print();
  }

  formatPay = card => {
    if (!card) return '';

    let formatCard = card.replace('(', '');
    formatCard = formatCard.replace(')', '');
    formatCard = formatCard.replace('...', '**** **** *** ');
    return formatCard;
  }

  formatDate = date => {
    if (!date) return '';

    const arrayDate = date.split('-');
    arrayDate[0] = arrayDate[0].replace(':', '-');
    const dateRes = arrayDate.join(' ');
    return dateFormatString(dateRes);
  }

  render() {
    let deadline_dateN
    const {name, cardNumber, date, refNumber, total, transactionId} = this.props.accountState.payment ||  {};
    const { accountImobly } =  this.props.accountState;
    if(accountImobly.result) { deadline_dateN = moment(new Date(this.props.accountState.accountImobly.result.deadline_date)).format('LL');}
    return (
      <>
        <div className="PaySuccess card">
          <img src={images.payment.success} alt="Pago exitoso" className="PaySuccess__icono"/>
          <h2 className="heading-2">
            <FormattedMessage id='Payment.Success.Title'/>
          </h2>

          <div className='space-success'>
            <p className="regular-text">
              <FormattedMessage id='Payment.Success.Description'/>
              {deadline_dateN}
            </p>

            <p className="regular-text">
              <FormattedMessage id='Payment.Success.Description.short'/>
            </p>
          </div>

          <div className="PaySuccess__content">
            <table className="PaySuccess__content__detail">
              <tbody>
              <tr>
                <td className="PaySuccess__content__detail__concept">
                  <FormattedMessage id='Payment.Success.Detail.Client'/>
                </td>
                <td className="PaySuccess__content__detail__concept-value PaySuccess__detail__name">
                  {name}
                </td>
              </tr>

              <tr>
                <td className="PaySuccess__content__detail__concept">
                  <FormattedMessage id='Payment.Success.Detail.Pay'/>
                </td>
                <td className="PaySuccess__content__detail__concept-value">
                  {this.formatPay(cardNumber)}
                </td>
              </tr>

              <tr>
                <td className="PaySuccess__content__detail__concept">
                  <FormattedMessage id='Payment.Success.Detail.Quantity'/>
                </td>
                <td className="PaySuccess__content__detail__concept-value">
                  $ {toMoneyFormat(total)}
                </td>
              </tr>

              <tr>
                <td className="PaySuccess__content__detail__concept">
                  <FormattedMessage id='Payment.Success.Detail.IdTransaction'/>
                </td>
                <td className="PaySuccess__content__detail__concept-value">
                  {transactionId}
                </td>
              </tr>
              <tr>
                <td className="PaySuccess__content__detail__concept">
                  <FormattedMessage id='Payment.Success.Detail.Date'/>
                </td>
                <td className="PaySuccess__content__detail__concept-value">
                  {this.formatDate(date)}
                </td>
              </tr>

              <tr>
                <td className="PaySuccess__content__detail__concept">
                  <FormattedMessage id='Payment.Success.Detail.Reference'/>
                </td>
                <td className="PaySuccess__content__detail__concept-value">
                  {refNumber}
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <div className='space-success'>
              <p className="regular-text"><FormattedMessage id='Payment.Success.Detail.Subscription'/></p>
              <p className="regular-text"><FormattedMessage id='Payment.Success.Detail.Subscription2'/></p>
          </div>

          <div className="PaySuccess__buttons">
            <Button color="primary" onClick={this.goPrint}>
              Imprimir
            </Button>

            <Button variant="contained" color="primary" onClick={this.goMyAccount}>
              Aceptar
            </Button>
          </div>
        </div>
      </>
    )
  }
}

PaySuccess.contextTypes = {
  router: PropTypes.shape()
}

const mapDispatchToProps = dispatch => bindActionCreators({ ...actionsAccount}, dispatch);

const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  accountState: state.get('accountState')
});

export default connect(mapStateToProps, mapDispatchToProps)(PaySuccess);
