import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import TabItem from './TabItem';

import './TabView.scss';

const TabView = props => {
  const [ selectedTab, setSelectedTab ] = useState({ });
  const { button, items, className, onChange } = props;

  const onTabChange = tab => {
    setSelectedTab(tab);
    onChange && onChange(tab);
  }

  useEffect(() => {
    if (!selectedTab.value || !items.find(item => item.value === selectedTab.value)) {
      onTabChange(items[0] || { });
    }
  }, [ onTabChange, items ]);

  useEffect(() => {
    onTabChange((items || [ ]).find(item => item.value === props.selectedTab) || { })
  }, [ props.selectedTab ]);

  return (
    <div className={ `TabView col ${ className }` }>
      <div className='tabs row'>
        <div className='row'>
          {
            items.map(tab => (
              <TabItem
                key={ tab.value }
                { ...props }
                { ...tab }
                active={ tab.value === selectedTab.value }
                onClick={ () => onTabChange(tab) }
              />
            ))
          }
        </div>
        { button && <Button { ...button } color="secondary"/> }
      </div>
      <div className='view'>
        { selectedTab.view }
      </div>
    </div>
  );
}

export default TabView;
