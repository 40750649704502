import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as Actions from '../providerActions';
import { addData, putConcept } from '../../Budget/budgetActions';

import Card from 'components/Card';

import AddProviderForm from './AddProviderForm';

import './AddProvider.scss';

export class AddProvider extends Component {

  componentDidMount() {
    const { providerID } = this.props.params;
    if(providerID) {
      this.props.getProvider({
        idProvider: providerID
      });
    }
  }
    
  componentWillUnmount() {
    this.props.clearDataProvider();
  }

  onSubmit = (event) => {
    event.preventDefault();
    const { providerID } = this.props.params;
    const dataProvider = this.props.providerState.new;
    dataProvider.account_type = Number(dataProvider.account_type);
    dataProvider.phone = `${dataProvider.phone}`;
    dataProvider.account_number = `${dataProvider.account_number}`;

    if(!providerID){
      this.props.setProvider({ 
        dataProvider
      }).then(()=>{
        this.goBack();
      });
      return null;
    }
    this.props.putProvider({
      idProvider: providerID, 
      dataProvider
    }).then(()=>{
      this.goBack();
    });
  }

  goBack = () => {
    this.context.router.goBack();
  }

  render() {
    const { providerID } = this.props.params;
    return (
      <div className='AddProvider col'>
        <h2>{ providerID ? 'Editar Proveedor' : 'Agregar Proveedor' }</h2>
        <Card>
          <div className='row row-centered align-center'>
            <AddProviderForm
              onCancel={this.goBack}
              onSubmit={this.onSubmit}
            />
          </div>
        </Card>
      </div>
    );
  }
}

AddProvider.contextTypes = {
  router: PropTypes.shape()
};
AddProvider.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({ ...Actions, addData, putConcept }, dispatch);
const mapStateToProps = state => ({
  providerState: state.get('providerState'),
  budgetState: state.get('budgetState'),
  loginState: state.get('loginState'),
  routerState: state.get('routing'),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProvider);
