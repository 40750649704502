import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SimpleModal from 'components/SimpleModal';
import Button from 'components/Button';
import Spinner from 'components/Spinner';

import ImageCity from 'assets/img/imagen-cuenta.png';
import './ModalInt.scss';

class ModalInt extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      open, onClose, goTo, isLoading, goToInvite
    } = this.props;
    return (
      <SimpleModal  open={open} onClose={onClose} classes={{
        paper: 'modal-card'
      }} hideClose>
        <div className={isLoading? 'ModalInt ModalInt__min' : 'ModalInt'}>
          {
            !isLoading
            ? <div className='ModalInt__container'>
                <img src={ImageCity} alt='Imagen End' />
                <div className='ModalInt__title'>¡Felicidades!</div>
                <div className='ModalInt__text'>
                  Has terminado la confguración de tu propiedad, ahora puedes
                  <br />
                  invitar a tus residentes a descargar la App Imobly.
                </div>
                <div className='ModalInt__options'>
                  <Button variant='outlined' color='secondary' className='ModalInt__options__button_one' onClick={goTo}>Envíar después</Button>
                  <Button variant='contained' color='secondary' className='ModalInt__options__button_one' onClick={goToInvite}>Envíar invitación</Button>
                </div>
              </div>
            : <Spinner loading />
          }
        </div>
      </SimpleModal>
    );
  }
}

ModalInt.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ModalInt;
