import React from 'react';
import PropTypes from 'prop-types';

import CheckBox from 'components/Checkbox';

const FieldRemember = (props) => {
  const { onChange, checked, disab } = props;
  return (
    <CheckBox
      checked={checked}
      onChange={onChange}
      styles={{
        margin: 0,
        padding: 0,
      }}
      disabled={disab}
    />
  );
};

FieldRemember.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

FieldRemember.defaultProps = {
  checked: false
};


export default FieldRemember;
