import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TabAmenityItem from './TabAmenityItem';

import './TabAmenity.scss';

class TabAmenity extends Component {
  render() {
    const { items, value, onChange } = this.props;
    return (
      <div className='TabAmenity'>
        {
          items.map(item => (
            <TabAmenityItem
              key={item.value}
              title={item.title}
              subtitle={item.subtitle}
              iconType={item.iconType}
              active={item.value === value}
              onChange={onChange(item.value)}
            />
          ))
        }
      </div>
    );
  }
}

TabAmenity.defaultProps = {
  items: [],
  value: null,
  onChange: null
};

TabAmenity.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func
};

export default TabAmenity;
