import React, { Component } from 'react';
import Proptypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../budgetActions';

import TextField from '@material-ui/core/TextField';
import SimpleModal from 'components/SimpleModal';
import Button from 'components/Button';
import { toMoneyFormat } from '../.././../../utils/format';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import './ModalTransferFunds.scss';
import  ModalTransferSuccess  from './ModalSuccess/ModalTransferSuccess';

export class ModalTransferFunds extends Component {
    constructor(props) { 
        super(props);
        this.state = { 
            origin_id: '',
            origin_type: '',
            destination_id: '',
            description: '',
            quantityRetirement: '',
            amount: 0,
            transfer: [],
            destinations: [],
            error: {},
            showModalSuccess: false
        }
    }

    componentWillReceiveProps(nextProps){
        if( this.props ){
             if( this.props.activeSelected !== nextProps.activeSelected){                 
                const { activeSelected } = nextProps;
                const { property,  } =  this.props;
                this.props.getTransferFund({
                    property,
                    active: activeSelected
                })
            }
            const { transfer } = nextProps.budgetState
            if(transfer.length > 0) {
                this.getTransfer(transfer);
            }
        }

    }

    onChange= (type) => (e) => {
        switch(type){
            case "retirement":
                const id_retirement = e.target.value; 
                if (id_retirement === '') {
                    this.setState({ origin_id: '' });
                } else {
                    this.setState({ origin_id: id_retirement });
                    this.getDestination(id_retirement);
                }
                break;
            case "deposit":
                const id_deposit = e.target.value;
                if(id_deposit === '') { 
                    this.setState({destination_id: '' })
                } else { 
                    this.setState({ destination_id: id_deposit })
                }
                break;
            case "quantity":
                e.target.value = e.target.value === "" ? "" : e.target.value;
                e.target.value =
                  e.target.value < 0 ? Math.abs(e.target.value) : e.target.value;
                if (!isNaN(Number(e.target.value))) {
                  if (Number(e.target.value) < 99999) {
                    this.setState({
                      [e.target.name]: e.target.value,
                      error: { ...this.state.error, [e.target.name]: undefined },
                    });
                    return null;
                  }
                }
                break;
            case "description":
                const description = e.target.value;
                if(description === '') {
                    this.setState({ description: '' });
                } else { 
                    this.setState({ description })
                }
                break;
            default:
                this.setState({
                  [e.target.name]: e.target.value,
                  error: { ...this.state.error, [e.target.name]: undefined },
                });
                return null;
            }
            return null;
    }

    onSubmit = (e) => { 
        e.preventDefault();
        let destination_type;
        let isError = false;
        const { origin_id, destination_id, amount, description, origin_type, destinations, quantityRetirement, error } = this.state;
        const { activeSelected } = this.props;
        const { property  } =  this.props;

        destinations.forEach( res => { 
            if(destination_id === res.id ){
                destination_type = res.type;
            }
        });

        if( origin_id === '' ){ 
            isError = true;
            error.origin_id = 'Tiene que selecionar cuenta retiro';
        }
        
        if(parseFloat(quantityRetirement) <= 0 ) {
           isError = true;
            error.origin_id = 'La cuenta retiro debe ser mayor a cero';
        }
        
        if( amount > parseFloat(quantityRetirement) ){ 
            isError =  true;
            error.amount = 'Fondos insuficientes';
        }

        if(parseFloat(amount) <= 0 ) {
            isError =  true;
            error.amount = 'El monto no puede ser a cero';
        }

        if( description === '' ){ 
            isError = true;
            error.description = 'Campo necesario';
        }

        if(isError){
            this.setState({error});
            return;
        }

        const dataTransfer = {
            origin_id,
            origin_type,
            destination_id,
            destination_type,
            amount: parseFloat(amount),
            description
        }

        this.props.setTransferFund({
            property,
            active: activeSelected,
            dataTransfer
        }).then(() => { 
            this.props.getTransferFund({
                property,
                active: activeSelected
            });
              this.props.getExpenses(activeSelected);
              this.props.getAssets({
                property,
                active: activeSelected
              });
              this.props.getActives({
                property
              });
        });

        this.clearStateForm();    
        this.props.onClose();
        this.onOpenModalSuccess();
    }

    getDestination = (id) => { 
        const { transfer } = this.state;
        const selectedAccount = transfer.find(orgin => orgin.id === id);
        const destinations = Object.values(selectedAccount?.destinations || { });

        this.setState({
            destinations,
            origin_type: selectedAccount?.type,
            quantityRetirement: selectedAccount?.available
        });
    }

    getTransfer = (transfer) => { 
        this.setState({
            transfer
        });
    }

    ofCloseModal = () => {
        this.props.onClose();
        this.clearStateForm();
    };

    clearStateForm = () => {
        this.setState({ amount: '', description: '', error: {}, destinations: [] });
    }

    onOpenModalSuccess = () => { 
        this.setState({ showModalSuccess: true })
    }

    ofCloseModalSuccess = () => {
        this.setState({ showModalSuccess: false });
        this.clearStateForm();
    }

    render() {
        const { transfer, description, amount, destinations, error, showModalSuccess } = this.state;
        const { open } = this.props;

        return (
            <>
            <ModalTransferSuccess
                openModSuc={showModalSuccess}
                closeModSuc={this.ofCloseModalSuccess}
            />
            { 
                !!transfer.length &&  
                <SimpleModal title='Transferir saldos entre cuentas' open={open} onClose={this.ofCloseModal}>
                    <div className="ModalTransferFunds">
                        <div className="ModalTransferFunds__transf" >Puedes transferir saldos entre la cuenta general y las cuentas de fondos.</div>
                        <form className="ModalTransferFunds__form" onSubmit={this.onSubmit}>
                            <div className="ModalTransferFunds__form__campos">
                                    <FormControl className="ModalTransferFunds__form__campos__select" >
                                        <Select
                                            className="selection"
                                            native
                                            inputProps={{
                                                name: 'age',
                                                id: 'cuenta-retiro',
                                            }}
                                            name="cuenta-retiro"
                                            onChange={this.onChange("retirement")}
                                            error={!!error.origin_id}
                                            size="20"
                                        >
                                            <option value="/" className="">Seleccione Cuenta de retiro </option>
                                            {transfer.map(origin => (
                                                <option key={origin.id} value={origin.id}> {origin.name} - Disponible ${ toMoneyFormat(origin.available)} </option>
                                            ))}
                                        </Select>
                                    </FormControl>

                                <FormControl className="ModalTransferFunds__form__campos__select">
                                    <Select
                                        native
                                        inputProps={{
                                            name: 'age',
                                            id: 'cuenta-deposito',
                                        }}
                                        name="cuenta-deposito"
                                        onChange={this.onChange("deposit")}
                                        disabled={destinations.length <= 0}
                                    >
                                    <option value="/">Seleccione Cuenta de depósito</option>
                                     {destinations.length > 0 && destinations.map(dest => (
                                            <option key={dest.id} value={dest.id}> {dest.name} - Disponible ${toMoneyFormat(dest.available)} </option>
                                    ))}
                                    </Select>
                                </FormControl>
                                
                                <FormControl className="ModalTransferFunds__form__campos__select">
                                    <TextField
                                    name="amount"
                                    label="Monto a transferir:"
                                    fullWidth
                                    value={amount}
                                    onChange={this.onChange("quantity")}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">$</InputAdornment>
                                            )
                                    }}
                                    error={!!error.amount}
                                    helperText={error.amount}
                                    disabled={destinations.length <= 0}
                                />
                                </FormControl> 

                                <FormControl className="ModalTransferFunds__form__campos__select" >
                                    <TextField
                                    name="description "
                                    label="Descripción de transferencia:"
                                    fullWidth
                                    value={description}
                                    onChange={this.onChange("description")}
                                    error={!!error.description}
                                    helperText={error.description}
                                    disabled={destinations.length <= 0}
                                />
                                </FormControl>
                                
                            </div>

                            <div className="ModalTransferFunds__form__button-paymen">
                                    <div className="botton-cancel" onClick={this.ofCloseModal}>
                                        <Button variant='outlined' color="secondary">
                                            Cancelar
                                        </Button>
                                    </div>
                                    <div className="botton-save">
                                        <Button type="submit" disabled={destinations.length <= 0} variant="contained" color="secondary">
                                            Guardar
                                        </Button>
                                    </div>
                            </div>
                        </form>
                    </div>
                </SimpleModal>
            }
            </>
        );
    }
}

ModalTransferFunds.propTypes = {
    getTransferFund: Proptypes.func,
    activeSelected: Proptypes.string,
    property: Proptypes.string,
    openModSuc: Proptypes.bool
}; 

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  budgetState: state.get('budgetState'),
  propertyState: state.get('propertyState')
});



export default connect(mapStateToProps, mapDispatchToProps) (ModalTransferFunds);
