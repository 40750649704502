import http from 'constants/config';
import sha512 from 'js-sha512';

export const getLoginN = data => http.post(
  'v1/login',
  {
    ...data,
    password: sha512(data.password)
  }
);

export const getLoginG = data => http.post(
  'v1/socialnetworks/google/login',
  data
);

export const getLoginF = data => http.post(
  'v1/socialnetworks/facebook/login',
  data
);

export const getRemember = email => http.post(
  'v1/password/reset',
  { email }
);

export const postRegisterN = data => http.post(
  'v1/register',
  JSON.stringify({
    ...data,
    password: sha512(data.password)
  })
);

export const putRegisterN = data => http.put(
  'v1/register',
  JSON.stringify({
    ...data,
    password: sha512(data.password)
  })
);

export const getResetN = data => http.post(
  'v1/register/sendcode',
  JSON.stringify(data)
);

export const putChangePasswordN = data => http.put(
  'v1/password/change',
  JSON.stringify(data)
);

export const getCodeN = data => http.put(
  'v1/register/active',
  JSON.stringify(data)
);
