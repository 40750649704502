import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import Text from 'components/Text';
import TextField from '@material-ui/core/TextField';

import * as Actions from '../../../Property/propertyActions';
import './Form.scss';
let renderTable = null;

class Form extends Component {

  constructor() {

    super();
    this.state = {
      buildingName: ''
    };
  }

  componentDidMount() {
    const { editable } = this.props;
    const { build } = this.props.propertyState;
    const { data } = build;
    if (editable) {
      this.setState({ buildingName: this.props.propertyState.build.name })
    }
    if (!data['0']) {
      this.props.addPropertyData({
        build: {
          ...build,
          name: '',
          data: {
            0: {
              initial: 0,
              content: []
            }
          }
        }
      }).then(() => {
        this.handleAddRows(1);
      })
    }
  }

  handleChange = type => (event) => {
    const { build } = this.props.propertyState;
    switch (type) {
      case 'input':
        if (event.target.name === 'name') {
          if (event.target.value === '') {
            this.props.setError({ name: "este valor no puede estar vacio" });
          }
          this.setState({ buildingName: event.target.value })
        }
        this.props.setError({});
        this.props.addPropertyData({ build: { ...build, [event.target.name]: event.target.value } });
        break;
      case 'number':
        event.target.value = (event.target.value === '') ? 1 : event.target.value;
        if (!isNaN(Number(event.target.value))) {
          this.props.addPropertyData({ build: { ...build, [event.target.name]: parseFloat(event.target.value) } });
          return null;
        }
        break;
    }
  };


  handleChangeRows = (event) => {
    const newRows = event.target.value === '' ? 1 : event.target.value;
    if (!isNaN(Number(event.target.value))) {
      this.props.addPropertyData({ build: { ...this.props.propertyState.build, floors: (event.target.value === '') ? 0 : parseInt(event.target.value) } });
      const { data } = this.props.propertyState.build;
      const oldRows = data && data[0] ? data[0].content.length : 0;
      clearTimeout(renderTable);
      renderTable = setTimeout(() => {
        if (newRows === '') {
          this.props.addPropertyData({ build: { ...this.props.propertyState.build, floors: 1 } });
          return null;
        }
        if (newRows > oldRows) {
          const rows = newRows - oldRows;
          this.handleAddRows(rows);
          if (newRows === 1) {
            this.props.addPropertyData({ build: { ...this.props.propertyState.build, floors: 1 } });
          }
          return null;
        }
        if (newRows < oldRows) {
          const rows = oldRows - newRows;
          this.handleRemoveRows(rows);
          if (newRows === 1) {
            this.props.addPropertyData({ build: { ...this.props.propertyState.build, floors: 1 } });
          }
          return null;
        }
        if (newRows === oldRows) {
          this.props.addPropertyData({ build: { ...this.props.propertyState.build, floors: 1 } });
          return null;
        }
      }, 1000);
    }
  }

  handleAddRows = (rows) => {
    const { build } = this.props.propertyState;
    const { data, date_start_admin } = build;
    const { content } = data[0];
    let { initial } = data[0];
    for (let i = 0; i < rows; i += 1) {
      initial += 1;
      const dwelling = {
        dwelling_id: 0,
        dwelling_name: `Casa ${initial}`,
        dwelling_number: `${initial}`,
        dwelling_admin_date: date_start_admin,
        dwelling_floor: `${0}`,
        resident_id: 0,
        resident_name: '',
        resident_last_name: '',
        resident_sec_last_name: '',
        resident_email: '',
        resident_phone: '',
      };
      content.push(dwelling);
    }
    this.props.addPropertyData({
      build: { ...build, data: { ...build.data, 0: { ...build.data[0], content, initial } } }
    });
  }

  handleRemoveRows = (rows) => {
    const { build } = this.props.propertyState;
    const oldRows = build.data[0].content.length;
    let { content, initial } = build.data[0];
    content.splice((oldRows - rows), rows);
    initial -= rows;
    this.props.addPropertyData({
      build: { ...build, data: { ...build.data, 0: { ...build.data[0], content, initial } } }
    });
  }

  render() {
    const { showFocus, editable, error } = this.props;
    const { name, date_start_admin, floors } = this.props.propertyState.build;

    return (
      <div className='Form deprecated'>
        <div className='Form__text'>
          <Text variant='body2' id='Home.form.title' />
        </div>
        <form className='Form__form'>
          <TextField
            label={<Text id='Home.form.dateAdmin' onlyformat />}
            type='date'
            name='date_start_admin'
            className='Form__separador'
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={date_start_admin}
            onChange={this.handleChange('input')}
          />
          {
            !editable
              ? <div className={showFocus ? 'Form__focus Form__focusOn' : 'Form__focus'}>
                <TextField
                  label={<Text id='Home.form.numberHomes' onlyformat />}
                  type='text'
                  name='floors'
                  className='Form__separador'
                  fullWidth
                  value={floors}
                  onChange={(event) => { this.handleChange(event); this.handleChangeRows(event); }}
                />
              </div>
              : null
          }
          <TextField
            label={<Text id='Home.form.name' onlyformat />}
            type='text'
            name='name'
            fullWidth
            inputProps={{
              maxLength: 35,
            }}
            InputLabelProps={{ shrink: true }}
            value={name}
            onChange={this.handleChange('input')}
            error={!!error.name}
            helperText={error.name}
          />
        </form>
      </div>
    );
  }
}

Form.contextTypes = {
  router: PropTypes.shape()
};

Form.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  propertyState: state.get('propertyState')
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
