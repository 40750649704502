import { createTheme } from '@material-ui/core/styles';

const primary = '#0F8570';
const secondary = '#14B497';

const textPrimary = '#3E4247';
const textSecondary = '#7E8083';

const Theme = createTheme({
  palette: {
    primary: {
      main: primary,
      contrastText: 'white'
    },
    secondary: {
      main: secondary,
      contrastText: 'white'
    },
    facebook: {
      main: 'blue'
    },
    text: {
      primary: textPrimary,
      secondary: textSecondary,
    }
  },
  overrides: {
    MuiButton: {
      root: {
        fontFamily: 'Poppins',
        textTransform: 'initial',
        fontSize: '16px',
        minWidth: '150px',
      },
      contained: {
        textTransform: 'initial',
        minWidth: '150px',
      },
      outlined: {
        textTransform: 'initial',
      },
    },
    MuiFormLabel: {
      root: {
        color: primary,
      }
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Poppins',
    ].join(','),

    display4: {
      fontSize: '50px',
      fontFamily: 'Poppins',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    display3: {
      fontSize: '46px',
      fontFamily: 'Poppins',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    display2: {
      fontSize: '40px',
      fontFamily: 'Poppins',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    display1: {
      fontSize: '32px',
      fontFamily: 'Poppins',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    headline: {
      fontSize: '28px',
      fontFamily: 'Poppins',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    title: {
      fontSize: '22px',
      fontFamily: 'Poppins',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    body1: {
      fontFamily: 'Poppins',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    body2: {
      fontSize: '16px',
      fontFamily: 'Poppins',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    caption: {
      fontSize: '12px',
      fontFamily: 'Poppins',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    }
  }
});

export default Theme;
