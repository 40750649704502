import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import * as profileActions from '../Profile/ProfileActions';

import TabView from 'components/TabView';
import SimpleModal from 'components/SimpleModal';

import Properties from './Properties/Properties';
import Privacy from './Privacy/Privacy';
import PropTypes from 'prop-types';
import AlertIcon from 'assets/img/icon_alert.svg'
import TermsAndConditionsText from 'components/TermsAndConditionsText/TermsAndConditionsText';
import AccountImobly from './AccountImobly/AccountImobly';
import Report from './Report/Report';
import ROLES from 'constants/roles';
import ConfigProfile from './ConfigProfile/ConfigProfile';

import './ProfilePage.scss';
import Button from 'components/Button';

export class Configuration extends Component {
  constructor(props) {
    super(props);
    this.state = { };
  }

  componentDidMount() {
    const { tab, inactive } = this.context.router.location.query;

    if (tab === 'accountimobly') {
      this.changeTabForParams(2);
    }
    if (inactive === 'true') {
      this.toggleAccountModal();
    }
  }

  changeTabForParams = (tab) => {
    this.setState({
      tab
    });
  }

  handleItems(){
    const { resident_role } = this.props.profileState;
    let items = [
      {
        value: 0,
        label: <FormattedMessage id="Tab.Configuration.Profile" />,
        view: <ConfigProfile params={this.props.params} />,
      },
      {
        value: 1,
        label: <FormattedMessage id="Tab.Configuration.Property" />,
        view: <Properties />,
      },
      {
        value: 2,
        label: <FormattedMessage id="Tab.Configuration.MyAccount" />,
        view: <AccountImobly params={this.props.params} changeStateTab={this.changeStateTab} />,
      },
      {
        value: 3,
        label: <FormattedMessage id="Tab.Configuration.Terms" />,
        view: <TermsAndConditionsText />,
      },
      {
        value: 4,
        label: <FormattedMessage id="Tab.Configuration.Privacy" />,
        view: <Privacy />,
      }
    ]
    if (resident_role.includes(ROLES.SUPER_ADMIN)) {
      items.push({
        value: 5,
        label: <FormattedMessage id="Tab.Configuration.Report" />,
        view: <Report setReportAdmin={this.props.setReportAdmin} />
      })

    }
    return items
  }

  toggleAccountModal() {
    const { inactiveModalVisible } = this.state;

    this.setState({
      inactiveModalVisible: !inactiveModalVisible
    })
  }

  render() {
    const { tab, inactiveModalVisible } = this.state;
    const items = this.handleItems();
    
    return (
      <div className="ProfilePage animate__animated animate__fadeIn">
        <h2>Configuración</h2>
        <TabView
          selectedTab={ tab }
          items={items}
        />
        <SimpleModal
          open={ inactiveModalVisible }
          hideClose={ true }
        >
          <div className='inactive-modal col align-center row-center'>
            <img src={ AlertIcon }/>
            <h3>¡Continua con Imobly!</h3>
            <p>Desafortunadamente no hemos recibido tu pago para el siguiente período, para poder seguir disfrutando de Imobly te recomendamos registrar tu tarjeta de credito en la pantalla de “Tu cuenta”.</p>
            <Button variant='contained' color='primary' onClick={ this.toggleAccountModal.bind(this) }>Validar pago</Button>
          </div>
        </SimpleModal>
      </div>
    );
  }
}

Configuration.contextTypes = {
  router: PropTypes.shape()
};

const mapDispatchToProps = dispatch => bindActionCreators({
  ...profileActions
}, dispatch);

const mapStateToProps = state => ({
  profileState: state.get('profileState'),
});

export default connect(mapStateToProps, mapDispatchToProps)(Configuration);
