import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import * as Actions from '../loginActions';
import stateReady from 'assets/img/state-warning.svg';
import './styles.scss';

class CodeError extends Component {
  activeAccount = () => {
    this.props.addLoginData({ loading: { ...this.props.loginState.loading, CODE_SIGNUP: undefined } });
    this.context.router.push(`/code`);
  }

  render() {
    return (
      <div className='Unauthorized'>
        <img src={stateReady} alt='image ready' />
        <h2 className='Unauthorized__error'>Código erroneo</h2>
        <p>Tu código ingresado no es correcto. Verifica e intenta nuevamente.</p>
        <Button onClick={this.activeAccount} variant='contained' color='secondary'>Continuar</Button>
      </div>
    );
  }
}

CodeError.contextTypes = { 
  router: PropTypes.shape()
};

CodeError.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
});

export default connect(mapStateToProps, mapDispatchToProps)(CodeError);
