import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import * as Actions from '../neighborsActions';

import Card from 'components/Card';
import TabView from 'components/TabView';
import Icon from 'components/Icon';

import NeighborPanel from './NeighborPanel';
import History from './History/History';
import Relations from './Relations/Relations';

import './NeighborDetail.scss';


export class NeighborDetail extends Component {
  constructor() {
    super();
  }

  componentDidMount() {
    const { property, contactID, dwellingID } = this.props.params;
    this.props.getNeighbor({
      pid: property,
      did: dwellingID
    });
    if (this.existUser(contactID)) {
      this.props.getNeighborContacts({
        idProperty: property,
        idResident: contactID
      });
    }
  }

  getAvailableOptions = (contactId) => {
    const { params} = this.props;
    
    const data = [{
      value: 2,
      label: 'Historial de pagos',
      view: <History params={params} />,
    }];
    if (this.existUser(contactId)) {
      data.push({
        value: 3,
        label: 'Contactos relacionados',
        view: <Relations params={params} />,
      });
    }
    return data;
  }
  
  createOrEdit = () => {
    const { property, contactID, dwellingID } = this.props.params;
    const { neighbor } = this.props.neighborState;
    
    this.context.router.push({ pathname: `/property/${property}/contacts/${contactID}/dwellings/${dwellingID}/edit`, state: { ...neighbor } });
  }

  goBack = () => {
    const { property } = this.props.params;
    this.context.router.push(`/property/${property}/contacts`);
  }

  existUser = id => (id !== 0 && id !== '0');

  render() {
    const { result } = this.props.neighborState.neighbor;
    const { contactID } = this.props.params;
    return (
      <div className='NeighborDetail'>
        <div className='NeighborDetail__back' onClick={this.goBack}>
          <Icon icon='arrow_back' color='secondary' />
          <label>Regresar</label>
        </div>
        <div className='NeighborDetail__title'>
          Detalle de Residente
        </div>
        <NeighborPanel
          {...result}
          contactID={contactID}
          goToEdit={this.createOrEdit}
        />
        <Card className='card-list SimpleCard'>
          <div className='NeighborDetail__tab'>
            <TabView
              items={this.getAvailableOptions(contactID)}
            />
          </div>
        </Card>
      </div>
    );
  }
}

NeighborDetail.contextTypes = {
  router: PropTypes.instanceOf(Object)
};
NeighborDetail.defaultProps = {
  params: null,
  loginState: null,
  neighborState: null,
  getNeighbor: null,
  getNeighborContacts: null
};
NeighborDetail.propTypes = {
  params: PropTypes.instanceOf(Object),
  loginState: PropTypes.instanceOf(Object),
  neighborState: PropTypes.instanceOf(Object),
  getNeighbor: PropTypes.func,
  getNeighborContacts: PropTypes.func
};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  neighborState: state.get('neighborState'),
  profileState: state.get("profileState"),
  loading: state.get('neighborState').loading
});

export default connect(mapStateToProps, mapDispatchToProps)(NeighborDetail);

