import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import * as Actions from '../../Property/propertyActions';

import Card from 'components/Card';
import Title from '../components/Title/Title';
import AmenityCounter from './AmenityCounter';

import './AddAmenityPage.scss';
import ListAmenities from './components/ListAmenities';
import FormAmenity from './components/FormAmenity';

export class AddHomePage extends Component {

  defaultReservationParameters = {
    cleaning_time: 0,
    guaranty_amount: 0,
    max_time: 0,
    min_time: 0,
    rate_type: '2',
    reservation_amount: 0
  };

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      amenities: [],
      selectedAmenities: [],
      selectedAmenity: null,
      complete: false
    }
  }

  componentDidMount() {
    this.getCatalogAmenities();
  }
  
  getCatalogAmenities() {    
    this.props.getCatalogAmenities().then(() => {
      const { result: amenities } = this.props.propertyState.catalog_amenities;
      this.setState({ amenities }, this.getAmenities.bind(this));
    });
  }

  getAmenities() {
    const { property: idProperty } = this.props.params;
    this.props.getAmenities({
      idProperty
    });
  }

  componentDidUpdate(nextProps) {
    const { amenities } = this.props.propertyState;
    if (nextProps.propertyState.amenities !== amenities && amenities.length ) {
      this.mapSavedAmenities();
    }
  }

  componentWillUnmount() {
    this.props.unmountBuild();
  }

  mapSelectedAmenities(amenities) {
    const selectedAmenities = [];
    const { amenityId } = this.props.params;

    amenities.forEach((item, index) => {
      if (item.value && (!amenityId || amenityId == item.amenity_id)) {
        selectedAmenities.push({
          ...item,
          key: item.name + index,
          nameList: `${item.name} ${index}`,
          label: `NOMBRE DE ${item.type}`,
          type_id: item.id,
          complete: true,
          edit: true
        })
      }
    });

    return selectedAmenities;
  }

  getSelectedAmenity(amenityId) {
    const { selectedAmenities } = this.state;
    const selectedAmenity = selectedAmenities.find(item => item.amenity_id === Number(amenityId));

    this.setState({
      selectedAmenity
    })
  }

  getSelectedAmenityFromParams() {
    const { amenityId } = this.props.params;

    this.getSelectedAmenity(amenityId);
  }

  mapSavedAmenities = () => {
    const { amenities: savedAmenities } = this.props.propertyState;

    this.setState(state => {
      let selectedAmenities = [];

      state.amenities = state.amenities.map((item) => {
        const sameTypeAmenities = savedAmenities.filter((savedAmenity) => savedAmenity.type_id === item.id);

        item = {
          ...item,
          checked: !!sameTypeAmenities.length,
          value: sameTypeAmenities.length,
        };

        sameTypeAmenities.forEach((amenity, index) => selectedAmenities.push({
          ...item,
          ...amenity,
          value: index + 1
        }))
        
        return item;
      });
      
      selectedAmenities = this.mapSelectedAmenities(selectedAmenities);

      return { ...state, selectedAmenities }
    }, this.getSelectedAmenityFromParams.bind(this));
  }

  onAmenityCountChange = ( amenityUpdated ) => {
    const amenities = [ ...this.state.amenities ];
    const amenityIndex = amenities.findIndex(amenity => amenity.id === amenityUpdated.id);
    
    amenities[amenityIndex] = amenityUpdated;
    
    this.setState({ amenities }, () => this.updateAmenitiesList(amenityUpdated));
  }

  filterExceededAmenities(amenity) {
    const { selectedAmenities } = this.state;
    
    return selectedAmenities.filter(item => {
      const hasMoreAmenitiesThanRequested = item.id === amenity.id && amenity.value > item.value
      const isNotSameAmenity = item.id !== amenity.id;

      return hasMoreAmenitiesThanRequested || isNotSameAmenity;
    });
  }

  addAmenityToList(list, amenity) {
    if (amenity.value) {
      const { property : property_id } = this.props.params;

      list.push({
        ...amenity,
        key: amenity.name +  amenity.value ,
        nameList: `${amenity.name} ${ amenity.value }`,
        label: `NOMBRE DE ${amenity.name} ${ amenity.value }`,
        description: '',
        property_id,
        type: amenity.name,
        type_id: amenity.id,
        reservation_parameters: { ...this.defaultReservationParameters }
      })
    }

    return list;
  }

  updateAmenitiesList = (amenity) => {
    const filteredList = this.filterExceededAmenities(amenity);

    const selectedAmenities = this.addAmenityToList(filteredList, amenity);

    this.setState({
      selectedAmenities,
      selectedAmenity: selectedAmenities[0]
    });
  }

  handleSelectAmenity = (selectedAmenity) => {
    this.setState({ selectedAmenity });
  }

  handleSaveTempAmenity = (amenity) => {
    const { amenityId } = this.props.params;
    
    this.setState(state => {
      let complete = false;
      const selectedAmenities = state.selectedAmenities.map(el => {
        if (el.key === amenity.key) {
          amenity.complete = true;
          return amenity;
        } else {
          return el;
        }
      });

      const amenityIndex = selectedAmenities.findIndex(item => item.key === amenity.key);
      
      const completedAmenities = selectedAmenities.filter(item => item.complete);
      
      let newAmenity = {};

      if (amenityIndex < selectedAmenities.length - 1 && !amenityId) {
        newAmenity = selectedAmenities[amenityIndex + 1];
      } else {
        newAmenity = amenity
      }

      if (completedAmenities.length === selectedAmenities.length) {
        complete = true;
      }

      return {selectedAmenities, selectedAmenity: newAmenity, complete};
    })
  }

  handleRemoveAmenity = (amenity) => () => {
    this.setState(state => {
      const amenityIndex = state.selectedAmenities.findIndex(item => item.key === amenity.key);
      const newArray = state.selectedAmenities;
      let newAmenity = null;
      newArray.splice(amenityIndex, 1)
      if (newArray.length > 0 && newArray.length < newArray.length - 1) {
        newAmenity = newArray[amenityIndex];
      }else{
        const index = amenityIndex === 0 ? 0 : (amenityIndex - 1)
        newAmenity = newArray[index];
      }
      const amenities = state.amenities.map((el) => {
        if (el.id === amenity.id) {
          el.value = el.value -1;
          el.checked = el.value !== 0;
        }
        return el;
      });
      return { selectedAmenity: newAmenity, amenities};
    })
  }

  handleSendAmenities = () => {
    const {selectedAmenities} = this.state;
    const data = [];
    const dataEdit = [];
    selectedAmenities.forEach(item => {
      if (item.complete && !item.edit) {
        data.push({
          description: item.description,
          is_reservable: item.is_reservable,
          name: item.name,
          property_id: Number(item.property_id),
          require_authorization: item.require_authorization,
          type: item.type.toUpperCase(),
          type_id: item.id,
          reservation_parameters: {
            cleaning_time: Number(item.reservation_parameters.cleaning_time),
            guaranty_amount: Number(item.reservation_parameters.guaranty_amount),
            max_time: Number(item.reservation_parameters.max_time),
            min_time: Number(item.reservation_parameters.min_time),
            rate_type: Number(item.reservation_parameters.rate_type),
            reservation_amount: Number(item.reservation_parameters.reservation_amount)
          }
        })
      } else if(item.edit === true) {
        dataEdit.push({
          description: item.description,
          is_reservable: item.is_reservable,
          name: item.name,
          property_id: Number(item.property_id),
          require_authorization: item.require_authorization,
          type: item.type.toUpperCase(),
          type_id: item.id,
          amenity_id: item.amenity_id,
          reservation_parameters: {
            cleaning_time: Number(item.reservation_parameters.cleaning_time),
            guaranty_amount: Number(item.reservation_parameters.guaranty_amount),
            max_time: Number(item.reservation_parameters.max_time),
            min_time: Number(item.reservation_parameters.min_time),
            rate_type: Number(item.reservation_parameters.rate_type),
            reservation_amount: Number(item.reservation_parameters.reservation_amount)
          }
        })
      }
    })
    if (data.length > 0) {
      this.props.createAnAmenitiesBulk({ data })
        .then(() => {
          this.goBack();
        })
    }
    if (dataEdit.length > 0) {
      this.props.updateAmenitiesBulk({ data: dataEdit })
        .then(() => {
          this.goBack();
        })
    }
  }

  goBack = () => {
    const { property } = this.props.params;
    this.props.getAmenities({
      idProperty: property
    });
    if (property) {
      this.context.router.push(`/Wizard/21/property/${property}`);
      return;
    }
    this.context.router.push('/Wizard/21/property');
  }

  render() {
    const { amenities, selectedAmenities, selectedAmenity, complete } = this.state;
    const { amenityId } = this.props.params;

    return (
      <div className="AmenityPage">
        <Title
          title={amenityId ? 'Editar amenidad' : 'Agregar amenidades'}
          goBack={this.goBack}
          hideIcon={false}
        />
        <div className="AmenityPage__body">
          <Card>
            { !amenityId && (
              <div>
                <h1 className="body-text1">
                  Selecciona las amenidades que tiene tu propiedad para configurarlas.
                </h1>
                <div className="AmenityPage__content">
                  <AmenityCounter
                    onChange={this.onAmenityCountChange}
                    amenities={ amenities }
                  />
                </div>
                <hr />
              </div>
            )}
            <div className="AmenityPage__content">
              <ListAmenities
                amenities={selectedAmenities}
                amenity={selectedAmenity}
                onSelect={this.handleSelectAmenity.bind(this)}
              />
              { !!selectedAmenity && (
                <FormAmenity
                  amenity={selectedAmenity}
                  complete={complete}
                  onDelete={this.handleRemoveAmenity}
                  onSave={this.handleSaveTempAmenity}
                  onSend={this.handleSendAmenities}
                />
              )}
            </div>
          </Card>
        </div>

      </div>
    );
  }
}

AddHomePage.contextTypes = {
  router: PropTypes.shape()
};

AddHomePage.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  propertyState: state.get('propertyState'),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddHomePage);
