import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { browserHistory } from 'react-router';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import PropTypes from 'prop-types';
import * as profileActions from '../../Profile/ProfileActions';
import Profile from 'assets/img/profileX.png';
import './ConfigProfile.scss';
import { roleProf } from './RoleProfile';
import Button from 'components/Button';

export class ConfigProfile extends Component {

  constructor(){
    super();
      this.state = { 
        roleProfileState: ''
      }
  }

  componentDidMount() {
    this.props.getProfile();
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.profileState.resident_last_view > 0){
        const { resident_role } = nextProps.profileState;
        let role = roleProf(resident_role);
        this.setState({
          roleProfileState: role
        });
    }
  }

  changePassword = () => {
    const { property } = this.props.params;
    browserHistory.push(`/property/${property}/profile/resetPassword`);
  }

  render() {
    const {
      resident_last_view,
      resident_name,
      resident_email,
      resident_phone,
      member_since,
      account_type
    } = this.props.profileState;
    const { roleProfileState } = this.state;
    return (
      <>
        {resident_last_view > 0 && (
          <div className="ConfigProfile">
            <div className="ConfigProfile__displayName">
              <img
                className="ConfigProfile__displayName__img"
                src={Profile}
                alt="Profile"
              />
              <div className="ConfigProfile__displayName__description">
                <h2 className="heading-2">{resident_name}</h2>
                <p>{roleProfileState}</p>
              </div>
            </div>
            <div className="ConfigProfile__dataProfile">
              <div className="ConfigProfile__dataProfile__personal">
                <h3><FormattedMessage id='ConfigProfile.title_information_personal' /></h3>
                <div className='ConfigProfile__dataProfile__personal__subtitle'>
                  <p className='subtitle'><FormattedMessage id='ConfigProfile.subtitle_name' /></p>
                  <p className='ConfigProfile__dataProfile__personal__subtitle__text' >{resident_name}</p>
                </div>
                <div>
                  <p className='subtitle'><FormattedMessage id='ConfigProfile.subtitle_phone' /></p>
                  <p className='ConfigProfile__dataProfile__personal__subtitle__text' >{resident_phone !== '' ? resident_phone : 'No indicado' }</p>
                </div>
                <div>
                  <p className='subtitle'><FormattedMessage id='ConfigProfile.subtitle_email' /></p>
                  <p className='ConfigProfile__dataProfile__personal__subtitle__text'>{resident_email}</p>
                </div>
                <div>
                    <p className='subtitle'><FormattedMessage id='ConfigProfile.subtitle_password' /></p>
                    <p className='ConfigProfile__dataProfile__personal__subtitle__text'>*****************</p>
                </div>
                <div>
                  { account_type === 0 && <Button variant="outlined" color="secondary" onClick={this.changePassword} ><FormattedMessage id='Button.change_password' /></Button> }
                </div>
              </div>
              <div className="ConfigProfile__dataProfile__information">
                <h3><FormattedMessage id='ConfigProfile.title_information_profile' /></h3>
                <div>
                  <p className='subtitle'><FormattedMessage id='ConfigProfile.member_since_imobly' /></p>
                  <p className='ConfigProfile__dataProfile__information__text'>{moment(new Date(member_since)).format('LL')}</p>
                </div>
                <div>
                  <p className='subtitle'><FormattedMessage id='ConfigProfile.profile_imobly' /></p>
                  <p className='ConfigProfile__dataProfile__information__text'>{roleProfileState}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

ConfigProfile.propTypes = { 
  roleProfileState: PropTypes.string
}

const mapDispatchToProps = dispatch => bindActionCreators({
    ...profileActions
  }, dispatch);

  const mapStateToProps = state => ({
    profileState: state.get('profileState'),
  });

export default connect( mapStateToProps, mapDispatchToProps) (ConfigProfile);
