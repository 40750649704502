import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = () => ({
  label: {
    fontSize: '20px',
  },
});

export const Input = (props) => {
  const { className } = props;
  return (
    <TextField
      className={`TextFieldComponent ${className}`}
      InputLabelProps={{ shrink: true, color: 'secondary' }}
      {...props}
    />
  );
};

Input.defaultProps = {
  className: '',
};

Input.propTypes = {
  className: PropTypes.string,
};

export default withStyles(styles)(Input);

