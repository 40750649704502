const name = process.env.NODE_ENV || 'local';

let environments;

// switch (name) {
//   case 'uat':
//     environments = {
//       apiUrl: 'https://api.uat.imobly.io/',
//       fiservUrl: 'https://test.ipg-online.com/connect/gateway/processing',
//       googleClientId: '1014160466216-qdtuop9am4jjp6lbaucvhe6glep2ijio.apps.googleusercontent.com',
//       facebookAppId: '614847583153880',
//       recaptchaSiteKey: '6Ld3uFkUAAAAAOF1yyEySvqfidwdB48jJpIQv7Tk',
//       playstoreUrl: 'https://play.google.com/store/apps/details?id=com.paloit.imoblyresidentes&hl=es-419&ah=EPPIV9WS1_YAYgpzhA6kWCMzkvA'
//     };
//     break;

//   case 'production':
environments = {
  apiUrl: 'https://api.imobly.io/',
  fiservUrl: 'https://test.ipg-online.com/connect/gateway/processing',
  googleClientId: '1014160466216-qdtuop9am4jjp6lbaucvhe6glep2ijio.apps.googleusercontent.com',
  facebookAppId: '614847583153880',
  recaptchaSiteKey: '6Ld3uFkUAAAAAOF1yyEySvqfidwdB48jJpIQv7Tk',
  playstoreUrl: 'https://play.google.com/store/apps/details?id=com.paloit.imoblyresidentes&hl=es-419&ah=EPPIV9WS1_YAYgpzhA6kWCMzkvA'
};
//     break;

//   default:
// environments = {
//   apiUrl: 'https://api.dev.imobly.io/',
//   fiservUrl: 'https://test.ipg-online.com/connect/gateway/processing',
//   googleClientId: '1014160466216-qdtuop9am4jjp6lbaucvhe6glep2ijio.apps.googleusercontent.com',
//   facebookAppId: '614847583153880',
//   recaptchaSiteKey: '6Ld3uFkUAAAAAOF1yyEySvqfidwdB48jJpIQv7Tk',
//   playstoreUrl: 'https://play.google.com/store/apps/details?id=com.paloit.imoblyresidentes&hl=es-419&ah=EPPIV9WS1_YAYgpzhA6kWCMzkvA'
// };
//     break;
// }

export default environments;
