import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../budgetActions';
import SimpleModal from 'components/SimpleModal';
import Button from 'components/Button';
import './BudgetPendingModal.scss';
import NoMoney from 'assets/img/state-nomoney.svg';

export class BudgetPendingModal extends Component {
  render() {
    const { openPayResponse } = this.props.budgetState;
    const { insufficient, close } = this.props;
    
    return (
      <div>
        <SimpleModal
          open={!!openPayResponse.code || openPayResponse.payments || insufficient }
          onClose={close}
          hideClose
        >
          <div className='BudgetPendingModal'>
            <img src={NoMoney} alt='Status' />
            <div className='BudgetPendingModal__title BudgetPendingModal__success'>
              <p><FormattedMessage id='Modal.Pending.Title' /></p>
            </div>
            <div className='BudgetPendingModal__text'>
              <p><FormattedMessage id='Modal.Pending.Description' /></p>
            </div>
            <div className='BudgetPendingModal__options'>
              <Button variant='contained' color='secondary' onClick={close}>
                <FormattedMessage id='Modal.Pending.Button' />
              </Button>
            </div>
          </div>
        </SimpleModal>
      </div>
    );
  }
}

BudgetPendingModal.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);

const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  budgetState: state.get('budgetState')
});

export default connect(mapStateToProps, mapDispatchToProps)(BudgetPendingModal);
