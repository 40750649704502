import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { ReactSVG } from 'react-svg';
import Edit from 'assets/logos/edit.svg'
import Delete from 'assets/logos/delete-button.svg'


import { showIconAmenity } from 'constants/amenityTypes';
import './AmenityListItem.scss';

export class AmenityListItem extends Component {
  render(){
    const {
      bindOptions, type_id, amenity_id, name, goTo, handleChecked, deleteItem, checked
    } = this.props;
    const amenity = showIconAmenity(type_id);
    return (
      <>
        <div
          className={
            bindOptions
              ? "AmenityListItem AmenityListItem__active"
              : "AmenityListItem"
          }
        >
          <div className="AmenityListItem__container">
            <div className="AmenityListItem__container__name">
              <div className="AmenityListItem__container__name__checkbox">
                {bindOptions ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="edit"
                        value={amenity_id}
                        checked={checked}
                        onChange={handleChecked}
                      />
                    }
                  />
                ) : null}
              </div>
              <div className="AmenityListItem__container__name__amenidad">
                <ReactSVG
                  className="AmenityListItem__container__amenidad__svg"
                  src={amenity.img_small}
                />
              </div>
              <div className="AmenityListItem__container__name__text">
                <p className="body-text1">{name}</p>
              </div>
            </div>

            <div className="AmenityListItem__container__options">
              <div className="AmenityListItem__container__options__edit">
                <ReactSVG src={Edit} onClick={() => goTo(amenity_id)} />
              </div>
              <div className="AmenityListItem__container__options__delete">
                <ReactSVG src={Delete} onClick={deleteItem(amenity_id)} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

AmenityListItem.propTypes = {
  bindOptions: PropTypes.bool.isRequired,
  type_id: PropTypes.number.isRequired,
  amenity_id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  goTo: PropTypes.func.isRequired,
  handleChecked: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
};

export default AmenityListItem;
