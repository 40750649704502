import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ImageIcon from '@material-ui/icons/Image';
import * as Actions from '../../../budgetActions';
import SimpleModal from 'components/SimpleModal';
import Form from 'components/Form';
import paymentMethods from 'constants/paymentMethods';

import './ModalPaymentService.scss';

export const ModalPaymentService = props => {
  
  const onSubmit = (values) => {
    const { activeSelected : active } = props.budgetState;
    const { property } = props;
    const { id : payment_id, status } = props.payment;
    const { amount, payment_date, payment_type, file } = values;

    props.putProviderPaymentsPendings({
      data: {
        amount,
        payment_date,
        payment_type,
        payment_id,
        status,
        mime_type: file?.file?.type || '',
        receipt: file?.base64 || ''
      },
      property,
      active
    }).then(() => { 
      props.getProviderPaymentPending({
        property,
        active
      });
      props.getActivesWithoutSelected({
        property
      });
      props.getExpenses(active);
      props.getTransferFund({
        property,
        active
      });
      props.onClose();
    });
  }

  const { open, onClose, payment } = props;
  
  return ( 
    <SimpleModal title='Registrar Pago de Servicio' open={open} onClose={onClose}>
      <Form
        loading={ props.budgetState.loading.PUT_PROVIDER_PAYMENTS_PENDINGS?.status }
        onCancel={ props.onClose }
        onSubmit={ onSubmit }
        clearOnSubmitted
        controls={[
          {
            name: 'service_name',
            label: 'Concepto/Servicio',
            props: {
              disabled: true,
              defaultValue: payment.service_name,
            }
          },
          {
            name: 'provider_name',
            label: 'Provedor',
            props: {
              disabled: true,
              defaultValue: payment.provider_name,
            }
          },
          {
            name: 'amount',
            label: 'Monto',
            props: {
              disabled: true,
              defaultValue: payment.amount,
            }
          },
          {
            name: 'payment_date',
            label: 'Fecha de Pago',
            type: 'date',
            props: {
              required: true,
              defaultValue: new Date().toISOString().substring(0, 10),
              InputLabelProps: {
                shrink: true
              }
            }
          },
          {
            name: 'payment_type',
            type: 'radio',
            props: {
              required: true,
              label: 'FORMA DE PAGO',
              row: true,
              defaultValue: 'CASH',
              options: paymentMethods
            }
          },
          {
            name: 'file',
            type: 'file',
            props: {
              label: 'EVIDENCIA (.docx, .pdf, .xlsx, .jpg, .png)',
              buttonProps: {
                startIcon: <ImageIcon />,
              },
              children: 'Adjuntar archivo'
            }
          },
        ]}
      />
    </SimpleModal>
  );
}

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  budgetState: state.get('budgetState')
});


export default connect(mapStateToProps, mapDispatchToProps)(ModalPaymentService);