import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import Button from 'components/Button';

import './Asset.scss';

class Asset extends Component {
  render() {
    const { image, name, children, handleCreateConcept, is_shared } = this.props;
    
    if (is_shared) {
      return (
        <div className='AssetInfo'>
          <ReactSVG src={image} className='AssetInfo__img' />
          <div className='AssetInfo__title'>
            {name}
          </div>
          <div className='AssetInfo__amount'>
            {children}
          </div>
          <PopupState variant="popover" popupId="demo-popup-popover">
            {popupState => (

                <div>
                  <Button color='secondary' icon='error_outline' {...bindTrigger(popupState)} />
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <p style={{ margin: 16 }}>Esta sección es solo informativa ya que representa el área común entre los activos y el gasto presupuestado correspondiente</p>
                  </Popover>
                </div>
              )}
        </PopupState>
        </div>
      );
    }
    return (
      <div className='Asset'>
        <div className='Asset__header'>
          <ReactSVG src={image} className='Asset__header__img' />
          <div className='Asset__header__text'>{name}</div>
        </div>
        <div className='Asset__body'>
          { children }
        </div>
        <div className='Asset__more' >
          <Button variant='outlined' color='secondary' onClick={handleCreateConcept}>+ Añadir concepto</Button>
        </div>
      </div>
    );
  }
}

Asset.defaultProps = {
  is_shared: false,
};

Asset.propTypes = {
  name: PropTypes.string.isRequired,
  handleCreateConcept: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  is_shared: PropTypes.bool,
};

export default Asset;