import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Select from 'components/Select';

import imgDefault from 'assets/img/user-profile.svg';

const Item = (props) => {
  const {
    image, name, onClick, noImage
  } = props;
  return (
    <span onClick={onClick}>
      {
        !noImage
        ? <img
          src={image || imgDefault}
          alt='Provider Logo'
          style={{
              marginRight: 11,
              height: 16,
              maxWidth: 16,
              borderRadius: '50%'
            }}
        />
        : null
      }
      {name}
    </span>
  );
};

Item.defaultProps = {
  noImage: false,
  onClick: () => {},
  name: '',
  image: null
};

Item.propTypes = {
  image: PropTypes.string,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  onClick: PropTypes.func,
  noImage: PropTypes.bool,
};

class FieldProvider extends Component {
  
  formatteData = (data) => {
    const { addProvider } = this.props;
    const array = [];
    if (data) {
      data.forEach((item) => {
        array.push({
          value: item.id,
          label: <Item image={item.url_image} name={item.name} />
        });
      });
    }
    if (addProvider) {
      array.push({
        value: 0,
        label: <Item noImage name={<div style={{ width: '90%' }}>Agregar proveedor</div>} onClick={addProvider} />
      });
    }
    return array;
  }

  render() {
    const { providersList, providerId, onChangeValue } = this.props;

    return (
      <Select
        items={this.formatteData(providersList)}
        value={providerId}
        onChange={onChangeValue}
        name='provider_id'
      />
    );
  }
}

FieldProvider.propTypes = {
  addProvider: PropTypes.func.isRequired,
  providerId: PropTypes.number.isRequired,
  onChangeValue: PropTypes.func.isRequired,
};

export default FieldProvider;