import * as ACTION from './actionTypes';
import { getLoginG, getLoginF, getLoginN, postRegisterN, putRegisterN, getResetN, getCodeN, getRemember, putChangePasswordN } from '../../services/loginService';
import { browserHistory } from 'react-router';

export function addLoginData(data) {
  return {
    type: ACTION.ADD_DATA_LOGIN,
    data,
  };
}

export function clearDataLogin() {
  return {
    type: ACTION.CLEAR_CHANGE_PASSWORD
  };
}

export function getLoginGoogle(loginData) {
  return {
    types: [
      ACTION.LOADING_GOOGLE_LOGIN,
      ACTION.RECEIVE_GOOGLE_LOGIN,
      ACTION.ERROR_GOOGLE_LOGIN,
    ],
    promise: getLoginG(loginData),
  };
}

export function getLoginFacebook(loginData) {
  return {
    types: [
      ACTION.LOADING_FACEBOOK_LOGIN,
      ACTION.RECEIVE_FACEBOOK_LOGIN,
      ACTION.ERROR_FACEBOOK_LOGIN,
    ],
    promise: getLoginF(loginData),
  };
}

export function getLoginNormal(loginData) {
  return {
    types: [
      ACTION.LOADING_NORMAL_LOGIN,
      ACTION.RECEIVE_NORMAL_LOGIN,
      ACTION.ERROR_NORMAL_LOGIN,
    ],
    promise: getLoginN(loginData),
  };
}

export function getRegister(userData) {
  return {
    types: [
      ACTION.LOADING_REGISTER_SIGNUP,
      ACTION.RECEIVE_REGISTER_SIGNUP,
      ACTION.ERROR_REGISTER_SIGNUP,
    ],
    registro: userData.email,
    promise: postRegisterN(userData),
  };
}

export function putRegister(userData) {
  return {
    types: [
      ACTION.LOADING_EDIT_REGISTER_SIGNUP,
      ACTION.RECEIVE_EDIT_REGISTER_SIGNUP,
      ACTION.ERROR_EDIT_REGISTER_SIGNUP,
    ],
    registro: userData.email,
    promise: putRegisterN(userData),
  };
}

export function getReset(email) {
  return {
    types: [
      ACTION.LOADING_RESET_SIGNUP,
      ACTION.RECEIVE_RESET_SIGNUP,
      ACTION.ERROR_RESET_SIGNUP,
    ],
    promise: getResetN({ email }),
  };
}

export function putChangePassword(data) {
  return {
    types: [
      ACTION.LOADING_CHANGE_PASSWORD,
      ACTION.RECEIVE_CHANGE_PASSWORD,
      ACTION.ERROR_CHANGE_PASSWORD,
    ],
    promise: putChangePasswordN(data),
  };
}

export function getCode(code, email) {
  return {
    types: [
      ACTION.LOADING_CODE_SIGNUP,
      ACTION.RECEIVE_CODE_SIGNUP,
      ACTION.ERROR_CODE_SIGNUP,
    ],
    promise: getCodeN({ code, email }),
  };
}

export function getCaptcha() {
  return {
    type: ACTION.CHANGE_CAPTCHA_LOGIN
  };
}

export function getRememberMe(email) {
  return {
    types: [
      ACTION.LOADING_REMEMBER_LOGIN,
      ACTION.RECEIVE_REMEMBER_LOGIN,
      ACTION.ERROR_REMEMBER_LOGIN,
    ],
    promise: getRemember(email),
  };
}

export function logout() {
  localStorage.clear();
  browserHistory.push('/');
  return {
    type: ACTION.LOG_OUT,
  };
}
export function unAuthorized() {
  return {
    type: ACTION.LOG_OUT,
  };
}

