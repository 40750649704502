import React from "react";
import TextField from "@material-ui/core/TextField";
import './FieldNextPayment.scss';

export const FieldNextPayment = (props) => {
  const  { onChangeNextPayment, next_payment_date} = props;
  
  return (
    <div className="field-date" >
      <TextField
        id="date-nextPayment"
        type="date"
        name="dateNextPayment"
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        onChange={onChangeNextPayment}
        value={next_payment_date}
      />
    </div>
  );
};


export default FieldNextPayment;
