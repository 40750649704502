import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import * as Actions from '../../../Property/propertyActions';

import Card from 'components/Card';

import AccordionDisplay from './AccordionDisplay';
import Table from './Table';
import './Accordion.scss';

let renderTable = null;

class Accordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
    };
  }
  componentDidMount(){
    clearTimeout(renderTable);
    this.bottomPage.scrollIntoView({ behavior: 'smooth', inline:'end'})
  }

  handleChangeExpand = () => {
    this.setState({ expanded: true });
  };

  handleCloseAcordion = () => {
    this.setState({ expanded: false });
  };
  

  handleChangeDataRows = (event) => {
    const { data } = this.props.propertyState.build;
    const oldRows = data[Number(event.target.name)].content.length;
    const newRows = event.target.value;
    this.props.handleChangeDataRows(newRows)
    const reg = event.target.name;
    clearTimeout(renderTable);
    renderTable = setTimeout(()=>{
      if(newRows === ''){ return null; }
      this.setState({ expanded: true });
      if(newRows > oldRows){
        const rows = newRows - oldRows;
        this.handleAddRows({rows, reg});
        return null;
      }
      if(newRows < oldRows){
        const rows = oldRows - newRows;
        this.handleRemoveRows({rows, reg});
        return null;
      }
    },1000);
  }

  handleAddRows = ({rows, reg}) => {
    const { build } = this.props.propertyState;
    let content = build.data[reg].content;
    let initial = build.data[reg].initial;
    for (let i = 0; i < rows; i += 1){
      initial += 1;
      const dwelling = {
        "dwelling_id": 0,
        "dwelling_name": 'Depto',
        "dwelling_number": `${initial}`,
        "dwelling_admin_date": build.date_start_admin,
        "dwelling_floor": `${reg}`,
        "resident_id": 0,
        resident_name: '',
        resident_last_name: '',
        resident_sec_last_name: '',
        resident_email: '',
        resident_phone: '',
      }
      content.push(dwelling);
    }
    this.props.addPropertyData({
      build: { ...build, data: { ...build.data, [reg]: { ...build.data[reg], content, initial } }}
    });
  }

  handleRemoveRows = ({rows, reg}) => {
    const { build } = this.props.propertyState;
    const oldRows = build.data[reg].content.length;
    let content = build.data[reg].content;
    let initial = build.data[reg].initial;
    content.splice((oldRows - rows),rows);
    initial -= rows;
    this.props.addPropertyData({
      build: { ...build, data: { ...build.data, [reg]: { ...build.data[reg], content, initial } }}
    });
  }

  handleAddDwelling = (rowDepto) => {
    const { build } = this.props.propertyState;
    const { idRow, params } = this.props;
    const { property, towerID } = params;
    let row = parseInt(rowDepto) + 1;
    const dwelling = [{
      "dwelling_id": 0,
      "dwelling_name": 'Depto',
      "dwelling_number": row.toString(),
      "dwelling_admin_date": build.date_start_admin,
      "dwelling_floor": idRow,
      "resident_id": 0,
      resident_name: '',
      resident_last_name: '',
      resident_sec_last_name: '',
      resident_email: '',
      resident_phone: '',
    }];
    this.props.setDwellings({
      idProperty: property,
      idBuilding: towerID,
      dwellingsData: dwelling
    }).then(()=>{
      this.props.getDwellings({
        idProperty: property,
        idBuilding: towerID
      });
    });
  }

  render() {
    let rowDeptos = 0;
    const { expanded } = this.state;
    const { idRow, params, showFocus, onDeleteDwelling, onDeleteFloor } = this.props;
    const { data } = this.props.propertyState.build;
    const deptos = data[idRow].content.length;
    if(data){
      data[idRow].content.forEach(element => {
        rowDeptos =( element.dwelling_number);
      });
    }
    return (
      <div className='Accordion'>
        <Card>
          <AccordionDisplay
            idRow={idRow}
            expanded={expanded}
            handleCloseAcordion={this.handleCloseAcordion}
            handleChangeExpand={this.handleChangeExpand}
            handleChangeDataRows={this.handleChangeDataRows}
            numDeptos={deptos > 0 ? deptos : null}
            toEdit={!params.towerID}
            onDeleteFloor={onDeleteFloor}
          />
          <div className={ expanded ? 'Accordion__panel' : 'Accordion__panelClose' }>
            <Table
              content={data[idRow].content}
              row={idRow}
              onDelete={onDeleteDwelling}
              toEdit={params.towerID}
              showFocus={showFocus}
            />
            {
              params.towerID
              ? <div className='Accordion__more'>
                  <label onClick={ e => this.handleAddDwelling(rowDeptos, e)}>+ Añadir departamento</label>
                </div>
              : null
            }            
          </div>
          <div style={{ float: 'left', clear: 'both' }} ref={(el) => { this.bottomPage = el }}></div>
        </Card>
      </div>
    );
  }
}

Accordion.contextTypes = {
  router: PropTypes.shape()
};

Accordion.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  propertyState: state.get('propertyState')
});

export default connect(mapStateToProps, mapDispatchToProps)(Accordion);

