import React, { Component } from 'react';
import { ReactSVG } from 'react-svg';
import { toMoneyFormat } from '../../../../utils/format';

import { showIconAmenity } from 'constants/amenityTypes';
import './BudgetTabItem.scss';

class BudgetTabItem extends Component {
  render() {
    const { active, onChange, iconType, label, amount } = this.props;
    
    return (
      <div className='BudgetTabItem' onClick={onChange}>
        <div className='BudgetTabItem__content'>
          <div className={ active ? 'BudgetTabItem__image' : 'BudgetTabItem__image BudgetTabItem__image__off'}>
            <ReactSVG src={showIconAmenity(iconType).img} alt='Section' />
          </div>
          <div className='BudgetTabItem__info'>
            <div className={ active ? 'BudgetTabItem__info__title BudgetTabItem__info__title__on' : 'BudgetTabItem__info__title' }>{label}</div>
            <div className={ active ? 'BudgetTabItem__info__amount BudgetTabItem__info__amount__on' : 'BudgetTabItem__info__amount' }>Saldo ${toMoneyFormat(amount)}</div>
          </div>
        </div>
        <div className={ active ? 'BudgetTabItem__selector BudgetTabItem__selector__on' : 'BudgetTabItem__selector' } />
      </div>
    );
  }
}

export default BudgetTabItem;
