import React from 'react';
import { toMoneyFormat } from '../../../../utils/format';
import { FormattedMessage } from 'react-intl';
import './QuantitiesPayService.scss';

export const QuantitiesPayService = (props) => {
const { imobly_price, service_fee, vat_calculation, total_payment, housing_units } = props.result;
  return (
    <div className='QuantitiesPayService'>
      <div className='QuantitiesPayService__quantities__col'>
        <p className='body-text1'><FormattedMessage id='Quantities.Pay.Service.price'/></p>
        <p className='heading-4 '>${toMoneyFormat(imobly_price)}</p>
      </div>
      <div className='QuantitiesPayService__quantities__col'>
        <p className='body-text1'><FormattedMessage id='Quantities.Pay.Service.house_dptos'/></p>
        <p className='heading-4 '>{housing_units}</p>
      </div>
      <div className='QuantitiesPayService__quantities__col'>
        <p className='body-text1'><FormattedMessage id='Quantities.Pay.Service.subtotal'/></p>
        <p className='heading-4 '>${toMoneyFormat(service_fee)}</p>
      </div>
      <div className='QuantitiesPayService__quantities__col'>
        <p className='body-text1'><FormattedMessage id='Quantities.Pay.Service.iva'/></p>
        <p className='heading-4 '>${toMoneyFormat(vat_calculation)}</p>
      </div>
      <div className='QuantitiesPayService__quantities__col'>
        <p className='heading-4'><FormattedMessage id='Quantities.Pay.Service.total'/></p>
        <p className='heading-4 '>${toMoneyFormat(total_payment)}</p>
      </div>
    </div>
  );
};

export default QuantitiesPayService;
