import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { FormattedMessage } from 'react-intl';
import environments from 'constants/environments';
import Text from 'components/Text';
import Button from 'components/Button';
import imoblyLogo from 'assets/img/logo-login.png';
import facebookLogo from 'assets/img/flogo-rgb-hex-72.png';
import './LoginForm.scss';


class LoginForm extends Component {
  render() {
    const {
      user, password, error, goTo, onChange, onSubmit, responseFacebook, responseGoogle, onFailure
    } = this.props;
    return (
      <div className='Login'>

        <div className='Login__title'>
          <img src={imoblyLogo} alt='imobly-logo' className="Login__title__logo" />
        </div>

        <div className='Login__social'>
          <div className='Login__social__google'>
            <GoogleLogin
              clientId={ environments.googleClientId }
              autoLoad={false}
              onSuccess={responseGoogle}
              onFailure={onFailure}
              className='Login__social__google__button'
            >
              <span><FormattedMessage id='LoginPage.google' /></span>
            </GoogleLogin>
          </div>
          <div className='Login__social__facebook'>
            <FacebookLogin
              appId={ environments.facebookAppId }
              language='es_MX'
              fields='name, email, picture.width(120)'
              callback={responseFacebook}
              onFailure={onFailure}
              textButton=''
              cssClass='Login__social__facebook__button'
              icon={
                <div className='Login__social__facebook__button__containerLogo'>
                  <img src={facebookLogo} alt='facebook-logo' className='Login__social__facebook__button__containerLogo__logo' />
                  <span><FormattedMessage id='LoginPage.facebook' /></span>
                </div>
              }
            />
          </div>
        </div>

        <div className='Login__options'>
          <Text variant='caption' id='LoginPage.or' />
          <div className='Login__options__divider' />
        </div>

        <div className='Login__panel'>
          <div className='Login__panel__entry Login__panel__entry__line'>
            <div className='Login__panel__entry__input'>
              <Text variant='caption' id='LoginPage.user' />
              <input
                placeholder=''
                className='Login__panel__entry__input__input'
                value={user}
                name='user'
                type='text'
                error={error.user ? undefined : 'Usuario invalido'}
                helpertext={error.user ? undefined : 'Usuario invalido'}
                onChange={onChange}
              />
            </div>
          </div>
          <div className='Login__panel__entry Login__panel__entry'>
            <div className='Login__panel__entry__input'>
              <Text variant='caption' id='LoginPage.password' />
              <input
                placeholder=''
                className='Login__panel__entry__input__input'
                value={password}
                name='password'
                type='password'
                onChange={onChange}
              />
            </div>
          </div>
        </div>

        <div className='Login__error'>
          {
            !!error.user
            ? <small>* {error.user}</small>
            : null
          }
          {
            !!error.password
            ? <small>* {error.password}</small>
            : null
          }
        </div>

        <div className='Login__reset' onClick={goTo('remember')}>
          <Text variant='caption' id='LoginPage.reset' />
        </div>

        <div className='Login__button'>
          <Button className='LoginButton__Normal' onClick={onSubmit}>
            <Text variant='button' id='LoginPage.login' />
          </Button>
        </div>

        <div className='Login__signup'>
          <div className='Login__signup__text'>
            <Text variant='caption' id='LoginPage.newAccount' />
          </div>
          <span className='Login__signup__register' onClick={goTo('signup')}>
            <Text variant='caption' id='LoginPage.register' />
          </span>
        </div>

        <div className='Login__signup'>
          <div className='Login__signup__text'>
            <Text variant='caption' id='LoginPage.condiciones' />
          </div>
          <span className='Login__signup__register' onClick={goTo('terms-and-conditions')}>
            <Text variant='caption' id='LoginPage.terms' />
          </span>
        </div>
        
        <div className='Login__signup'>
          <div className='Login__signup__text'>
            <Text variant='caption' id='LoginPage.avise-1' />
          </div>
          <span className='Login__signup__register' onClick={goTo('privacy-notice')}>
            <Text variant='caption' id='LoginPage.avise' />
          </span>
        </div>

      </div>
    );
  }
}

LoginForm.defaultProps = {
  user: '',
  password: '',
  error: {},
};

LoginForm.propTypes = {
  user: PropTypes.string,
  password: PropTypes.string,
  error: PropTypes.object,
  versionName: PropTypes.string.isRequired,
  goTo: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  responseFacebook: PropTypes.func.isRequired,
  responseGoogle: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired
};

export default LoginForm;
