import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import * as Actions from '../../../Budget/budgetActions';

import RadioGroup from 'components/RadioGroup';
import Input from 'components/Input';
import Button from 'components/Button';
import { simpleDate } from '../../../../utils/format';
import { defaultDateFormat } from 'constants/dates';

import './Frecuency.scss';

class Frecuency extends Component {

  onChange = (type) => (event) => {
    const { id, asset_id } = this.props;
    const { concepts } = this.props.budgetState;
    let value = 1;
    switch(type){
      case 'radio':
        value = Number(event.target.value);
      break;
      case 'date':
        value = moment(event.target.value, defaultDateFormat ,'es',true).format();
      break;
      case 'day':
        value = Number(event.target.value);
        if(value > 31) { value = 31; }
        if(value < 1) { value = 1; }
      break;
      default:
        value = event.target.value;  
      break;
    }
    concepts[asset_id][id][event.target.name] = value;
    this.props.addData({ concepts });
  };

  onSend = () => {
    const { property } = this.props.params;
    const { id : cid, asset_id } = this.props;
    const { activeSelected } = this.props.budgetState;
    const activeType = this.props.budgetState.actives.result[activeSelected];

    const conceptData = this.props.budgetState.concepts[asset_id][cid];
    if (activeType.type !== 'building'){
      conceptData.asset_type=activeType.icon;
    }
    
    this.props.createService(conceptData.service_name_n, conceptData.asset_type).then(()=>{
      conceptData.service_id = this.props.budgetState.last_created_service;
      this.props.putConcept({ 
        conceptData,
        cid
      }).then(() => {
        this.props.getConcepts({
          property,
          active: activeSelected
        });
        let types;
        if (activeType.type === 'building') {
          let array = [];
          let assets = this.props.budgetState.assets.result;
          if (assets) {
            assets.map(asset => array.push(asset.type));
            types = array.join(',');
          }
        } else {
          types = activeType.icon;
        }
        this.props.getCatalogServices({ types });
        
        this.props.closePopover();
      })      
    });
  }

  render() {
    const { period_type_id, period_date, period_day, serviceName, isService } = this.props;
    return (
      <div className='Frecuency'>
        <div className='Frecuency__text'>{isService ? 'Agrega un nuevo servicio': 'El servicio se realiza cada:'}</div>
        { 
          !isService && (
            <RadioGroup
              value={ period_type_id }
              onChange={ this.onChange('radio') }
              name='period_type_id'
              className={ 'Frecuency__item' }
              options={[
                { value: 1, label: 'Pago único' },
                { value: 4, label: 'Mensual' },
              ]}
            />
          )
        }
        {
          !isService ? 
          <div className='Frecuency__input'>
            <Input
              label='Inicio del servicio'
              type='date'
              name='period_date'
              className='Frecuency__input'
              InputLabelProps={{
                shrink: true
              }}
              value={ simpleDate(period_date) }
              onChange={this.onChange('date')}
            />
          </div>
          : null
        }
        {
          !isService ? 
          <div className='Frecuency__input'>
            <Input
              label='Día de pago'
              type='number'
              name='period_day'
              className='Frecuency__input'
              InputLabelProps={{
                shrink: true
              }}
              value={period_day}
              onChange={this.onChange('day')}
            />
            <div className='Frecuency__button'>
              <Button variant='outlined' color='secondary' onClick={this.onSend}>Confirmar</Button>
            </div>
          </div>
          : 
          <div>
            <Input
                label='Nombre'
                type='text'
                name='service_name_n'
                className='Frecuency__input'
                InputLabelProps={{
                  shrink: true
                }}
                value={serviceName}
                onChange={this.onChange('service')} 
              />
            <div className='Frecuency__button'>
              <Button variant='outlined' color='secondary' onClick={this.onSend}>Confirmar</Button>
            </div>
          </div>
        }
        </div>
    );
  }
}

Frecuency.contextTypes = {
  router: PropTypes.shape()
};

Frecuency.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  budgetState: state.get('budgetState'),
});

export default connect(mapStateToProps, mapDispatchToProps)(Frecuency);

