import React, { Component } from 'react';

import './TabItem.scss';

class TabItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { active, label, onClick, labelClassName } = this.props;
    return (
      <div className={ `TabItem heading-5 ${ active && 'active' } ${ labelClassName }` } onClick={onClick}>
        {label}
      </div>
    );
  }
}

export default TabItem;
