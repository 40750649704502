import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, withStyles } from '@material-ui/core';
import { IMaskInput } from 'react-imask';
import InputAdornment from '@material-ui/core/InputAdornment';
import classNames from 'classnames';
import './CurrencyInput.scss';


const styles = theme => ({
  margin: {
    margin: 0,
  },
  textField: {
    flexBasis: 200,
    backgroundColor: '#FFF',
    height: 30,
    border: '1px solid #d5e2ee',
    borderRadius: 5,
    padding: '0px 10px',
    boxSizing: 'border-box',
    fontSize: 14,
  },
});

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  
  const configBlocks = {
    currency: {
      mask: Number,
      radix: '.',
      scale: 2,
      signed: true,
      thousandsSeparator: ',',
      padFractionalZeros: false,
      normalizeZeros: true,
      value: '',
      unmask: true
    }
  };

  return (
    <IMaskInput
      {...other}
      blocks={configBlocks}
      mask="currency"
      definitions={{
        '#': /[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)/
      }}
      data-testid="currencyInput"
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
      thousandsSeparator=""
    />
  );
});

const CurrencyInput = (props) => {
  const { classes } = props;
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
    if (props.onChange) {
      props.onChange({
        ...event,
        target: {
          ...event.target,
          value: (event.target.value || '').replace(/,/g, '')
        }
      });
    }
  };

  useEffect(() => setValue(''), []);

  useEffect(() => {
    if (props.value !== '-') {
      setValue(Number(props.value) ? Number(props.value) : '')
    }
  }, [props.value]);

  return (
    <div className={`CurrencyInput ${!!value && 'filled'}`} data-testid="CurrencyInput">
      <Input
        name='amount'
        disableUnderline
        {...props}
        classes={null}
        className={classNames(classes.margin, classes.textField)}
        fullWidth
        startAdornment={
          <InputAdornment position='start'>$</InputAdornment>
        }
        value={String(value)}
        onChange={handleChange}
        inputComponent={TextMaskCustom}
      />
    </div>
  );
};

CurrencyInput.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired
};


export default withStyles(styles)(CurrencyInput);
