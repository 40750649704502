import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import MenuSimple from './MenuSimple';
import NavDashboard from './NavDashboard/NavDashboard';
import ItemProperties from './NavDashboard/ItemProperties/ItemProperties';
import images from 'assets/img';
import { FormattedMessage } from 'react-intl';

import './PanelDashboard.scss';


export class PanelDashboard extends Component {
  constructor(props) {
    super(props);
  }


  items = () => {
    const { resident_last_view } = this.props.profileState;
    const property = `property/${ resident_last_view }`;
    
    return [
      {
        url: `${ property }/dashboard`,
        path: 'dashboard',
        name: <FormattedMessage id="Layout.Menu.Budget"/>,
        image: images.menu.budget
      },
      {
        url: `${ property }/contacts`,
        path: 'contacts',
        name: <FormattedMessage id="Layout.Menu.Contacts"/>,
        image: images.menu.neighbors
      },
      {
        url: `${ property }/regulation`,
        path: 'regulation',
        name: <FormattedMessage id="Layout.Menu.Documents"/>,
        image: images.menu.rules
      },
      {
        url: `${ property }/providers`,
        path: 'providers',
        name: <FormattedMessage id="Layout.Menu.Providers"/>,
        image: images.menu.providers
      },
      {
        url: `${ property }/budget/edition`,
        path: 'budget/edition',
        name: <FormattedMessage id="Layout.Menu.Expenses"/>,
        image: images.menu.expenses
      },
      {
        url: `${ property }/bookings`,
        path: 'budget/edition',
        name: <FormattedMessage id="Layout.Menu.bookings" />,
        image: images.menu.bookings
      }
    ];
  }

  handleLogOut = () => {
    localStorage.clear();
    this.context.router.push('');
  };

  handleURL = (where) => () => {
    this.context.router.push(`/${where}`);
  };

  render() {
    const { children, params, profileState ,showProperties } = this.props;

    return (
      <div className='PanelDashboard'>
        <div className='PanelDashboard__menu'>
          <div className='PanelDashboard__menu__simple'>
            <div className='PanelDashboard__menu__simple__container'>
              <MenuSimple
                items={this.items()}
                urlConfig={`property/${this.props.params.property}/profile/configuration`}
                resident_role={profileState.resident_role}
                handleURL={this.handleURL}
              />
            </div>
          </div>
        </div>
        {
          !showProperties ?
          <div id='ItemProperties'>
            <ItemProperties/>
          </div>: null
        }
        <div className='PanelDashboard__container'>
          <div className='PanelDashboard__container__nav'>
            <NavDashboard
              params={params}
              showProperties={!showProperties}
              profileState={ profileState }
              handleURL={this.handleURL}
            />
          </div>
          <div className='PanelDashboard__container__body col'>
            {children}
          </div>
        </div>
      </div>
    );
  }
}

PanelDashboard.contextTypes = {
  router: PropTypes.shape()
};
PanelDashboard.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
const mapStateToProps = state => {
  return {
    loginState: state.loginState,
    profileState: state.get('profileState'),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PanelDashboard);
