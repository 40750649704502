import React from 'react';
import { FormattedMessage } from 'react-intl';
import StarBorder from '@material-ui/icons/StarBorder';
import Today from '@material-ui/icons/Today';
import NoteIcon from '@material-ui/icons/Note';
import './EventDetail.scss';
import Button from 'components/Button';

const EventDetail = event => {
    const {
        full_name,
        building_name,
        dwelling_number,
        status,
        title,
        created,
        start,
        end,
        notes,
        onReject,
        onRegisterPayment,
        onCancel,
        onApprove
    } = event;

    const statusLowerCased = status.toLocaleLowerCase();

    return (
        <div className={ `EventDetail col subtitle` }>
            <h5>{ full_name }</h5>
            <label className='subtitle'>{ building_name }, { dwelling_number }</label>
            <div className={ `status ${ statusLowerCased }-colors subtitle-2` }>
                <FormattedMessage id={ `Status.${ status }` }/>
            </div>
            <hr/>
            <h5>{ title }</h5>
            <div className='row'>
                <StarBorder />
                <div className='col'>
                    <label>
                        <FormattedMessage id='EventDetail.startDate' />
                    </label>
                    <label>{ start.format('dddd, DD [de] MMMM') }</label>
                    <label>{ start.format('HH:mm') } - { end.format('HH:mm') }</label>
                </div>
            </div>
            <div className='row'>
                <Today/>
                <div className='col'>
                    <label>
                        <FormattedMessage id='EventDetail.createdDate' />
                    </label>
                    <label>{ created.format('dddd, DD [de] MMMM') }</label>
                </div>
            </div>
            { !!notes && <div className='row'>
                    <NoteIcon/>
                    <div className='col'>
                        <label>
                            <FormattedMessage id='EventDetail.notes' />
                        </label>
                        <label>{ notes }</label>
                    </div>
                </div>
            }
            <hr/>
            <div className='row row-flex-end'>
                { status.indexOf('PENDING') > -1 && (
                    <Button variant="outlined" color='secondary' onClick={_ => onReject(event)}>
                        <FormattedMessage id='EventDetail.reject'/>
                    </Button>
                )}
                { status === 'PENDING' && (
                    <Button variant="contained" color='primary' onClick={_ => onApprove(event)}>
                        <FormattedMessage id='EventDetail.approve'/>
                    </Button>
                )}
                { status.indexOf('PENDING_PAYMENT') > -1 && (
                    <Button variant="contained" color='primary' onClick={_ => onRegisterPayment(event)}>
                        <FormattedMessage id='EventDetail.payment'/>
                    </Button>
                )}
                { (status.indexOf('APPROVED') > -1 || status.indexOf('PAID') > -1) && (
                    <Button variant="outlined" color='secondary' onClick={_ => onCancel(event)}>
                        <FormattedMessage id='EventDetail.cancel'/>
                    </Button>
                )}
            </div>
        </div>
    )
};

export default EventDetail;
