import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import * as Actions from '../../../budgetActions';

import BudgetAsset from '../../../components/BudgetAsset/BudgetAsset';
import Table from 'components/Table';
import PDF from 'assets/img/pdf.svg';

import './BudgetCharged.scss';

export class BudgetCharged extends Component {
  componentDidMount() {
    const { activeSelected } = this.props.budgetState;
    this.handleUpdateData(activeSelected);
  }

  componentDidUpdate(prevProps){
    const activeSelectedBefore = prevProps.budgetState.activeSelected;
    const activeSelectedNext = this.props.budgetState.activeSelected;
    if(activeSelectedBefore !== activeSelectedNext){
      const { activeSelected } = this.props.budgetState;
      this.handleUpdateData(activeSelected);
    }
  }

  handleUpdateData = (active) => {
    const { property } = this.props.params;
    this.props.clearDataConcepts();
    this.props.getIncomesChargered({
      property,
      active
    });
  }

  handleOpen(row) {
    window.open(row.url_receipt, "_blank")
  }

  componentWillUnmount(){
    this.props.clearDataConcepts();
  }

  render() {
    const { conceptsToCollect, loading } = this.props.budgetState;
    
    return (
      <div className='BudgetCharged'>
        <BudgetAsset
          headTitle='Ingresos de cuotas de mantenimiento'
        />
        <Table
          columns={[
            {
              headerName: 'Casa/Depto.',
              field: 'number'
            }, {
              headerName: 'Residente',
              field: 'full_name',
              placeholder: 'No encontrado'
            }, {
              headerName: 'Fecha de registro',
              field: 'payment_date',
              date: true
            }, {
              headerName: 'Monto pagado',
              field: 'payment_amount',
              currency: true
            }, {
              headerName: 'Recibo',
              cellProps: {
                align: 'right'
              },
              svg: PDF,
              onClick: this.handleOpen.bind(this),
            }
          ]}
          tableProps={{
            stickyHeader: true
          }}
          loading={ loading.GET_INCOMES_CHARGERED?.status }
          rows={ conceptsToCollect }
        />
      </div>
    );
  }
}

BudgetCharged.contextTypes = { 
  router: PropTypes.shape()
};

BudgetCharged.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  budgetState: state.get('budgetState')
});

export default connect(mapStateToProps, mapDispatchToProps)(BudgetCharged);
