import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import Tools from 'assets/img/amenities/settings.svg';
import { FormattedMessage } from 'react-intl';
import './MenuSimple.scss';

export class MenuSimple extends Component {

  isOptionSelected(pathName) {
    const { currentPath } = this.props;
    const { pathname } = currentPath.locationBeforeTransitions;
    return pathname.indexOf((`/${ pathName }`).replace('//', '/')) > -1 ? 'item selected' : 'item';
  }

  render() {
    const {
      items,
      urlConfig,
      handleURL
    } = this.props;
    return (
      <div className="side-menu">
        <div className="subtitle">
          <FormattedMessage id="Layout.Menu.Title"/>
        </div>
        <div className="options">
          {
            (items || []).map((item, index) => (
              <div
                key={index}
                className = {this.isOptionSelected(item.url)}
                onClick={handleURL(item.url)}
              >
                <div className="icon-container">
                  <ReactSVG src={item.image} />
                </div>
                <label className='heading-5'>{ item.name }</label>
              </div>
            ))
          }
        </div>
        <div
          className={
            this.isOptionSelected('configuration') + ' config-button'
          }
          onClick={handleURL(urlConfig)}
        >
          <div className="icon-container">
            <ReactSVG src={Tools} />
          </div>
          <label className='heading-5'>
            <FormattedMessage id="Layout.Menu.Config"/>
          </label>
        </div>
      </div>
    );
  }
}

MenuSimple.contextTypes = {
  router: PropTypes.shape()
};
MenuSimple.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
const mapStateToProps = state => ({
  currentPath: state.get('routing')
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuSimple);
