import * as ACTION from 'containers/Accounts/actionTypes';

const initialState = {
  loading: {},
  imoblyAccount: false,
  accounts: {
    result: [],
  },
  accountNew: {
    type_id: 1,
    bank_id: '0',
    number_clabe: '',
    alias_account: '',
    error: {}
  },
  idNewAccount: null,
  costs: {
    trial_days: 0,
    monthly_costs: 0,
  },
  accountImobly: {
  }
};

const accountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.ADD_DATA_ACCOUNTS:
      return { ...state, ...action.data };
    // /* ------------------ SET ACCOUNT ------------------ */
    case ACTION.LOADING_SET_ACCOUNT:
      return { ...state, loading: { SET_ACCOUNT: { status: true } } };
    case ACTION.RECEIVE_SET_ACCOUNT:
      return { ...state, loading: { SET_ACCOUNT: undefined }, idNewAccount: action.result.data.result };
    case ACTION.ERROR_SET_ACCOUNT:
      return { ...state, loading: { SET_ACCOUNT: { status: false, detail: action.error.response } } };

    // /* ------------------ GET ACCOUNT ------------------ */
    case ACTION.LOADING_GET_ACCOUNTS:
      return { ...state, loading: { SET_ACCOUNT: { status: true } } };
    case ACTION.RECEIVE_GET_ACCOUNTS:
      return { ...state, loading: { SET_ACCOUNT: undefined }, accounts: { ...state.data, ...action.result.data } };
    case ACTION.ERROR_GET_ACCOUNTS:
      return { ...state, loading: { SET_ACCOUNT: { status: false, detail: action.error.response } } };


    case ACTION.LOADING_GET_COSTS:
      return { ...state, loading: { GET_COSTS: { status: true } } };
    case ACTION.RECEIVE_GET_COSTS:
      return { ...state, loading: { GET_COSTS: undefined }, costs: action.result.data.result };
    case ACTION.ERROR_GET_COSTS:
      return { ...state, loading: { GET_COSTS: { status: false, detail: action.error.response } } };


    case ACTION.LOADING_GET_ACCOUNT_IMOBLY:
      return { ...state, loading: { GET_ACCOUNT_IMOBLY: { status: true } } };
    case ACTION.RECEIVE_GET_ACCOUNT_IMOBLY:
      return { ...state, loading: { GET_ACCOUNT_IMOBLY: undefined }, accountImobly: action.result.data };
    case ACTION.ERROR_GET_ACCOUNT_IMOBLY:
      return { ...state, loading: { GET_ACCOUNT_IMOBLY: { status: false, detail: action.error.response } } };


    default:
      return { ...state };
  }
};

export default accountsReducer;
