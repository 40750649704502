import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import Button from 'components/Button';
import TextField from '@material-ui/core/TextField';

import './Code.scss';

class CodeForm extends Component {
  render() {
    const {
      registro, isRestore, emailError, code, codeError, goTo, onChangeCode, onSubmit, resendCode
    } = this.props;
    const colorText = codeError ? 'red' : '#616161';
    return (
      <div className='Code'>
        <div className='Code__title'>¡Ya está lista tu cuenta!</div>
        <div className='Code__text'>
          Ahora solo introduce el <strong>código</strong> que acabamos de enviarte por e-mail.
        </div>
        {
          isRestore
          ? <div className='Code__email'>
            <TextField
              type='email'
              name='registro'
              label='Correo a validar'
              fullWidth
              value={registro}
              onChange={this.onChange}
              error={!!emailError}
              helperText={emailError}
            />
            </div>
          : null
        }
        <div className='Code__input'>
          <InputMask
            mask='0 0 0 0 0'
            maskChar='_'
            alwaysShowMask
            formatChars={{ 0: '[0-9]' }}
            style={{
              outline: '0px',
              letterSpacing: '10px',
              borderWidth: 0,
              width: '100%',
              textAlign: 'center',
              fontWeight: '200',
              fontSize: 60,
              color: colorText,
            }}
            onChange={onChangeCode}
            value={code}
          />
          {
            codeError
            ? <label className='Code__error'>{codeError}</label>
            : null
          }
        </div>
        <div className='Code__button'>
          <Button variant='contained' color='secondary' onClick={onSubmit}>Continuar</Button>
        </div>
        <div className='Code__label'>
          {
            registro
            ? <label className='Code__label__label' onClick={resendCode}>Envíar código nuevamente</label>
            : null
          }
          <label className='Code__label__label' onClick={goTo('login')}>Iniciar sesión</label>
        </div>
      </div>
    );
  }
}

CodeForm.defaultProps = {
  user: '',
  password: '',
  error: {},
};

CodeForm.propTypes = {
  user: PropTypes.string,
  password: PropTypes.string,
  error: PropTypes.object,
  versionName: PropTypes.string,
  goTo: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  responseFacebook: PropTypes.func,
  responseGoogle: PropTypes.func,
  onFailure: PropTypes.func
};

export default CodeForm;
