import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../Budget/budgetActions';
import BudgetTab from '../Budget/components/BudgetTab/BudgetTab';
import Tabs from './Tabs';
import Body from './Body';
import './Bookings.scss';

export class Bookings extends Component {
    constructor(){
        super();
        this.state = {
        }
    }

    componentDidMount() {
      this.getPropertySelected();
    }
    
    getPropertySelected(){
      const { property } = this.props.params;

      this.props.getActives({ property }).then(() => {
        this.getAmenitiesFromSelectedProperty();
      })
    }

    getSharedAmenityIndex() {
      const actives = this.handleGetActivesFiltered();
      const  { activeSelected } = this.props.budgetState;

      return actives.amenity.findIndex(amenity => amenity.value === activeSelected);
    }

    getAmenitiesFromSelectedProperty(){
      const  { activeSelected } = this.props.budgetState;
      let actives = this.handleGetActivesFiltered();
      const sharedAmenityIndex = this.getSharedAmenityIndex();

      if (sharedAmenityIndex > -1) {
        this.setState({
          amenities: null,
          selectedAmenityId: actives.amenity[sharedAmenityIndex].value,
          selectedNameAmenity: actives.amenity[sharedAmenityIndex].label
        });
      } else {
        const { selectedAmenityIndex } = this.state;
        const selectedBuilding = actives.buildings.find(
          (building) => building.value === activeSelected
        );
        const amenities = (selectedBuilding?.amenities || [ ]).sort((a, b) => {
          if (a.id > b.id) return 1;
          if (b.id > a.id) return -1;
        
          return 0;
        });

        this.setState({
          amenities: amenities,
          selectedAmenityId: amenities[selectedAmenityIndex || 0]?.id,
          selectedNameAmenity: amenities[selectedAmenityIndex || 0]?.name
        });
      }
    }

    handleGetActivesFiltered = () => {
        const { actives } = this.props.budgetState;
        const buildings = [];
        const amenity = [];

        if (actives?.result) {
          Object.values(actives.result).forEach(item => {
            const value = item.type === 'amenity' && item.amenities ? item.amenities[0]?.id : item.id;

            const row = {
              ...item,
              value,
              label: item.name,
              iconType: item.icon,
              amount: item.balance
            };
            
            if (item.type === 'building') {
              buildings.push(row);
            } else {
              amenity.push(row);
            }
          });
        }
        return { buildings, amenity };
    }

    mapCondominiumItems = () => {
        const actives = this.handleGetActivesFiltered();

        const sections = [];
        let auxSection = [];
        if (actives.buildings.length) {
          actives.buildings.forEach(elem => {
            if(elem.amenities) {
              auxSection.push({...elem});
            }
          });
          if (auxSection.length) {
            sections.push({
              title: 'Inmuebles',
              content: auxSection
            });
          }
        }
        if (actives.amenity.length) {
          sections.push({
            title: 'Amenidades compartidas',
            content: actives.amenity
          });
        }
        return sections; 
    }

    onPropertyChange = id => () => {
      this.props.addData({ activeSelected: id }).then(this.getAmenitiesFromSelectedProperty.bind(this));
    };

    onTabChange = (index) => {
      const { amenities } = this.state;

      this.setState({
        selectedAmenityId: amenities[index]?.id,
        selectedAmenityIndex: index,
        selectedNameAmenity: amenities[index]?.name
      });
    }

    render(){
        const { activeSelected } = this.props.budgetState;
        const { amenities, selectedAmenityId, selectedNameAmenity } = this.state;
        
        return (
          <>
            <div className="Bookings col animate__animated animate__fadeIn">
              <div className="row flex-zero">
                <BudgetTab
                  items={this.mapCondominiumItems()}
                  value={activeSelected}
                  onChange={this.onPropertyChange}
                />
              </div>
              <div className="col">
                <Tabs
                  defaultActiveKey={ String(this.getSharedAmenityIndex()) }
                  items={ amenities }
                  onChange={ this.onTabChange.bind(this) }
                />
                <Body
                  amenityId={selectedAmenityId}
                  selectedNameAmenity={selectedNameAmenity}
                  refresh={ this.getPropertySelected.bind(this) }
                />
              </div>
            </div>
          </>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  budgetState: state.get('budgetState'),
});

export default connect(mapStateToProps, mapDispatchToProps) (Bookings);