import axios from 'axios';
import environments from './environments';
import { browserHistory } from 'react-router';

const http = () => {

    let baseURL = environments.apiUrl;

    const client = axios.create({
	    baseURL,
	    headers: {
			'Content-Type': 'application/json'
	    },
	});
	client.interceptors.request.use(config => {
		const token = localStorage.getItem('imobly');
		config.headers.Authorization = token ? `Bearer ${ token }` :  null;
		return config;
	}, err => Promise.reject(err));

	client.interceptors.response.use(config => config, err => {
		if (err.response.status === 401) {
			localStorage.clear();
			browserHistory.push('/login');
		}
		return Promise.reject(err);
	});

	return client;
}

export default http();
