import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Recaptcha from 'react-recaptcha';

import TextField from '@material-ui/core/TextField';
import environments from 'constants/environments';
import Button from 'components/Button';
import Text from 'components/Text';

import './RememberForm.scss';

class RememberForm extends Component {
  render() {
    const {
      email, emailError, onChange, callback, onSubmit, goTo
    } = this.props;
    return (
      <div className='Remember'>
        <div className='Remember__container'>
          <div className='Remember__container__title'>Recuperar contraseña</div>
          <div className='Remember__container__separator' />
          <div className='Remember__text'>
            Ingresa los siguientes datos, envíaremos una contraseña provisional a tu correo.
          </div>
          <div className='Remember__input'>
            <TextField
              label='Email'
              fullWidth
              type='email'
              value={email}
              error={!!emailError}
              helperText={emailError}
              onChange={onChange}
            />
          </div>
          <div className='Remember__input'>
            <Recaptcha
              sitekey={ environments.recaptchaSiteKey }
              render='explicit'
              verifyCallback={callback}
            />
          </div>
          <div className='Remember__button'>
            <Button variant='contained' color='secondary' onClick={onSubmit}>Continuar</Button>
          </div>
          <div className='Login__signup'>
            <div className='Login__signup__text'>
              <Text variant='caption' id='LoginPage.newAccount' />
            </div>
            <span className='Login__signup__register' onClick={goTo('login')}>
              <Text variant='caption' id='LoginPage.login' />
            </span>
          </div>
        </div>
      </div>
    );
  }
}

RememberForm.defaultProps = {
  email: '',
  emailError: '',
};

RememberForm.propTypes = {
  email: PropTypes.string,
  emailError: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired
};

export default RememberForm;
