import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import * as Actions from '../../neighborsActions';

import RelationItem from './RelationItem';
import NewContact from '../NewContact/NewContact';

import './Relations.scss';

class Relations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAdd: false,
      toEdit: 0,
    };
  }

  handleShowAddContact = () => {
    this.setState({ showAdd: true });
  }

  handleCloseAddContact = () => {
    this.setState({ showAdd: false, toEdit: 0 });
  }

  handleEditItem = id => () => {
    this.setState({ toEdit: id });
  }

  render() {
    const { showAdd, toEdit } = this.state;
    const { params } = this.props;
    const { result } = this.props.neighborState.contacts;
    return (
      <div className='Relations'>
        <div className='Relations__list'>
          {
            result && result.length > 0
            ? result.map((item)=>{
              return item.id != toEdit
              ? <RelationItem {...item} onEdit={this.handleEditItem(item.id)}/>
              : <NewContact params={params} onCancel={this.handleCloseAddContact} onEdit={toEdit} onEditData={item} />
              })
            : <div className='Relations__list__cont'>Sin contactos relacionados encontrados.</div>
          }
        </div>
        {
          (result && result.length === 0) || showAdd
          ? <NewContact params={params} onEdit={toEdit} onCancel={this.handleCloseAddContact} />
          : null
        }   
        <div className='Relations__opt'>
          {
            !showAdd
            ? <div className='Relations__opt__label' onClick={this.handleShowAddContact}>Agregar otro contacto</div>
            : null
          }
        </div>
      </div>
    );
  }
}

Relations.contextTypes = { 
  router: PropTypes.shape()
};

Relations.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  neighborState: state.get('neighborState'),
});

export default connect(mapStateToProps, mapDispatchToProps)(Relations);

