import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import * as Actions from '../../Property/propertyActions';

import BuildListItem from './BuildListItem';
import './BuildList.scss';

class BuildList extends React.Component {

  handleBindOpen = (id, name) => {
    const { behaviorScreen, access } = this.props.propertyState;
    this.props.addPropertyData({
      behaviorScreen: {
        ...behaviorScreen,
        bindOptions: true,
        bindName: name,
        bindWho: id,
        bindItems: access[id] || [],
      }
    });
  }

  goTo = (type,uid) => () => {
    const { id, property } = this.props.params;
    const json = { 1: 'tower', 2: 'home'};
    this.context.router.push(`/Wizard/${id}/property/${property}/${json[type]}/${uid}`);
  }

  handleDelete = id => {
    const { property } = this.props.params;
    this.props.delBuilding({
      idProperty: property,
      idBuilding: id
    }).then(()=>{
      this.props.getBuildings({
        idProperty: property
      }).then(()=>{
        const { result } = this.props.propertyState.buildings;
        if(result===null){return;}
        for (let i = 0; i < result.length; i += 1) {
          this.props.getAmenitiesAccess({
            idProperty: property,
            idBuilding: result[i].id
          });
        }
      });
    });
  }

  render() {
    const { params } = this.props;
    const { bindWho } = this.props.propertyState.behaviorScreen;
    const { result } = this.props.propertyState.buildings;
    return (
      <div className='BuildList'>
        {
          result ? result.map((item, index)=>{
            return(
              <BuildListItem 
                selected={ item.id !== bindWho ? false : true }
                hide={ bindWho && item.id !== bindWho }
                key={`BL${index}`} 
                {...item} 
                params={params}
                goTo={this.goTo}
                onDelete={this.handleDelete}
                onAdd={this.handleBindOpen}
              />
            );
          })
          : <div className='BuildList__default'>Aún no tienes edificios o casas</div>
        }
      </div>
    );
  }
}

BuildList.contextTypes = {
  router: PropTypes.shape()
};

BuildList.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  propertyState: state.get('propertyState')
});

export default connect(mapStateToProps, mapDispatchToProps)(BuildList);

