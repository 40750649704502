import React, { Component } from 'react';
import { toMoneyFormat } from '../../../utils/format';

import './SectionResume.scss';

export class SectionResume extends Component {
  render() {
    const { available_balance, estimated_budget, pending_toPay } = this.props;
    return (
      <div className='SectionResumeStatus'>
        <div className='SectionResume__tabs'>
          <div className='SectionResume__tabs__double'>
            <i className='SectionResume__tabs__double__line' />
            <div>
              <label className="subtitle-2">Saldo Disponible</label>
             <p className='heading-3'>$ {toMoneyFormat(available_balance || 0)} </p>
            </div>
            <i className='SectionResume__tabs__double__line' />
            <div>
              <label className="subtitle-2">Gastos presupuestados</label>
              <p className='heading-3'>$ {toMoneyFormat(estimated_budget || 0)} </p>
            </div>
            <i className='SectionResume__tabs__double__line' />
            <div className='SectionResume__tabs__tab'>
              <label className="subtitle-2">Pago pendiente</label>
              <p className='heading-3'>$ {toMoneyFormat(pending_toPay || 0)}</p>
          </div>
          </div>
         
        </div>
      </div>
    );
  }
}

export default SectionResume;
