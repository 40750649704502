export const LOADING_BOOKING_REJECTION = 'LOADING_BOOKING_REJECTION';
export const RECEIVE_BOOKING_REJECTION = 'RECEIVE_BOOKING_REJECTION';
export const ERROR_BOOKING_REJECTION = 'ERROR_BOOKING_REJECTION';

export const LOADING_BOOKING_APPROVEMENT = 'LOADING_BOOKING_APPROVEMENT';
export const RECEIVE_BOOKING_APPROVEMENT = 'RECEIVE_BOOKING_APPROVEMENT';
export const ERROR_BOOKING_APPROVEMENT = 'ERROR_BOOKING_APPROVEMENT';

export const LOADING_BOOKING_CANCELLATION = 'LOADING_BOOKING_CANCELLATION';
export const RECEIVE_BOOKING_CANCELLATION = 'RECEIVE_BOOKING_CANCELLATION';
export const ERROR_BOOKING_CANCELLATION = 'ERROR_BOOKING_CANCELLATION';

export const LOADING_GET_AMENITY = 'LOADING_GET_AMENITY';
export const RECEIVE_GET_AMENITY = 'RECEIVE_GET_AMENITY';
export const ERROR_GET_AMENITY = 'ERROR_GET_AMENITY';