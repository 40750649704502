import wizardHeader from './img-bg-bon.png';
import wizardLogo from './imobly-tutorial.svg';
import wizardCarousel1 from './carousel/help1.png';
import wizardCarousel2 from './carousel/help2.png';
import wizardCarousel3 from './carousel/help3.png';
import wizardCarousel4 from './carousel/help4.png';
import wizardCarousel5 from './carousel/help5.png';

import menuLogo from './logo-bar.svg';
import menuNeighbors from './vecinos.svg';
import menuRules from './reglamentos.svg';
import menuProviders from './proveedores.svg';
import menuPayments from './validacionpagos.svg';
import menuBudget from './dashboard.svg';
import menuExpenses from './gastos-y-cuotas.svg';
import menuBookings from './Reservation.svg';

import paymentSuccess from './paySucces.png';
import paymentError from './pay-error.png';
import accountOk from './ok.png';
import deleteIcon from './delete.svg';

const images = {
    wizard: {
        header: wizardHeader,
        logo: wizardLogo,
        carousel: [
            wizardCarousel1,
            wizardCarousel2,
            wizardCarousel3,
            wizardCarousel4,
            wizardCarousel5,
        ]
    },
    menu: {
        logo: menuLogo,
        neighbors: menuNeighbors,
        rules: menuRules,
        providers: menuProviders,
        payments: menuPayments,
        budget: menuBudget,
        expenses: menuExpenses,
        bookings: menuBookings,
    },
    payment: {
        success: paymentSuccess,
        error: paymentError
    },
    account: {
        ok:  accountOk
    },
    delete: deleteIcon
}

export default images;
