import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ImageIcon from '@material-ui/icons/Image';
import * as Actions from '../../../../Budget/budgetActions';
import SimpleModal from 'components/SimpleModal';
import Form from 'components/Form';

import './EditBalanceModal.scss';

export const EditBalanceModal = props => {
  
  const [ amount, setAmount ] = useState(0);
  
  const [ action, setAction ] = useState('positive');

  const onSubmit = (values) => {
    const { activeSelected : active } = props.budgetState;
    const { property } = props.params;
    const { file } = values;

    return props.adjustBalance(active, {
      ...values,
      file: file?.file
    }).then(_ => props.getActives({
        property
      })
    ).then(props.onClose);
  }

  const { open, onClose, initialBalance } = props;
  
  return ( 
    <SimpleModal title='Ajustar saldo actual' open={ open } onClose={ onClose }>
      <Form
        loading={ props.budgetState.loading.ADJUST_BALANCE?.status }
        onCancel={ props.onClose }
        onSubmit={ onSubmit }
        clearOnSubmitted
        submitDisabled={ formValue => !formValue.disclaimer }
        notificationMessage='El saldo de tu edificio/conjunto ha sido ajustado'
        changeInterceptor={(name, value) => {
          if (name === 'amount') {
            setAmount(value);
          }
          if (name === 'type') {
            setAction(value);
          }
        }}
        controls={[
          {
            type: 'currency',
            label: 'SALDO INICIAL REGISTRADO',
            props: {
              defaultValue: initialBalance,
              InputProps: {
                readOnly: true,
              },
              className: 'align-left read-only'
            }
          },
          {
            name: 'type',
            type: 'radio',
            props: {
              required: true,
              label: 'ELIGE LA OPCIÓN DESEADA',
              row: true,
              defaultValue: 'positive',
              options: [            
                { value: 'positive', label: 'Agregar saldo' },
                { value: 'negative', label: 'Restar saldo' },
              ]
            }
          },
          [
            {
              name: 'amount',
              label: 'SALDO A AGREGAR/RESTAR',
              props: {
                required: true
              },
              type: 'currency'
            },
            {
              label: 'SALDO AJUSTADO',
              type: 'currency',
              props: {
                value: Number(initialBalance) + Number(action ==='positive' ? amount : amount * -1),
                InputProps: {
                  readOnly: true,
                },
                className: 'align-left read-only'
              }
            },
          ],
          {
            name: 'file',
            type: 'file',
            props: {
              label: 'EVIDENCIA (.docx, .pdf, .xlsx, .jpg, .png)',
              buttonProps: {
                startIcon: <ImageIcon />,
              },
              children: 'Adjuntar archivo'
            }
          },
          {
            name: 'disclaimer',
            type: 'checkbox',
            label: 'Estoy consciente del ajuste que he realizado y se verá reflejado en el saldo actual de mi edificio/conjunto de casas.',
            props: {
              required: true
            },
          }
        ]}
      />
    </SimpleModal>
  );
}

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  budgetState: state.get('budgetState'),
  providerState: state.get('providerState')
});


export default connect(mapStateToProps, mapDispatchToProps)(EditBalanceModal);