import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import * as Actions from '../../neighborsActions';

import HistoryHeader from './HistoryHeader';
import HistoryItem from './HistoryItem';

import './History.scss';

class History extends Component {

  componentDidMount() {
    const { property, dwellingID} = this.props.params;
    this.props.getNeighborPayments({
      pid: property,
      did: dwellingID
    });
  }

  render() {
    const { historyPays } = this.props.neighborState;
    const long = historyPays.length;
    return (
      <div className='History'>
        <HistoryHeader />
        {
          historyPays && long > 0
          ? historyPays.map((item, index)=>{
              return(
                <HistoryItem {...item} isLast={(index + 1) === long} />
              );
            })
          : <div className='History__null'>Sin historico encontrado</div>
        }
      </div>
    );
  }
}

History.contextTypes = { 
  router: PropTypes.shape()
};

History.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  neighborState: state.get('neighborState'),
});

export default connect(mapStateToProps, mapDispatchToProps)(History);
