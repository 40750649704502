import * as ACTION from './actionTypes';
import {
  getNeighborsN,
  getNeighborN,
  putNeighborN,
  setNeighborContactN,
  putNeighborContactN,
  getNeighborContactsN,
  getNeighborBalancesN,
  getNeighborPaymentsN, 
  setNeighborN,
  sendEmailResidentsN
} from '../../services/neighborService';

export function addData(data) {
  return {
    type: ACTION.ADD_DATA_NEIGHBOR,
    data
  };
}

export function getNeighbors({ idProperty, idBuilding }) {
  return {
    types: [
      ACTION.LOADING_GET_NEIGHBORS,
      ACTION.RECEIVE_GET_NEIGHBORS,
      ACTION.ERROR_GET_NEIGHBORS,
    ],
    promise: getNeighborsN({ idProperty, idBuilding })
  };
}

export function getNeighbor({ pid, did }) {
  return {
    types: [
      ACTION.LOADING_GET_NEIGHBOR,
      ACTION.RECEIVE_GET_NEIGHBOR,
      ACTION.ERROR_GET_NEIGHBOR,
    ],
    promise: getNeighborN({ pid, did })
  };
}

export function putNeighbor({ idProperty, idDwelling, dataResident }) {
  return {
    types: [
      ACTION.LOADING_PUT_NEIGHBOR,
      ACTION.RECEIVE_PUT_NEIGHBOR,
      ACTION.ERROR_PUT_NEIGHBOR,
    ],
    promise: putNeighborN({ idProperty, idDwelling, dataResident })
  };
}

export function setNeighbor({ idProperty, dataResident }) {
  return {
    types: [
      ACTION.LOADING_SET_NEIGHBOR,
      ACTION.RECEIVE_SET_NEIGHBOR,
      ACTION.ERROR_SET_NEIGHBOR,
    ],
    promise: setNeighborN({ idProperty, dataResident })
  };
}

export function setNeighborContact({
  idProperty, idResident, dataContact
}) {
  return {
    types: [
      ACTION.LOADING_SET_NEIGHBOR_CONTACT,
      ACTION.RECEIVE_SET_NEIGHBOR_CONTACT,
      ACTION.ERROR_SET_NEIGHBOR_CONTACT,
    ],
    promise: setNeighborContactN({
      idProperty, idResident, dataContact
    })
  };
}

export function getNeighborContacts({ idProperty, idResident }) {
  return {
    types: [
      ACTION.LOADING_GET_NEIGHBOR_CONTACTS,
      ACTION.RECEIVE_GET_NEIGHBOR_CONTACTS,
      ACTION.ERROR_GET_NEIGHBOR_CONTACTS,
    ],
    promise: getNeighborContactsN({ idProperty, idResident })
  };
}

export function putNeighborContact({
  idProperty, idResident, idContact, dataContact
}) {
  return {
    types: [
      ACTION.LOADING_PUT_NEIGHBOR_CONTACT,
      ACTION.RECEIVE_PUT_NEIGHBOR_CONTACT,
      ACTION.ERROR_PUT_NEIGHBOR_CONTACT,
    ],
    promise: putNeighborContactN({
      idProperty, idResident, idContact, dataContact
    })
  };
}

export function getNeighborBalances({ pid, did }) {
  return {
    types: [
      ACTION.LOADING_GET_NEIGHBOR_BALANCES,
      ACTION.RECEIVE_GET_NEIGHBOR_BALANCES,
      ACTION.ERROR_GET_NEIGHBOR_BALANCES,
    ],
    promise: getNeighborBalancesN({ pid, did })
  };
}

export function getNeighborPayments({ pid, did }) {
  return {
    types: [
      ACTION.LOADING_GET_NEIGHBOR_PAYMENTS,
      ACTION.RECEIVE_GET_NEIGHBOR_PAYMENTS,
      ACTION.ERROR_GET_NEIGHBOR_PAYMENTS,
    ],
    promise: getNeighborPaymentsN({ pid, did })
  };
}

export function sendEmailResidents(data) {
  return {
    types: [
      ACTION.LOADING_SEND_EMAIL_RESIDENTS,
      ACTION.RECEIVE_SEND_EMAIL_RESIDENTS,
      ACTION.ERROR_SEND_EMAIL_RESIDENTS
    ],
    promise: sendEmailResidentsN(data)
  };
}
