import React from 'react';
import imoblyLogo from 'assets/logos/logo-login.png';
import { browserHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';
import './Header.scss';

const Header = () => {

    const Register = () => { 
        browserHistory.push("/signup");
    }
    
    const login = () =>{ 
        window.location.href = "/landing.html";
    }

    return (
        <div className='header-terms' >
           <div className='img-logo'>  
                <img src={imoblyLogo} alt='imobly-logo'  className='logo' onClick={login} />
          </div>
           <div className='buttons-reg'>
             <p to='/' className='enter-okay' onClick={login} > <FormattedMessage id="Header.landing.button.enterOkay" /></p>
             <p className='test-free' onClick={Register} ><FormattedMessage id="Header.landing.button.testFree" /></p>
            </div>
        </div>
    );
}

export default  Header;
