import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';

import './MaxLengthTextField.scss';

const MaxLengthTextField = props => {

    const { maxLength } = props;

    const [ value, setValue ] = useState('');

    useEffect(() => {
        setValue(props.value || '');
    }, [ props.value ])

    const onChange = event => {
        const slicedValue = event.target.value.toString().slice(0, maxLength);
        setValue(slicedValue);
        props.onChange && props.onChange(slicedValue);
    }

    return (
        <div className='MaxLengthTextField col'>
            <TextField
                InputLabelProps={{
                    shrink: true
                }}
                { ...props }
                onChange={ onChange }
                value={ value }
            />
            <span className='max-length subtitle-2'>{ `${ value.length }/${ maxLength }` }</span>
        </div>
    );
}

export default MaxLengthTextField;
