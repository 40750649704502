import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import FormLabel from '@material-ui/core/FormLabel';

import './FileButton.scss';

export const FileButton = (props) => {
  
  const { children, label, required, name, buttonProps, inputProps, onChange } = props;

  const [ files, setFiles ] = useState({ });

  const getBase64 = (file, index) => new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = _ => resolve({
      base64: reader.result,
      index,
      file,
      sizeInKb: Math.round(file.size / 1000),
    });

    reader.onerror = _ => reject();
  });

  const onFileChange = e => Promise.all(Array.from(e.target.files).map((file, index) => getBase64(file, index))).then(setFiles);

  useEffect(_ => {
    onChange(inputProps?.inputProps?.multiple ? files : (files || [])[0]);
  }, [ files ])

  return (
    <div className='FileButton col'>
      { label && <FormLabel className='subtitle-2'>{ label }</FormLabel> }
      <label className='col' htmlFor="outlined-button-file">
        <Input
          id="outlined-button-file"
          type="file"
          name={ name }
          required={ required }
          onChange={ onFileChange }
          { ...inputProps }
        />
        <Button
          variant="outlined"
          component="span"
          color='primary'
          { ...buttonProps }
        >
          { children }
        </Button>
        <div className='col'>
          { 
            (Object.values(files) || [ ]).map((value, index) => (
              <label key={ index } className='file-name subtitle-2'>{ value.file.name }</label>
            ))
          }
        </div>
      </label>
    </div>
  );
};

export default FileButton;
