import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import Button from 'components/Button';

import './Form.scss';

export class Form extends Component {
  render() {
    const {
      handleChangeDataAccordion, onChange, name, date_start_admin, floors, handleSetFloors, error
    } = this.props;
    const { towerID } = this.props.params;

    return (
      <div className='AddTowerForm'>
        <div className='AddTowerForm__text'>Inicio de administración</div>
        <form className='AddTowerForm__form'>

          <TextField
            label='Fecha de inicio'
            type='date'
            name='date_start_admin'
            className='AddTowerForm__separador'
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={date_start_admin}
            onChange={onChange}
          />

          <TextField
            label='Nombre del edificio'
            type='text'
            name='name'
            fullWidth
            value={name}
            onChange={onChange}
            className='AddTowerForm__separador'
            InputLabelProps={{ shrink: true }}
            inputProps={{
              maxLength: 35,
            }}
            error={!!error.name}
            helperText={error.name}
          />

          {
            !towerID
              ? <div className='AccordionDisplay__options'>
                <TextField
                  label='Numero de pisos'
                  type='text'
                  name='floors'
                  fullWidth
                  value={floors}
                  onChange={handleChangeDataAccordion}
                  className={`${(floors === '' || floors === 0) && 'AccordionDisplay__options__input invalid'}`}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    maxLength: 2,
                  }}
                  disabled={towerID}
                />
              </div>
              : null
          }
          {
            towerID ?
              <Button className='Button_add' variant='outlined' color='secondary' onClick={handleSetFloors} type='button'>Agregar Piso</Button>
              : null
          }
        </form>

      </div>
    );
  }
}


Form.propTypes = {
  handleChangeDataAccordion: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  date_start_admin: PropTypes.string.isRequired,
  floors: PropTypes.number.isRequired
};

export default Form;
