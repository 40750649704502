import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { ReactSVG } from 'react-svg';
import * as Actions from '../actions';
import SimpleModal from 'components/SimpleModal';
import MaxLengthTextField from 'components/MaxLengthTextField';
import Button from 'components/Button';

import IconRequestRejected from 'assets/img/icon_reserva_rechazada.svg';

import './RejectModal.scss';

export const RejectModal = (props) => {

    const [ reason, setReason ] = useState('');

    const { onClose } = props;

    const onSubmit = _ => {
        const { booking_id } = props.request;
        
        props.onClose();
        props.rejectBooking(booking_id, reason).then(props.refresh);
    };

    const onReasonChange = value => setReason(value);

    return (
        <SimpleModal { ...props } title={ <FormattedMessage id='RejectModal.title'/> }>
            <div className='RejectModal'>
                <form className='col'>
                    <ReactSVG
                        className='icon row row-centered'
                        src={ IconRequestRejected }
                    />
                    <label>
                        <FormattedMessage id='RejectModal.description'/>
                    </label>
                    <MaxLengthTextField
                        autoFocus
                        onChange={ onReasonChange }
                        label={
                            <FormattedMessage id='RejectModal.reasonLabel'/>
                        }
                        maxLength={ 500 }
                    />
                </form>
                <hr/>
                <div className='row row-flex-end'>
                    <Button variant="outlined" color='secondary' onClick={ onClose }>
                        <FormattedMessage id='Button.cancel'/>
                    </Button>
                    <Button onClick={ onSubmit } disabled={ !reason?.length } variant="contained" color='primary'>
                        <FormattedMessage id='Button.accept'/>
                    </Button>
                </div>
            </div>
        </SimpleModal>
    );
}

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);

const mapStateToProps = state => ({
  bookingState: state.get('bookingState'),
});

export default connect(mapStateToProps, mapDispatchToProps) (RejectModal);