import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';

import './DateRangePicker.scss';

const DateRangePicker = (props) => {
  
  const [ start, setStart ] = useState();
  
  const [ end, setEnd ] = useState();
  
  useEffect(() => {
    if (start && end) {
      props.onChange({
        start,
        end
      })
    }
  }, [ end, start ]);

  return (
    <div className='DateRangePicker row align-center'>
      <TextField
        type='date'
        { ...props }
        InputLabelProps={{
          shrink: true
        }}
        value={ start }
        onChange={ e => setStart(e.target.value) }
        InputProps={
          {
            inputProps: { max: end }
          }
        }
      ></TextField>
      <label>al</label>
      <TextField
        type='date'
        { ...props }
        InputLabelProps={{
          shrink: true
        }}
        value={ end }
        onChange={ e => setEnd(e.target.value) }
        InputProps={
          {
            inputProps: { min: start }
          }
        }
      ></TextField>
    </div>
  );
};

export default DateRangePicker;
