import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import * as Actions from '../loginActions';
import stateReady from 'assets/img/state-warning.svg';
import './styles.scss';

class ErrorSend extends Component {
  activeAccount = () => {
    this.props.addLoginData({ loading: {} });
    this.context.router.push(`/login`);
  }

  render() {
    return (
      <div className='Unauthorized'>
        <img src={stateReady} alt='image ready' />
        <h2 className='Unauthorized__error'>Error al recuperar contraseña</h2>
        <p>Usuario no registrado en el sistema.</p>
        <Button onClick={this.activeAccount} variant='contained' color='secondary'>Continuar</Button>
      </div>
    );
  }
}

ErrorSend.contextTypes = { 
  router: PropTypes.shape()
};

ErrorSend.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorSend);
