import React from 'react';
import Select from 'components/Select';

const Item = (props) => {
  const {
    name
  } = props;
  return (
    <span>
      {name}
    </span>
  );
};

export const FieldPeriodicity = (props) => {
  
  const selectPeriodicity = () => {
    const { periods } = props;
    const arraySelect = [];

    if (periods.length > 0) {
      periods.forEach((item) => {
        arraySelect.push({
          value: item.period_id,
          label: <Item name={item.name} />
        });
      });
    }
    
    return arraySelect;
  };


  return (
    <Select
    items={selectPeriodicity()}
    name='periodicity'
    onChange={props.onChangePeriodicity}
    value={props.value}    
    />
  );
};

export default FieldPeriodicity;
