import http from 'constants/config';

// ********************* ACCOUNT ********************* //

export const getAccountsN = ({ property }) => http.get(
  `v1/properties/${property}/accounts`
);

export const setAccountN = ({ property, account }) => http.post(
  `v1/properties/${property}/accounts`,
  JSON.stringify(account)
);

export const getCostsN = ({ property }) => http.get(
  `v1/properties/${property}/monthly_costs`
);

export const getAccountImoblyN = (property) => http.get(
  `v1/clients/admin/account/${property}`
);


export const putCancelRecurringPaymentN = () => http.put(
  'v2/client/admin/account',
  JSON.stringify({})
);

export const postCreateSuscriptionN = () => http.post(
  'v2/client/admin/subscribe',
  JSON.stringify({})
);
