import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import * as Actions from '../loginActions';
import stateWarning from 'assets/img/state-warning.svg';
import './styles.scss';

class WithPending extends Component {
  activeAccount = () => {
    const { registro, loading } = this.props.loginState;
    this.props.getReset(registro).then(()=>{
      this.context.router.push(`/code`);
      this.props.addLoginData({
        loading: { 
          ...loading,
          REGISTER_SIGNUP: undefined,
          NORMAL_LOGIN: undefined
        },
        restore: true
      });
    });
  }

  render() {
    return (
      <div className='Unauthorized'>
        <img src={stateWarning} alt='image ready' />
        <h2 className='Unauthorized__warning'>¡Usuario pendiente de activación!</h2>
        <p>Tu cuenta ya fue registrada con anterioridad, sin embargo falta realizar su activación. Activa tu cuenta y disfruta de Imobly</p>
        <Button onClick={this.activeAccount} variant='contained' color='secondary'>Activar cuenta</Button>
      </div>
    );
  }
}


WithPending.contextTypes = { 
  router: PropTypes.shape()
};

WithPending.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithPending);
