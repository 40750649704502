export const LOG_OUT = 'LOG_OUT';

export const ADD_DATA_PROFILE = 'ADD_DATA_PROFILE';

export const LOADING_GET_PROFILE = 'LOADING_GET_PROFILE';
export const RECEIVE_GET_PROFILE = 'RECEIVE_GET_PROFILE';
export const ERROR_GET_PROFILE = 'ERROR_GET_PROFILE';

export const LOADING_PUT_PROFILE = 'LOADING_PUT_PROFILE';
export const RECEIVE_PUT_PROFILE = 'RECEIVE_PUT_PROFILE';
export const ERROR_PUT_PROFILE = 'ERROR_PUT_PROFILE';

export const LOADING_SET_REPORT = 'LOADING_SET_REPORT';
export const RECEIVE_SET_REPORT = 'RECEIVE_SET_REPORT';
export const ERROR_SET_REPORT = 'ERROR_SET_REPORT';
