import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SnackbarOrigin from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import './SnackBar.scss';

export class Snackbar extends Component {
  constructor() {
    super();
    this.state = {
      open: true,
      showDetail: false,
    }
  }

  handleOnClose = () => {
    this.props.onDelete();
    this.setState({ open: false });
  }

  handleToggleDetail = () => {
    this.setState({ showDetail: !this.state.showDetail });
  }

  render() {
    const { open, showDetail } = this.state;
    const { message, detail } = this.props;
    return (
      <SnackbarOrigin
        variant='error'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={10000}
        onClose={this.handleOnClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={
          <div className='SnackBar'>
            <span id='message-id'>{message}</span>
            {
              showDetail
              ? <div className='SnackBar__detail'>{detail}</div>
              : null
            }
          </div>
        }
        action={[
          <Button key='undo' color='secondary' size='small' onClick={this.handleToggleDetail}>
            { showDetail ? 'Ocultar' : 'Detalle' }
          </Button>,
          <IconButton
            key='close'
            aria-label='Close'
            color='inherit'
            onClick={this.handleOnClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    );
  }
}

Snackbar.defaultProps = {
  onDelete: () => {}
};

Snackbar.propTypes = {
  message: PropTypes.string,
  detail: PropTypes.string,
  onDelete: PropTypes.func,
};

export default Snackbar;
