import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';

import * as Actions from '../neighborsActions';
import * as propertyActions from '../../Property/propertyActions';
import Card from 'components/Card';
import TabAmenity from 'components/TabAmenity';
import environments from 'constants/environments';
import Button from 'components/Button';
import Table from 'components/Table';
import ModalInvitations from './ModalInvitations';
import ModalComposeMessage from './ModalComposeMessage';

import Message from 'assets/img/icon-email2.svg';
import CellPhone from 'assets/img/cellphone.svg';
import './Neighbors.scss';


export class Neighbors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showModalMessage: false,
    };
  }

  componentDidMount() {
    const { property } = this.props.params;
    this.props.getBuildings({
      idProperty: property
    }).then(() => {
      const { result } = this.props.propertyState.buildings;
      if (result && result[0]) {
        const buildingSelected = result[0].id;
        this.props.addData({
          buildingSelected
        }).then(() => {
          this.props.getNeighbors({
            idProperty: property,
            idBuilding: buildingSelected
          });
        });
      }
    });
  }

  onBuildingSelectedChange(prevProps) {
    const buildingSelectedBefore = prevProps.neighborState.buildingSelected;
    const buildingSelectedNext = this.props.neighborState.buildingSelected;
    if (buildingSelectedBefore !== buildingSelectedNext) {
      const { property } = this.props.params;
      const { buildingSelected } = this.props.neighborState;
      this.props.getNeighbors({
        idProperty: property,
        idBuilding: buildingSelected
      });
    }
  }

  componentDidUpdate(prevProps) {
    this.onBuildingSelectedChange(prevProps);
  }

  handleChangeBuildingSelected = value => () => {
    this.props.addData({ buildingSelected: value });
  }

  handleDefineBuildings = () => {
    let { result } = this.props.propertyState.buildings;
    return (result || []).map(item => ({
      value: item.id,
      title: item.name,
      subtitle: this.handleDefineTypeBuilding({ type: item.type_id, number: item.dwelling_number }),
      iconType: item.type_id === 1 ? 'tower' : 'home'
    }));
  }

  handleDefineTypeBuilding = ({ type, number }) => {
    if (type === 1) {
      return number > 1 ? 'Departamentos' : 'Departamento';
    }
    return number > 1 ? 'Casas' : 'Casa';
  }

  handleDefineTypeRow = () => {
    const { result } = this.props.propertyState.buildings;
    const { buildingSelected } = this.props.neighborState;
    
    const filter = (result || [ ]).filter(item => item.id === buildingSelected);

    if (!filter.length) {
      return 1;
    }
    return filter[0].type_id;
  }

  handleDefineNameuild = () => {
    const { buildingSelected } = this.props.neighborState;
    const { result } = this.props.propertyState.buildings;
    
    const filter = (result || [ ]).filter(item => item.id === buildingSelected);

    if (!filter.length) {
      return '';
    }
    
    return filter[0].name;
  }

  goTo({ id_client, dwelling_id }) {
    const { property } = this.props.params;
    this.context.router.push(`/property/${ property }/contacts/${ id_client }/dwellings/${ dwelling_id }`);
  }

  handleOpenModal = () => {
    this.setState({ showModal: true });
  }

  handleOpenModalMessage = () => {
    this.setState({ showModalMessage : true });
  }

  handleCloseModalMessage = () => {
    this.setState({ showModalMessage: false });
  }

  handleCloseModal = () => {
    this.setState({ showModal: false });
  }

  handleGoToInvitations = () => {
    const { property } = this.props.params;
    this.props.setInvitations({
      idProperty: property
    }).then(() => {
      this.setState({ showModal: false });
    });
  }

  render() {
    const { SEND_INVITATIONS } = this.props.propertyState.loading;
    const { showModal, showModalMessage } = this.state;
    const type = this.handleDefineTypeRow();
    const name = this.handleDefineNameuild();
    let { buildingSelected, neighbors, loading } = this.props.neighborState;
    
    neighbors = neighbors?.result || [];
    
    let tableColumns = [
      {
        headerName: type === 1 ? 'Depto' : 'Núm/Nombre',
        field: 'number'
      },
      {
        headerName: 'Nombre',
        field: 'full_name',
        placeholder: 'No indicado'
      },
      {
        headerName: 'Email',
        field: 'email',
        placeholder: 'No indicado'
      },
      {
        headerName: 'Teléfono',
        field: 'phone',
        placeholder: 'No indicado'
      },
      {
        button: 'Ver detalle',
        cellProps: {
          align: 'right'
        },
        onClick: this.goTo.bind(this)
      }
    ];

    if (type === 1) {
      tableColumns = [{
        headerName: 'Piso',
        field: 'floor'
      }].concat(tableColumns);
    }
    
    return (
      <div className='Neighbors col animate__animated animate__fadeIn'>
        <ModalInvitations
          open={showModal}
          onClose={this.handleCloseModal}
          goTo={ environments.playstoreUrl }
          sendInvitations={this.handleGoToInvitations}
          loading={SEND_INVITATIONS}
          name={name}
        />
        { neighbors.length > 0 &&
          <ModalComposeMessage 
            open={showModalMessage}
            onClose={this.handleCloseModalMessage}
            recipients={ neighbors }
            sendEmailResidents={this.props.sendEmailResidents}
            loadingNeighbor={this.props.loadingNeighbor}
          />
        }

        <div className='Neighbors__header'>
            <div className='Neighbors__header__tabs'>
              <div className='Neighbors__header__tabs__actives' >Inmuebles</div>
              <TabAmenity
                items={this.handleDefineBuildings()}
                value={buildingSelected}
                onChange={this.handleChangeBuildingSelected}
              />
            </div>
        </div>
        <div className='Neighbors__body'>

        <div className='Neighbors__title row space-between flex-zero'>
          <div className='heading-2 self-center'>Residentes</div>
          <div className='Neighbors__header__options'>
            <Button variant='outlined' color='secondary' onClick={this.handleOpenModalMessage}>
              <ReactSVG src={Message} className='Neighbors__header__options__email' id='message_email' />
              Redactar mensaje
            </Button>
            <Button variant='outlined' color='secondary' onClick={this.handleOpenModal}>
              <ReactSVG src={CellPhone} className='Neighbors__header__options__phone' />
              Enviar Invitación
            </Button>
          </div>
        </div>
        <Card className='card-list'>
          <div className='Neighbors__table'>
            <Table
              columns={ tableColumns }
              loading={ loading.GET_NEIGHBORS?.status }
              rows={ neighbors || [] }
            />
          </div>
        </Card>
        </div>
      </div>
    );
  }
}

Neighbors.contextTypes = {
  router: PropTypes.object
};

Neighbors.defaultProps = {
  getBuildings: null,
  getNeighbors: null,
  loginState: null,
  params: null,
  propertyState: null,
  neighborState: null,
  addData: null,
  setInvitations: null
};

Neighbors.propTypes = {
  getBuildings: PropTypes.func,
  getNeighbors: PropTypes.func,
  loginState: PropTypes.shape({
    loading: PropTypes.object,
    registro: PropTypes.string,
    restore: PropTypes.bool
  }),
  params: PropTypes.oneOfType([PropTypes.object]),
  propertyState: PropTypes.instanceOf(Object),
  neighborState: PropTypes.instanceOf(Object),
  addData: PropTypes.func,
  setInvitations: PropTypes.func,
};

const mapDispatchToProps = dispatch => bindActionCreators({ ...Actions, ...propertyActions }, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  neighborState: state.get('neighborState'),
  propertyState: state.get('propertyState'),
  profileState: state.get("profileState"),
  loading: state.get('propertyState').loading,
  loadingNeighbor: state.get('neighborState').loading
});

export default connect(mapStateToProps, mapDispatchToProps)(Neighbors);

