import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button/Button';

import './ModalCancelPayment.scss';
import SimpleModal from 'components/SimpleModal';
import SuccessIcon from 'assets/img/icon_alert.svg';
import Worry from 'assets/img/039-flushed.png';

class ModalCancelPayment extends Component {

  render() {
    const { open, onClose, loading, onCancelPayment, serviceDate } = this.props;
    
    return (
      <>
        <SimpleModal
          title={ !loading ? <FormattedMessage id='ModalCancelPayment.title_one_moment'/> : <FormattedMessage id='ModalCancelPayment.title_pity'/> }
          open={open}
          onClose={onClose}
        >
          <div className='ModalCancel'>
            <div className='body'>
              <div className='form'>
                {!loading ? (
                  <div className='image-worry'>
                    <img src={Worry} alt='Worry' />
                    <p className="regular-text space-regular">
                      <FormattedMessage id='ModalCancelPayment.text_pay_automatic'/>
                    </p>
                  </div>
                ) : (
                  <div className="success">
                    <img src={SuccessIcon} alt="Heart Broken"/>
                    <p className="regular-text text-justify">
                      <FormattedMessage
                        id='ModalCancelPayment.text_request_cancel'
                        values={{ serviceDate }}
                      />
                      <b>soporte@imobly.com.</b>
                    </p>
                  </div>
                )}
                <div className='ModalCancel__options'>
                  {!loading ? (
                    <>
                      <Button
                        variant='outlined'
                        color='secondary'
                        onClick={onClose}
                      >
                        No
                      </Button>
                      <Button
                        variant='contained'
                        color='secondary'
                        onClick={onCancelPayment}
                      >
                        Si
                      </Button>
                    </>
                    ) : (
                      <Button
                        variant='contained'
                        color='secondary'
                        onClick={onClose}
                      >
                        Aceptar
                      </Button>
                    )}
                </div>
              </div>
            </div>
          </div>
        </SimpleModal>
      </>
    );
  }
}

export default ModalCancelPayment;
