import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Text from 'components/Text';
import TextField from '@material-ui/core/TextField';

import * as propertyActions from '../../Property/propertyActions';

import './AddressAutocomplete.scss';

let searchTO = null;

export class AddressAutocomplete extends Component {
  constructor(props){
    super(props);
    this.state = { 
      error: {}
    }
  }

  handleChange = (type) => (e) => {
    const newProperty = this.props.propertyState.new;
    
    if (type === 'input') {
      if (e.target.name === 'name') {
        e.target.value = e.target.value.replace(/[^A-Za-zÀ-ú0-9\s]/g, "")
      }
      this.props.addPropertyData({ new: { ...newProperty, [e.target.name]: e.target.value } });
    }
  }

  handleChangeAddress = (e) => {
    this.props.addPropertyData({
      new: {
        ...this.props.propertyState.new,
        address: {
          ...this.props.propertyState.new.address,
          description: e.target.value
        }
      }
    });
    clearTimeout(searchTO);
    searchTO = setTimeout(() => {
      geocodeByAddress(this.props.propertyState.new.address.description)
        .then(results => getLatLng(results[0]))
        .then((latLng) => {
          this.props.addPropertyData({
            new: {
              ...this.props.propertyState.new,
              address: {
                ...this.props.propertyState.new.address,
                latitude: latLng.lat,
                longitude: latLng.lng,
              }
            }
          });
        }).catch(error => {
          console.error('Error', error);
        });
    }, 1400);
  }

  componentDidMount() {
    clearTimeout(searchTO)
  }

  render() {
    const { address, name } = this.props.propertyState.new;
    const { description } = address;
    const { error } = this.props;
    return (
      <form className='AddressAutocomplete'>
        <Text variant='caption' id='Address.instructions1' className='AddressAutocomplete__title' />
        <br />
        <TextField
          fullWidth
          name='name'
          type='text'
          id="propertyname"
          label={<Text onlyformat id='Address.propertyname' />}
          color="secondary"
          value={name}
          onChange={this.handleChange('input')}
          error={error && error.name ? true : false}
          helperText={error && error.name ? error.name : ''}
          InputLabelProps={{ shrink: true }}
          style={{ marginTop: '10px' }}
        />

        <TextField
          label={<Text onlyformat id='Address.address' />}
          name='description'
          type='text'
          fullWidth
          value={address.description ? description : ''}
          onChange={this.handleChangeAddress}
          error={error && error.description ? true : false}
          helperText={error && error.description ? error.description : ''}
          InputLabelProps={{ shrink: true }}
          style={{ marginTop: '10px' }}
        />

      </form>
    );
  }
}

AddressAutocomplete.contextTypes = {
  router: PropTypes.shape()
};

AddressAutocomplete.propTypes = {
  addPropertyData: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  ...propertyActions,
}, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  propertyState: state.get('propertyState')
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressAutocomplete);
