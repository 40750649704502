export const ADD_DATA_NEIGHBOR = 'ADD_DATA_NEIGHBOR';

export const LOADING_GET_NEIGHBORS = 'LOADING_GET_NEIGHBORS';
export const RECEIVE_GET_NEIGHBORS = 'RECEIVE_GET_NEIGHBORS';
export const ERROR_GET_NEIGHBORS = 'ERROR_GET_NEIGHBORS';

export const LOADING_GET_NEIGHBOR = 'LOADING_GET_NEIGHBOR';
export const RECEIVE_GET_NEIGHBOR = 'RECEIVE_GET_NEIGHBOR';
export const ERROR_GET_NEIGHBOR = 'ERROR_GET_NEIGHBOR';

export const LOADING_PUT_NEIGHBOR = 'LOADING_PUT_NEIGHBOR';
export const RECEIVE_PUT_NEIGHBOR = 'RECEIVE_PUT_NEIGHBOR';
export const ERROR_PUT_NEIGHBOR = 'ERROR_PUT_NEIGHBOR';

export const LOADING_SET_NEIGHBOR = 'LOADING_SET_NEIGHBOR';
export const RECEIVE_SET_NEIGHBOR = 'RECEIVE_SET_NEIGHBOR';
export const ERROR_SET_NEIGHBOR = 'ERROR_SET_NEIGHBOR';

export const LOADING_SET_NEIGHBOR_CONTACT = 'LOADING_SET_NEIGHBOR_CONTACT';
export const RECEIVE_SET_NEIGHBOR_CONTACT = 'RECEIVE_SET_NEIGHBOR_CONTACT';
export const ERROR_SET_NEIGHBOR_CONTACT = 'ERROR_SET_NEIGHBOR_CONTACT';

export const LOADING_GET_NEIGHBOR_CONTACTS = 'LOADING_GET_NEIGHBOR_CONTACTS';
export const RECEIVE_GET_NEIGHBOR_CONTACTS = 'RECEIVE_GET_NEIGHBOR_CONTACTS';
export const ERROR_GET_NEIGHBOR_CONTACTS = 'ERROR_GET_NEIGHBOR_CONTACTS';

export const LOADING_PUT_NEIGHBOR_CONTACT = 'LOADING_PUT_NEIGHBOR_CONTACT';
export const RECEIVE_PUT_NEIGHBOR_CONTACT = 'RECEIVE_PUT_NEIGHBOR_CONTACT';
export const ERROR_PUT_NEIGHBOR_CONTACT = 'ERROR_PUT_NEIGHBOR_CONTACT';

export const LOADING_GET_NEIGHBOR_BALANCES = 'LOADING_GET_NEIGHBOR_BALANCES';
export const RECEIVE_GET_NEIGHBOR_BALANCES = 'RECEIVE_GET_NEIGHBOR_BALANCES';
export const ERROR_GET_NEIGHBOR_BALANCES = 'ERROR_GET_NEIGHBOR_BALANCES';

export const LOADING_GET_NEIGHBOR_PAYMENTS = 'LOADING_GET_NEIGHBOR_PAYMENTS';
export const RECEIVE_GET_NEIGHBOR_PAYMENTS = 'RECEIVE_GET_NEIGHBOR_PAYMENTS';
export const ERROR_GET_NEIGHBOR_PAYMENTS = 'ERROR_GET_NEIGHBOR_PAYMENTS';

export const LOADING_SEND_EMAIL_RESIDENTS = 'LOADING_SEND_EMAIL_RESIDENTS';
export const RECEIVE_SEND_EMAIL_RESIDENTS = 'RECEIVE_SEND_EMAIL_RESIDENTS';
export const ERROR_SEND_EMAIL_RESIDENTS = 'ERROR_SEND_EMAIL_RESIDENTS';