import * as ACTION from './action-types';
import { getAdminsService, modifyTrialService } from '../../services/subscriptions-service';

export function getAdmins() {
  return {
    types: [
      ACTION.LOADING_GET_ADMINS,
      ACTION.RECEIVE_GET_ADMINS,
      ACTION.ERROR_GET_ADMINS,
    ],
    promise: getAdminsService()
  };
}

export function modifyTrial(aid, weeks) {
  return {
    types: [
      ACTION.LOADING_MODIFY_TRIAL,
      ACTION.RECEIVE_MODIFY_TRIAL,
      ACTION.ERROR_MODIFY_TRIAL,
    ],
    param: aid,
    promise: modifyTrialService(aid, weeks)
  };
}
