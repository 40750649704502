import * as ACTION from 'containers/Property/actionTypes';
import { simpleDate } from '../utils/format';
import moment from 'moment';

const initialState = {
  loading: {},
  properties: {},
  new: {
    id: null,
    name: '',
    address: {
      description: '',
      latitude: 0,
      longitude: 0,
    },
  },
  build: {
    name: '',
    date_start_admin: simpleDate(new Date()),
    floors: 1,
    data: {},
    edited: {},
    error: [],
  },
  buildings: {
    result: []
  },
  regulations: {
    result: []
  },
  amenities: {
    result: []
  },
  amenity: {},
  catalog_amenities: {
    result: []
  },
  access: {},
  bookings: [],
  behaviorScreen: {
    editAmenities: false,
    bindOptions: false,
    bindName: null,
    bindWho: null,
    bindItems: [],
  },
};

const propertyReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.ADD_PROPERTY_DATA:
      return { ...state, ...action.data };

    case ACTION.UNMOUNT_BUILD:
      return { ...state, build: { ...initialState.build, data: {}, edited: {} }, behaviorScreen: initialState.behaviorScreen };

    case ACTION.LOADING_SEND_INVITATIONS:
      return { ...state, loading: { ...state.loading, SEND_INVITATIONS: { status: true } } };
    case ACTION.RECEIVE_SEND_INVITATIONS:
      return { ...state, loading: { ...state.loading, SEND_INVITATIONS: undefined }, sendInvitations: true };
    case ACTION.ERROR_SEND_INVITATIONS:
      return { ...state, loading: { ...state.loading, SEND_INVITATIONS: { status: false, detail: action.error.response } } };


    case ACTION.LOADING_DOWNLOAD_MASIVE_TEMPLETE:
      return { ...state, loading: { ...state.loading, DOWNLOAD_MASIVE_TEMPLETE: { status: true } } };
    case ACTION.RECEIVE_DOWNLOAD_MASIVE_TEMPLETE:
      return { ...state, loading: { ...state.loading, DOWNLOAD_MASIVE_TEMPLETE: undefined } };
    case ACTION.ERROR_DOWNLOAD_MASIVE_TEMPLETE:
      return { ...state, loading: { ...state.loading, DOWNLOAD_MASIVE_TEMPLETE: { status: false, detail: action.error.response } } };


    case ACTION.LOADING_UPLOAD_MASIVE_TEMPLETE:
      return { ...state, loading: { ...state.loading, UPLOAD_MASSIVE_TEMPLATE: { status: true } } };
    case ACTION.RECEIVE_UPLOAD_MASIVE_TEMPLETE:
      return { ...state, loading: { ...state.loading, UPLOAD_MASSIVE_TEMPLATE: undefined }, masiveCharge: true };
    case ACTION.ERROR_UPLOAD_MASIVE_TEMPLETE:
      return { ...state, loading: { ...state.loading, UPLOAD_MASSIVE_TEMPLATE: { status: false, detail: action.error.response } } };

    // ------------------------ GET PROPERTIES ------------------------ //
    case ACTION.LOADING_GET_PROPERTIES:
      return { ...state, loading: { ...state.loading, GET_PROPERTIES: { status: true } } };
    case ACTION.RECEIVE_GET_PROPERTIES:
      return { ...state, loading: { ...state.loading, GET_PROPERTIES: undefined }, properties: { ...action.result.data.result } };
    case ACTION.ERROR_GET_PROPERTIES:
      return { ...state, loading: { ...state.loading, GET_PROPERTIES: { status: false, error: action.error.response } } };

    // ------------------------ GET PROPERTY ------------------------ //
    case ACTION.LOADING_GET_PROPERTY:
      return { ...state, loading: { ...state.loading, GET_PROPERTY: { status: true } } };
    case ACTION.RECEIVE_GET_PROPERTY:
      return { ...state, loading: { ...state.loading, GET_PROPERTY: undefined }, new: { ...state.new, ...action.result.data.result } };
    case ACTION.ERROR_GET_PROPERTY:
      return { ...state, loading: { ...state.loading, GET_PROPERTY: { status: false, error: action.error.response } } };

    // ------------------------ SET PROPERTY ------------------------ //
    case ACTION.LOADING_SET_PROPERTY:
      return { ...state, loading: { ...state.loading, SET_PROPERTY: { status: true } } };
    case ACTION.RECEIVE_SET_PROPERTY:
      return { ...state, loading: { ...state.loading, SET_PROPERTY: undefined }, new: { ...state.new, id: action.result.data.result } };
    case ACTION.ERROR_SET_PROPERTY:
      return { ...state, loading: { ...state.loading, SET_PROPERTY: { status: false, error: action.error.response } } };

    // ------------------------ PUT PROPERTY ------------------------ //
    case ACTION.LOADING_PUT_PROPERTY:
      return { ...state, loading: { ...state.loading, PUT_PROPERTY: { status: true } } };
    case ACTION.RECEIVE_PUT_PROPERTY:
      return { ...state, loading: { ...state.loading, PUT_PROPERTY: undefined }, new: { ...state.new, id: action.result.data.result } };
    case ACTION.ERROR_PUT_PROPERTY:
      return { ...state, loading: { ...state.loading, PUT_PROPERTY: { status: false, error: action.error.response } } };

    case ACTION.LOADING_DEL_PROPERTY:
      return { ...state, loading: { ...state.loading, DEL_PROPERTY: { status: true } } };
    case ACTION.RECEIVE_DEL_PROPERTY:
      return { ...state, loading: { ...state.loading, DEL_PROPERTY: undefined } };
    case ACTION.ERROR_DEL_PROPERTY:
      return { ...state, loading: { ...state.loading, DEL_PROPERTY: { status: false, error: action.error.response } } };

    // ------------------------ GET BUILDINGS ------------------------ //
    case ACTION.LOADING_GET_BUILDINGS:
      return { ...state, loading: { ...state.loading, GET_BUILDINGS: { status: true } } };
    case ACTION.RECEIVE_GET_BUILDINGS:
      return { ...state, loading: { ...state.loading, GET_BUILDINGS: undefined }, buildings: { ...action.result.data } };
    case ACTION.ERROR_GET_BUILDINGS:
      return { ...state, loading: { ...state.loading, GET_BUILDINGS: { status: false, error: action.error.response } } };

    // ------------------------ GET BUILDING ------------------------ //
    case ACTION.LOADING_GET_BUILDING:
      return { ...state, loading: { ...state.loading, GET_BUILDING: { status: true } } };
    case ACTION.RECEIVE_GET_BUILDING:
      return { ...state, loading: { ...state.loading, GET_BUILDING: undefined }, build: { ...state.build, ...action.result.data.result } };
    case ACTION.ERROR_GET_BUILDING:
      return { ...state, loading: { ...state.loading, GET_BUILDING: { status: false, error: action.error.response } } };

    // ------------------------ SET BUILDING ------------------------ //
    case ACTION.LOADING_SET_BUILDING:
      return { ...state, loading: { ...state.loading, SET_BUILDING: { status: true } } };
    case ACTION.RECEIVE_SET_BUILDING:
      return { ...state, loading: { ...state.loading, SET_BUILDING: undefined }, build: { ...state.build, idBuilding: action.result.data.result } };
    case ACTION.ERROR_SET_BUILDING:
      return { ...state, loading: { ...state.loading, SET_BUILDING: { status: false, error: action.error.response } } };

    // ------------------------ DELETE BUILDING ------------------------ //
    case ACTION.LOADING_DEL_BUILDING:
      return { ...state, loading: { ...state.loading, DEL_BUILDING: { status: true } } };
    case ACTION.RECEIVE_DEL_BUILDING:
      return { ...state, loading: { ...state.loading, DEL_BUILDING: undefined } };
    case ACTION.ERROR_DEL_BUILDING:
      return { ...state, loading: { ...state.loading, DEL_BUILDING: { status: false, error: action.error.response } } };

    // ------------------------ GET DWELLINGS ------------------------ //
    case ACTION.LOADING_GET_DWELLINGS:
      return { ...state, loading: { ...state.loading, GET_DWELLINGS: { status: true } } };
    case ACTION.RECEIVE_GET_DWELLINGS: {
      const data = {};
      const content = action.result.data.result;
      if (content) {
        content.forEach((item) => {
          if (!data[item.dwelling_floor]) {
            data[item.dwelling_floor] = { content: [] };
          }
          data[item.dwelling_floor].content.push(item);
        });
      }
      return { ...state, loading: { ...state.loading, GET_DWELLINGS: undefined }, build: { ...state.build, data } };
    }
    case ACTION.ERROR_GET_DWELLINGS:
      return { ...state, loading: { ...state.loading, GET_DWELLINGS: { status: false, error: action.error.response } } };

    // /* ------------------ SET DWELLINGS ------------------ */
    case ACTION.LOADING_SET_DWELLINGS:
      return { ...state, loading: { ...state.loading, SET_DWELLINGS: { state: true } } };
    case ACTION.RECEIVE_SET_DWELLINGS:
      return { ...state, loading: { ...state.loading, SET_DWELLINGS: undefined } };
    case ACTION.ERROR_SET_DWELLINGS:
      return { ...state, loading: { ...state.loading, SET_DWELLINGS: { state: false, detail: action.error.response } } };

    // /* ------------------ PUT DWELLING ------------------ */
    case ACTION.LOADING_PUT_DWELLING:
      return { ...state, loading: { ...state.loading, PUT_DWELLING: { state: true } } };
    case ACTION.RECEIVE_PUT_DWELLING:
      return { ...state, loading: { ...state.loading, PUT_DWELLING: undefined } };
    case ACTION.ERROR_PUT_DWELLING:
      return { ...state, loading: { ...state.loading, PUT_DWELLING: { state: false, detail: action.error.response } } };

    // /* ------------------ PUT DWELLING ------------------ */
    case ACTION.LOADING_DELETE_DWELLING:
      return { ...state, loading: { ...state.loading, DELETE_DWELLING: { state: true } } };
    case ACTION.RECEIVE_DELETE_DWELLING:
      return { ...state, loading: { ...state.loading, DELETE_DWELLING: undefined } };
    case ACTION.ERROR_DELETE_DWELLING:
      return { ...state, loading: { ...state.loading, DELETE_DWELLING: { state: false, detail: action.error.response } } };


    // ------------------------ AMENITIES ------------------------ //

    case ACTION.LOADING_GET_CATALOG_AMENITIES:
      return { ...state, loading: { ...state.loading, GET_CATALOG_AMENITIES: { status: true } } };
    case ACTION.RECEIVE_GET_CATALOG_AMENITIES:
      return { ...state, loading: { ...state.loading, GET_CATALOG_AMENITIES: undefined }, catalog_amenities: action.result.data };
    case ACTION.ERROR_GET_CATALOG_AMENITIES:
      return { ...state, loading: { ...state.loading, GET_CATALOG_AMENITIES: { status: false, error: action.error.response } } };

    case ACTION.LOADING_CREATE_AN_AMENITY:
      return { ...state, loading: { ...state.loading, CREATE_AN_AMENITIES: { status: true } } };
    case ACTION.RECEIVE_CREATE_AN_AMENITY:
      return { ...state, loading: { ...state.loading, CREATE_AN_AMENITIES: undefined } };
    case ACTION.ERROR_CREATE_AN_AMENITY:
      return { ...state, loading: { ...state.loading, CREATE_AN_AMENITIES: { status: false, error: action.error.response } } };

    case ACTION.LOADING_CREATE_AMENITY_BULK:
      return { ...state, loading: { ...state.loading, CREATE_AMENITY_BULK: { status: true } } };
    case ACTION.RECEIVE_CREATE_AMENITY_BULK:
      return { ...state, loading: { ...state.loading, CREATE_AMENITY_BULK: undefined } };
    case ACTION.ERROR_CREATE_AMENITY_BULK:
      return { ...state, loading: { ...state.loading, CREATE_AMENITY_BULK: { status: false, error: action.error.response } } };

    case ACTION.LOADING_GET_PAGINATED_BOOKINGS:
      return { ...state, loading: { ...state.loading, GET_PAGINATED_BOOKINGS: { status: true } } };
    case ACTION.RECEIVE_GET_PAGINATED_BOOKINGS:
      return { ...state, loading: { ...state.loading, GET_PAGINATED_BOOKINGS: undefined }, bookings: action.result.data.result };
    case ACTION.ERROR_GET_PAGINATED_BOOKINGS:
      return { ...state, loading: { ...state.loading, GET_PAGINATED_BOOKINGS: { status: false, error: action.error.response } } };
  
    case ACTION.LOADING_GET_BOOKINGS:
      return { ...state, loading: { ...state.loading, GET_BOOKINGS: { status: true } } };
    case ACTION.RECEIVE_GET_BOOKINGS:
      return { ...state, loading: { ...state.loading, GET_BOOKINGS: undefined }, month_bookings: action.result.data.result, lastBookingsUpdate: moment().valueOf() };
    case ACTION.ERROR_GET_BOOKINGS:
      return { ...state, loading: { ...state.loading, GET_BOOKINGS: { status: false, error: action.error.response } } };
    

    case ACTION.LOADING_GET_AMENITIES:
      return { ...state, loading: { ...state.loading, GET_AMENITIES: { status: true } } };
    case ACTION.RECEIVE_GET_AMENITIES:
      return { ...state, loading: { ...state.loading, GET_AMENITIES: undefined }, amenities: action.result.data };
    case ACTION.ERROR_GET_AMENITIES:
      return { ...state, loading: { ...state.loading, GET_AMENITIES: { status: false, error: action.error.response } } };


    case ACTION.LOADING_GET_AMENITIES_BIND:
      return { ...state, loading: { ...state.loading, GET_AMENITIES_BIND: { status: true } } };
    case ACTION.RECEIVE_GET_AMENITIES_BIND:
      return { ...state, loading: { ...state.loading, GET_AMENITIES_BIND: undefined }, access: { ...state.access, [action.param]: action.result.data.result } };
    case ACTION.ERROR_GET_AMENITIES_BIND:
      return { ...state, loading: { ...state.loading, GET_AMENITIES_BIND: { status: false, detail: action.error.response } } };
      
    case ACTION.LOADING_GET_AMENITY:
      return { ...state, loading: { ...state.loading, GET_AMENITY: { status: true } } };
    case ACTION.RECEIVE_GET_AMENITY:
      return { ...state, loading: { ...state.loading, GET_AMENITY: undefined }, amenity: action.result.data.result };
    case ACTION.ERROR_GET_AMENITY:
      return { ...state, loading: { ...state.loading, GET_AMENITY: { status: false, detail: action.error.response } } };


    case ACTION.LOADING_SET_AMENITY:
      return { ...state, loading: { ...state.loading, SET_AMENITY: { status: true } } };
    case ACTION.RECEIVE_SET_AMENITY:
      return { ...state, loading: { ...state.loading, SET_AMENITY: undefined } };
    case ACTION.ERROR_SET_AMENITY:
      return { ...state, loading: { ...state.loading, SET_AMENITY: { status: false, detail: action.error.response } } };

    case ACTION.LOADING_GET_REGULATIONS:
      return { ...state, loading: { ...state.loading, GET_REGULATIONS: { status: true } } };
    case ACTION.RECEIVE_GET_REGULATIONS:
      return { ...state, loading: { ...state.loading, GET_REGULATIONS: undefined }, regulations: { ...action.result.data } };
    case ACTION.ERROR_GET_REGULATIONS:
      return { ...state, loading: { ...state.loading, GET_REGULATIONS: { status: false, error: action.error.response } } };

    case ACTION.LOADING_GET_FOUNDS:
      return { ...state, loading: { ...state.loading, GET_FOUNDS: { status: true } } };
    case ACTION.RECEIVE_GET_FOUNDS:
      return { ...state, loading: { ...state.loading, GET_FOUNDS: undefined }, founds: { ...action.result.data } };
    case ACTION.ERROR_GET_FOUNDS:
      return { ...state, loading: { ...state.loading, GET_FOUNDS: { status: false, error: action.error.response } } };

    case ACTION.LOADING_SET_PAYMENT_RESIDENTS:
      return { ...state, loading: { ...state.loading, SET_PAYMENT_RESIDENTS: { status: true }}}
    case ACTION.RECEIVE_SET_PAYMENT_RESIDENTS:
      return { ...state, loading: { ...state.loading, SET_PAYMENT_RESIDENTS: undefined }}
    case ACTION.ERROR_SET_PAYMENT_RESIDENTS:
      return { ...state, loading: { ...state.loading, SET_PAYMENT_RESIDENTS: { status: false }}}

    case ACTION.LOADING_SET_SURCHARGE:
      return { ...state, loading: { ...state.loading, SET_SURCHARGE: { status: true }}}
    case ACTION.RECEIVE_SET_SURCHARGE:
      return { ...state, loading: { ...state.loading, SET_SURCHARGE: undefined }}
    case ACTION.ERROR_SET_SURCHARGE:
      return { ...state, loading: { ...state.loading, SET_SURCHARGE: { status: false }}}

    case ACTION.LOADING_SET_DISCOUNT:
      return { ...state, loading: { ...state.loading, SET_DISCOUNT: { status: true }}}
    case ACTION.RECEIVE_SET_DISCOUNT:
      return { ...state, loading: { ...state.loading, SET_DISCOUNT: undefined }}
    case ACTION.ERROR_SET_DISCOUNT:
      return { ...state, loading: { ...state.loading, SET_DISCOUNT: { status: false }}}
      
    case ACTION.LOG_OUT:
      return initialState;

    default:
      return { ...state };
  }
};

export default propertyReducer;
