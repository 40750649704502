import * as ACTION from './actionTypes';
import { uploadRegulationN, getRegulationsN } from '../../services/propertiesService';

export function uploadRegulation({ idProperty, data }) {
  return {
    types: [
      ACTION.LOADING_UPLOAD_REGULATION,
      ACTION.RECEIVE_UPLOAD_REGULATION,
      ACTION.ERROR_UPLOAD_REGULATION,
    ],
    promise: uploadRegulationN({ idProperty, data })
  };
}

export function getRegulations({ idProperty, tipe }) {
  return {
    types: [
      ACTION.LOADING_GET_REGULATIONS,
      ACTION.RECEIVE_GET_REGULATIONS,
      ACTION.ERROR_GET_REGULATIONS,
    ],
    promise: getRegulationsN({ idProperty, tipe  })
  };
}
