import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Step from './StepperWizardItem';
import './StepperWizard.scss';

class StepperWizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: [
        { label: 'Paso 1', text: 'Datos generales' },
        { label: 'Paso 2', text: 'Construye tu propiedad' },
        { label: 'Paso 3', text: 'Agregar presupuesto' },
        { label: 'Paso 4', text: 'Cuotas' },
        { label: 'Paso 5', text: 'Cuenta' },
      ],
    };
  }

  renderSteps(list, active, last) {
    if (!list) return null;
    const filtered = list.map((item, i) => {
      const number = i + 1;
      return (<Step
        key={number}
        number={number}
        label={item.text}
        last={number === last}
        active={number === active}
      />);
    });
    return filtered;
  }

  render() {
    const { id } = this.props.params;
    const active = parseInt(id.slice(0, 1));
    const { steps } = this.state;
    const last = steps.length;
    return (
      <div className='StepperWizard'>
        { this.renderSteps(steps, active, last) }
        <div className="progress-bar">
          <span style={{
             width: `${((active-1) * 100) / steps.length}%`
          }}/>
        </div>
      </div>
    );
  }
}

StepperWizard.propTypes = {
  params: PropTypes.object.isRequired,
};

export default StepperWizard;
