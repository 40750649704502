
import * as ACTION from 'containers/Subscriptions/action-types';

const initialState = {
    loading: { }
};

const subscriptionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.LOADING_GET_ADMINS:
            return { ...state, loading: { ...state.loading, GET_ADMINS: { status: true } } };
        case ACTION.RECEIVE_GET_ADMINS:
            return { ...state, loading: { ...state.loading, GET_ADMINS: undefined }, admins: action.result.data.result };
        case ACTION.ERROR_GET_ADMINS:
            return { ...state, loading: { ...state.loading, GET_ADMINS: { status: false, detail: action.error.response } } };

        case ACTION.LOADING_MODIFY_TRIAL:
            return { ...state, loading: { ...state.loading, MODIFY_TRIAL: { status: true, adminId: action.param } } };
        case ACTION.RECEIVE_MODIFY_TRIAL:
            return { ...state, loading: { ...state.loading, MODIFY_TRIAL: undefined } };
        case ACTION.ERROR_MODIFY_TRIAL:
            return { ...state, loading: { ...state.loading, MODIFY_TRIAL: { status: false, adminId: undefined, detail: action.error.response } } };
    
        default:
            return { ...state };
    }
};

export default subscriptionsReducer;
