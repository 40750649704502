import React from 'react';
import Radio from '@material-ui/core/Radio';
import MaterialRadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import './RadioGroup.scss';

const RadioGroup = (props) => {

  const { options, label } = props;

  const getOptions = _ => (options || [ ]).map((option, index) => (
    <FormControlLabel
      key={ index }
      value={ `${ option.value }` }
      control={ <Radio color="primary"/> }
      label={ option.label }
    />
  ));

  return (
    <div className='RadioGroup'>
      <FormLabel className='subtitle-2'>{ label }</FormLabel>
      <MaterialRadioGroup { ...props }>
        { getOptions() }
      </MaterialRadioGroup>
    </div>
  );
};

export default RadioGroup;
