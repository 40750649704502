import React, { Component } from 'react';
import ReactQuill from 'react-quill';

export class TemplateFonts extends Component {

  modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
    ],
  };

  formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
  ];

  render() {
    const { textHtml, onChange } = this.props;
    return (
      <>
        <ReactQuill
          theme="snow"
          modules={this.modules}
          formats={this.formats}
          value={textHtml}
          onChange={onChange}
        ></ReactQuill>
      </>
    );
  }
}

export default TemplateFonts;

