import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import './QuotaHeader.scss';
import Checkbox from 'components/Checkbox';

export class QuotaHeader extends Component {
  onChangeCourt = () =>{
    const {  is_valid, onChangeCourt } = this.props;
    onChangeCourt({targetCheck: !is_valid})
  }
  render() {
    const { option,type,validTableCourt,is_valid } = this.props;
    return (
      <div className='QuotaHeader'>
        <div className='QuotaHeader__item QuotaHeader__item__number'>{type === 1 ?'Departamento':'Casa'}</div>
        <div className='QuotaHeader__item QuotaHeader__item__name'><FormattedMessage id="Quota.Name" /></div>
        <div className='QuotaHeader__item QuotaHeader__item__balance'><FormattedMessage id="Quota.InitialBalance" /></div>
        <div className='QuotaHeader__item QuotaHeader__item__fee'>
          <FormattedMessage id="Quota.Fee" />
          <div className='QuotaHeader__item__fee__options'>{option}</div>
        </div>
        <div className='QuotaHeader__item QuotaHeader__item__interest'>
          <div >
            <Checkbox className="Header_checkbox"
              checked={is_valid ? is_valid : false}
              onChange={validTableCourt ? null : this.onChangeCourt}
                styles={{
                  margin: 0,
                  padding: 0,
                }}
              />
          </div>
          <FormattedMessage id="Quota.Validate" />

        </div>
      </div>
    );
  }
}

export default QuotaHeader;
