import React, { Component } from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';

import Icon from 'components/Icon';
import IconDelete from 'assets/img/icon-delete.svg';

import './AccordionDisplay.scss';

class AccordionDisplay extends Component {
  constructor(props) {
    super(props);
    const { numDeptos } = props;
    this.state = {
      number: numDeptos,
    };
  }


  handleChange = (event) => {
    const number = Number(event.target.value) >= 100 ? 100 : Number(event.target.value);
    this.setState({ number: isNaN(number) ? 1 : number });
    this.props.handleChangeDataRows(event);
  }

  render() {
    const {
      idRow, expanded, handleCloseAcordion, handleChangeExpand, toEdit, onDeleteFloor
    } = this.props;
    const { number } = this.state;
    return (
      <div className='AccordionDisplay'>
        {
          expanded
          ? <Icon color='secondary' icon='keyboard_arrow_up' onClick={handleCloseAcordion} className='AccordionDisplay__icon' />
          : <Icon color='secondary' icon='keyboard_arrow_down' onClick={handleChangeExpand} className='AccordionDisplay__icon' />
        }
        <div className='AccordionDisplay__options'>Piso {Number(idRow) + 1}</div>
        {
          toEdit
          ? <div className='AccordionDisplay__options'>
              Número de departamentos:
              <input
                value={number}
                name={idRow} 
                onChange={this.handleChange}
                className={`AccordionDisplay__options__input ${ (number === '' || number === 0) && 'invalid' }`}
                placeholder='Agrega número de departamentos'
              />
            </div>
          : <div className='AccordionDisplay__button'>
              {
                idRow > 0
                ? <IconButton onClick={onDeleteFloor(idRow)}>
                    <img src={IconDelete} alt='Delete floor' />
                  </IconButton>
                : null 
              }
            </div> 
        }
      </div>
    );
  }
}

AccordionDisplay.defaultProps = {
  idRow: '1',
  handleCloseAcordion: () => {},
  handleChangeExpand: () => {},
  handleChangeDataRows: () => {},
  toEdit: true,
  numDeptos: '',
};

AccordionDisplay.propTypes = {
  idRow: PropTypes.string,
  expanded: PropTypes.bool,
  handleCloseAcordion: PropTypes.func,
  handleChangeExpand: PropTypes.func,
  handleChangeDataRows: PropTypes.func,
  toEdit: PropTypes.bool,
  numDeptos: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

export default AccordionDisplay;
