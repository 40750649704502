import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { showIconAmenity } from 'constants/amenityTypes';
import './ListAmenities.scss';

export class ListAmenities extends Component {
  render() {
    const { amenities, onSelect } = this.props;
    const amenity = this.props.amenity || { };
    
    return (
      <List className="ListAmenities">
        {
          (amenities) ?
            amenities.map((item, index) => (
              <div
                key={ index }
                onClick={() => onSelect(item)}
              >
                <ListItem
                  button
                  key={item.key}
                  className={(item.key === amenity.key) ? 'ListAmenities__Item__active' : 'ListAmenities__Item'}
                  disabled={(item.key !== amenity.key)}
                >
                  <ListItemAvatar>
                    <Avatar src={showIconAmenity(item.id).img_small} />
                  </ListItemAvatar>
                  <ListItemText primary={(item.name === '' ? item.nameList : item.name)} />
                </ListItem>
              </div>
              ))
            : null
        }
      </List>
    );
  }
}

ListAmenities.propTypes = {};

export default ListAmenities;
