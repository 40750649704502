import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import * as Actions from '../budgetActions';

import Card from 'components/Card';
import TabView from 'components/TabView';

import BudgetTab from '../components/BudgetTab/BudgetTab';
import BudgetEntry from './BudgetEntry/BudgetEntry';
import BudgetIncome from './BudgetIncome/BudgetIncome';

import SectionResume from './SectionResume';
import SectionExpenditure from './SectionExpenditure';
import SectionIncome from './SectionIncome';
import SectionProcrastination from './SectionProcrastination';
import SectionStatus from './SectionStatus';
import ReportModal from './ReportModal';
import Button from 'components/Button';
import Descargar from 'assets/img/download-exort.svg';
import { ReactSVG } from 'react-svg';
import ModalTransferFunds from './ModalTransfer/ModalTransferFunds';

import './DashboardPage.scss';


export class DashboardPage extends Component {
  constructor(props) {
    super(props
    );
    this.state = {
      totalFunds:0,
      hasActives:false,
      showDashAmenity: false
    };
  }

  componentDidMount() {
    this.getActives();
  }

  getActives() {
    const { property } = this.props.params;
    this.props.getActives({
      property
    }).then(() => {
      const { activeSelected, actives } = this.props.budgetState;
      const hasActives = !!actives.total_elements;
      
      this.setState({ hasActives });

      if (hasActives){
        this.props.getExpenses(activeSelected);
        this.props.getFundTransferAccess({
          pid: property,
          aid: activeSelected,
          type: 'total'
        })
        .then(() => {
          const myFunds = this.handleGetFunds();
          if (!!myFunds.length){
            this.setState({ fund: myFunds[0].id });
          }
          this.handleTotalFund();
        });
        this.props.getTransferFund({
          property,
          active: activeSelected
        });
      } else {
        this.goToAddBuilding();
      }
    });
  }
  
  componentWillUnmount() {
    this.props.clearData();
  }

  handleChangeBudget = activeSelected => () => {
    const actives = this.handleGetActivesFiltered();
    const isAmenity = !!actives.amenity.find(item => activeSelected === item.value);
    
    this.setState({ showDashAmenity: isAmenity });

    this.props.addData({ activeSelected }).then(() => {
      this.props.getExpenses(activeSelected);
    });
  };
  
  handleTotalFund = () => {
    const { funds_transfer_access } = this.props.budgetState;
    let totalFunds = 0;

    Object.values(funds_transfer_access?.result || { }).forEach(item => {
      totalFunds += item.total;
    });
    this.setState({ totalFunds });
  }

  handleGetActivesFiltered = () => {
    const { actives } = this.props.budgetState;
    const buildings = [ ];
    const amenity = [ ];
    Object.values(actives?.result || { }).forEach(item => {
      const row = {
        value: item.id,
        label: item.name,
        iconType: item.icon,
        amount: item.balance
      };
      if (item.type === 'building') {
        buildings.push(row);
      } else {
        amenity.push(row);
      }
    });
    return { buildings, amenity };
  }

  goToDetailFunds = () => {
    const { property } = this.props.params;
    this.context.router.push(`/property/${property}/budget/edition?tab=funds`);
  }

  goToAddBuilding = () => {
    const { property } = this.props.params;
    this.context.router.push(`/Wizard/21/property/${property}`);
  }

  handleDetermineSections = () => {
    const actives = this.handleGetActivesFiltered();
    const sections = [];
    if (actives.buildings.length) {
      sections.push({
        title: 'Inmuebles',
        content: actives.buildings
      });
    }
    if (actives.amenity.length) {
      sections.push({
        title: 'Áreas compartidas',
        content: actives.amenity
      });
    }
    return sections;
  }

  handleGetFunds = () => {
    const { funds, activeSelected } = this.props.budgetState;
    
    return (funds?.result || []).filter(item => item.active_id === activeSelected);
  };

  toggleReportModal = () => {
    this.setState({ showReportModal: !this.state.showReportModal });
  }

  toggleTransferModal = () => {
    this.setState({ showModalTransfer: !this.state.showModalTransfer });
  }

  renderTabs() {
    const { params } = this.props;
    const { showDashAmenity } = this.state;
    
    if (showDashAmenity) {
      return <TabView
        items={[
          {
            value: 1,
            label: <FormattedMessage id="Tab.Payments"/>,
            view: <BudgetEntry params={ params }/>
          }
        ]}
      />;
    } else {
      return <TabView
        items={[
          {
            value: 2,
            label: <FormattedMessage id="Tab.Resident"/>,
            view: <BudgetIncome params={ params }/>
          },
          {
            value: 3,
            label: <FormattedMessage id="Tab.Payments"/>,
            view: <BudgetEntry params={ params } { ...this.props }/>
          }
        ]}
      />
    }
  }

  render() {
    const { params } = this.props;
    const { activeSelected, expenses } = this.props.budgetState;
    const { hasActives, showReportModal, showModalTransfer, showDashAmenity } = this.state;

    return (
      <div className="DashboardPage animate__animated animate__fadeIn">
        <BudgetTab
          items={ this.handleDetermineSections() }
          value={ activeSelected }
          onChange={ this.handleChangeBudget }
        />
        <div className="body col">
          <p>Balance de cuenta general</p>
          <div className="section row">
            <SectionStatus
              available_balance={ expenses.balance }
              totalFunds={ expenses.funds }
              goToDetailFunds={this.goToDetailFunds}
              hadleOpenModalTransf={this.toggleTransferModal}
              hadleCloseModalTransf={this.toggleTransferModal}
            />
            { showDashAmenity ?
                <SectionExpenditure
                  paid={ expenses.paid }
                  pending_toPay={ expenses.pending_toPay }
                  estimated_budget={ expenses.estimated_budget }
                /> :
                <SectionProcrastination params={ params } expenses={ expenses } />
            }
          </div>
          { !showDashAmenity &&
            <div className="section row">
              <SectionIncome
                charged={ expenses.charged }
                pending_charged={ expenses.pending_charged }
              />
              <SectionExpenditure
                paid={ expenses.paid }
                pending_toPay={ expenses.pending_toPay }
                estimated_budget={ expenses.estimated_budget }
              />
            </div>
          }
          <Card className='table-wrapper'>
            <div className="subtitle row space-between">
              <div className='row space-between'>
                <div className='heading-4'>Registro de operaciones</div>
                {
                  !showDashAmenity && (
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={ this.toggleReportModal }
                    >
                      <ReactSVG src={Descargar} />
                      <div>&nbsp;&nbsp;&nbsp;</div>
                      Generar Reporte
                    </Button>
                )}
              </div>
              <SectionResume
                available_balance={ expenses.balance }
                estimated_budget={ expenses.estimated_budget }
                pending_toPay={ expenses.pending_toPay }
              />
            </div>
            { hasActives && this.renderTabs() }
          </Card>
        </div>
        <ReportModal
          { ...this.props }
          open={ showReportModal }
          onClose={ this.toggleReportModal }
        />
        <ModalTransferFunds
          open={ showModalTransfer }
          onClose={this.toggleTransferModal}
          property={params.property}
          activeSelected={activeSelected}
        />
      </div>
    );
  }
}

DashboardPage.contextTypes = {
  router: PropTypes.shape()
};

DashboardPage.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
  budgetState: state.get('budgetState'),
  profileState: state.get("profileState"),
  loading: state.get('budgetState').loading
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
