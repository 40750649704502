import http from 'constants/config';

export const rejectBookingService = (bookingId, reason) => http.patch(`V2/bookings/${ bookingId }/reject`, {
    reason
});

export const approveBookingService = (bookingId) => http.patch(`V2/bookings/${ bookingId }/approve`);

export const bookingPaymentService = (bookingId, data) => http.patch(`V2/bookings/${ bookingId }/pay`, data);

export const cancelBookingService = (bookingId, reason) => http.patch(`V2/bookings/${ bookingId }/cancel`, {
    reason
});