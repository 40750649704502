import moment from 'moment';
import 'moment/locale/es';

export const simpleDate = (date) => {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  const d = date.getDate();
  const m = date.getMonth() + 1;
  const y = date.getFullYear();
  return `${y}-${m <= 9 ? `0${m}` : m}-${d <= 9 ? `0${d}` : d}`;
};
export const addDays = (date, days = 0) => {
  const d = date.getDate() + days;
  const m = date.getMonth() + 1;
  const y = date.getFullYear();
  return `${y}-${m <= 9 ? `0${m}` : m}-${d <= 9 ? `0${d}` : d}`;
};
export const simpleTime = (date) => {
  const h = date.getHours();
  const m = date.getMinutes();
  return `${h <= 9 ? `0${h}` : h}:${m <= 9 ? `0${m}` : m}`;
};
export const addHours = (date, hours = 0) => {
  const h = date.getHours() + hours;
  const m = date.getMinutes();
  return `${h <= 9 ? `0${h}` : h}:${m <= 9 ? `0${m}` : m}`;
};
export const simpleTimeUTC = (cadena) => {
  const d = new Date(cadena);
  const h = d.getUTCHours();
  const m = d.getUTCMinutes();
  return `${h <= 9 ? `0${h}` : h}:${m <= 9 ? `0${m}` : m}`;
};
export const simpleMonth = (date) => {
  const m = date.getMonth();
  const month = [];
  month[0] = 'Enero';
  month[1] = 'Febrero';
  month[2] = 'Marzo';
  month[3] = 'Abril';
  month[4] = 'Mayo';
  month[5] = 'Junio';
  month[6] = 'Julio';
  month[7] = 'Agosto';
  month[8] = 'Septiembre';
  month[9] = 'Octubre';
  month[10] = 'Noviembre';
  month[11] = 'Diciembre';
  return month[m];
};
export const simpleDayLetter = (date) => {
  const d = date.getDay();
  const weekday = [];
  weekday[0] = 'D';
  weekday[1] = 'L';
  weekday[2] = 'M';
  weekday[3] = 'M';
  weekday[4] = 'J';
  weekday[5] = 'V';
  weekday[6] = 'S';
  return weekday[d];
};
export const simpleDay = (date) => {
  const d = date.getDate();
  return `${d <= 9 ? `0${d}` : d}`;
};
export const simpleDayUTC = (cadena) => {
  const date = new Date(cadena);
  const d = date.getDate();
  return `${d <= 9 ? `0${d}` : d}`;
};

export const dateUTCtoComplete = cadena => new Date(cadena);

export const toMoneyFormat = number => ((parseFloat(number) || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'));

export const dateFormatString = (dateString) => {
  const date = new Date(dateString);
  return moment(date).format('LLL A');
}
