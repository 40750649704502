import React, { Component } from 'react';
import { ReactSVG } from 'react-svg';

import Card from 'components/Card';

import { showIconAmenity } from 'constants/amenityTypes';
import { toMoneyFormat } from '../../../../utils/format';
import './RelationsAmenityItem.scss';

class RelationsAmenityItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { concept_name, icon_type, suggested_fee, monthly_fee, isEnd, isFund } = this.props;
    const name = concept_name.length < 25 ? concept_name : `${concept_name.slice(0, 22)}...`;
    return (
      <div className='RelationsAmenityItem'>
        <Card>
          <div className='RelationsAmenityItem__content'>
            <ReactSVG src={showIconAmenity(icon_type).img_line} />
            <div className='RelationsAmenityItem__title'>{name}</div>

            <div className='RelationsAmenityItem__unit'>
              <div className='RelationsAmenityItem__unit__label'>Monto/vivienda</div>
              <div className='RelationsAmenityItem__unit__number'>$ {toMoneyFormat(suggested_fee)}</div>
            </div>
            {
              !isFund ?
              <div className='RelationsAmenityItem__unit RelationsAmenityItem__unit2'>
                <div className='RelationsAmenityItem__unit__label'>Total</div>
                <div className='RelationsAmenityItem__unit__number'>$ {toMoneyFormat(monthly_fee)}</div>
              </div>:null
            }
          </div>
        </Card>
        {
          !isEnd ? <span>+</span> : null
        }        
      </div>
    );
  }
}

export default RelationsAmenityItem;
