import React, { Component } from 'react';
import { toMoneyFormat } from '../../../../utils/format';
import './TableProperties.scss';

export class TableProperties extends Component {
  render() {
    const { properties } = this.props;
    return (
      <div>
        <table>
          <tbody>
           {properties.map(eleme => {
               return (
                   <tr key={`TableProperties${eleme.property_id}`} >
                       <td className='body-text1' >{eleme.name}</td>
                       <td>{eleme.house_units} UH</td>
                       <td className='heading-5'>$ {toMoneyFormat(eleme.cost)}</td>
                   </tr>
               )
           })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default TableProperties;
