import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

class Lang extends Component {
  render() {
    const { id, children } = this.props;
    return (
      <FormattedMessage id={id}>
        {children}
      </FormattedMessage>
    );
  }
}

Lang.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node
};

export default Lang;
