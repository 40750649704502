export const LOG_OUT = 'LOG_OUT';

export const ADD_PROVIDERS_DATA = 'ADD_PROVIDERS_DATA';
export const CLEAR_DATA_PROVIDER = 'CLEAR_DATA_PROVIDER';

export const LOADING_GET_PROVIDERS = 'LOADING_GET_PROVIDERS';
export const RECEIVE_GET_PROVIDERS = 'RECEIVE_GET_PROVIDERS';
export const ERROR_GET_PROVIDERS = 'ERROR_GET_PROVIDERS';

export const LOADING_GET_PROVIDER = 'LOADING_GET_PROVIDER';
export const RECEIVE_GET_PROVIDER = 'RECEIVE_GET_PROVIDER';
export const ERROR_GET_PROVIDER = 'ERROR_GET_PROVIDER';

export const LOADING_SET_PROVIDER = 'LOADING_SET_PROVIDER';
export const RECEIVE_SET_PROVIDER = 'RECEIVE_SET_PROVIDER';
export const ERROR_SET_PROVIDER = 'ERROR_SET_PROVIDER';

export const LOADING_PUT_PROVIDER = 'LOADING_PUT_PROVIDER';
export const RECEIVE_PUT_PROVIDER = 'RECEIVE_PUT_PROVIDER';
export const ERROR_PUT_PROVIDER = 'ERROR_PUT_PROVIDER';

export const LOADING_GET_PROVIDER_DETAIL = 'LOADING_GET_PROVIDER_DETAIL';
export const RECEIVE_GET_PROVIDER_DETAIL = 'RECEIVE_GET_PROVIDER_DETAIL';
export const ERROR_GET_PROVIDER_DETAIL = 'ERROR_GET_PROVIDER_DETAIL';

export const LOADING_GET_PROVIDER_ACTIVE_DETAIL = 'LOADING_GET_PROVIDER_ACTIVE_DETAIL';
export const RECEIVE_GET_PROVIDER_ACTIVE_DETAIL = 'RECEIVE_GET_PROVIDER_ACTIVE_DETAIL';
export const ERROR_GET_PROVIDER_ACTIVE_DETAIL = 'ERROR_GET_PROVIDER_ACTIVE_DETAIL';

export const LOADING_GET_PROVIDER_ACTIVE_CONCEPTS = 'LOADING_GET_PROVIDER_ACTIVE_CONCEPTS';
export const RECEIVE_GET_PROVIDER_ACTIVE_CONCEPTS = 'RECEIVE_GET_PROVIDER_ACTIVE_CONCEPTS';
export const ERROR_GET_PROVIDER_ACTIVE_CONCEPTS = 'ERROR_GET_PROVIDER_ACTIVE_CONCEPTS';
