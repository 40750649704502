import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import * as Actions from '../loginActions';
import stateReady from 'assets/img/state-ready.svg';
import './styles.scss';

class RememberMe extends Component {
  clearError = () => {
    this.props.addLoginData({
      errors: {},
      remember: undefined,
      capcha: undefined,
    });
    this.context.router.push('/login');
  }

  render() {
    return (
      <div className='Unauthorized'>
        <img src={stateReady} alt='image ready' />
        <h2 className='Unauthorized__success'>¡Listo!</h2>
        <p>Hemos enviado tu nueva contraseña a tu correo electrónico.</p>
        <Button onClick={this.clearError} variant='contained' color='secondary'>Iniciar sesión</Button>
      </div>
    );
  }
}

RememberMe.contextTypes = {
  router: PropTypes.shape()
};

RememberMe.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
});

export default connect(mapStateToProps, mapDispatchToProps)(RememberMe);


