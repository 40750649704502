import http from 'constants/config';

export const getNeighborsN = ({ idProperty, idBuilding }) => http.get(
  `/v2/properties/by/id/${ idProperty }/building/${ idBuilding }/residents`
);

export const getNeighborN = ({ pid, did }) => http.get(
  `v1/properties/${pid}/dwellings/${did}/residents`
);

export const putNeighborN = ({
  idProperty, idDwelling, dataResident
}) => http.put(
  `v1/properties/${idProperty}/dwellings/${idDwelling}/residents`,
  JSON.stringify(dataResident)
);

export const setNeighborN = ({ idProperty, dataResident }) => http.post(
  `v1/properties/${idProperty}/residents`,
  JSON.stringify(dataResident)
);

export const setNeighborContactN = ({ idProperty, idResident, dataContact }) => http.post(
  `v1/properties/${idProperty}/residents/${idResident}/contacts`,
  JSON.stringify(dataContact)
);

export const sendEmailResidentsN = (data) => http.post(
  'v1/notifications',
  JSON.stringify(data)
);

export const getNeighborContactsN = ({ idProperty, idResident }) => http.get(
  `v1/properties/${idProperty}/residents/${idResident}/contacts`
);

export const putNeighborContactN = ({ idProperty, idResident, idContact, dataContact }) => http.put(
  `v1/properties/${idProperty}/residents/${idResident}/contacts/${idContact}`,
  JSON.stringify(dataContact)
);

export const getNeighborBalancesN = ({ pid, did }) => http.get(
  `v1/properties/${pid}/dwellings/${did}/residents_balances`
);

export const getNeighborPaymentsN = ({ pid, did }) => http.get(
  `v1/properties/${pid}/dwellings/${did}/residents_payments`
);

