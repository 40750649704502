import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DrawerItem from './DrawerItem';
import './Drawer.scss';

class Drawer extends Component {
  render() {
    const { items, value, onClick } = this.props;
    return (
      <div className='BudgetDrawer'>
        {
          items.map((item,index)=>{
            return (
              <div className='BudgetDrawer__section' key={`BudgetDrawer${index}`}>
                <div className='BudgetDrawer__section__title'>
                  {item.titleSection}
                </div>
                {
                  item.content.map((itemContent)=>{
                    return(
                      <DrawerItem 
                        key={`DrawerItem${itemContent.id}`}
                        budgetBuilding={itemContent} 
                        active={itemContent.id === value ? true : false } 
                        onClick={() => onClick(itemContent.id)}
                        icon={itemContent.icon}
                        showAmount={item.withAmount}
                        quota={itemContent.fee}
                      />
                    );
                  })
                }
              </div>
            );
          })
        }
      </div>
    );
  }
}

Drawer.defaultProps = {
  items: [],
  value: '',
};

Drawer.contextTypes = {
  router: PropTypes.shape()
};

Drawer.propTypes = {};

export default Drawer;

