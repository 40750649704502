import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Text from 'components/Text';

import StepperWizard from './StepperWizard/StepperWizard';
import LogoText from 'assets/img/imobly-bar.png';
import './PanelWizard.scss';


class PanelWizard extends Component {
  componentDidMount() {
    this.bottomPage.scrollIntoView();
  }
  render() {
    const { children, params, onLogout } = this.props;
    return (
      <div className='PanelWizard'>
        <div style={{ float: 'left', clear: 'both' }} ref={(el) => { this.bottomPage = el }}></div>
        <div className='PanelWizard__header'>
          <div className='PanelWizard__header__logo'>
            <img src={LogoText} alt='LogoText' />
          </div>
          <div className='PanelWizard__header__steper'>
            <StepperWizard params={params} />
          </div>
          <div className='PanelWizard__header__close' onClick={onLogout}>
            <Text variant='caption' id='Layout.Wizard.Close' />
          </div>
        </div>
        <div className='PanelWizard__body'>
          {children}
        </div>
      </div>
    );
  }
}

PanelWizard.contextTypes = {
  router: PropTypes.shape()
};
PanelWizard.propTypes = {}

export default PanelWizard;