export const ADD_DATA_BUDGET = 'ADD_DATA_BUDGET';

export const ADD_DWELLING_DATA = 'ADD_DWELLING_DATA';

export const CLEAR_BUDGET = 'CLEAR_BUDGET';
export const CLEAR_BUDGET_CONCEPTS = 'CLEAR_BUDGET_CONCEPTS';

export const LOADING_GET_BUDGETS = 'LOADING_GET_BUDGETS';
export const RECEIVE_GET_BUDGETS = 'RECEIVE_GET_BUDGETS';
export const ERROR_GET_BUDGETS = 'ERROR_GET_BUDGETS';

export const LOADING_GET_ACTIVES = 'LOADING_GET_ACTIVES';
export const RECEIVE_GET_ACTIVES = 'RECEIVE_GET_ACTIVES';
export const ERROR_GET_ACTIVES = 'ERROR_GET_ACTIVES';

export const RECEIVE_GET_ACTIVES_UPDATE = 'RECEIVE_GET_ACTIVES_UPDATE';

export const LOADING_GET_ASSETS = 'LOADING_GET_ASSETS';
export const RECEIVE_GET_ASSETS = 'RECEIVE_GET_ASSETS';
export const ERROR_GET_ASSETS = 'ERROR_GET_ASSETS';

export const LOADING_GET_CONCEPTS = 'LOADING_GET_CONCEPTS';
export const RECIVED_GET_CONCEPTS = 'RECIVED_GET_CONCEPTS';
export const ERROR_GET_CONCEPTS = 'ERROR_GET_CONCEPTS';

export const LOADING_GET_PERIODS = 'LOADING_GET_PERIODS';
export const RECIVED_GET_PERIODS = 'RECIVED_GET_PERIODS';
export const ERROR_GET_PERIODS = 'ERROR_GET_PERIODS';

export const LOADING_GET_BUDGETS_FEES = 'LOADING_GET_BUDGETS_FEES';
export const RECIVED_GET_BUDGETS_FEES = 'RECIVED_GET_BUDGETS_FEES';
export const ERROR_GET_BUDGETS_FEES = 'ERROR_GET_BUDGETS_FEES';

export const LOADING_GET_BUDGETS_FEES_AMENITIES = 'LOADING_GET_BUDGETS_FEES_AMENITIES';
export const RECIVED_GET_BUDGETS_FEES_AMENITIES = 'RECIVED_GET_BUDGETS_FEES_AMENITIES';
export const ERROR_GET_BUDGETS_FEES_AMENITIES = 'ERROR_GET_BUDGETS_FEES_AMENITIES';

export const LOADING_SET_CONCEPT = 'LOADING_SET_CONCEPT';
export const RECIVED_SET_CONCEPT = 'RECIVED_SET_CONCEPT';
export const ERROR_SET_CONCEPT = 'ERROR_SET_CONCEPT';

export const LOADING_PUT_CONCEPT = 'LOADING_PUT_CONCEPT';
export const RECIVED_PUT_CONCEPT = 'RECIVED_PUT_CONCEPT';
export const ERROR_PUT_CONCEPT = 'ERROR_PUT_CONCEPT';

export const LOADING_POST_SERVICE = 'LOADING_POST_SERVICE';
export const RECIVED_POST_SERVICE = 'RECIVED_POST_SERVICE';
export const ERROR_POST_SERVICE = 'ERROR_POST_SERVICE';

export const UPDATE_CONCEPT = 'UPDATE_CONCEPT';
export const CLEAR_UPDATE_CONCEPT = 'CLEAR_UPDATE_CONCEPT';

export const LOADING_DELETE_CONCEPT = 'LOADING_DELETE_CONCEPT';
export const RECIVED_DELETE_CONCEPT = 'RECIVED_DELETE_CONCEPT';
export const ERROR_DELETE_CONCEPT = 'ERROR_DELETE_CONCEPT';

export const LOADING_SET_REMINDER = 'LOADING_SET_REMINDER';
export const RECIVED_SET_REMINDER = 'RECIVED_SET_REMINDER';
export const ERROR_SET_REMINDER = 'ERROR_SET_REMINDER';

export const LOADING_GET_CATALOG_SERVICES = 'LOADING_GET_CATALOG_SERVICES';
export const RECIVED_GET_CATALOG_SERVICES = 'RECIVED_GET_CATALOG_SERVICES';
export const ERROR_GET_CATALOG_SERVICES = 'ERROR_GET_CATALOG_SERVICES';

export const LOADING_GET_CATALOG_AMENITIES = 'LOADING_GET_CATALOG_AMENITIES';
export const RECIVED_GET_CATALOG_AMENITIES = 'RECIVED_GET_CATALOG_AMENITIES';
export const ERROR_GET_CATALOG_AMENITIES = 'ERROR_GET_CATALOG_AMENITIES';

export const LOADING_GET_QUOTAS = 'QUOTA::LOADING_GET_QUOTAS';
export const RECEIVE_GET_QUOTAS = 'QUOTA::RECEIVE_GET_QUOTAS';
export const ERROR_GET_QUOTAS = 'QUOTA::ERROR_GET_QUOTAS';

export const LOADING_PUT_DWELLING_BALANCE = 'LOADING_PUT_DWELLING_BALANCE';
export const RECEIVE_PUT_DWELLING_BALANCE = 'RECEIVE_PUT_DWELLING_BALANCE';
export const ERROR_PUT_DWELLING_BALANCE = 'ERROR_PUT_DWELLING_BALANCE';

export const LOADING_PUT_FEE = 'FEE::LOADING_PUT_FEE';
export const RECEIVE_PUT_FEE = 'FEE::RECEIVE_PUT_FEE';
export const ERROR_PUT_FEE = 'FEE::ERROR_PUT_FEE';

export const LOADING_SET_FEE = 'FEE::LOADING_SET_FEE';
export const RECEIVE_SET_FEE = 'FEE::RECEIVE_SET_FEE';
export const ERROR_SET_FEE = 'FEE::ERROR_SET_FEE';

export const LOADING_CREATE_POCAHONTAS = 'LOADING_CREATE_POCAHONTAS';
export const RECEIVE_CREATE_POCAHONTAS = 'RECEIVE_CREATE_POCAHONTAS';
export const ERROR_CREATE_POCAHONTAS = 'ERROR_CREATE_POCAHONTAS';

export const LOADING_GET_EXPENSES = 'LOADING_GET_EXPENSES';
export const RECEIVE_GET_EXPENSES = 'RECEIVE_GET_EXPENSES';
export const ERROR_GET_EXPENSES = 'ERROR_GET_EXPENSES';

export const LOADING_GET_CONCEPTS_TO_VALIDATE = 'LOADING_GET_CONCEPTS_TO_VALIDATE';
export const RECEIVE_GET_CONCEPTS_TO_VALIDATE = 'RECEIVE_GET_CONCEPTS_TO_VALIDATE';
export const ERROR_GET_CONCEPTS_TO_VALIDATE = 'ERROR_GET_CONCEPTS_TO_VALIDATE';

export const LOADING_SET_PROVIDER_PAY = 'LOADING_SET_PROVIDER_PAY';
export const RECEIVE_SET_PROVIDER_PAY = 'RECEIVE_SET_PROVIDER_PAY';
export const ERROR_SET_PROVIDER_PAY = 'ERROR_SET_PROVIDER_PAY';

export const LOADING_SET_TRANSFERFUND = 'LOADING_SET_TRANSFERFUND';
export const RECEIVE_SET_TRANSFERFUND = 'RECEIVE_SET_TRANSFERFUND';
export const ERROR_SET_TRANSFERFUND = 'ERROR_SET_TRANSFERFUND';

export const LOADING_GET_TRANSFERFUND = 'LOADING_GET_TRANSFERFUND';
export const RECEIVE_GET_TRANSFERFUND = 'RECEIVE_GET_TRANSFERFUND';
export const ERROR_GET_TRANSFERFUND = 'ERROR_GET_TRANSFERFUND';

export const LOADING_GET_PROVIDER_PAYMENT_PENDING = 'LOADING_GET_PROVIDER_PAYMENT_PENDING';
export const RECEIVE_GET_PROVIDER_PAYMENT_PENDING = 'RECEIVE_GET_PROVIDER_PAYMENT_PENDING';
export const ERROR_GET_PROVIDER_PAYMENT_PENDING = 'ERROR_GET_PROVIDER_PAYMENT_PENDING';

export const LOADING_PUT_PROVIDER_PAYMENTS_PENDINGS = 'LOADING_PUT_PROVIDER_PAYMENTS_PENDINGS';
export const RECEIVE_PUT_PROVIDER_PAYMENTS_PENDINGS = 'RECEIVE_PUT_PROVIDER_PAYMENTS_PENDINGS';
export const ERROR_PUT_PROVIDER_PAYMENTS_PENDINGS = 'ERROR_PUT_PROVIDER_PAYMENTS_PENDINGS';

export const LOADING_PUT_PROVIDER_PAYMENTS = 'LOADING_PUT_PROVIDER_PAYMENTS';
export const RECEIVE_PUT_PROVIDER_PAYMENTS = 'RECEIVE_PUT_PROVIDER_PAYMENTS';
export const ERROR_PUT_PROVIDER_PAYMENTS = 'ERROR_PUT_PROVIDER_PAYMENTS';

export const LOADING_GET_INCOMES_CHARGED = 'LOADING_GET_INCOMES_CHARGED';
export const RECEIVE_GET_INCOMES_CHARGED = 'RECEIVE_GET_INCOMES_CHARGED';
export const ERROR_GET_INCOMES_CHARGED = 'ERROR_GET_INCOMES_CHARGED';

export const LOADING_GET_INCOMES_CHARGERED = 'LOADING_GET_INCOMES_CHARGERED';
export const RECEIVE_GET_INCOMES_CHARGERED = 'RECEIVE_GET_INCOMES_CHARGERED';
export const ERROR_GET_INCOMES_CHARGERED = 'ERROR_GET_INCOMES_CHARGERED';

export const LOADING_PUT_INITIAL_BALANCE = 'LOADING_PUT_INITIAL_BALANCE';
export const RECEIVE_PUT_INITIAL_BALANCE = 'RECEIVE_PUT_INITIAL_BALANCE';
export const ERROR_PUT_INITIAL_BALANCE = 'ERROR_PUT_INITIAL_BALANCE';

export const LOADING_POST_PAYMENT = 'LOADING_POST_PAYMENT';
export const RECEIVE_SPEI_PAYMENT = 'RECEIVE_SPEI_PAYMENT';
export const RECEIVE_PAYMENT = 'RECEIVE_PAYMENT';
export const ERROR_POST_PAYMENT = 'ERROR_POST_PAYMENT';

export const LOADING_SET_CODE_TO_PAY = 'LOADING_SET_CODE_TO_PAY';
export const RECEIVE_SET_CODE_TO_PAY = 'RECEIVE_SET_CODE_TO_PAY';
export const ERROR_SET_CODE_TO_PAY = 'ERROR_SET_CODE_TO_PAY';

export const LOADING_SET_FUND = 'LOADING_SET_FUND';
export const RECEIVE_SET_FUND = 'RECEIVE_SET_FUND';
export const ERROR_SET_FUND = 'ERROR_SET_FUND';

export const LOADING_SET_FUND_TRANSFER = 'LOADING_SET_FUND_TRANSFER';
export const RECEIVE_SET_FUND_TRANSFER = 'RECEIVE_SET_FUND_TRANSFER';
export const ERROR_SET_FUND_TRANSFER = 'ERROR_SET_FUND_TRANSFER';

export const LOADING_PUT_FUND_TRANSFER = 'LOADING_PUT_FUND_TRANSFER';
export const RECEIVE_PUT_FUND_TRANSFER = 'RECEIVE_PUT_FUND_TRANSFER';
export const ERROR_PUT_FUND_TRANSFER = 'ERROR_PUT_FUND_TRANSFER';

export const LOADING_GET_FUNDS_TRANSFER = 'LOADING_GET_FUNDS_TRANSFER';
export const RECEIVE_GET_FUNDS_TRANSFER = 'RECEIVE_GET_FUNDS_TRANSFER';
export const ERROR_GET_FUNDS_TRANSFER = 'ERROR_GET_FUNDS_TRANSFER';

export const LOADING_GET_FUNDS_TRANSFER_ACCESS = 'LOADING_GET_FUNDS_TRANSFER_ACCESS';
export const RECEIVE_GET_FUNDS_TRANSFER_ACCESS = 'RECEIVE_GET_FUNDS_TRANSFER_ACCESS';
export const ERROR_GET_FUNDS_TRANSFER_ACCESS = 'ERROR_GET_FUNDS_TRANSFER_ACCESS';

export const LOADING_GET_FUNDS_ICOME = 'LOADING_GET_FUNDS_ICOME';
export const RECEIVE_GET_FUNDS_ICOME = 'RECEIVE_GET_FUNDS_ICOME';
export const ERROR_GET_FUNDS_ICOME = 'ERROR_GET_FUNDS_ICOME';

export const LOADING_GET_FUNDS = 'LOADING_GET_FUNDS';
export const RECEIVE_GET_FUNDS = 'RECEIVE_GET_FUNDS';
export const ERROR_GET_FUNDS = 'ERROR_GET_FUNDS';

export const LOADING_PUT_FUNDS = 'LOADING_PUT_FUNDS';
export const RECEIVE_PUT_FUNDS = 'RECEIVE_PUT_FUNDS';
export const ERROR_PUT_FUNDS = 'ERROR_PUT_FUNDS';

export const LOADING_DELETE_FUNDS = 'LOADING_DELETE_FUNDS';
export const RECEIVE_DELETE_FUNDS = 'RECEIVE_DELETE_FUNDS';
export const ERROR_DELETE_FUNDS = 'ERROR_DELETE_FUNDS';

export const LOADING_GET_NEIGHBOR_PAYMENTS = 'LOADING_GET_NEIGHBOR_PAYMENTS';
export const RECEIVE_GET_NEIGHBOR_PAYMENTS = 'RECEIVE_GET_NEIGHBOR_PAYMENTS';
export const ERROR_GET_NEIGHBOR_PAYMENTS = 'ERROR_GET_NEIGHBOR_PAYMENTS';

export const LOADING_PUT_NEIGHBOR_PAYMENTS = 'LOADING_PUT_NEIGHBOR_PAYMENTS';
export const RECEIVE_PUT_NEIGHBOR_PAYMENTS = 'RECEIVE_PUT_NEIGHBOR_PAYMENTS';
export const ERROR_PUT_NEIGHBOR_PAYMENTS = 'ERROR_PUT_NEIGHBOR_PAYMENTS';

export const LOADING_SET_ACCESS = 'LOADING_SET_ACCESS';
export const RECEIVE_SET_ACCESS = 'RECEIVE_SET_ACCESS';
export const ERROR_SET_ACCESS = 'ERROR_SET_ACCESS';

export const LOADING_GET_ACCESS = 'LOADING_GET_ACCESS';
export const RECEIVE_GET_ACCESS = 'RECEIVE_GET_ACCESS';
export const ERROR_GET_ACCESS = 'ERROR_GET_ACCESS';

export const LOADING_PUT_ACCESS = 'LOADING_PUT_ACCESS';
export const RECEIVE_PUT_ACCESS = 'RECEIVE_PUT_ACCESS';
export const ERROR_PUT_ACCESS = 'ERROR_PUT_ACCESS';

export const LOADING_DELETE_ACCESS = 'LOADING_DELETE_ACCESS';
export const RECEIVE_DELETE_ACCESS = 'RECEIVE_DELETE_ACCESS';
export const ERROR_DELETE_ACCESS = 'ERROR_DELETE_ACCESS';

export const LOADING_GET_REPORT = 'LOADING_GET_REPORT';
export const RECEIVE_GET_REPORT = 'RECEIVE_GET_REPORT';
export const ERROR_GET_REPORT = 'ERROR_GET_REPORT';

export const DOWNLOAD_REPORT = 'DOWNLOAD__REPORT';

export const LOADING_GET_DWELLING_ACCOUNT = 'LOADING_GET_DWELLING_ACCOUNT';
export const RECEIVE_GET_DWELLING_ACCOUNT = 'RECEIVE_GET_DWELLING_ACCOUNT';
export const ERROR_GET_DWELLING_ACCOUNT = 'ERROR_GET_DWELLING_ACCOUNT';

export const LOADING_POST_EXTRA_PROVIDER_PAYMENT = 'LOADING_POST_EXTRA_PROVIDER_PAYMENT';
export const RECEIVE_POST_EXTRA_PROVIDER_PAYMENT = 'RECEIVE_POST_EXTRA_PROVIDER_PAYMENT';
export const ERROR_POST_EXTRA_PROVIDER_PAYMENT = 'ERROR_POST_EXTRA_PROVIDER_PAYMENT';

export const LOADING_ADJUST_BALANCE = 'LOADING_ADJUST_BALANCE';
export const RECEIVE_ADJUST_BALANCE = 'RECEIVE_ADJUST_BALANCE';
export const ERROR_ADJUST_BALANCE = 'ERROR_ADJUST_BALANCE';