import React, { Component } from 'react';
import ItemProperties from './ItemProperties/ItemProperties';
import SpinnerConnected from 'components/SpinnerConnected';
import images from 'assets/img';
import './NavDashboard.scss';

class NavDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleURLDash = () =>{
    const { resident_last_view } = this.props.profileState;
    const condition = resident_last_view;
    const property = `property/${condition ? resident_last_view : null}`;
    const action = this.props.handleURL(`${property}/dashboard`);
    action();
  }

  render() {
    const { showProperties } = this.props;
    return (
      <div className='NavDashboard'>
        <div className='PanelDashboard__menu__simple__nav' onClick={this.handleURLDash}>
          <img src={images.menu.logo} alt='Logo' />
        </div>
        <SpinnerConnected />
        {showProperties ? <ItemProperties/> : null}
      </div>
    );
  }
}

export default NavDashboard;
