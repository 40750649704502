import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import * as Actions from '../loginActions';

import RememberForm from './RememberForm';
import { EMAIL_REGEX_2 } from 'constants/globalRegex';
import { FormattedMessage } from 'react-intl';

export class Remember extends Component {
  constructor(){
    super();
    this.state = {
      email: '',
      emailError: ''
    };
  }

  callback = () => {
    this.props.getCaptcha();
  };

  onChange = (event) => {
    const email = event.target.value;
    if(!EMAIL_REGEX_2.test(email)){
      this.setState({ emailError: <FormattedMessage id="Message.Remember.Email.invalid"/>, email });
    } else {
      this.setState({ email, emailError: undefined });
    }
  }

  goTo = (where) => () => {
    this.context.router.push(`/${where}`);
  }

  onSubmit = () => {
    const { capcha } = this.props.loginState;
    const { email } = this.state;
    if(EMAIL_REGEX_2.test(email) && capcha){
      this.props.getRememberMe(email);
    } else {
      this.setState({ emailError: <FormattedMessage id="Message.Remember.Email.invalid"/> });
    }
  }

  render(){
    const { email, emailError } = this.state;
    return(
      <RememberForm
        email={email}
        emailError={emailError}
        onChange={this.onChange}
        callback={this.callback}
        onSubmit={this.onSubmit}
        goTo={this.goTo}
      />
    );
  }
}

Remember.contextTypes = {
  router: PropTypes.shape()
};

Remember.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  loginState: state.get('loginState'),
});

export default connect(mapStateToProps, mapDispatchToProps)(Remember);
