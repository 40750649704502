import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import SnackBarOrigin from 'components/SnackBar';
import http from 'constants/config';

class SnackBar extends Component {
  state = { errors: [] }

  componentDidMount() {
    http.interceptors.response.use(value => value, err => {
      const errors = [ err ];
      
      if (err.response.status === 403) {
        const { property } = this.props.params;

        this.props.router.push(`/property/${ property }/profile/configuration?tab=accountimobly&inactive=true`);
      } else {
        this.setState({
          errors  
        });
      }

      return Promise.reject(err);
    });
  }

  render() {
    const { errors } = this.state;

    return errors.map((error, i) => {
      const { message } = error;
      
      return (
        <SnackBarOrigin
          key={i}
          message={`Error: ${message}`}
          detail={`${ JSON.stringify(error) }`}
        />
      );
    });
  }
}

SnackBar.defaultProps = {
  loaderState: {}
}
SnackBar.propTypes = {
  loaderState: PropTypes.instanceOf(Object)
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
const mapStateToProps = state => ({
  loaderState: state,
});

export default connect(mapStateToProps, mapDispatchToProps)(SnackBar);

