import React, { Component } from 'react';
import environments from 'constants/environments';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Intl from 'components/Intl';

import './IframeFiserv.scss';
import { bindActionCreators } from 'redux';
import * as actionsAccount from '../../../Accounts/accountActions';
import { connect } from 'react-redux';

export class IframeFiserv extends Component {

    constructor(props) {
        super(props)
        this.state = {
            timezone: 'America/Mexico_City',
        }
    }

    componentDidMount(){
        this.clickFormIframeFiserv();
    }

    clickFormIframeFiserv = () => {
        if(this.props.hashExtended) {
            const form = document.getElementById('formIframeFiserv');
            const {hashExtended, txndatetime} = this.props;
            document.getElementById('idDateTime').value = txndatetime;
            document.getElementById('hashExtended').value = hashExtended;
            form.submit();
            window.addEventListener("message", this.receiveMessage, false);
        }
    }

    receiveMessage = (event) => {
        if (environments.fiservUrl.indexOf(event.origin) < 0 )
            return;
        var elementArr = event.data.elementArr;
        this.forwardForm(event.data, elementArr);
    }

    intlProvider = id => (
        <Intl>
            <FormattedMessage id={id}/>
        </Intl>
    )

    forwardForm = (responseObj, elementArr) => {
        const { property } = this.props;
        if (responseObj.redirectURL.indexOf("error=true") > -1){
            this.context.router.push(`/property/${property}/profile/payment/pay-error`);
        } else{

            const detailPay = {
                name: '',
                cardNumber: '',
                total: '',
                transactionId: '',
                date: '',
                refNumber: ''
            };
            if (elementArr.length > 0) {
                elementArr.forEach(item => {
                    if (item.name === 'bname') {
                        detailPay.name = item.value
                    } else if (item.name === 'cardnumber') {
                        detailPay.cardNumber = item.value;
                    } else if (item.name === 'chargetotal') {
                        detailPay.total = item.value;
                    } else if (item.name === 'ipgTransactionId') {
                        detailPay.transactionId = item.value;
                    } else if (item.name === 'txndatetime') {
                        detailPay.date = item.value;
                    } else if (item.name === 'refnumber') {
                        detailPay.refNumber = item.value;
                    }
                });
            }

            this.props.addData({ payment: detailPay } );
            this.context.router.push(`/property/${property}/profile/payment/pay-success`);
        }
    }

    render() {
        const {oid, stored_id, total_payment, currency,
            ponumber,recurringComments,recurringInstallmentFrequency,
            recurringInstallmentPeriod,recurringInstallmentCount, hashExtended} = this.props;

        const { timezone } = this.state;
        return (
            <>
                <iframe name="myFrame"></iframe>
                <form
                    id="formIframeFiserv"
                    method="post"
                    action={environments.fiservUrl}
                    target="myFrame"
                >
                    <input type="hidden" name="checkoutoption" value="combinedpage" />
                    <input type="hidden" name="oid" value={oid} />
                    <input type="hidden" name="txntype" value="sale" />
                    <input type="hidden" name="timezone" value={timezone} />
                    <input
                        type="hidden"
                        id="idDateTime"
                        name="txndatetime"
                    />
                    <input
                        type="hidden"
                        name="parentUri"
                        value={`${window.location.href}`}
                    />
                    <input type="hidden" name="hash_algorithm" value="HMACSHA256" />
                    <input type="hidden" name="hashExtended" id="hashExtended" value={hashExtended} />
                    <input type="hidden" name="storename" value={stored_id} />
                    <input type="hidden" name="currency" value={currency} />
                    <input type="hidden" name="chargetotal" value={total_payment} />

                    <input
                        type="hidden"
                        name="responseFailURL"
                        value={`${window.location.href}?error=true`}
                    />
                    <input
                        type="hidden"
                        name="transactionNotificationURL"
                        value={`${environments.apiUrl}webhook`}
                    />
                    <input
                        type="hidden"
                        name="responseSuccessURL"
                        value={`${window.location.href}`}
                    />
                    <input type="hidden" name="recurringInstallmentCount" value={recurringInstallmentCount} />
                    <input type="hidden" name="recurringInstallmentPeriod" value={recurringInstallmentPeriod} />
                    <input type="hidden" name="recurringInstallmentFrequency" value={recurringInstallmentFrequency} />
                    <input type="hidden" name="recurringComments" value={recurringComments} />
                    <input type="hidden" name="ponumber" value={ponumber} />
                </form>
            </>
        )
    }
}

IframeFiserv.contextTypes = {
    router: PropTypes.shape()
}

IframeFiserv.propTypes = {
    timezone: PropTypes.string,
    currency: PropTypes.number,
    oid: PropTypes.number,
    stored_id: PropTypes.number,
    total_payment: PropTypes.string
};

const mapDispatchToProps = dispatch => bindActionCreators({ ...actionsAccount}, dispatch);
const mapStateToProps = state => ({
    loginState: state.get('loginState'),
    accountState: state.get('accountState')
});

export default connect(mapStateToProps, mapDispatchToProps)(IframeFiserv);
