import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import { toMoneyFormat } from '../../../../utils/format';
import EditBalanceModal from './EditBalanceModal';
import CurrencyInput from 'components/CurrencyInput/CurrencyInput';

import './BalanceAvailable.scss';

export const BalanceAvailable = props => {

    const [ balanceModalVisible, setBalanceModalVisible ] = useState();

    const { disabledEnter, initial_balance, balance, onChange, onBlur, editBalance } = props;

    return (
      <form
        noValidate
        autoComplete="off"
        onKeyPress={disabledEnter}
        className="col BalanceAvailable"
      >
        <p className='bottom-space body-text1'>
          <FormattedMessage id="Budget.header.label.balance" />{" "}
        </p>
        { editBalance ? (
          <div>
            <h2>$ { toMoneyFormat(balance) }</h2>
            <Button onClick={ _ => setBalanceModalVisible(!balanceModalVisible) } color='secondary' size='small'>Ajustar</Button>
            <EditBalanceModal
              { ...props }
              initialBalance={ balance }
              open={ balanceModalVisible }
              onClose={ _ => setBalanceModalVisible(false) }
            />
          </div>
        ) : (
          <CurrencyInput
            value={initial_balance}
            onChange={onChange}
            onBlur={onBlur}
            className='body-text1'
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        )}
      </form>  
    );
}
 
export default BalanceAvailable;