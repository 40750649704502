export const LOG_OUT = 'LOG_OUT';

export const ADD_DATA_ACCOUNTS = 'ADD_DATA_ACCOUNTS';

export const LOADING_SET_ACCOUNT = 'LOADING_SET_ACCOUNT';
export const RECEIVE_SET_ACCOUNT = 'RECEIVE_SET_ACCOUNT';
export const ERROR_SET_ACCOUNT = 'ERROR_SET_ACCOUNT';

export const LOADING_GET_ACCOUNTS = 'LOADING_GET_ACCOUNTS';
export const RECEIVE_GET_ACCOUNTS = 'RECEIVE_GET_ACCOUNTS';
export const ERROR_GET_ACCOUNTS = 'ERROR_GET_ACCOUNTS';

export const LOADING_GET_COSTS = 'LOADING_GET_COSTS';
export const RECEIVE_GET_COSTS = 'RECEIVE_GET_COSTS';
export const ERROR_GET_COSTS = 'ERROR_GET_COSTS';

export const LOADING_GET_ACCOUNT_IMOBLY = 'LOADING_GET_ACCOUNT_IMOBLY';
export const RECEIVE_GET_ACCOUNT_IMOBLY = 'RECEIVE_GET_ACCOUNT_IMOBLY';
export const ERROR_GET_ACCOUNT_IMOBLY = 'ERROR_GET_ACCOUNT_IMOBLY';

export const LOADING_PUT_CANCEL_RECURRING_PAYMENT = 'LOADING_PUT_CANCEL_RECURRING_PAYMENT';
export const RECEIVE_PUT_CANCEL_RECURRING_PAYMENT = 'RECEIVE_PUT_CANCEL_RECURRING_PAYMENT';
export const ERROR_PUT_CANCEL_RECURRING_PAYMENT = 'ERROR_PUT_CANCEL_RECURRING_PAYMENT';
