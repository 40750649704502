import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ColCard from 'components/ColCard';
import GraphicProcastination from '../components/GraphicProcrastination/GraphicProcrastination';

import './SectionProcrastination.scss';

class SectionProcrastination extends Component {
  
  handleShowMore = () => {
    const { property } = this.props.params;
    this.context.router.push(`/property/${property}/budget/procrastination`);
  }

  render() {
    const {
      procrastination_zero,
      procrastination_three,
      procrastination_six,
      procrastination_nine,
      procrastination_twelve,
      procrastination_more_twelve
    } = this.props.expenses;
    return (
      <div className='SectionProcrastination col'>
        <ColCard
          title='Morosidad por mes'
          columns={[{
            view: (
              <GraphicProcastination
                row1={procrastination_zero || 0}
                row2={procrastination_three || 0}
                row3={procrastination_six || 0}
                row4={procrastination_nine || 0}
                row5={procrastination_twelve || 0}
                row6={procrastination_more_twelve || 0}
                showMoreLabel={this.handleShowMore}
              />
            )
          }]}
        />
      </div>
    );
  }
}

SectionProcrastination.contextTypes = {
  router: PropTypes.shape()
};

SectionProcrastination.propsTypes = {
  expenses: PropTypes.object.isRequired,
};

export default SectionProcrastination;
