import React, { Component } from 'react';
import coinFund from 'assets/img/amenities/coin-fondo.svg';

import './Founds.scss';
import { ReactSVG } from 'react-svg';
import FieldDelete from './ConceptItem/FieldDelete';
import FieldEdit from './ConceptItem/FieldEdit';

export class Founds extends Component {
  getDuration = (duration) => {
    switch (duration) {
      case 1:
        return '1 Mes';
      case 2:
        return '2 Meses';
      case 3:
        return '3 Meses';
      case 4:
        return '4 Meses';
      case 5:
        return '5 Meses';
      case 6:
        return '6 Meses';
      case 7:
        return '7 Meses';
      case 8:
        return '8 Meses';
      case 9:
        return '9 Meses';
      case 10:
        return '10 Meses';
      case 11:
        return '11 Meses';
      case 12:
        return '1 Año';
      case 24:
        return '2 Años';
      default:
        return '1 Mes';
    }
  };

  render() {
    const { founds, handleShowFound, handleOnDelete } = this.props;
    return(
      <div className='Founds'>
        <div className='Founds__divider' />
        <div className='Founds__header'>
          <ReactSVG src={coinFund} className='Founds__header__img' />
          <div className='Founds__header__text'>Fondos</div>
        </div>
        <div className='Founds__table'>
          <div className='Founds__table__header'>
            <div className='Founds__table__headerTab__name'>
              <span>Nombre de fondo</span>
            </div>
            <div className='Founds__table__headerTab'>
              <span>Tipo</span>
            </div>
            <div className='Founds__table__headerTab'>
              <span>Duración</span>
            </div>
            <div className='Founds__table__headerTab'>
              <span>Monto recurrente</span>
            </div>
            <div className='Founds__table__headerTab'>
              <span>Monto objetivo</span>
            </div>
            <div className='Founds__table__headerTabEdit'>
              <span>Acciones</span>
            </div>
            
          </div>
          <div className='Founds__table__body'>
            {founds.map(found => (
              <div className='Founds__table__body__item Name' key={`Founds__table__body${found.id}`}>
                <div className='Founds__table__body__item__sectionName'>
                  <span>{found.name_found}</span>
                </div>
                <div className='Founds__table__body__item__section'>
                  <span>{found.type === 1 ? 'Objetivo': 'Recurrente'}</span>
                </div>
                <div className='Founds__table__body__item__section'>
                  <span>{found.type === 1 ? this.getDuration(found.duration): 'N/A'}</span>
                </div>
                
                <div className='Founds__table__body__item__section'>
                  <span>{found.type === 2 ? '$ '+found.target_amount:'N/A'}</span>
                </div>
                <div className='Founds__table__body__item__section'>
                  <span>{found.type === 1 ? '$ '+found.target_amount: 'N/A'}</span>
                </div>
                <div className='Founds__table__body__item__sectionEdit'>
                  <FieldEdit onClick={handleShowFound(found)}/>
                  <FieldDelete onClick={handleOnDelete({...found,active:false})}/>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default Founds;
